import { useEffect } from "react";

import type { DeploymentVersionDetail } from "libs/data/models";
import type { AddedEntity } from "./types";

// each time we have required dependencies
// we have to fetch the versions and add them to the deployment list
export default (
  requiredDeployments: string[],
  deployments: AddedEntity[],
  setDeployments: (entities: AddedEntity[]) => void,
  fetchDeploymentVersions: (
    name: string
  ) => Promise<{ [key: string]: DeploymentVersionDetail }>
) => {
  const stringifiedRequiredDeployments = JSON.stringify(requiredDeployments);

  useEffect(() => {
    async function fetchRequiredVersions() {
      const requiredVersions = await Promise.all(
        requiredDeployments.map((name) => fetchDeploymentVersions(name))
      );

      const otherEntityVersions = deployments.filter(
        (selectedVersion) => !requiredDeployments.includes(selectedVersion.name)
      );

      const requiredDeploymentVersions = requiredVersions
        .map((versions) => {
          const versionNames = Object.keys(versions);

          return versionNames.map((version) => {
            const name = versions[version].deployment;

            return { name, version };
          });
        })
        .flat();
      const newDeployments = [
        ...otherEntityVersions,
        ...requiredDeploymentVersions,
      ];
      const needToUpdate =
        JSON.stringify(newDeployments) !== JSON.stringify(deployments);
      if (needToUpdate) {
        setDeployments(newDeployments);
      }
    }

    fetchRequiredVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedRequiredDeployments, deployments]);
};
