import { Grid, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { PageHeader } from "components/molecules/PageLayout";
import { STRUCTURED_TYPE } from "libs/constants/constants";
import {
  FIELD_DEFAULT_VERSION,
  FIELD_DESCRIPTION,
  FIELD_INPUT_FIELDS,
  FIELD_INPUT_TYPE,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_OUTPUT_FIELDS,
  FIELD_OUTPUT_TYPE,
} from "libs/constants/fields";
import { useDeploymentUpdate } from "libs/data/customized/deployment/useDeploymentUpdate";
import {
  useDeploymentsGet,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import {
  formatInputOutputFields,
  formatOldInputOutputFields,
} from "libs/utilities/input-parser";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { getChanges } from "libs/utilities/patch-helper";
import { routes } from "routes";

import { InfoAlert } from "components/atoms";
import {
  FormSection,
  GeneralFieldsSection,
  InputOutputField,
  PageContainer,
} from "components/molecules";
import { FormContainer, LabelsForm } from "components/organisms";

import { DeploymentCreateEnvVars } from "./DeploymentCreateEnvVars";
import { BucketPermissionsWithDeployment } from "./formSections/BucketPermissionsWithDeployment";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type {
  DeploymentInputFieldCreate,
  DeploymentOutputFieldCreate,
  DeploymentUpdate as DeploymentUpdateModel,
} from "libs/data/models";
import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";

const DeploymentUpdate = () => {
  useGoogleAnalytics();
  const theme = useTheme() as AppThemeProps;
  const history = useHistory();
  const methods = useForm({ mode: "onBlur" });
  const { setValue } = methods;
  const match = useRouteMatch();
  const { organizationName, projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();
  const updateDeployment = useDeploymentUpdate(projectName, deploymentName);
  const { data: buckets } = useBucketsList(projectName);
  const { data: deployment } = useDeploymentsGet(projectName, deploymentName);
  const { data: versions } = useDeploymentVersionsList(
    projectName,
    deploymentName
  );

  const [defaultInputFields, setDefaultInputFields] = useState(
    () => deployment?.input_fields
  );
  const [defaultOutputFields, setDefaultOutputFields] = useState(
    () => deployment?.output_fields
  );

  useEffect(() => {
    if (deployment?.labels) {
      setValue(FIELD_LABELS, formatLabels(deployment.labels));
    }
    if (deployment?.input_fields) {
      setDefaultInputFields(deployment.input_fields);
    }
    if (deployment?.output_fields) {
      setDefaultOutputFields(deployment.output_fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployment?.input_fields, deployment?.labels, deployment?.output_fields]);

  const handleDeploymentUpdate = async (data: any) => {
    // Make sure old deployment input/output fields are structured similarly for a fair comparison (remove "tableData")
    const oldDeployment: any = { ...deployment };
    if (
      oldDeployment[FIELD_INPUT_TYPE] === STRUCTURED_TYPE &&
      oldDeployment[FIELD_INPUT_FIELDS]
    ) {
      oldDeployment[FIELD_INPUT_FIELDS] = formatOldInputOutputFields(
        oldDeployment[FIELD_INPUT_FIELDS]
      );
    }
    if (
      oldDeployment[FIELD_OUTPUT_TYPE] === STRUCTURED_TYPE &&
      oldDeployment[FIELD_OUTPUT_FIELDS]
    ) {
      oldDeployment[FIELD_OUTPUT_FIELDS] = formatOldInputOutputFields(
        oldDeployment[FIELD_OUTPUT_FIELDS]
      );
    }

    const newDeployment: DeploymentUpdateModel = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
    };

    if (data[FIELD_DEFAULT_VERSION]) {
      newDeployment[FIELD_DEFAULT_VERSION] = data[FIELD_DEFAULT_VERSION].value;
    }

    if (data[FIELD_INPUT_TYPE] === STRUCTURED_TYPE) {
      newDeployment[FIELD_INPUT_FIELDS] = data[FIELD_INPUT_FIELDS]
        ? (formatInputOutputFields(
            data[FIELD_INPUT_FIELDS]
          ) as DeploymentInputFieldCreate[])
        : [];
    }

    if (data[FIELD_OUTPUT_TYPE] === STRUCTURED_TYPE) {
      newDeployment[FIELD_OUTPUT_FIELDS] = data[FIELD_OUTPUT_FIELDS]
        ? (formatInputOutputFields(
            data[FIELD_OUTPUT_FIELDS]
          ) as DeploymentOutputFieldCreate[])
        : [];
    }

    const changes: DeploymentUpdateModel = getChanges(
      newDeployment as Record<string, string>,
      oldDeployment as Record<string, string>
    );
    changes[FIELD_LABELS] = formatLabelsForRequest(data[FIELD_LABELS]);

    const result = await updateDeployment(changes);

    if (result) {
      const redirectUrl = routes.organizations[":organizationName"](
        organizationName
      )
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](result.name)
        .index();
      history.push(redirectUrl);
    }
  };

  const options = useMemo(
    () =>
      versions?.map(({ version }) => ({
        label: version,
        value: version,
      })) || [],
    [versions]
  );

  const defaultValue = useMemo(
    () =>
      deployment && {
        label: deployment?.default_version || "",
        value: deployment?.default_version || "",
      },
    [deployment]
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Edit</BreadcrumbsItem>
      <PageContainer>
        <PageHeader title={`Edit "${deploymentName}"`} />
        <FormContainer
          buttonLabel="Save"
          onSubmit={handleDeploymentUpdate}
          formMethods={methods}
        >
          {deployment && versions && (
            <>
              <GeneralFieldsSection
                validateValue="deployment"
                namePlaceholder="Ex: my-deployment-1"
                nameDefaultValue={deployment?.name}
                descriptionDefaultValue={deployment?.description}
              >
                <AutoCompleteSelectHookForm
                  name={FIELD_DEFAULT_VERSION}
                  label="Default version"
                  options={options}
                  defaultValue={defaultValue}
                  isSearchable={true}
                />
              </GeneralFieldsSection>
              {deployment.supports_request_format && (
                <>
                  <Grid
                    item
                    container
                    spacing={1}
                    xs={12}
                    sm={7}
                    style={{ marginLeft: "auto", marginTop: "-1em" }}
                  >
                    <InfoAlert>
                      {explanations.deployments.editInfoBanner}
                    </InfoAlert>
                  </Grid>
                  <InputOutputField
                    title="Input"
                    description={
                      explanations.deployments.inputOutput({
                        type: "input",
                        color: theme.palette.primary.main,
                      } as any) as any
                    }
                    defaultType={deployment.input_type}
                    defaultFields={defaultInputFields}
                    typeName={FIELD_INPUT_TYPE}
                    fieldsName={FIELD_INPUT_FIELDS}
                    isEditForm
                  />
                  <InputOutputField
                    title="Output"
                    description={
                      explanations.deployments.inputOutput({
                        type: "output",
                        color: theme.palette.primary.main,
                      } as any) as any
                    }
                    defaultType={deployment.output_type}
                    defaultFields={defaultOutputFields}
                    typeName={FIELD_OUTPUT_TYPE}
                    fieldsName={FIELD_OUTPUT_FIELDS}
                    isEditForm
                  />
                  {!!buckets && buckets?.length > 1 && (
                    <FormSection
                      title="Bucket permissions"
                      description={explanations.deployments.bucketPermissions}
                    >
                      <BucketPermissionsWithDeployment
                        deploymentName={deploymentName}
                        projectName={projectName}
                      />
                    </FormSection>
                  )}
                </>
              )}
              <DeploymentCreateEnvVars deploymentName={deployment?.name} />
              <FormSection title="Labels">
                <LabelsForm name={FIELD_LABELS} />
              </FormSection>
            </>
          )}
        </FormContainer>
      </PageContainer>
    </>
  );
};

export default DeploymentUpdate;
