import {
  disabledStatuses,
  errorStatuses,
  infoStatuses,
  secondaryStatuses,
  successStatuses,
  warningStatuses,
} from "./statusConstants";

export const colorStringByStatus = (status: string) => {
  if (successStatuses.includes(status)) {
    return "success";
  } else if (infoStatuses.includes(status)) {
    return "info";
  } else if (secondaryStatuses.includes(status)) {
    return "secondary";
  } else if (errorStatuses.includes(status)) {
    return "error";
  } else if (warningStatuses.includes(status)) {
    return "warning";
  } else if (disabledStatuses.includes(status)) {
    return "default";
  } else {
    return undefined;
  }
};
