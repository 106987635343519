import BackIcon from "@mui/icons-material/ArrowBack";
import MailIcon from "@mui/icons-material/EmailOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  CssBaseline,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { IconLaptop } from "assets/images/IconLaptop";
import { IconPipe } from "assets/images/IconPipe";
import { IconQuestion } from "assets/images/IconQuestion";
import { ReactComponent as IlluSignUp } from "assets/images/illustration-sign-up.svg";

import { LINK_COPYRIGHT } from "libs/constants/documentation-links";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";

import {
  AuthenticationLink,
  Copyright,
  Divider,
  SecondaryButton,
} from "components/atoms";

import "./SignUp.scss";
import SignUpOAuth from "./SignUpOAuth";
import SignUpRegister from "./SignUpRegister";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

const SignUp = () => {
  useGoogleAnalytics();
  const methods = useForm({
    mode: "onBlur",
  });

  const { email } = useParams<{ email: string }>();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search) {
      const voucherCode = Object.fromEntries(
        new URLSearchParams(history.location.search)
      )?.voucher;

      voucherCode && env.set(ENV_NAMES.PROMO_CODE, voucherCode);
    }
  }, [history.location]);

  const theme = useTheme() as AppThemeProps;

  const [signUpWithEmail, setSignUpWithEmail] = useState(!!email);

  const toggleSignUpWithEmail = () => setSignUpWithEmail((prev) => !prev);
  const Logo = theme.logoVertical;

  return (
    <Box className="sign-up">
      <CssBaseline />
      <Box
        className="sign-up__form-section"
        sx={{ backgroundColor: theme.palette.background.auth }}
      >
        <Link href={LINK_COPYRIGHT}>
          <Avatar
            className="sign-up__logo"
            alt="Ubiops logo"
            variant="square"
            sx={{ backgroundColor: "transparent" }}
          >
            <Logo />
          </Avatar>
        </Link>
        <Typography variant="h2" align="center" gutterBottom>
          Sign up
        </Typography>
        <Typography variant="subtitle1" align="center">
          Create your free account
        </Typography>
        <Card
          className="sign-up__form"
          variant="outlined"
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <CardContent>
            <FormProvider {...methods}>
              {env.get(ENV_NAMES.OAUTH_ENABLED) && (
                <div>
                  <SignUpOAuth />
                  <Divider my={1} />
                  <Typography variant="subtitle1" align="center">
                    OR
                  </Typography>
                  <Divider mt={1} mb={3} />
                </div>
              )}
              <SecondaryButton
                startIcon={<MailIcon />}
                disabled={signUpWithEmail}
                onClick={() => toggleSignUpWithEmail()}
                style={{ width: "100%" }}
              >
                Sign up with email
              </SecondaryButton>
              <Collapse in={signUpWithEmail}>
                <Box mt={3}>
                  <SignUpRegister />
                </Box>
              </Collapse>
              <Divider my={3} />
              <Box textAlign="center">
                <Box component="span">Already have an account? </Box>
                <AuthenticationLink to="/sign-in">Sign in</AuthenticationLink>
              </Box>
            </FormProvider>
          </CardContent>
        </Card>
        {signUpWithEmail && (
          <Button
            className="sign-up__back-button"
            startIcon={<BackIcon />}
            color="primary"
            onClick={() => toggleSignUpWithEmail()}
          >
            Back
          </Button>
        )}
      </Box>
      <Box className="sign-up__presentation-section">
        <IlluSignUp width="100%" style={{ height: "min-content" }} />
        <Box className="sign-up__selling-points">
          <Typography
            variant="h1"
            color="#151F32"
            className="sign-up__title"
            align="center"
          >
            Try UbiOps for{" "}
            <Typography component="span" color="secondary" fontWeight="800">
              Free
            </Typography>{" "}
            for 14 days
          </Typography>
          <List>
            <ListItem className="sign-up__selling-point">
              <ListItemIcon>
                <IconLaptop />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="h4"
                  dangerouslySetInnerHTML={{
                    __html: "Experiment with the UbiOps platform",
                  }}
                />
              </ListItemText>
            </ListItem>
            <ListItem className="sign-up__selling-point">
              <ListItemIcon>
                <IconPipe />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="h4"
                  dangerouslySetInnerHTML={{
                    __html: "Run your first Multiple Models and Pipelines",
                  }}
                />
              </ListItemText>
            </ListItem>
            <ListItem className="sign-up__selling-point">
              <ListItemIcon>
                <IconQuestion />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="h4"
                  dangerouslySetInnerHTML={{
                    __html: "Personal support from our Product Experts",
                  }}
                />
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Box
          component="span"
          className="sign-up__copyright"
          color={theme.palette.tertiary.light}
        >
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
