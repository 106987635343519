import { Grid, Typography } from "@mui/material";
import { sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { PortForwarding } from "components/molecules/PortForwarding";
import {
  DEFAULT_DEPLOYMENT_VERSION_IDLE_TIME,
  DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH,
  DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS,
} from "libs/constants/constants";
import {
  FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS,
  FIELD_DEPLOYMENT_VERSION_ENVIRONMENT,
  FIELD_DEPLOYMENT_VERSION_FILE,
  FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
  FIELD_DESCRIPTION,
  FIELD_GPU_INSTANCE_ENABLED,
  FIELD_INSTANCE_PROCESSES,
  FIELD_LABELS,
  FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
  FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_SCALING_STRATEGY,
  FIELD_STATIC_IP,
  FIELD_VERSION,
} from "libs/constants/fields";
import { useDeploymentVersionsCreate } from "libs/data/customized/deployment-versions/useDeploymentVersionsCreate";
import { useDeploymentVersionsFileUpload } from "libs/data/customized/deployment-versions/useDeploymentVersionsFileUpload";
import {
  useDeploymentsGet,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  useOrganizationsFeaturesGet,
  useOrganizationsResourceUsage,
} from "libs/data/endpoints/organizations/organizations";
import { gtmEvent, useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { defaultLanguageOption } from "libs/utilities/labels-mapping";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { LOADING, UNLOADED } from "libs/utilities/request-statuses";
import { generateVersionName } from "libs/utilities/utils";
import { routes } from "routes";

import { Accordion, Loader, Notes } from "components/atoms";
import {
  CodeEnvironmentDialog,
  CodeEnvironmentSection,
  DependencyInfo,
  DeploymentVersionAdvancedParameters,
  DeploymentVersionInstanceType,
  FirstDeploymentCard,
  FormSection,
  GeneralFieldsSection,
  NetworkSettings,
  PageContainer,
  UploadPackageField,
} from "components/molecules";
import {
  FormContainer,
  LabelsForm,
  RequestSettings,
  UpgradeSubscriptionDialog,
} from "components/organisms";

import { DeploymentVersionScalingStrategy } from "./:versionName/DeploymentVersionScalingStrategy";
import { NoPackageWarning } from "./:versionName/NoPackageWarning";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type {
  DeploymentVersionList,
  TemplateDeploymentList,
} from "libs/data/models";
import type { DeploymentVersionCreateForm } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/types";
import type { ProjectDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/types";
import type { ReactNode } from "react";

const defaultValues = (supportsRequestFormat: boolean) =>
  ({
    [FIELD_VERSION]: "v1",
    [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]: defaultLanguageOption,
    [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
      DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE,
    [FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]: supportsRequestFormat ? 0 : 1,
    [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: 1,
    [FIELD_DEPLOYMENT_VERSION_IDLE_TIME]: DEFAULT_DEPLOYMENT_VERSION_IDLE_TIME,
    [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]: DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS,
    [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH,
    [FIELD_SCALING_STRATEGY]: "default",
  } as any);

interface DeploymentVersionCreateProps {
  children?: ReactNode;
  deployment?: string; // This would only be used within the DeploymentCreate form, where a route param is not available
  isPage?: boolean;
  preloadedDeploymentChoice?: TemplateDeploymentList | undefined;
}

export const DeploymentVersionCreate = ({
  children,
  deployment,
  isPage = true,
  preloadedDeploymentChoice,
}: DeploymentVersionCreateProps) => {
  useGoogleAnalytics();

  const history = useHistory();
  const {
    organizationName,
    projectName,
    deploymentName: deploymentNameFromUrl,
  } = useParams<ProjectDetailsRouteParams & { deploymentName?: string }>();
  const deploymentName = (deployment ?? deploymentNameFromUrl) as string;

  const versionCreate = useDeploymentVersionsCreate(
    projectName,
    deploymentName,
    "deployment version"
  );
  const fileUpload = useDeploymentVersionsFileUpload(
    projectName,
    deploymentName
  );

  const [nameDefaultValue, setNameDefaultValue] =
    useState<string | undefined>(undefined);
  const [descriptionDefaultValue, setDescriptionDefaultValue] =
    useState(undefined);
  const [envDefaultValue, setEnvDefaultValue] =
    useState<string | undefined>(undefined);
  const [instanceDefaultValue, setInstanceDefaultValue] =
    useState<string | undefined>(undefined);
  const [defaultReqRetentionMode, setDefaultReqRetentionMode] = useState("");
  const [defaultReqRetentionTime, setDefaultReqRetentionTime] =
    useState<number | undefined>(undefined);
  const [deploymentTemplateId, setDeploymentTemplateId] = useState("");
  const [correctZipStructure, setCorrectZipStructure] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);
  const [latestVersion, setLatestVersion] =
    useState<DeploymentVersionList | null>(null);
  const [environmentDialogOpen, setEnvironmentDialogOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const { data: deploymentDetails } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const { data: versions, error: versionsError } = useDeploymentVersionsList(
    projectName,
    deploymentName
  );
  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;

  const { data: organizationResources } =
    useOrganizationsResourceUsage(organizationName);

  const methods = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    defaultValues: {
      [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]: DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS,
      [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH,
    } as DeploymentVersionCreateForm,
  });
  const { control, setValue, getValues } = methods;
  const versionFile = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_FILE,
  }) as any;
  const fileHasRequirements = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  });
  const gpuInstanceModeEnabled: boolean | undefined = !!useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const setPreloadedDeploymentVersionData = useCallback(() => {
    const version = preloadedDeploymentChoice?.details?.version;
    setNameDefaultValue(version.name);
    setDescriptionDefaultValue(version.description);
    setDefaultReqRetentionMode(version.request_retention_mode);
    setDefaultReqRetentionTime(version.request_retention_time);
    setLatestVersion(version);
    setEnvDefaultValue(version.environment);
    setInstanceDefaultValue(version.instance_type_group_name);
    setValue(FIELD_LABELS, formatLabels(version.labels ?? {}));
    setDeploymentTemplateId(preloadedDeploymentChoice?.id ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preloadedDeploymentChoice?.details?.version,
    preloadedDeploymentChoice?.id,
  ]);

  useEffect(() => {
    if (
      organizationResources?.deployment_versions &&
      organizationFeatures?.max_deployment_versions &&
      organizationResources?.deployment_versions >=
        organizationFeatures?.max_deployment_versions
    ) {
      setIsSubscriptionDialogOpen(true);
    }
  }, [
    organizationResources,
    organizationFeatures,
    setIsSubscriptionDialogOpen,
  ]);

  useEffect(() => {
    if (versions?.length && isPage) {
      const sortedVersions = sortBy(versions, ["creation_date"]);
      const latestVersion = sortedVersions[sortedVersions.length - 1];
      setLatestVersion(latestVersion);
      setNameDefaultValue(generateVersionName(latestVersion.version));
    } else if (preloadedDeploymentChoice) {
      setPreloadedDeploymentVersionData();
    } else if (deploymentDetails) {
      setLatestVersion(
        defaultValues(deploymentDetails?.supports_request_format)
      );
      setNameDefaultValue(
        defaultValues(deploymentDetails?.supports_request_format)[FIELD_VERSION]
      );
    }
  }, [
    isPage,
    preloadedDeploymentChoice,
    setPreloadedDeploymentVersionData,
    versions,
    deploymentDetails,
  ]);

  const handleVersionUpload = useCallback(
    async (name: string) => {
      gtmEvent("deployment_version_create", {
        event_category: "deployment_versions",
      });

      if (versionFile?.length) {
        await fileUpload(
          {
            version: name,
            deployment: deploymentName,
          },
          {
            file: versionFile[0],
          },
          versionFile[0]?.name
        );
      } else {
        await fileUpload(
          {
            version: name,
            deployment: deploymentName,
          },
          { template_deployment_id: deploymentTemplateId },
          nameDefaultValue as string
        );
      }
    },
    [
      versionFile,
      deploymentName,
      fileUpload,
      deploymentTemplateId,
      nameDefaultValue,
    ]
  );

  const onSubmit = async (data: any) => {
    const newVersion = {
      [FIELD_VERSION]: data[FIELD_VERSION],
      [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]:
        data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]?.value,
      [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE]?.value,
      [FIELD_RETENTION_TIME]: data[FIELD_RETENTION_TIME]?.value,
      [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
        data[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]?.value,
      [FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]:
        data[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY],
      [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]:
        data[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY],
      [FIELD_DEPLOYMENT_VERSION_IDLE_TIME]:
        data[FIELD_DEPLOYMENT_VERSION_IDLE_TIME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
      [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]:
        data[FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS],
      [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: data[FIELD_MAXIMUM_QUEUE_SIZE_BATCH],
      [FIELD_STATIC_IP]: data[FIELD_STATIC_IP],
      [FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS]:
        data[FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS],
      [FIELD_SCALING_STRATEGY]: data[FIELD_SCALING_STRATEGY],
      [FIELD_INSTANCE_PROCESSES]: data[FIELD_INSTANCE_PROCESSES],
    };
    // @ts-ignore
    delete newVersion[FIELD_DEPLOYMENT_VERSION_FILE];

    // set max instances equal to min instances for application types
    if (!deploymentDetails?.supports_request_format) {
      newVersion[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY] =
        data[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY];
    }

    const response = await versionCreate(newVersion);
    if (response) {
      if (deploymentDetails?.supports_request_format)
        await handleVersionUpload(newVersion.version);

      const baseUrl = routes.organizations[":organizationName"](
        organizationName
      )
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deploymentName);

      const redirectUrl = newVersion.version
        ? baseUrl.versions[":versionName"](newVersion.version).index()
        : baseUrl.index();

      history.replace(redirectUrl);
    }
  };

  const handleSave = (data: any) => {
    if (
      !versionFile &&
      deploymentDetails?.supports_request_format &&
      !preloadedDeploymentChoice
    )
      setIsWarningDialogOpen(true);
    else onSubmit(data);
  };

  const parentFileFieldValidationChecker = useCallback(
    // Workaround function to pass props from child to parent
    (flag: boolean | ((prevState: boolean) => boolean)) =>
      setCorrectZipStructure(flag),
    []
  );

  const fileFieldRules = useMemo(() => {
    if (!preloadedDeploymentChoice)
      return {
        validate: {
          correctZipStructure: () => correctZipStructure,
        },
      };

    return null;
  }, [preloadedDeploymentChoice, correctZipStructure]);

  const handleOnCreate = (option: AutocompleteSelectOption) => {
    setValue(FIELD_DEPLOYMENT_VERSION_ENVIRONMENT, option, {
      shouldDirty: true,
    });
  };

  return (
    <PageContainer>
      {isPage && <PageHeader title="Create new version" />}
      <FormContainer
        onSubmit={handleSave}
        buttonLabel="Create"
        formMethods={methods}
        status={!versions && !versionsError ? LOADING : UNLOADED}
      >
        {latestVersion && nameDefaultValue ? (
          <>
            {preloadedDeploymentChoice && <FirstDeploymentCard versionCreate />}

            <GeneralFieldsSection
              name={FIELD_VERSION}
              label="Version name"
              ruleName="version name"
              validateValue="deployment version"
              namePlaceholder="Ex: my-deployment-version-1"
              nameDefaultValue={nameDefaultValue}
              descriptionDefaultValue={descriptionDefaultValue}
              title="Name your version"
              description={explanations.deployments.templates}
              info={
                <Typography variant="body2">
                  Your deployment name: <b>{deployment ?? deploymentName}</b>
                </Typography>
              }
              notes={
                latestVersion !==
                  defaultValues(
                    deploymentDetails?.supports_request_format || false
                  ) &&
                !preloadedDeploymentChoice && (
                  <Notes>
                    Your latest version was: <b>{latestVersion.version}</b>
                  </Notes>
                )
              }
            />

            {deploymentDetails?.supports_request_format && (
              <FormSection
                title="Deployment package"
                description={explanations.deployments.configuration}
              >
                <div className="required-field-with-asterisk">
                  <UploadPackageField
                    hasRequirementsName={
                      FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS
                    }
                    name={FIELD_DEPLOYMENT_VERSION_FILE}
                    rules={fileFieldRules}
                    label="Deployment package (.zip)"
                    passFormValidation={parentFileFieldValidationChecker}
                    withLoadedExample={!!preloadedDeploymentChoice}
                  />
                </div>{" "}
                {fileHasRequirements ? <DependencyInfo /> : null}
              </FormSection>
            )}

            <FormSection
              title="Environment settings"
              description={
                explanations.deployments.versions.environmentSettings
              }
            >
              <DeploymentVersionInstanceType
                preLoadedDefaultInstanceType={instanceDefaultValue}
                disableGPUToggle={!gpuEnabled}
              />
              <Grid item>
                <Typography variant="h6">Select code environment</Typography>
                <CodeEnvironmentSection
                  onAddEnvironment={() => setEnvironmentDialogOpen(true)}
                  isEnvImplicit={fileHasRequirements === true}
                  defaultEnvironment={envDefaultValue}
                  deployment={deploymentName}
                  gpuEnabled={
                    (gpuEnabled as boolean) &&
                    (gpuInstanceModeEnabled as boolean)
                  }
                  name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
                  projectName={projectName}
                  supportsRequestFormat={
                    deploymentDetails?.supports_request_format
                  }
                />
              </Grid>
            </FormSection>
            <Grid item container spacing={5} justifyContent="flex-end">
              <Grid item>
                <Accordion
                  title="Optional / Advanced settings"
                  titleVariant="h3"
                >
                  <Grid container spacing={5} marginTop={1}>
                    {children}
                    <DeploymentVersionAdvancedParameters
                      version={latestVersion}
                      supportsRequestFormat={
                        deploymentDetails?.supports_request_format
                      }
                    />

                    {deploymentDetails?.supports_request_format && (
                      <>
                        <DeploymentVersionScalingStrategy />
                        <RequestSettings
                          defaultMode={defaultReqRetentionMode}
                          defaultTime={defaultReqRetentionTime}
                          defaultQueueSize={DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS}
                          defaultBatchQueueSize={
                            DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH
                          }
                        />
                      </>
                    )}

                    <NetworkSettings />
                    <PortForwarding />
                    <FormSection
                      title="Labels"
                      description={explanations.labels.description(
                        "deploymentVersion"
                      )}
                    >
                      <LabelsForm name={FIELD_LABELS} />
                    </FormSection>
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
          </>
        ) : (
          <Loader />
        )}
      </FormContainer>
      <UpgradeSubscriptionDialog
        open={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
      >
        You have reached your current subscription limit of{" "}
        <b>
          {organizationFeatures?.max_deployment_versions} maximum deployment
          versions
        </b>
        . Please upgrade your subscription.
      </UpgradeSubscriptionDialog>
      <NoPackageWarning
        onClose={() => setIsWarningDialogOpen(false)}
        open={isWarningDialogOpen}
        onAction={() => onSubmit(getValues())}
      />
      <CodeEnvironmentDialog
        gpuEnabled={gpuEnabled}
        onClose={() => setEnvironmentDialogOpen(false)}
        onCreate={handleOnCreate}
        open={environmentDialogOpen}
        projectName={projectName}
        shouldSupportRequests={deploymentDetails?.supports_request_format}
      />
    </PageContainer>
  );
};
