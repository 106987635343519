/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { Key, SWRConfiguration } from "swr";
import type { ErrorType } from "../../axios/index";
import type {
  EnvironmentBuildCreate,
  EnvironmentBuildDependency,
  EnvironmentBuildList,
  EnvironmentBuildUpdate,
  EnvironmentCreate,
  EnvironmentDetail,
  EnvironmentList,
  EnvironmentRevisionCreate,
  EnvironmentRevisionDetail,
  EnvironmentRevisionsFileUploadBody,
  EnvironmentsListParams,
  EnvironmentsUsageParams,
  EnvironmentUpdate,
  EnvironmentUsage,
} from "../../models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
Environments can be filtered according to the labels they have by giving labels as a query parameter. Environments that have at least one of the labels on which is filtered, are returned.

### Optional Parameters
- `labels`: Filter on labels of the environment. Should be given in the format 'label:label_value'. Separate multiple label-pairs with a comma (,). This parameter should be given as query parameter.
- `environment_type`: Filter on the type of the environment. It can be one of the following: 'base', 'custom' or 'all'. The default value is 'all'.
- `supports_request_format`: Filter on whether the environment supports the request format

### Response Structure
A list of details of the environments
- `id`: Unique identifier for the environment
- `name`: Name of the environment
- `display_name`: Display name of the environment
- `base_environment`: Base environment name on which this environment is based
- `project`: Project name in which the environment is defined. It is null for base environments.
- `creation_date`: The date when the environment was created
- `last_updated`: The date when the environment was last updated
- `description`: Description of the environment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `gpu_required`: A boolean indicating whether the environment requires GPUs
- `status`: Status of the environment
- `implicit`: A boolean indicating whether the environment is implicitly created
- `hidden`: A boolean indicating whether the environment is hidden
- `deprecated`: A boolean indicating whether the environment is deprecated
- `supports_request_format`: A boolean indicating whether the environment supports the request format

#### Response Examples
```
[
  {
    "id": "1319895f-467b-4732-9804-7de500099233",
    "name": "python3-8",
    "display_name": "Python 3.8",
    "base_environment": null,
    "project": null,
    "creation_date": "2023-03-01T08:32:14.876451Z",
    "last_updated": "2023-03-01T10:52:23.124784Z",
    "description": "Base environment containing Python 3.8",
    "labels": {},
    "gpu_required": false,
    "status": "active",
    "implicit": false,
    "deprecated": false,
    "hidden": false,
    "supports_request_format": true
  },
  {
    "id": "3a7d94ca-4df4-4be3-857c-d6b9995cd17a",
    "name": "python3-8-custom",
    "display_name": "Custom Python 3.8",
    "base_environment": "python3-8",
    "project": "project-1",
    "creation_date": "2023-03-02T12:15:43.124751Z",
    "last_updated": "2023-03-03T13:14:23.865421Z",
    "description": "Custom environment based on Python 3.8",
    "labels": {
      "type": "environment"
    },
    "gpu_required": false,
    "status": "active",
    "implicit": false,
    "deprecated": false,
    "hidden": false,
    "supports_request_format": true
  }
]
```

 * @summary List environments
 */
export const environmentsList = (
  projectName: string,
  params?: EnvironmentsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentList[]>(
    { url: `/projects/${projectName}/environments`, method: "get", params },
    options
  );
};

export const getEnvironmentsListKey = (
  projectName: string,
  params?: EnvironmentsListParams
) =>
  [
    `/projects/${projectName}/environments`,
    ...(params ? [params] : []),
  ] as const;

export type EnvironmentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentsList>>
>;
export type EnvironmentsListQueryError = ErrorType<unknown>;

/**
 * @summary List environments
 */
export const useEnvironmentsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: EnvironmentsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEnvironmentsListKey(projectName, params) : null));
  const swrFn = () => environmentsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a custom environment

### Required Parameters
- `name`: Name of the environment
- `base_environment`: Base environment name on which this environment is based

### Optional Parameters
- `display_name`: Display name of the environment. If not set, 'name' is used instead.
- `description`: Description for the environment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `supports_request_format`: A boolean indicating whether the environment supports the request format

#### Request Examples
```
{
  "name": "python3-8-custom",
  "base_environment": "python3-8"
}
```

```
{
  "name": "python3-8-custom-1",
  "display_name": "Custom Python 3.8",
  "base_environment": "python3-8"
}
```

### Response Structure
Details of the created environment
- `id`: Unique identifier for the environment
- `name`: Name of the environment
- `display_name`: Display name of the environment
- `base_environment`: Base environment name on which this environment is based
- `project`: Project name in which the environment is defined
- `creation_date`: The date when the environment was created
- `last_updated`: The date when the environment was last updated
- `description`: Description of the environment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `gpu_required`: A boolean indicating whether the environment requires GPUs
- `status`: Status of the environment
- `implicit`: A boolean indicating whether the environment is implicitly created
- `hidden`: A boolean indicating whether the environment is hidden
- `deprecated`: A boolean indicating whether the environment is deprecated
- `supports_request_format`: A boolean indicating whether the environment supports the request format

#### Response Examples
```
{
  "id": "3a7d94ca-4df4-4be3-857c-d6b9995cd17a",
  "name": "python3-8-custom",
  "display_name": "Custom Python 3.8",
  "base_environment": "python3-8",
  "project": "project-1",
  "creation_date": "2023-03-01T08:32:14.876451Z",
  "last_updated": "2023-03-01T08:32:14.876451Z",
  "description": "Custom environment based on Python 3.8",
  "labels": {
    "type": "environment"
  },
  "gpu_required": false,
  "status": "active",
  "implicit": false,
  "deprecated": false,
  "hidden": false,
  "supports_request_format": true
}
```

 * @summary Create environments
 */
export const environmentsCreate = (
  projectName: string,
  environmentCreate: EnvironmentCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentList>(
    {
      url: `/projects/${projectName}/environments`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of an environment

### Response Structure
Details of an environment
- `id`: Unique identifier for the environment
- `name`: Name of the environment
- `display_name`: Display name of the environment
- `base_environment`: Base environment name on which this environment is based
- `project`: Project name in which the environment is defined
- `creation_date`: The date when the environment was created
- `last_updated`: The date when the environment was last updated
- `description`: Description of the environment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `gpu_required`: A boolean indicating whether the environment requires GPUs
- `status`: Status of the environment
- `active_revision`: UUID of the active revision of the environment
- `active_build`: UUID of the active build of the environment
- `latest_revision`: UUID of the latest revision of the environment
- `latest_build`: UUID of the latest build of the environment
- `implicit`: A boolean indicating whether the environment is implicitly created
- `hidden`: A boolean indicating whether the environment is hidden
- `deprecated`: A boolean indicating whether the environment is deprecated
- `supports_request_format`: A boolean indicating whether the environment supports the request format

#### Response Examples
```
{
  "id": "3a7d94ca-4df4-4be3-857c-d6b9995cd17a",
  "name": "python3-8-custom",
  "display_name": "Custom Python 3.8",
  "base_environment": "python3-8",
  "project": "project-1",
  "creation_date": "2023-03-01T08:32:14.876451Z",
  "last_updated": "2023-03-01T10:52:23.124784Z",
  "description": "Custom environment based on Python 3.8",
  "labels": {
    "type": "environment"
  },
  "gpu_required": false,
  "status": "active",
  "implicit": false,
  "deprecated": false,
  "hidden": false,
  "supports_request_format": true,
  "active_revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "active_build": "e3021050-b9ac-4b8e-89f4-adb9e7c9aba6",
  "latest_revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "latest_build": "e3021050-b9ac-4b8e-89f4-adb9e7c9aba6"
}
```

 * @summary Get environment
 */
export const environmentsGet = (
  projectName: string,
  environmentName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentDetail>(
    {
      url: `/projects/${projectName}/environments/${environmentName}`,
      method: "get",
    },
    options
  );
};

export const getEnvironmentsGetKey = (
  projectName: string,
  environmentName: string
) => [`/projects/${projectName}/environments/${environmentName}`] as const;

export type EnvironmentsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentsGet>>
>;
export type EnvironmentsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get environment
 */
export const useEnvironmentsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  environmentName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && environmentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getEnvironmentsGetKey(projectName, environmentName) : null);
  const swrFn = () =>
    environmentsGet(projectName, environmentName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update an environment. When updating labels, the labels will replace the existing value for labels.

### Optional Parameters
- `name`: Name of the environment
- `display_name`: Display name of the environment
- `description`: Description for the environment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Request Examples
```
{
  "name": "new-python3-8-custom"
}
```

### Response Structure
Details of the updated environment
- `id`: Unique identifier for the environment
- `name`: Name of the environment
- `display_name`: Display name of the environment
- `base_environment`: Base environment name on which this environment is based
- `project`: Project name in which the environment is defined
- `creation_date`: The date when the environment was created
- `last_updated`: The date when the environment was last updated
- `description`: Description of the environment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `gpu_required`: A boolean indicating whether the environment requires GPUs
- `status`: Status of the environment
- `active_revision`: UUID of the active revision of the environment
- `active_build`: UUID of the active build of the environment
- `latest_revision`: UUID of the latest revision of the environment
- `latest_build`: UUID of the latest build of the environment
- `implicit`: A boolean indicating whether the environment is implicitly created
- `hidden`: A boolean indicating whether the environment is hidden
- `deprecated`: A boolean indicating whether the environment is deprecated
- `supports_request_format`: A boolean indicating whether the environment supports the request format

#### Response Examples
```
{
  "id": "3a7d94ca-4df4-4be3-857c-d6b9995cd17a",
  "name": "new-python3-8-custom",
  "display_name": "Custom Python 3.8",
  "base_environment": "python3-8",
  "project": "project-1",
  "creation_date": "2023-03-01T08:32:14.876451Z",
  "last_updated": "2023-03-01T10:52:23.124784Z",
  "description": "Custom environment based on Python 3.8",
  "labels": {
    "type": "environment"
  },
  "gpu_required": false,
  "status": "active",
  "implicit": false,
  "deprecated": false,
  "hidden": false,
  "supports_request_format": true,
  "active_revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "active_build": "e3021050-b9ac-4b8e-89f4-adb9e7c9aba6",
  "latest_revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "latest_build": "e3021050-b9ac-4b8e-89f4-adb9e7c9aba6"
}
```

 * @summary Update environment
 */
export const environmentsUpdate = (
  projectName: string,
  environmentName: string,
  environmentUpdate: EnvironmentUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentDetail>(
    {
      url: `/projects/${projectName}/environments/${environmentName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: environmentUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete an environment. The environment cannot be deleted if it is referenced by a deployment version.

 * @summary Delete environment
 */
export const environmentsDelete = (
  projectName: string,
  environmentName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/environments/${environmentName}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
List revisions of an environment

### Response Structure
A list of details of the revisions
- `id`: Unique identifier for the revision
- `environment`: Environment to which the revision is linked
- `creation_date`: The date when the revision was created
- `created_by`: The email of the user that uploaded the environment file. In case the revision is created by a service, the field will have a "UbiOps" value.
- `expired`: A boolean indicating whether the environment file has been deleted for the revision

#### Response Examples
```
[
  {
    "id": "8760570f-6eda-470b-99af-bde810d418d8",
    "environment": "python3-8-custom",
    "creation_date": "2023-01-23T12:17:11.863+00:00",
    "created_by": "UbiOps",
    "expired": false
  },
  {
    "id": "593bac21-7cd2-476a-aee8-ec9fc7f56232",
    "version": "python3-8-custom",
    "creation_date": "2023-01-29T17:12:43.108+00:00",
    "created_by": "test@example.com",
    "expired": false
  }
]
```

 * @summary List revisions
 */
export const environmentRevisionsList = (
  projectName: string,
  environmentName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentRevisionDetail[]>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions`,
      method: "get",
    },
    options
  );
};

export const getEnvironmentRevisionsListKey = (
  projectName: string,
  environmentName: string
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/revisions`,
  ] as const;

export type EnvironmentRevisionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentRevisionsList>>
>;
export type EnvironmentRevisionsListQueryError = ErrorType<unknown>;

/**
 * @summary List revisions
 */
export const useEnvironmentRevisionsList = <TError = ErrorType<unknown>>(
  projectName: string,
  environmentName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentRevisionsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && environmentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentRevisionsListKey(projectName, environmentName)
        : null);
  const swrFn = () =>
    environmentRevisionsList(projectName, environmentName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Upload a file for an environment. Uploading a file will create a new revision and trigger a build. This file should contain all the dependencies that the environment should have in the zip format.

It is **also possible** to provide a source environment from which the environment file will be copied. This will also create a new revision and trigger a build.

### Optional Parameters
- `file`: Environment file
- `source_environment`: Environment from which the environment file will be copied

Either **file** or **source_environment** must be provided.

### Response Structure
- `success`: Boolean indicating whether the environment file upload/copy succeeded
- `revision`: ID of the created revision for the file upload
- `build`: ID of the build created for the file upload

 * @summary Upload environment file
 */
export const environmentRevisionsFileUpload = (
  projectName: string,
  environmentName: string,
  environmentRevisionsFileUploadBody: EnvironmentRevisionsFileUploadBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  const formData = new FormData();
  if (environmentRevisionsFileUploadBody.file !== undefined) {
    formData.append("file", environmentRevisionsFileUploadBody.file);
  }
  if (environmentRevisionsFileUploadBody.source_environment !== undefined) {
    formData.append(
      "source_environment",
      environmentRevisionsFileUploadBody.source_environment
    );
  }

  return orvalAxios<EnvironmentRevisionCreate>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a revision of an environment

### Response Structure
Details of a revision
- `id`: Unique identifier for the revision
- `environment`: Environment to which the revision is linked
- `creation_date`: The date when the revision was created
- `created_by`: The email of the user that uploaded the environment file. In case the revision is created by a service, the field will have a "UbiOps" value.
- `expired`: A boolean indicating whether the environment file has been deleted for the revision

#### Response Examples
```
{
  "id": "593bac21-7cd2-476a-aee8-ec9fc7f56232",
  "version": "python3-8-custom",
  "creation_date": "2023-01-29T17:12:43.108+00:00",
  "created_by": "test@example.com",
  "expired": false
}
```

 * @summary Get revision
 */
export const environmentRevisionsGet = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentRevisionDetail>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}`,
      method: "get",
    },
    options
  );
};

export const getEnvironmentRevisionsGetKey = (
  projectName: string,
  environmentName: string,
  revisionId: string
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}`,
  ] as const;

export type EnvironmentRevisionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentRevisionsGet>>
>;
export type EnvironmentRevisionsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get revision
 */
export const useEnvironmentRevisionsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  environmentName: string,
  revisionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentRevisionsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && environmentName && revisionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentRevisionsGetKey(
            projectName,
            environmentName,
            revisionId
          )
        : null);
  const swrFn = () =>
    environmentRevisionsGet(
      projectName,
      environmentName,
      revisionId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List builds of an environment. A build is triggered when a new environment file is uploaded.

### Response Structure
A list of details of the builds
- `id`: Unique identifier for the build
- `revision`: ID of the revision to which the build is linked
- `creation_date`: The date when the build was created
- `status`: Status of the build. It can be one of the following: 'queued', 'processing', 'success' or 'failed'.
- `error_message`: Error message which explains why the build has failed. It is empty if the build is successful.
- `trigger`: Action that triggered the build

#### Response Examples
```
[
  {
    "id": "e3021050-b9ac-4b8e-89f4-adb9e7c9aba6",
    "revision": "593bac21-7cd2-476a-aee8-ec9fc7f56232",
    "creation_date": "2023-01-23T12:17:11.863+00:00",
    "status": "failed",
    "error_message": "Could not find the requirements file",
    "trigger": "Environment file upload"
  },
  {
    "id": "038ae310-6629-4887-952d-868b6e533b90",
    "revision": "8760570f-6eda-470b-99af-bde810d418d8",
    "creation_date": "2023-01-29T17:12:43.108+00:00",
    "status": "queued",
    "error_message": "",
    "trigger": "Environment file upload"
  }
]
```

 * @summary List builds
 */
export const environmentBuildsList = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentBuildList[]>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds`,
      method: "get",
    },
    options
  );
};

export const getEnvironmentBuildsListKey = (
  projectName: string,
  environmentName: string,
  revisionId: string
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds`,
  ] as const;

export type EnvironmentBuildsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentBuildsList>>
>;
export type EnvironmentBuildsListQueryError = ErrorType<unknown>;

/**
 * @summary List builds
 */
export const useEnvironmentBuildsList = <TError = ErrorType<unknown>>(
  projectName: string,
  environmentName: string,
  revisionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentBuildsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && environmentName && revisionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentBuildsListKey(projectName, environmentName, revisionId)
        : null);
  const swrFn = () =>
    environmentBuildsList(
      projectName,
      environmentName,
      revisionId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Retrieve details of a build of an environment

### Response Structure
Details of a build
- `id`: Unique identifier for the build
- `revision`: ID of the revision to which the build is linked
- `creation_date`: The date when the build was created
- `status`: Status of the build. It can be one of the following: 'queued', 'processing', 'success' or 'failed'.
- `error_message`: Error message which explains why the build has failed. It is empty if the build is successful.
- `trigger`: Action that triggered the build

#### Response Examples
```
{
  "id": "e2c5f430-265d-4f79-a828-259ada415ae4",
  "revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "creation_date": "2023-01-30T12:27:12.108+00:00",
  "status": "success",
  "error_message": "",
  "trigger": "Rebuild triggered"
}
```

 * @summary Get build
 */
export const environmentBuildsGet = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentBuildList>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds/${buildId}`,
      method: "get",
    },
    options
  );
};

export const getEnvironmentBuildsGetKey = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds/${buildId}`,
  ] as const;

export type EnvironmentBuildsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentBuildsGet>>
>;
export type EnvironmentBuildsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get build
 */
export const useEnvironmentBuildsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentBuildsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && environmentName && revisionId && buildId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentBuildsGetKey(
            projectName,
            environmentName,
            revisionId,
            buildId
          )
        : null);
  const swrFn = () =>
    environmentBuildsGet(
      projectName,
      environmentName,
      revisionId,
      buildId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Cancel a build of an environment

### Required Parameters
- `status`: Status that the build will be updated to. It can only be cancelled.

#### Request Examples
```
{
    "status": "cancelled"
}
```

### Response Structure
Details of the cancelled build
- `id`: Unique identifier for the build
- `revision`: ID of the revision to which the build is linked
- `creation_date`: The date when the build was created
- `status`: Status of the build. It can be one of the following: 'queued', 'processing', 'success' or 'failed'.
- `error_message`: Error message which explains why the build has failed. It is empty if the build is successful.
- `trigger`: Action that triggered the build

#### Response Examples
```
{
  "id": "e2c5f430-265d-4f79-a828-259ada415ae4",
  "revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "creation_date": "2023-01-30T12:27:12.108+00:00",
  "status": "cancelled",
  "error_message": "",
  "trigger": "Rebuild triggered"
}
```

 * @summary Update build
 */
export const environmentBuildsUpdate = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string,
  environmentBuildUpdate: EnvironmentBuildUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentBuildList>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds/${buildId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: environmentBuildUpdate,
    },
    options
  );
};

/**
 * 
### Description
List the dependency files and their contents in an environment build

### Response Structure
A list of details of the dependency files
- `name`: Name of the dependency file
- `content`: Content of the dependency file

#### Response Examples
```
[
  {
    "name": "requirements.txt",
    "content": "ubiops==3.6.1\nrequests==2.30.0\n"
  },
  {
    "name": "ubiops.yaml",
    "content": "environment_variables:\n- ACCEPT_EULA=Y\napt:\n  packages:\n    - python3-dev\n"
  }
]
```

 * @summary List dependency files
 */
export const environmentBuildDependenciesList = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentBuildDependency[]>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds/${buildId}/dependency-files`,
      method: "get",
    },
    options
  );
};

export const getEnvironmentBuildDependenciesListKey = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/builds/${buildId}/dependency-files`,
  ] as const;

export type EnvironmentBuildDependenciesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentBuildDependenciesList>>
>;
export type EnvironmentBuildDependenciesListQueryError = ErrorType<unknown>;

/**
 * @summary List dependency files
 */
export const useEnvironmentBuildDependenciesList = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  environmentName: string,
  revisionId: string,
  buildId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentBuildDependenciesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && environmentName && revisionId && buildId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentBuildDependenciesListKey(
            projectName,
            environmentName,
            revisionId,
            buildId
          )
        : null);
  const swrFn = () =>
    environmentBuildDependenciesList(
      projectName,
      environmentName,
      revisionId,
      buildId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Download the file of a revision of an environment

### Response Structure
 - `file`: Environment file

 * @summary Download environment file
 */
export const environmentRevisionsFileDownload = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Blob>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/download`,
      method: "get",
      responseType: "blob",
    },
    options
  );
};

export const getEnvironmentRevisionsFileDownloadKey = (
  projectName: string,
  environmentName: string,
  revisionId: string
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/download`,
  ] as const;

export type EnvironmentRevisionsFileDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentRevisionsFileDownload>>
>;
export type EnvironmentRevisionsFileDownloadQueryError = ErrorType<unknown>;

/**
 * @summary Download environment file
 */
export const useEnvironmentRevisionsFileDownload = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  environmentName: string,
  revisionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentRevisionsFileDownload>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && environmentName && revisionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentRevisionsFileDownloadKey(
            projectName,
            environmentName,
            revisionId
          )
        : null);
  const swrFn = () =>
    environmentRevisionsFileDownload(
      projectName,
      environmentName,
      revisionId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Trigger a rebuild for a revision of an environment

### Response Structure
Details of the created build
- `id`: Unique identifier for the build
- `revision`: ID of the revision to which the build is linked
- `creation_date`: The date when the build was created
- `status`: Status of the build. It can be one of the following: 'queued', 'processing', 'success' or 'failed'.
- `error_message`: Error message which explains why the build has failed. It is empty if the build is successful.
- `trigger`: Action that triggered the build

#### Response Examples
```
{
  "id": "e2c5f430-265d-4f79-a828-259ada415ae4",
  "revision": "8760570f-6eda-470b-99af-bde810d418d8",
  "creation_date": "2023-01-30T12:27:12.108+00:00",
  "status": "queued",
  "error_message": "",
  "trigger": "Rebuild triggered"
}
```

 * @summary Rebuild revision
 */
export const environmentRevisionsRebuild = (
  projectName: string,
  environmentName: string,
  revisionId: string,
  environmentBuildCreate: EnvironmentBuildCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentBuildList>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/revisions/${revisionId}/rebuild`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentBuildCreate,
    },
    options
  );
};

/**
 * 
### Description
List the deployment versions used by an environment

### Response Structure
A list of details of the deployment versions
- `id`: Unique identifier for the deployment version (UUID)
- `deployment`: Deployment name to which the version is associated
- `version`: Version name
- `environment`: Environment of the version
- `environment_display_name`: Human readable name of the environment
- `status`: The status of the version

#### Response Examples
```
[
  {
    "id": "4ae7d14b-4803-4e16-b96d-3b18caa4b605",
    "deployment": "deployment-1",
    "version": "version-1",
    "environment": "python3-8",
    "environment_display_name": "Python 3.8",
    "status": "available"
  },
  {
    "id": "24f6b80a-08c3-4d52-ac1a-2ea7e70f16a6",
    "deployment": "deployment-1",
    "version": "version-2",
    "environment": "python3-8",
    "environment_display_name": "Python 3.8",
    "status": "unavailable"
  }
]
```

 * @summary List usage of environment
 */
export const environmentsUsage = (
  projectName: string,
  environmentName: string,
  params?: EnvironmentsUsageParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentUsage[]>(
    {
      url: `/projects/${projectName}/environments/${environmentName}/usage`,
      method: "get",
      params,
    },
    options
  );
};

export const getEnvironmentsUsageKey = (
  projectName: string,
  environmentName: string,
  params?: EnvironmentsUsageParams
) =>
  [
    `/projects/${projectName}/environments/${environmentName}/usage`,
    ...(params ? [params] : []),
  ] as const;

export type EnvironmentsUsageQueryResult = NonNullable<
  Awaited<ReturnType<typeof environmentsUsage>>
>;
export type EnvironmentsUsageQueryError = ErrorType<unknown>;

/**
 * @summary List usage of environment
 */
export const useEnvironmentsUsage = <TError = ErrorType<unknown>>(
  projectName: string,
  environmentName: string,
  params?: EnvironmentsUsageParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof environmentsUsage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && environmentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getEnvironmentsUsageKey(projectName, environmentName, params)
        : null);
  const swrFn = () =>
    environmentsUsage(projectName, environmentName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
