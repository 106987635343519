import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { checkPermissionToRefreshAccessToken } from "libs/data/axios/utilities";
import { userGet } from "libs/data/endpoints/user/user";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { routes } from "routes";
import { setIsAuthenticated } from "store/features/authentication";

import { Route } from "./Route";

import type { RouteProps } from "react-router-dom";

const shouldGetUser = () =>
  env.get(ENV_NAMES.ACCESS_TOKEN) || checkPermissionToRefreshAccessToken(env);

export const AuthenticationRoute = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const history = useHistory();
  const [isLoggedIn] = useState(shouldGetUser());
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      userGet()
        .then(() => {
          dispatch(setIsAuthenticated(true));
          const redirectUrl =
            env.get(ENV_NAMES.REDIRECT_URL) || routes.organizations.index();
          history.push(redirectUrl?.pathname || redirectUrl);
        })
        .catch(() => {
          return;
        });
    }
  }, [history, isLoggedIn, dispatch]);

  if (!Component) return null;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
