import HomeIcon from "@mui/icons-material/Home";
import { Grid, Typography } from "@mui/material";

import { useGetCurrentProject } from "store/features/organizationProjects";

import { Card, CopyToClipboardButton } from "components/atoms";

export const ProjectIdCard = () => {
  const currentProject = useGetCurrentProject();

  return (
    <Card title="Project ID" icon={HomeIcon}>
      <Grid container alignItems="center">
        {currentProject?.id && (
          <>
            <Typography variant="body1">{currentProject?.id}</Typography>
            <CopyToClipboardButton
              contentToCopy={currentProject?.id}
              htmlColor="secondary"
              hoverColor="secondary"
            />
          </>
        )}
      </Grid>
    </Card>
  );
};
