import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useReactFlow } from "reactflow";

import { spacing } from "assets/styles/theme";
import validators from "libs/utilities/validators";

import { PrimaryButton } from "components/atoms";

import { ObjectNameTextField, ObjectNameTitle } from "./styles";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { Dispatch, SetStateAction } from "react";
import type { EdgeDataType, NodeDataType } from "../types";

type NodeNameEditableTitleProps = {
  name: string;
  fieldName: string;
  isEditable?: boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
};

export const NodeNameEditableTitle = ({
  name,
  fieldName,
  isEditable = true,
  isEditing,
  setIsEditing,
}: NodeNameEditableTitleProps) => {
  const { getNodes } = useReactFlow<NodeDataType, EdgeDataType>();
  const objectNames = getNodes()
    .filter((node) => node.data.pipelineObject.name !== name)
    .map((node) => node.data.pipelineObject.name);
  const theme = useTheme() as AppThemeProps;

  return (
    <div
      style={{
        height: spacing[28],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {isEditing && isEditable && (
        <>
          <ObjectNameTextField
            errorColor={theme.palette.error.main}
            backgroundColor={theme.palette.primary.contrastText}
            name={fieldName}
            defaultValue={name}
            autoFocus
            withError
            rules={{
              required: validators.required.message(),
              pattern: {
                value: validators.name.pattern,
                message: validators.name.message(),
              },
              validate: (value) =>
                objectNames.find((name) => name === value) &&
                "Another pipeline object already has that name.",
            }}
            style={{ width: "95%", background: "transparent" }}
          />
          <PrimaryButton
            style={{ height: "inherit", marginLeft: spacing[8] }}
            size="small"
            type="submit"
          >
            Save
          </PrimaryButton>
        </>
      )}
      <Box
        display={isEditing ? "none" : "flex"}
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        height="inherit"
      >
        <Tooltip
          title={<Typography variant="body1">{name}</Typography>}
          placement="top-start"
        >
          <ObjectNameTitle variant="h4">{name}</ObjectNameTitle>
        </Tooltip>
        {isEditable && (
          <IconButton
            onClick={() => setIsEditing(true)}
            style={{ width: spacing[28], height: spacing[28] }}
          >
            <EditIcon color="secondary" fontSize="small" />
          </IconButton>
        )}
      </Box>
    </div>
  );
};
