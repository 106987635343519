import { Box } from "@mui/material";
import { IntroText, RequestCreate, RequestResults } from "@ubiops/widgets";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import {
  FIELD_INPUT_WIDGETS,
  FIELD_OUTPUT_WIDGETS,
} from "libs/constants/fields";
import { LOADED } from "libs/utilities/request-statuses";
import { isArrayHasData } from "libs/utilities/utils";
import {
  defineDummyResults,
  updateFieldsWidgets,
} from "libs/utilities/widgets-helpers";

import "./RequestPagePreview.scss";

const RequestPagePreview = ({ requestObject, requestType, methods }) => {
  const [clickedRequestCreate, setClickedRequestCreate] = useState(false);

  const { watch } = methods;
  const inputWidgets = watch(FIELD_INPUT_WIDGETS);
  const outputWidgets = watch(FIELD_OUTPUT_WIDGETS);

  useEffect(() => {
    return () => {
      setClickedRequestCreate(false);
    };
  }, [setClickedRequestCreate]);

  return (
    <Box className="requests-page-configuration__preview">
      <IntroText />
      <RequestCreate
        name={requestObject.name}
        isPipeline={requestType === "pipeline"}
        description={requestObject.description}
        inputType={requestObject.input_type}
        inputFields={updateFieldsWidgets(
          requestObject.input_fields,
          inputWidgets
        )}
        warningText={clickedRequestCreate ? "This is just a preview." : ""}
        createRequest={() => setClickedRequestCreate(true)}
        createFileStatus={LOADED}
        onDelete={() => {}}
      />
      <RequestResults
        tooltip={
          isArrayHasData(requestObject.output_fields)
            ? `This preview is generated with dummy data. On the ${requestType} interface, the actual output of the request will be shown.`
            : null
        }
        inputFields={requestObject.input_fields}
        outputFields={updateFieldsWidgets(
          requestObject.output_fields,
          outputWidgets,
          "output"
        )}
        results={{
          id: "test-id",
          error_message: null,
          result: defineDummyResults(
            updateFieldsWidgets(requestObject.output_fields, outputWidgets)
          ),
        }}
        getBlobStatus={{ blobId: LOADED }}
        getBlob={() => Promise.resolve("/image-placeholder.svg")}
        downloadPreviews={{
          blobId: {
            filename: "ubiops-logo.svg",
            objectUrl: "/image-placeholder.svg", // logo.svg in public folder
          },
        }}
      />
    </Box>
  );
};

RequestPagePreview.propTypes = {
  requestObject: PropTypes.object.isRequired,
  requestType: PropTypes.string.isRequired,
  methods: PropTypes.object.isRequired,
};

export default RequestPagePreview;
