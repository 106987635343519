import { Box, Grid } from "@mui/material";
import { find } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { PageHeader } from "components/molecules/PageLayout";
import { DESCRIPTION_FIELD_MAX_CHARACTERS } from "libs/constants/constants";
import {
  FIELD_BUCKET,
  FIELD_DEPLOYMENT_VERSION_ENVIRONMENT,
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DESCRIPTION,
  FIELD_GPU_INSTANCE_ENABLED,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_RETENTION_TIME,
  FIELD_STATIC_IP,
  FIELD_VERSION,
} from "libs/constants/fields";
import { useDeploymentVersionsUpdate } from "libs/data/customized/deployment-versions/useDeploymentVersionsUpdate";
import {
  deploymentVersionEnvironmentVariablesCreate,
  deploymentVersionEnvironmentVariablesUpdate,
  useDeploymentVersionEnvironmentVariablesList,
  useDeploymentVersionsGet,
} from "libs/data/endpoints/deployments/deployments";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { useInstanceTypeGroupsGet } from "libs/data/endpoints/instances/instances";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { explanations } from "libs/utilities/explanations";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { createErrorNotification } from "libs/utilities/notifications";
import { LOADED, LOADING, UNLOADED } from "libs/utilities/request-statuses";
import validators from "libs/utilities/validators";
import { routes } from "routes";

import {
  Accordion,
  FormSwitch,
  FormTextField,
  InfoAlert,
} from "components/atoms";
import {
  CodeEnvironmentDialog,
  CodeEnvironmentSection,
  FormSection,
  InstanceTypeField,
  NetworkSettings,
  PageContainer,
} from "components/molecules";
import { FormContainer, LabelsForm } from "components/organisms";

import { AssignBucketPermissionsToExperiment } from "../../AssignBucketPermissionsToExperiment";
import { SYS_DEFAULT_BUCKET, TRAINING_DEPLOYMENT } from "../../constants";
import { AdvancedParameters } from "../AdvancedParameters";
import { ExperimentUpdateEnvVars } from "./ExperimentUpdateEnvVars";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { DeploymentVersionUpdate } from "libs/data/models";

const descriptionPlaceholder =
  "Ex: Experiment for training a model that can detect cancer from CT scans.";

type FormData = {
  [FIELD_NAME]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_GPU_INSTANCE_ENABLED]: boolean;
  [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]: { label: string; value: string };
  [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]: { label: string; value: string };
  [FIELD_BUCKET]: AutocompleteSelectOption;
  [FIELD_LABELS]: Array<{ key: string; value: string }>;
  [FIELD_RETENTION_TIME]: AutocompleteSelectOption;
  [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: number;
  [FIELD_STATIC_IP]: boolean;
};

export const ExperimentUpdate = () => {
  const formMethods = useForm<FormData>({ mode: "onBlur" });
  const [environmentDialogOpen, setEnvironmentDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue } = formMethods;
  const history = useHistory();
  const { organizationName, projectName, experimentName } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
    }>();

  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const { data: buckets } = useBucketsList(projectName);
  const { data: experiment, mutate } = useDeploymentVersionsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName
  );
  const { data: variables } = useDeploymentVersionEnvironmentVariablesList(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName
  );
  const { data: currentInstanceType } = useInstanceTypeGroupsGet(
    projectName,
    experiment?.[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE] ?? ""
  );

  const defaultGPUInstanceModeEnabled = useMemo(() => {
    return !!currentInstanceType?.instance_types?.[0].accelerator || 0 > 0;
  }, [currentInstanceType]);

  useEffect(() => {
    if (experiment?.labels) {
      setValue(FIELD_LABELS, formatLabels(experiment.labels));
    }
  }, [experiment, setValue]);

  const defaultBucketVariable = useMemo(
    () => find(variables, (variable) => variable?.name === SYS_DEFAULT_BUCKET),
    [variables]
  );

  const [formStatus, setFormStatus] =
    useState<typeof UNLOADED | typeof LOADING | typeof LOADED>(UNLOADED);

  const gpuInstanceModeEnabled = !!useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;
  const updateExperiment = useDeploymentVersionsUpdate(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    "experiment"
  );

  const onSubmit = async (data: FormData) => {
    setFormStatus(LOADING);
    const experimentData: DeploymentVersionUpdate = {
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]:
        data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]?.value,
      [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
        data[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]?.value,
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
      [FIELD_VERSION]: data[FIELD_NAME],
      [FIELD_RETENTION_TIME]: Number(data[FIELD_RETENTION_TIME]?.value),
      [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: Number(
        data[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]
      ),
      [FIELD_STATIC_IP]: data[FIELD_STATIC_IP],
    };

    try {
      await updateExperiment(experimentData);
      const bucketName = (data[FIELD_BUCKET]?.value as string) || "";
      if (defaultBucketVariable) {
        await deploymentVersionEnvironmentVariablesUpdate(
          projectName,
          TRAINING_DEPLOYMENT,
          data[FIELD_NAME],
          defaultBucketVariable.id,
          {
            name: SYS_DEFAULT_BUCKET,
            value: bucketName,
            secret: false,
          }
        );
      } else if (data[FIELD_BUCKET].value !== "default") {
        await deploymentVersionEnvironmentVariablesCreate(
          projectName,
          TRAINING_DEPLOYMENT,
          data[FIELD_NAME],
          {
            name: SYS_DEFAULT_BUCKET,
            value: bucketName,
            secret: false,
          }
        );
      }
      AssignBucketPermissionsToExperiment(projectName, bucketName, dispatch);

      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .training.experiments[":experimentName"](
            experimentData.version as string
          )
          .index()
      );
      mutate();
    } catch (error: any) {
      dispatch(createErrorNotification(error?.message));
    }
  };
  const defaultBucket =
    find(buckets, (bucket) => bucket.name === defaultBucketVariable?.value) ??
    find(buckets, (bucket) => bucket.name === "default") ??
    buckets?.[0];

  const handleOnCreate = (option: AutocompleteSelectOption) => {
    setValue(FIELD_DEPLOYMENT_VERSION_ENVIRONMENT, option);
  };

  useEffect(() => {
    if (
      organizationFeatures &&
      buckets &&
      variables &&
      experiment !== undefined &&
      currentInstanceType
    ) {
      setFormStatus(LOADED);
    }
  }, [
    buckets,
    variables,
    experiment,
    organizationFeatures,
    currentInstanceType,
  ]);

  const options = useMemo(
    () =>
      buckets?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [],
    [buckets]
  );

  const defaultValue = useMemo(
    () => ({
      label: defaultBucket?.name || "default",
      value: defaultBucket?.name || "default",
    }),
    [defaultBucket]
  );

  return (
    <PageContainer>
      <PageHeader title="Edit training experiment" />
      <FormContainer
        onSubmit={handleSubmit(onSubmit)}
        formMethods={formMethods}
        buttonLabel="Save"
        status={formStatus}
      >
        <FormSection
          title="General"
          description={explanations.training.experiments.general}
        >
          <FormTextField
            name={FIELD_NAME}
            defaultValue={experimentName}
            label="Name"
            placeholder="Ex: cancer-detection"
            rules={{
              required: validators.required.message(FIELD_NAME),
              pattern: {
                value: validators.name.pattern,
                message: validators.name.message(FIELD_NAME),
              },
              validate: validators.name.value("experiment"),
            }}
          />
          <FormTextField
            name={FIELD_DESCRIPTION}
            defaultValue={experiment?.description}
            minRows={3}
            label="Description"
            placeholder={descriptionPlaceholder}
            withCharCounter
            multiline
            inputProps={{
              maxLength: DESCRIPTION_FIELD_MAX_CHARACTERS,
            }}
            maxChars={DESCRIPTION_FIELD_MAX_CHARACTERS}
          />
        </FormSection>
        <FormSection
          title="Hardware settings"
          description={explanations.training.experiments.hardwareSettings}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormSwitch
                name={FIELD_GPU_INSTANCE_ENABLED}
                label="Enable accelerated hardware"
                infoTooltipText={
                  gpuEnabled
                    ? explanations.deployments.versions.instanceMode.gpu
                    : explanations.deployments.versions.instanceMode.gpuDisabled
                }
                disabled={!gpuEnabled}
                defaultValue={defaultGPUInstanceModeEnabled}
              />
            </Grid>
            {!gpuEnabled && (
              <Grid item xs={12}>
                <InfoAlert>
                  {
                    explanations.deployments.versions.instanceMode
                      .gpuDisabledWithLink
                  }
                </InfoAlert>
              </Grid>
            )}
            <Grid item xs={12}>
              <InstanceTypeField
                gpuEnabled={gpuEnabled}
                defaultValue={experiment?.instance_type_group_id}
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSection
          title="Environment settings"
          description={explanations.training.experiments.environment}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CodeEnvironmentSection
                onAddEnvironment={() => setEnvironmentDialogOpen(true)}
                defaultEnvironment={experiment?.environment}
                gpuEnabled={gpuEnabled && gpuInstanceModeEnabled}
                isEnvImplicit={false}
                name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
                projectName={projectName}
                deployment={TRAINING_DEPLOYMENT}
                supportsRequestFormat={true}
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSection
          title="Select default output bucket"
          description={explanations.training.experiments.output_location}
        >
          <Box maxWidth={spacing[220]}>
            <AutoCompleteSelectHookForm
              name={FIELD_BUCKET}
              loading={buckets === undefined}
              options={options || []}
              defaultValue={defaultValue}
            />
          </Box>
        </FormSection>
        <Grid item container spacing={5} justifyContent="flex-end">
          <Grid item>
            <Accordion title="Optional / Advanced settings" titleVariant="h3">
              <Grid container spacing={5} marginTop={1}>
                <ExperimentUpdateEnvVars experimentName={experimentName} />
                <NetworkSettings
                  entity="training run"
                  defaultValue={experiment?.static_ip}
                />

                <AdvancedParameters experiment={experiment} />
                <FormSection
                  title="Labels"
                  description={explanations.training.experiments.labels}
                >
                  <LabelsForm name={FIELD_LABELS} />
                </FormSection>
              </Grid>
            </Accordion>
          </Grid>
        </Grid>
      </FormContainer>
      <CodeEnvironmentDialog
        gpuEnabled={gpuEnabled}
        onClose={() => setEnvironmentDialogOpen(false)}
        onCreate={handleOnCreate}
        open={environmentDialogOpen}
        projectName={projectName}
        shouldSupportRequests
      />
    </PageContainer>
  );
};
