import ContactIcon from "@mui/icons-material/QuestionAnswer";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";

import { LINK_SUPPORT } from "libs/constants/documentation-links";

import { ConditionalWrapper } from "components/atoms";
import "./SidebarSupportSection.scss";

const list = [
  {
    label: "Contact support",
    url: LINK_SUPPORT,
    icon: ContactIcon,
  },
];

interface SidebarSupportSectionProps {
  sidebarIsOpen: boolean;
}

const SidebarSupportSection = ({
  sidebarIsOpen,
}: SidebarSupportSectionProps) => {
  const theme = useTheme();

  return (
    <List disablePadding className="sidebar-support">
      {list.map(({ label, url, icon: Icon }) => (
        <ConditionalWrapper
          condition={!sidebarIsOpen}
          key={label}
          wrapper={(children) => (
            <Tooltip title={label} placement="right">
              {children}
            </Tooltip>
          )}
        >
          <ListItem
            key={label}
            component={Link}
            href={url}
            target="_blank"
            underline="always"
            className="sidebar-support__item"
            sx={{
              ":hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon className="sidebar-support__icon">
              <Icon
                fontSize="small"
                color="inherit"
                sx={{ color: theme.palette.text.primary }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.text.primary }}>
              {label}
            </ListItemText>
          </ListItem>
        </ConditionalWrapper>
      ))}
    </List>
  );
};

export default SidebarSupportSection;
