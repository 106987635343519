import { Box } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  FIELD_NEWSLETTER,
  FIELD_TERMS_CONDITIONS,
} from "libs/constants/fields";
import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { useUserUpdate } from "libs/data/customized/user";
import { useGoogleAnalytics } from "libs/hooks";
import { routes } from "routes";

import { SecondaryButton } from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import { TCNewsletterCheckboxes } from "./TCNewsletterCheckboxes";

const SignUpTerms = () => {
  useGoogleAnalytics();

  const methods = useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [, setCookie] = useCustomCookies();
  const updateUser = useUserUpdate();

  const onSubmit = async (data: any) => {
    setLoading(true);
    setCookie(COOKIE_NAMES.NEWSLETTER, data[FIELD_NEWSLETTER]);
    setCookie(COOKIE_NAMES.TERMS_CONDITIONS, data[FIELD_TERMS_CONDITIONS]);
    await updateUser({
      [FIELD_NEWSLETTER]: data[FIELD_NEWSLETTER],
      [FIELD_TERMS_CONDITIONS]: data[FIELD_TERMS_CONDITIONS],
    });
    setLoading(false);
    history.push(routes.organizations.index());
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <AuthenticationContainer title="To sign in, please accept the terms and conditions:">
          <TCNewsletterCheckboxes />
          <Box mt={2}>
            <SecondaryButton disabled={loading} type="submit">
              Confirm
            </SecondaryButton>
          </Box>
        </AuthenticationContainer>
      </form>
    </FormProvider>
  );
};

export default SignUpTerms;
