import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";

import { PreselectLabels } from "components/molecules/ControlledInputs/ControlledLabelsInput/PreselectLabels";
import {
  FormikSelect,
  FormikTextInput,
} from "components/molecules/FormikInputs";
import { parseErrorFromControl } from "components/molecules/FormikInputs/utils";
import {
  groupedlabelKeyOptions,
  predefinedLabels,
} from "libs/utilities/labels-mapping";
import validators, { yupValidators } from "libs/utilities/validators";

import { AddButton, LabelChip } from "components/atoms";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { FormikHelpers, FormikProps, FormikValues } from "formik";

const KEY = "key";
const VALUE = "value";

export interface Label {
  [KEY]: string;
  [VALUE]: string;
}

export interface LabelsDict {
  [key: string]: string | null;
}

const initialValues: Partial<Label> = { [KEY]: undefined, [VALUE]: "" };

interface ControlledLabelsInputProps {
  error?: string;
  onChange: (value: LabelsDict) => void;
  value: LabelsDict;
}

export const ControlledLabelsInput = ({
  onChange,
  value,
}: ControlledLabelsInputProps) => {
  const keys = useMemo(() => Object.keys(value), [value]);

  const onLabelAdd = (label: Label) => {
    onChange({
      ...value,
      [label[KEY]]: label[VALUE],
    });
  };

  const onLabelRemove = (key: string) => () => {
    const { [key]: _, ...newDict } = value;
    onChange(newDict);
  };

  const handlePressEnter =
    (submit?: () => void) =>
    (event: {
      keyCode: number;
      which: number;
      key: string;
      preventDefault: () => void;
    }) => {
      const keyCode = event.keyCode ?? event.which;
      if (event.key === "Enter" || keyCode === 13) {
        event.preventDefault();
        submit && submit();
      }
    };

  const handleSubmit = (
    label: Partial<Label>,
    helpers: FormikHelpers<Partial<Label>>
  ) => {
    onLabelAdd(label as Label);
    helpers.resetForm();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ paddingBottom: 12 }}>
        <PreselectLabels
          onAdd={onLabelAdd}
          labels={predefinedLabels.filter((x) => !keys.includes(x.key))}
        />
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          key: Yup.string()
            .required(validators.required.message(KEY))
            .matches(validators.labelKey.pattern, validators.labelKey.message)
            .test(
              "uniqueKeys",
              yupValidators.uniqueKeys.message,
              yupValidators.uniqueKeys.test(keys)
            ),
          value: Yup.string()
            .matches(
              validators.labelValue.pattern,
              validators.labelValue.message
            )
            .required(validators.required.message(VALUE)),
        })}
      >
        {(control: FormikProps<FormikValues>) => {
          const error =
            parseErrorFromControl(control, KEY) ??
            parseErrorFromControl(control, VALUE);

          return (
            <>
              <Grid item xs={6} display={"flex"} alignItems="center">
                <FormikSelect
                  control={control}
                  name={KEY}
                  freeSolo
                  label="Key"
                  groupBy={(option: AutocompleteSelectOption) =>
                    option.group ?? ""
                  }
                  options={groupedlabelKeyOptions}
                  showErrors={false}
                  value={control.values[KEY]}
                  placeholder='Ex: "type"'
                  styles={{ marginBottom: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextInput
                  control={control}
                  name={VALUE}
                  label="Value"
                  placeholder={`Ex: "connector"`}
                  onKeyPress={handlePressEnter(control.submitForm)}
                  showErrors={false}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 8 }}>
                <AddButton onClick={control.submitForm}>Add label</AddButton>
              </Grid>
              {!!error && (
                <Grid item xs={12}>
                  <Typography color="error" variant="caption">
                    {error}
                  </Typography>
                </Grid>
              )}
            </>
          );
        }}
      </Formik>

      <Grid item xs={12}>
        {keys.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="caption">No labels specified</Typography>
          </Grid>
        )}
        <Grid container direction="row" item xs={12}>
          <Grid item xs={12} container alignItems="center" spacing={1}>
            {keys.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="caption">Added labels:</Typography>
              </Grid>
            )}
            {keys.map((key) => (
              <Grid item key={key}>
                <LabelChip
                  label={key}
                  value={value[key]}
                  onDelete={onLabelRemove(key)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
