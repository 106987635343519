import { Box, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { PageHeader } from "components/molecules/PageLayout";
import {
  DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  DESCRIPTION_FIELD_MAX_CHARACTERS,
} from "libs/constants/constants";
import {
  FIELD_BUCKET,
  FIELD_DEPLOYMENT_VERSION_ENVIRONMENT,
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DESCRIPTION,
  FIELD_GPU_INSTANCE_ENABLED,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_RETENTION_TIME,
  FIELD_STATIC_IP,
  FIELD_VERSION,
} from "libs/constants/fields";
import { useDeploymentVersionsCreate } from "libs/data/customized/deployment-versions/useDeploymentVersionsCreate";
import {
  deploymentVersionEnvironmentVariablesCreate,
  revisionsFileUpload,
  useDeploymentVersionsList,
  useTemplateDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { explanations } from "libs/utilities/explanations";
import { formatLabelsForRequest } from "libs/utilities/labels-util";
import { LOADED, LOADING, UNLOADED } from "libs/utilities/request-statuses";
import validators from "libs/utilities/validators";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";

import {
  Accordion,
  FormSwitch,
  FormTextField,
  InfoAlert,
} from "components/atoms";
import {
  CodeEnvironmentDialog,
  CodeEnvironmentSection,
  FormSection,
  InstanceTypeField,
  NetworkSettings,
  PageContainer,
} from "components/molecules";
import { FormContainer, LabelsForm } from "components/organisms";

import { AssignBucketPermissionsToExperiment } from "../AssignBucketPermissionsToExperiment";
import { SYS_DEFAULT_BUCKET, TRAINING_DEPLOYMENT } from "../constants";
import { AdvancedParameters } from "./AdvancedParameters";
import { ExperimentCreateEnvVars } from "./ExperimentCreateEnvVars";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type {
  DeploymentVersionCreate,
  InheritedEnvironmentVariableList,
} from "libs/data/models";

const descriptionPlaceholder =
  "Ex: Experiment for training a model that can detect objects inside images.";

type FormData = {
  [FIELD_NAME]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_GPU_INSTANCE_ENABLED]: boolean;
  [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]: { label: string; value: string };
  [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]: { label: string; value: string };
  [FIELD_BUCKET]: AutocompleteSelectOption;
  [FIELD_LABELS]?: Array<{ key: string; value: string }>;
  [FIELD_RETENTION_TIME]: AutocompleteSelectOption;
  [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: number;
  [FIELD_STATIC_IP]: boolean;
};

export const ExperimentCreate = () => {
  const createExperimentFormMethods = useForm<FormData>({ mode: "onBlur" });
  const [environmentDialogOpen, setEnvironmentDialogOpen] = useState(false);
  const { control, handleSubmit, setValue } = createExperimentFormMethods;
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useGetCurrentOrganization();

  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const { data: buckets } = useBucketsList(projectName);
  const { data: templateDeployments } = useTemplateDeploymentsList();
  const { data: experiments } = useDeploymentVersionsList(
    projectName,
    TRAINING_DEPLOYMENT
  );

  const trainingDeployment = templateDeployments?.find(
    (deployment) => deployment.details?.name === TRAINING_DEPLOYMENT
  );

  const [variables, setVariables] = useState<
    InheritedEnvironmentVariableList[] | []
  >([]);

  const [formStatus, setFormStatus] =
    useState<typeof UNLOADED | typeof LOADING | typeof LOADED>(UNLOADED);

  const gpuInstanceModeEnabled = !!useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;
  const createExperiment = useDeploymentVersionsCreate(
    projectName,
    TRAINING_DEPLOYMENT,
    "experiment"
  );

  const onSubmit = async (data: FormData) => {
    setFormStatus(LOADING);
    const experimentData: DeploymentVersionCreate = {
      [FIELD_VERSION]: data[FIELD_NAME],
      [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]:
        data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]?.value,
      [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
        data[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]?.value,
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_RETENTION_TIME]: Number(data[FIELD_RETENTION_TIME]?.value),
      [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: Number(
        data[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]
      ),
      [FIELD_STATIC_IP]: data[FIELD_STATIC_IP],
    };

    if (organization?.subscription === "free") {
      experimentData.maximum_instances = 1;
    }

    if (data?.[FIELD_LABELS] && data[FIELD_LABELS]?.length > 0) {
      experimentData.labels = formatLabelsForRequest(data[FIELD_LABELS]);
    }
    await createExperiment(experimentData);
    revisionsFileUpload(projectName, TRAINING_DEPLOYMENT, data[FIELD_NAME], {
      template_deployment_id: trainingDeployment?.id,
    });
    const bucketName = (data[FIELD_BUCKET].value as string) || "";
    if (bucketName && bucketName !== "default") {
      deploymentVersionEnvironmentVariablesCreate(
        projectName,
        TRAINING_DEPLOYMENT,
        data[FIELD_NAME],
        {
          name: SYS_DEFAULT_BUCKET,
          value: bucketName,
          secret: false,
        }
      );
      AssignBucketPermissionsToExperiment(projectName, bucketName, dispatch);
    }
    if (variables.length > 0) {
      variables.forEach((variable) =>
        deploymentVersionEnvironmentVariablesCreate(
          projectName,
          TRAINING_DEPLOYMENT,
          data[FIELD_NAME],
          {
            name: variable.name,
            value: variable?.value ?? "",
            secret: variable.secret,
          }
        )
      );
    }

    history.replace(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .training.experiments[":experimentName"](data[FIELD_NAME])
        .index(),
      { shouldOpenCodeSnippet: !experiments?.length }
    );
  };

  const handleOnCreate = (option: AutocompleteSelectOption) => {
    setValue(FIELD_DEPLOYMENT_VERSION_ENVIRONMENT, option);
  };

  useEffect(() => {
    if (organizationFeatures && buckets) {
      setFormStatus(LOADED);
    }
  }, [buckets, organizationFeatures]);

  const options = useMemo(
    () =>
      buckets?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [],
    [buckets]
  );

  const defaultBucketName = useMemo(
    () =>
      buckets?.find(({ name }) => name === "default")?.name ||
      buckets?.[0]?.name ||
      "",
    [buckets]
  );

  const defaultValue = useMemo(
    () => ({ label: defaultBucketName, value: defaultBucketName }),
    [defaultBucketName]
  );

  return (
    <PageContainer>
      <PageHeader title="Create new training experiment" />
      <FormContainer
        onSubmit={handleSubmit(onSubmit)}
        formMethods={createExperimentFormMethods}
        buttonLabel="Create"
        status={formStatus}
      >
        <FormSection
          title="General"
          description={explanations.training.experiments.general}
        >
          <FormTextField
            name={FIELD_NAME}
            label="Name"
            placeholder="Ex: object-detection"
            rules={{
              required: validators.required.message(FIELD_NAME),
              pattern: {
                value: validators.name.pattern,
                message: validators.name.message(FIELD_NAME),
              },
              validate: validators.name.value("experiment"),
            }}
          />
          <FormTextField
            name={FIELD_DESCRIPTION}
            minRows={3}
            label="Description"
            placeholder={descriptionPlaceholder}
            withCharCounter
            multiline
            inputProps={{
              maxLength: DESCRIPTION_FIELD_MAX_CHARACTERS,
            }}
            maxChars={DESCRIPTION_FIELD_MAX_CHARACTERS}
          />
        </FormSection>
        <FormSection
          title="Hardware settings"
          description={explanations.training.experiments.hardwareSettings}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormSwitch
                name={FIELD_GPU_INSTANCE_ENABLED}
                label="Enable accelerated hardware"
                infoTooltipText={
                  gpuEnabled
                    ? explanations.deployments.versions.instanceMode.gpu
                    : explanations.deployments.versions.instanceMode.gpuDisabled
                }
                disabled={!gpuEnabled}
                defaultValue={false}
              />
            </Grid>
            {!gpuEnabled && (
              <Grid item xs={12}>
                <InfoAlert>
                  {
                    explanations.deployments.versions.instanceMode
                      .gpuDisabledWithLink
                  }
                </InfoAlert>
              </Grid>
            )}
            <Grid item xs={12}>
              <InstanceTypeField
                defaultValue={DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE}
                gpuEnabled={gpuEnabled}
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSection
          title="Environment settings"
          description={explanations.training.experiments.environment}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CodeEnvironmentSection
                gpuEnabled={gpuEnabled && gpuInstanceModeEnabled}
                onAddEnvironment={() => setEnvironmentDialogOpen(true)}
                isEnvImplicit={false}
                name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
                projectName={projectName}
                deployment={TRAINING_DEPLOYMENT}
                supportsRequestFormat={true}
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSection
          title="Select default output bucket"
          description={explanations.training.experiments.output_location}
        >
          <Box maxWidth={spacing[220]}>
            <AutoCompleteSelectHookForm
              name={FIELD_BUCKET}
              loading={!buckets}
              options={options || []}
              defaultValue={defaultValue}
            />
          </Box>
        </FormSection>
        <Grid item container spacing={5} justifyContent="flex-end">
          <Grid item>
            <Accordion title="Optional / Advanced settings" titleVariant="h3">
              <Grid container spacing={5} marginTop={1}>
                <ExperimentCreateEnvVars
                  variables={variables}
                  setVariables={setVariables}
                />

                <NetworkSettings entity="training run" />

                <AdvancedParameters
                  experiment={trainingDeployment?.details?.version}
                />

                <FormSection
                  title="Labels"
                  description={explanations.training.experiments.labels}
                >
                  <LabelsForm name={FIELD_LABELS} />
                </FormSection>
              </Grid>
            </Accordion>
          </Grid>
        </Grid>
      </FormContainer>
      <CodeEnvironmentDialog
        gpuEnabled={gpuEnabled}
        onClose={() => setEnvironmentDialogOpen(false)}
        onCreate={handleOnCreate}
        open={environmentDialogOpen}
        projectName={projectName}
        shouldSupportRequests
      />
    </PageContainer>
  );
};
