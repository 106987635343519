import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, FormHelperText, Typography } from "@mui/material";

import { PrimaryButton } from "components/atoms/Button";
import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";

import { Icon } from "components/atoms";

export interface ControlledFileUploadFieldProps {
  error?: string | false;
  onChange: (value: File) => void;
  value: File;
  allowedTypes?: string[];
}

export const ControlledFileUpload = ({
  error,
  onChange,
  value,
  allowedTypes,
}: ControlledFileUploadFieldProps) => {
  const handleChange = (file: File) => {
    if (file) {
      onChange(file);
    }
  };

  return (
    <>
      <DropSelectFile handleChange={handleChange} types={allowedTypes}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          marginBottom={2}
        >
          <Typography align="center">
            Drag and drop your file here to upload it
          </Typography>
          <Typography>or</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <PrimaryButton>{value ? "Change file" : "Upload file"}</PrimaryButton>
        </Box>
        {value && (
          <Box
            display="flex"
            alignItems="center"
            marginTop={2}
            justifyContent="center"
          >
            {!error && (
              <Icon component={CheckCircleRoundedIcon} status="available" />
            )}
            <Typography variant="body2" display="inline">
              {value?.name}
            </Typography>
          </Box>
        )}
      </DropSelectFile>
      {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </>
  );
};
