import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { FIELD_ORGANIZATION, FIELD_VOUCHER } from "libs/constants/fields";
import { useVouchersGet } from "libs/data/endpoints/organizations/organizations";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { createId } from "libs/utilities/utils";
import validators from "libs/utilities/validators";

import {
  Alert,
  ErrorAlert,
  FormTextField,
  SecondaryButton,
  TextField,
} from "components/atoms";

type NewAccountFirstOrganizationProps = {
  submitForm: () => void;
};

export const NewAccountFirstOrganization = ({
  submitForm,
}: NewAccountFirstOrganizationProps) => {
  const voucherFromUrl = env.get(ENV_NAMES.PROMO_CODE);
  const [expanded, setExpanded] = useState(!!voucherFromUrl);
  const [promoCode, setPromoCode] = useState(voucherFromUrl);
  const [codeToValidate, setCodeToValidate] = useState(voucherFromUrl);
  const theme = useTheme();

  const { setValue, register } = useFormContext();

  const { data, error } = useVouchersGet(codeToValidate, {
    swr: { enabled: !!codeToValidate },
  });

  useEffect(() => {
    if (data && codeToValidate) {
      setValue(FIELD_VOUCHER, codeToValidate);
    }
  }, [setValue, data, codeToValidate, error]);

  const handleCodeChange = (value: string) => {
    setPromoCode(value);
    setValue(FIELD_VOUCHER, "");
    setCodeToValidate("");
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent enter key from refreshing page
      e.preventDefault();
      submitForm();
    }
  };

  return (
    <form>
      {env.get(ENV_NAMES.ON_PREMISE) ? (
        <Box mb={2}>
          You are now on <b>UbiOps OnPremise</b> subscription.
        </Box>
      ) : (
        <Box mb={2}>
          <Typography>
            You are now on <b>UbiOps Free</b> subscription. You can upgrade your
            subscription later in the Subscription & Usage section.
          </Typography>
        </Box>
      )}
      <Box mb={2}>Create your organization to get started.</Box>
      <Box width="80%">
        <FormTextField
          id={FIELD_ORGANIZATION}
          name={FIELD_ORGANIZATION}
          label="Organization name"
          rules={{
            required: validators.required.message(FIELD_ORGANIZATION),
            pattern: {
              value: validators.name.pattern,
              message: validators.name.message(FIELD_ORGANIZATION),
            },
          }}
          tooltip="UbiOps uses organizations to group projects. Depending on the subscription, users are able, for example, to create multiple projects in their organization."
          defaultValue={`my-organization-${createId()}`}
          placeholder="Ex: my-organization"
          onKeyDown={handleKeyPress}
        />

        <Accordion
          elevation={0}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          sx={{
            background: "inherit",
            boxShadow: 0,
            "&.MuiAccordion-root:before": { display: "none" },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: theme.palette.grey[700] }} />
            }
            style={{ padding: 0, width: "fit-content" }}
          >
            <Typography color={theme.palette.grey[700]}>
              I have a promo code
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", padding: 0 }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              <input hidden name={FIELD_VOUCHER} ref={register} />
              <TextField
                label="Promo code"
                name={FIELD_VOUCHER}
                onChange={(e) => handleCodeChange(e.target.value)}
                value={promoCode}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <SecondaryButton onClick={() => setCodeToValidate(promoCode)}>
                Apply
              </SecondaryButton>
            </Box>
            {(data || error) && (
              <Box>
                <Typography color="GrayText" marginY={2}>
                  Promo code applied:
                </Typography>
                {data ? (
                  <Alert
                    sx={{
                      backgroundColor: theme.palette.success.main,
                      color: theme.palette.success.contrastText,
                      "& .MuiAlert-icon": {
                        color: theme.palette.success.contrastText,
                      },
                    }}
                    severity="success"
                  >
                    {data.description}
                  </Alert>
                ) : (
                  <ErrorAlert>{error?.message}</ErrorAlert>
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </form>
  );
};
