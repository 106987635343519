import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import { useRef, useState } from "react";

import { NavLink } from "components/atoms";

import type { IconButtonProps } from "@mui/material";
import type { ReactElement } from "react";

import "./DropdownMenu.scss";

interface Props extends IconButtonProps {
  icon: ReactElement;
  items?: {
    label: string;
    path?: string;
    url?: string;
    divider?: boolean;
    hidden?: boolean;
    onClick?: () => void;
  }[];
  size?: "medium" | "small";
  tooltip?: string;
}

const DropdownMenu = ({
  icon,
  items,
  size = "medium",
  tooltip = "",
  ...props
}: Props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip} arrow>
        <IconButton
          ref={anchorRef}
          onClick={handleToggle}
          color="inherit"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          size={size}
          {...props}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} className="dropdown-menu__content">
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="dropdown-menu" onClick={handleClose}>
                  {(items || []).map(
                    ({
                      label,
                      path,
                      url,
                      divider = false,
                      hidden = false,
                      onClick,
                    }) =>
                      !hidden && (
                        <MenuItem
                          component={path ? NavLink : url ? "a" : "li"}
                          to={path}
                          href={url}
                          target={url && "_blank"}
                          key={label}
                          divider={divider}
                          disabled={!path && !url && !onClick}
                          onClick={onClick}
                        >
                          {label}
                        </MenuItem>
                      )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownMenu;
