import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import { Evaluation } from "./evaluation/Evaluation";
import { ExperimentsRoutesContainer } from "./experiments";
import { ExperimentCreate } from "./experiments/ExperimentCreate";
import { ExperimentsOverview } from "./experiments/ExperimentsOverview";
import { TrainingsOverview } from "./TrainingsOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").training;

export const TrainingsRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{
      organizationName: string;
      projectName: string;
    }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).training;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Training</BreadcrumbsItem>
      <Switch>
        <Route
          path={basePath.experiments.create.index()}
          component={ExperimentCreate}
          exact
        />
        <Route
          path={basePath.experiments[":experimentName"](
            ":experimentName"
          ).index()}
          component={ExperimentsRoutesContainer}
        />
        <Route path={basePath.index()}>
          <TrainingsOverview>
            <Switch>
              <Route
                exact
                path={basePath.experiments.index()}
                component={ExperimentsOverview}
              />
              <Route
                strict
                path={basePath.evaluation.index()}
                component={Evaluation}
              />
              <Redirect to={baseUrl.experiments.index()} />
            </Switch>
          </TrainingsOverview>
        </Route>

        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};
