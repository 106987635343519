import Plus from "@mui/icons-material/AddBoxRounded";
import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  MIN_TIME_OUT_REQUESTS,
  STRUCTURED_TYPE,
} from "libs/constants/constants";
import { explanations } from "libs/utilities/explanations";
import { formatRequestData } from "libs/utilities/input-parser";
import { isInRange } from "libs/utilities/util-functions";

import { InfoTooltip, SecondaryButton, TextField } from "components/atoms";

import RequestCreate from "../RequestCreate/RequestCreate";

import type {
  DeploymentDetail,
  DeploymentRequestsCreateDataBody,
  PipelineDetail,
} from "libs/data/models";

const REQUEST_KEY = "1";

type CreateDirectRequestSectionProps = {
  objectName: string;
  objectType: "deployment" | "pipeline";
  inputType?: DeploymentDetail["input_type"] | PipelineDetail["input_type"];
  inputFields?:
    | DeploymentDetail["input_fields"]
    | PipelineDetail["input_fields"];
  onSubmit: (args: DeploymentRequestsCreateDataBody) => void;
  requestTimeout: number;
  maxRequestTimeout: number;
  setRequestTimeout: (requestNumber: number) => void;
};

export const CreateDirectRequestSection = ({
  objectName,
  objectType,
  inputType = STRUCTURED_TYPE,
  inputFields = [],
  onSubmit,
  requestTimeout,
  setRequestTimeout,
  maxRequestTimeout,
}: CreateDirectRequestSectionProps) => {
  const formMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit } = formMethods;

  const onRequestSending = ({ requests = [] }) => {
    // @ts-ignore
    const request = requests?.[REQUEST_KEY];
    const requestData = formatRequestData(inputFields, inputType, request);

    return onSubmit(requestData);
  };

  const isDisabled = useMemo(
    () => !isInRange(requestTimeout, MIN_TIME_OUT_REQUESTS, maxRequestTimeout),
    [maxRequestTimeout, requestTimeout]
  );

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item container>
        <Grid item>
          <Typography variant="h3">Direct request</Typography>
        </Grid>
        <Grid item component={InfoTooltip}>
          Direct request is used to send one data point to the {objectType}.
        </Grid>
      </Grid>
      <Grid item>{explanations.directRequests.createDirectRequest}</Grid>
      <Grid item>
        <TextField
          name="timeout"
          error={isDisabled}
          helperText={
            isDisabled &&
            `Value should be between ${MIN_TIME_OUT_REQUESTS} and ${maxRequestTimeout}`
          }
          label="Timeout (seconds)"
          value={requestTimeout}
          onChange={(e) => setRequestTimeout(Number(e.target.value))}
          size="small"
          InputLabelProps={{ shrink: true }}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <form>
            <RequestCreate
              objectName={objectName}
              objectType={objectType}
              fieldName={`requests[${REQUEST_KEY}]`}
              inputType={inputType}
              fields={inputFields}
            />
          </form>
        </FormProvider>
      </Grid>
      <Grid item>
        <SecondaryButton
          startIcon={<Plus />}
          size="small"
          disabled={isDisabled}
          onClick={handleSubmit(onRequestSending)}
        >
          Create direct request
        </SecondaryButton>
      </Grid>
    </Grid>
  );
};
