import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LaunchIcon from "@mui/icons-material/Launch";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useMemo } from "react";

import { spacing } from "assets/styles/theme";
import { RequestResultSection } from "components/molecules/RequestResults/RequestResultSection";
import {
  CONTINUOUS_INSTANCES_DELAY,
  REQUEST_DATA_MAX_SIZE,
} from "libs/constants/constants";
import { RootUrlContext } from "libs/contexts";
import { useInstancesList } from "libs/data/endpoints/instances/instances";
import { useColorByStatus, useInterval, useLogsUrl } from "libs/hooks";
import {
  FULL_DATE_TIME_FORMAT,
  getFormattedDate,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { toUpperFirstCase } from "libs/utilities/util-functions";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { routes } from "routes";

import {
  Alert,
  CopyToClipboardButton,
  DetailsDialogItem,
  Divider,
  InfoAlert,
  Link,
  NavLink,
  OverflowTooltip,
  StatusIcon,
} from "components/atoms";

import { STATUS_FILTERS } from "../ActiveInstances/constants";
import { getCreatedByFromOrigin } from "./utils";

import type {
  DeploymentRequestSingleDetail,
  InputOutputFieldDetail,
} from "libs/data/models";

export type DeploymentRequestDetailsType = DeploymentRequestSingleDetail & {
  timeout: number;
};

interface DeploymentRequestResultsProps {
  organizationName: string;
  projectName: string;
  results: DeploymentRequestDetailsType;
  inputFields: InputOutputFieldDetail[];
  outputFields: InputOutputFieldDetail[];
  handleViewHugeResult: () => void;
}

export const DeploymentRequestDetails = ({
  organizationName,
  projectName,
  inputFields,
  outputFields,
  results,
  handleViewHugeResult,
}: DeploymentRequestResultsProps) => {
  const {
    id,
    status,
    error_message,
    time_created,
    time_started,
    time_completed,
    result,
    request_data: data,
    deployment,
    version,
    origin,
    timeout,
    input_size = undefined,
    output_size = undefined,
  } = results || {};

  const rootUrl = useContext(RootUrlContext);
  const colorByStatus = useColorByStatus();

  const { data: instances, mutate } = useInstancesList(
    projectName,
    deployment ?? "",
    version ?? "",
    { limit: 50 },
    {
      swr: { enabled: !!deployment && !!version },
    }
  );

  useInterval(
    () => {
      if (status === "pending") {
        mutate();
      }
    },
    [status, mutate],
    CONTINUOUS_INSTANCES_DELAY
  );

  const redirectUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName),
    [organizationName, projectName]
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_request_id: id,
      from_date: getFormattedDate(time_created),
      to_date: time_completed && getFormattedDate(time_completed),
      deployment_name: deployment,
      deployment_version: version,
    },
    redirectUrl: redirectUrl.index(),
  });

  const originIncludingType = {
    ...(origin || {}),
    type: origin?.request_schedule
      ? "request_schedule"
      : origin?.pipeline
      ? "pipeline"
      : "deployment",
  };

  const { createdBy, url: createdByUrl } = getCreatedByFromOrigin(
    // @ts-ignore
    originIncludingType,
    rootUrl
  );

  const isResultVisible =
    data ||
    result ||
    (input_size || 0) > REQUEST_DATA_MAX_SIZE ||
    (output_size || 0) > REQUEST_DATA_MAX_SIZE;

  const deploymentLink =
    deployment === TRAINING_DEPLOYMENT
      ? redirectUrl.training.experiments.index()
      : redirectUrl.deployments[":deploymentName"](deployment || "").index();

  const versionLink =
    deployment === TRAINING_DEPLOYMENT
      ? redirectUrl.training.experiments[":experimentName"](
          version || ""
        ).index()
      : redirectUrl.deployments[":deploymentName"](deployment || "")
          .versions[":versionName"](version || "")
          .index();

  const requestDetailsLink =
    deployment === TRAINING_DEPLOYMENT
      ? redirectUrl.training.experiments[":experimentName"](version || "")
          .runs[":runId"](id)
          .index()
      : redirectUrl.deployments[":deploymentName"](deployment || "")
          .versions[":versionName"](version || "")
          .requests.express[":requestId"](id)
          .index();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {error_message && (
          <Alert severity="error">Error: {error_message}</Alert>
        )}
      </Grid>
      <Grid item xs={6}>
        <DetailsDialogItem title="Request ID" isLoaded={!!id}>
          <Box display="flex" alignItems="center">
            <Link to={requestDetailsLink}>{id}</Link>
            <CopyToClipboardButton
              defaultLabel="Copy ID"
              contentToCopy={id}
              size="small"
              htmlColor="secondary"
              hoverColor="secondary"
            />
            <Tooltip title="View logs">
              <IconButton
                edge="end"
                component={NavLink}
                size="small"
                to={logsUrl}
                color="secondary"
              >
                <LogsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </DetailsDialogItem>
        <DetailsDialogItem title="Deployment" isLoaded={!!deployment}>
          <Link to={deploymentLink}>{deployment}</Link>
        </DetailsDialogItem>
        <DetailsDialogItem title="Version" isLoaded={!!version}>
          <Link to={versionLink}>{version}</Link>
        </DetailsDialogItem>
        <DetailsDialogItem title="Timeout" isLoaded={!!timeout}>
          {timeout} seconds
        </DetailsDialogItem>
        <Grid container spacing={2}>
          <Grid
            item
            xs={5}
            container
            alignItems={status === "pending" ? "flex-start" : "center"}
          >
            <Typography variant="h5">Status</Typography>
          </Grid>
          <Grid item xs={7}>
            <Box display="flex" flexDirection="column" gap={2}>
              <StatusIcon label={status ?? ""} status={status} />
              {status === "pending" && (
                <>
                  <InfoAlert style={{ width: spacing[256], marginBottom: 0 }}>
                    Assigning request to an instance
                  </InfoAlert>
                  <Box display="flex" gap={1}>
                    {STATUS_FILTERS.slice(1).map(({ title }) => (
                      <Chip
                        variant="outlined"
                        key={title}
                        size={"small"}
                        icon={
                          <FiberManualRecordIcon
                            style={{ color: colorByStatus(title) }}
                          />
                        }
                        label={`${
                          instances?.results.filter(
                            (instance) => instance.status === title
                          )?.length ?? 0
                        } ${toUpperFirstCase(title)}`}
                        style={{
                          borderColor: colorByStatus(title),
                        }}
                      />
                    ))}
                  </Box>
                  <Link
                    to={routes.organizations[":organizationName"](
                      organizationName
                    )
                      .projects[":projectName"](projectName)
                      .deployments[":deploymentName"](deployment ?? "")
                      .versions[":versionName"](version ?? "")
                      .activeInstances.index()}
                    target="_blank"
                  >
                    View all active instances of the deployment version
                    <LaunchIcon
                      style={{
                        fontSize: 12,
                        position: "relative",
                        top: "2px",
                        right: "0px",
                        marginLeft: "1px",
                      }}
                    />
                  </Link>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <DetailsDialogItem title="Created" isLoaded={!!time_created}>
          <OverflowTooltip component={Typography}>
            {getTzAwareDate(time_created).format(FULL_DATE_TIME_FORMAT)}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Started" isLoaded={!!time_started}>
          <OverflowTooltip component={Typography}>
            {getTzAwareDate(time_started || undefined).format(
              FULL_DATE_TIME_FORMAT
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Completed" isLoaded={!!time_completed}>
          <OverflowTooltip component={Typography}>
            {getTzAwareDate(time_completed || undefined).format(
              FULL_DATE_TIME_FORMAT
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Created by" isLoaded={!!createdBy}>
          <OverflowTooltip component={Typography}>
            {createdByUrl ? (
              <Link to={createdByUrl}>{createdBy}</Link>
            ) : (
              createdBy
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
      </Grid>
      {isResultVisible && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <RequestResultSection
              data={data}
              result={result}
              error={!!error_message}
              inputFields={inputFields}
              outputFields={outputFields}
              projectName={projectName}
              organizationName={organizationName}
              inputSize={input_size}
              outputSize={output_size}
              handleViewHugeResult={handleViewHugeResult}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
