import { Grid } from "@mui/material";

import {
  FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
  FIELD_INSTANCE_PROCESSES,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";
import { useGetOrganizationFeatures } from "store/features/organizations";

import { FormTextField } from "components/atoms";
import { FormSection } from "components/molecules";

import type { DeploymentVersionDetail } from "libs/data/models";

type DeploymentVersionAdvancedParametersProps = {
  version?: DeploymentVersionDetail;
  supportsRequestFormat?: boolean;
};

export const DeploymentVersionAdvancedParameters = ({
  version,
  supportsRequestFormat,
}: DeploymentVersionAdvancedParametersProps) => {
  const organizationFeatures = useGetOrganizationFeatures();

  return supportsRequestFormat ? (
    <FormSection
      title="Scaling & Resource settings"
      description={explanations.deployments.versions.scaling}
    >
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY}
            id={FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY}
            label="Minimum number of instances"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            tooltip={explanations.deployments.versions.minInstances}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY}
            id={FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY}
            label="Maximum number of instances"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            tooltip={explanations.deployments.versions.maxInstances}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_IDLE_TIME}
            id={FIELD_DEPLOYMENT_VERSION_IDLE_TIME}
            label="Maximum idle time (seconds)"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_IDLE_TIME]}
            type="number"
            InputProps={{
              inputProps: { min: 10 },
            }}
            tooltip={explanations.deployments.versions.maxIdleTime}
            fullWidth
          />
        </Grid>
        <Grid item>
          {organizationFeatures && (
            <FormTextField
              name={FIELD_INSTANCE_PROCESSES}
              id={FIELD_INSTANCE_PROCESSES}
              label="Request concurrency per instance"
              placeholder="3"
              defaultValue={version?.instance_processes ?? 1}
              type="number"
              rules={{
                min: {
                  value: 0,
                  message: validators.minimum_instance_processes.message(0),
                },
                max: {
                  value:
                    organizationFeatures?.max_deployment_instance_processes,
                  message: validators.maximum_instance_processes.message(
                    organizationFeatures?.max_deployment_instance_processes
                  ),
                },
              }}
              tooltip={explanations.deployments.versions.concurrency}
            />
          )}
        </Grid>
      </Grid>
    </FormSection>
  ) : (
    <FormSection
      title="Scaling & Resource settings"
      description={explanations.deployments.versions.dockerScaling}
    >
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY}
            id={FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY}
            label="Number of instances"
            defaultValue={version?.[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            tooltip={explanations.deployments.versions.numberOfInstances}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
