import { Box, Card, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { isEmpty, isObject } from "lodash";
import { useEffect, useState } from "react";

import { getBorders } from "assets/styles/theme";
import {
  DICT_TYPE,
  FILE_TYPE,
  STRUCTURED_TYPE,
  TAB_JSON_EDITOR,
  TAB_VISUAL_EDITOR,
} from "libs/constants/constants";
import {
  formatInput,
  JSON_EDITOR,
  VISUAL_EDITOR,
} from "libs/utilities/input-parser";
import { fieldTypesLabels } from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";

import { ControlledTextarea } from "components/atoms";
import {
  ControlledFileInputField,
  ControlledTextInput,
  FormikTextInput,
} from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { FormikProps, FormikValues } from "formik";
import type {
  DeploymentListOutputType,
  InputOutputFieldDetail,
} from "libs/data/models";
import type { ChangeEvent } from "react";

type ControlledRequestCreateProps = {
  defaultFormFields?: { [key: string]: any };
  fieldName: string;
  control: FormikProps<FormikValues>;
  objectName: string;
  objectType: "deployment" | "pipeline";
  inputType: DeploymentListOutputType;
  fields: InputOutputFieldDetail[];
};

const JSON_AREA_NAME = "jsonAreaName";

export const ControlledRequestCreate = ({
  defaultFormFields = {},
  fields,
  inputType,
  objectName,
  objectType,
  fieldName,
  control,
  ...props
}: ControlledRequestCreateProps) => {
  const theme = useTheme() as AppThemeProps;
  const [editorField, setEditorField] =
    useState<"visualEditor" | "jsonEditor">(VISUAL_EDITOR);
  const [values, setValues] =
    useState<{ [key: string]: any }>(defaultFormFields);
  const [jsonValue, setJsonValue] = useState(
    JSON.stringify(defaultFormFields, null, 1)
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setValues(defaultFormFields);
    setJsonValue(JSON.stringify(defaultFormFields, null, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleEditorChange = (
    _event: ChangeEvent<any>,
    newValue: typeof editorField
  ) => {
    if (Object.values(errors).some((error) => !!error)) return;

    setEditorField(newValue);
    if (newValue === JSON_EDITOR) {
      const formattedValues = formatInput(fields, values);
      setJsonValue(JSON.stringify(formattedValues, null, 1));
    }
  };

  const handleChange = (
    name: string,
    dataType: keyof typeof fieldTypesLabels | "json",
    value: any
  ) => {
    control.setFieldTouched(name, true);
    const validation = validators[dataType]?.validate?.(value);

    if (typeof validation === "string") {
      setErrors({ ...errors, [name]: validation });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    const newValues = { ...values, [name]: value };
    setValues(newValues);
    control.setFieldValue(fieldName, newValues);
  };

  const handleJsonChange = (value: string) => {
    const validation = validators.json.validate?.(value);

    if (typeof validation === "string") {
      setErrors({ ...errors, [JSON_AREA_NAME]: validation });
    } else {
      setErrors({ ...errors, [JSON_AREA_NAME]: "" });
      setValues(JSON.parse(value));
      control.setFieldValue(fieldName, JSON.parse(value));
    }
    setJsonValue(value);
  };

  return inputType === STRUCTURED_TYPE ? (
    <div {...props}>
      {!fields.length ? (
        <>
          <Typography variant="caption">No data required.</Typography>
        </>
      ) : (
        <Card variant="outlined">
          <Tabs
            value={editorField}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleEditorChange}
            variant="fullWidth"
          >
            <Tab
              sx={{ borderBottom: getBorders(theme).secondary }}
              label="Visual editor"
              id={VISUAL_EDITOR}
              aria-controls={VISUAL_EDITOR}
              value={VISUAL_EDITOR}
            />
            <Tab
              sx={{ borderBottom: getBorders(theme).secondary }}
              label="JSON editor"
              id={JSON_EDITOR}
              aria-controls={JSON_EDITOR}
              value={JSON_EDITOR}
            />
          </Tabs>
          <Box p={2}>
            <div
              hidden={editorField !== VISUAL_EDITOR}
              id={VISUAL_EDITOR}
              aria-labelledby={TAB_VISUAL_EDITOR}
            >
              {editorField === VISUAL_EDITOR &&
                fields.map(({ name, data_type }, fieldKey) =>
                  data_type === FILE_TYPE ? (
                    <ControlledFileInputField
                      key={objectName}
                      label={`${name} (${fieldTypesLabels[data_type]})`}
                      name={`${fieldName}.${name}`}
                      objectName={objectName}
                      objectType={objectType}
                      value={values[name]}
                      setValue={(file) => handleChange(name, data_type, file)}
                    />
                  ) : data_type === DICT_TYPE ? (
                    <ControlledTextInput
                      maxRows={10}
                      minRows={1}
                      multiline
                      label={`${name} (${fieldTypesLabels[data_type]})`}
                      name={`${fieldName}.${name}`}
                      error={errors?.[name]}
                      key={fieldKey}
                      value={
                        isObject(values[name])
                          ? JSON.stringify(values[name])
                          : values[name]
                      }
                      onChange={(value) => handleChange(name, data_type, value)}
                    />
                  ) : (
                    <ControlledTextInput
                      rows={1}
                      name={`${fieldName}.${name}`}
                      label={`${name} (${fieldTypesLabels[data_type]})`}
                      error={errors?.[name]}
                      key={fieldKey}
                      value={values[name]}
                      onChange={(value) => handleChange(name, data_type, value)}
                    />
                  )
                )}{" "}
            </div>
            <div
              hidden={editorField !== JSON_EDITOR}
              id={JSON_EDITOR}
              aria-labelledby={TAB_JSON_EDITOR}
            >
              <ControlledTextarea
                error={errors?.[JSON_AREA_NAME]}
                onChange={(value) => handleJsonChange(value as string)}
                value={jsonValue}
                maxRows={40}
              />
            </div>
          </Box>
        </Card>
      )}
    </div>
  ) : (
    <div {...props}>
      <FormikTextInput
        name={fieldName}
        control={control}
        rows={5}
        label="Plain"
        defaultValue={isEmpty(values) ? null : values}
      />
    </div>
  );
};
