import Plus from "@mui/icons-material/AddBoxRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import { IconButton, Link, Tooltip } from "@mui/material";
import { filter } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import { IlluRoles } from "assets/images/IlluRoles";
import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { useRoleAssignmentsDelete } from "libs/data/customized/roles";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { useGetPermissions } from "store/features/permissions";

import { Loader, PrimaryButton } from "components/atoms";
import { BaseTable, EmptyOverview } from "components/molecules";
import { RoleAssignmentDialog, RoleDetailsDialog } from "components/organisms";

import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  ProjectUserList,
  RoleAssignmentList,
  ServiceUserList,
} from "libs/data/models";

type TokenRolesProps = {
  currentUser?: ProjectUserList | ServiceUserList;
  title?: string;
  isUsedInForm?: boolean;
};

export const TokenRoles = ({
  currentUser,
  title = "",
  isUsedInForm = false,
}: TokenRolesProps) => {
  const { userId, projectName } =
    useParams<{ projectName: string; userId: string }>();
  const match = useRouteMatch();
  const [roleAssignments, setRoleAssignments] = useState<RoleAssignmentList[]>(
    []
  );
  const [currentRole, setCurrentRole] = useState("");
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [roleAssignmentDialogOpen, setRoleAssignmentDialogOpen] =
    useState(false);

  const { data: userRoles } = useRoleAssignmentsPerObjectList(projectName, {
    assignee: userId ?? currentUser?.id,
    assignee_type: "user",
  });

  const roleAssignmentsDelete = useRoleAssignmentsDelete(
    projectName,
    userId ?? currentUser?.id
  );

  const [currentPermissions] = useGetPermissions();

  const onRoleDetailsClick = useCallback(
    (role: any) => {
      setCurrentRole(role);
      setShowRoleDialog(!showRoleDialog);
    },
    [setCurrentRole, setShowRoleDialog, showRoleDialog]
  );
  const onDelete = useCallback(
    async (userRole: RoleAssignmentList) => {
      await roleAssignmentsDelete(userRole.id);
    },
    [roleAssignmentsDelete]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "Role",
        field: "role",
        width: "30%",
        nowrap: true,
        render: (rowData: RoleAssignmentList) =>
          currentPermissions[USER_ROLE_PERMISSIONS["role_get"]] ? (
            <Link component="button" variant="h5">
              {rowData.role}
            </Link>
          ) : (
            rowData.role
          ),
        defaultSort: "asc",
      },
      {
        title: "Role level",
        field: "resource_type",
        width: "25%",
        nowrap: true,
      },
      {
        title: "Applied to",
        field: "resource",
        width: "25%",
        nowrap: true,
      },
      {
        disableClick: true,
        align: "right",
        width: "5%",
        render: (rowData: RoleAssignmentList) => {
          return (
            <div className="actions_container">
              <Tooltip title="Remove role">
                <span>
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDelete(rowData);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }, [currentPermissions, onDelete]);

  useEffect(() => {
    if (userRoles) {
      setRoleAssignments(filter(userRoles, { assignee: currentUser?.id }));
    }
  }, [userRoles, currentUser]);

  return (
    <div>
      <BreadcrumbsItem to={match.url}>{title}</BreadcrumbsItem>
      {!userRoles ? (
        <Loader />
      ) : roleAssignments.length ? (
        <BaseTable
          title={isUsedInForm ? "" : title}
          columns={columns as BaseColumn[]}
          data={roleAssignments}
          action={
            <PrimaryButton
              startIcon={<Plus />}
              disabled={
                !currentPermissions[USER_ROLE_PERMISSIONS["assignment_create"]]
              }
              onClick={() => setRoleAssignmentDialogOpen(true)}
            >
              Assign role
            </PrimaryButton>
          }
          onRowClick={
            currentPermissions[USER_ROLE_PERMISSIONS["role_get"]]
              ? (
                  _: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
                  rowData: RoleAssignmentList
                ) => onRoleDetailsClick(rowData.role)
              : undefined
          }
          hasSearchField={false}
        />
      ) : (
        <EmptyOverview
          isAllowed={
            !!currentPermissions[USER_ROLE_PERMISSIONS["assignment_create"]]
          }
          onClick={() => setRoleAssignmentDialogOpen(true)}
          message="No roles to display."
          buttonLabel="Assign roles to user"
          Illustration={IlluRoles}
        />
      )}
      <RoleAssignmentDialog
        currentUser={currentUser}
        toggleDialog={() =>
          setRoleAssignmentDialogOpen(!roleAssignmentDialogOpen)
        }
        isOpen={roleAssignmentDialogOpen}
      />
      <RoleDetailsDialog
        currentRole={currentRole}
        open={showRoleDialog}
        toggleDialog={() => setShowRoleDialog(!showRoleDialog)}
      />
    </div>
  );
};
