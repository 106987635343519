import { useState } from "react";

import { FIELD_ASSIGNEE, FIELD_ROLE } from "libs/constants/fields";
import { BucketPermissionsFormSection } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/formSections/BucketPermissionsFormSection";

import type { BucketList, RoleAssignmentList } from "libs/data/models";
import type { DeploymentBucketRole } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/formSections/BucketPermissionsFormSection";

export interface BucketPermissionsWithDeploymentProps {
  buckets: BucketList[];
  permissions?: RoleAssignmentList[];
}

export const BucketPermissionsWithoutDeployment = ({
  buckets,
  permissions: defaultPermissions,
}: BucketPermissionsWithDeploymentProps) => {
  const [permissions, setPermissions] = useState<DeploymentBucketRole[]>(
    defaultPermissions?.map((permission) => ({
      [FIELD_ASSIGNEE]: permission.resource as string,
      [FIELD_ROLE]: permission.role,
    })) ?? []
  );

  const handleOnAdd = (role: DeploymentBucketRole) => {
    setPermissions((permissions) => [...permissions, role]);
  };

  const handleOnDelete = (role: DeploymentBucketRole) => {
    setPermissions((permissions) =>
      permissions.filter(
        (permission) =>
          permission.role !== role.role || permission.assignee !== role.assignee
      )
    );
  };

  return (
    <BucketPermissionsFormSection
      buckets={buckets}
      onAdd={handleOnAdd}
      onDelete={handleOnDelete}
      permissions={permissions}
    />
  );
};
