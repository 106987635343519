import { isArray, isEqualWith, isObject } from "lodash";

const isEqual = (newObj?: string, oldObj?: string) => {
  if (
    (newObj === null && oldObj === "") ||
    (newObj === "" && oldObj === null)
  ) {
    return true;
  }
  if (oldObj?.toString() === newObj) {
    return true;
  }

  return;
};

export const getChanges = (
  newObject: Record<string, string>,
  oldObject: Record<string, string>
) => {
  const changes = {};

  if (isEqualWith(newObject, oldObject, isEqual)) {
    return changes;
  }

  for (const key in newObject) {
    const newValue = newObject[key];
    const oldValue = oldObject[key];
    if (!isEqualWith(newValue, oldValue, isEqual)) {
      if (newValue && !isArray(newValue) && isObject(newValue)) {
        // @ts-ignore
        changes[key] = {};
        // @ts-ignore
        for (const key2 in newValue) {
          // @ts-ignore
          if (!isEqualWith(newValue[key2], oldValue[key2], isEqual)) {
            // @ts-ignore
            changes[key][key2] = newValue[key2];
          }
        }
      } else {
        // @ts-ignore
        changes[key] = newValue;
      }
    }
  }

  return changes;
};
