import dagre from "dagre";
import { Position } from "reactflow";

import { NodeTypes } from "./types";

import type { Edge, Node } from "reactflow";
import type { NodeDataType } from "./types";

export const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

export const getLayoutedElements = (
  nodes: Node<NodeDataType>[],
  edges: Edge[],
  shouldReset = false
) => {
  if (shouldReset) {
    nodes.forEach((node) => {
      dagreGraph.removeNode(node.id);
    });
  }

  dagreGraph.setGraph({ rankdir: "LR" });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.width || nodeWidth,
      height: node.height || nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;

    if (shouldReset) {
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - (node.width || nodeWidth) / 2,
        y: nodeWithPosition.y - (node.height || nodeHeight) / 2,
      };
      if (nodes.length === 2) {
        if (node.id === NodeTypes.pipeline_start) {
          node.position.x = 0;
          node.position.y = 0;
        }
        if (node.id === NodeTypes.pipeline_end) {
          node.position.x = 200;
          node.position.y = 0;
        }
      }
    }

    return node;
  });

  return { nodes, edges };
};
