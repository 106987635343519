import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { checkPermissionToRefreshAccessToken } from "libs/data/axios/utilities";
import { env, ENV_NAMES } from "libs/env";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

const initialToken = env.get(ENV_NAMES.ACCESS_TOKEN);

const initialState: {
  email: string | null;
  error: string | null;
  provider: string | null;
  url: string | null;
  oauthKey?: string;
  isAuthenticated: boolean;
  accessToken: string | null;
  // activationToken: string | null;
} = {
  email: null,
  error: null,
  provider: null,
  url: null,
  isAuthenticated:
    Boolean(initialToken) || Boolean(checkPermissionToRefreshAccessToken(env)),
  accessToken: initialToken,
  // activationToken: null,
};

export const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setProvider: (state, action: PayloadAction<string>) => {
      state.provider = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setOauthKey: (state, action: PayloadAction<string | undefined>) => {
      state.oauthKey = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    clearAuthState: (state) => {
      state.email = null;
      state.error = null;
      state.provider = null;
      state.url = null;
      state.isAuthenticated = false;
    },
  },
});

export const useAuthentication = () => {
  return useSelector((store: RootState) => store.authentication);
};

export const {
  setEmail,
  setError,
  setProvider,
  setUrl,
  setOauthKey,
  setIsAuthenticated,
  clearAuthState,
} = authentication.actions;

export default authentication.reducer;
