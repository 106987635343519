import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";

import { refreshToken } from "./refresh-token";
import {
  checkPermissionToRefreshAccessToken,
  doesAccessTokenExpireSoon,
  getAuthorizationHeader,
} from "./utilities";

import type { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

export const axiosRequestInterceptorRefreshAccessTokenBeforeItExpires = (
  config: AxiosRequestConfig
) => {
  const accessToken = env.get(ENV_NAMES.ACCESS_TOKEN);

  if (accessToken) {
    const tokenIsExpiringSoon = doesAccessTokenExpireSoon(accessToken);

    if (tokenIsExpiringSoon && checkPermissionToRefreshAccessToken(env)) {
      return refreshToken(config);
    }

    config.headers = {
      ...(config?.headers || {}),
      Authorization: getAuthorizationHeader(accessToken),
    } as AxiosRequestHeaders;
  }

  return config;
};
