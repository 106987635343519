import BackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, Grid, IconButton } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { useGoogleAnalytics } from "libs/hooks";
import validators from "libs/utilities/validators";
import { setError, useAuthentication } from "store/features/authentication";

import {
  AuthenticationLink,
  FormTextField,
  SecondaryButton,
} from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import { useAuthorize } from "../../../libs/data/customized/authorize/useAuthorize";

const SignInPassword = () => {
  useGoogleAnalytics();
  const { email, error } = useAuthentication();
  const { authorize, isLoading } = useAuthorize();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit } = methods;
  const history = useHistory();

  useEffect(() => {
    if (!email) {
      history.replace("/sign-in");
    }
  }, [email, history]);

  const handleLogin = ({ password }: { password: string }) => {
    if (email && password) {
      dispatch(setError(null));
      authorize({ email, password });
    }
  };

  const goBack = () => {
    dispatch(setError(null));
    history.push("/sign-in");
  };

  return (
    <AuthenticationContainer
      title="Sign in with your UbiOps account"
      info={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="nowrap"
          mt={1}
        >
          <Box>
            <IconButton onClick={goBack}>
              <BackIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box>{email}</Box>
        </Box>
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid container direction="column">
            {error && (
              <Alert
                variant="filled"
                sx={{
                  marginBottom: spacing[16],
                }}
                severity="error"
              >
                {error}
              </Alert>
            )}
            <FormTextField
              name="password"
              type="password"
              id="password"
              label="Password"
              autoFocus
              rules={{
                required: validators.required.message("password"),
              }}
            />
            <Box component="span" my={1}>
              <AuthenticationLink to="/reset-password">
                Forgot your password?
              </AuthenticationLink>
            </Box>
            <SecondaryButton type="submit" disabled={isLoading}>
              Sign in
            </SecondaryButton>
          </Grid>
        </form>
      </FormProvider>
    </AuthenticationContainer>
  );
};

export default SignInPassword;
