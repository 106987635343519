import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";

import { spacing } from "assets/styles/theme";

import { HighlightedText } from "components/atoms";

import type { AlertProps } from "@mui/material";
import type {
  ImportDetailDeployments,
  ImportDetailPipelines,
} from "libs/data/models";
import type { EntityType } from "./types";
import type { EntityTypeNames, NewNamesLayer } from "./useImportConflicts";

interface Props {
  entities: ImportDetailDeployments | ImportDetailPipelines | undefined;
  title: string;
  entityType: string;
  entityRequirementType?: string;
  newNamesLayer: NewNamesLayer;
  openConflictDialog?: (type: string, name: string) => void;
  isMissingEnvironmentVariables?: (name: string) => boolean;
  isEntityNameConflict?: (name: string) => boolean;
  isEntityRequired?: (name: string) => boolean;
  isEntitySelected: (name: string) => boolean;
  isVersionSelected: (name: string, versionName: string) => boolean;
  toggleEntity: (name: string, isAdd: boolean) => void;
  toggleVersion: (name: string, versionName: string, isAdd: boolean) => void;
  toggleAll?: (isAdd: boolean) => void;
  onRowExpand?: (name: string) => void;
  disableAutoToggle?: boolean;
  innerScroll?: boolean;
}

type ImportWarningProps = AlertProps & {
  onSolve: () => void;
};

const ImportWarning = ({ children, onSolve }: ImportWarningProps) => {
  const theme = useTheme();
  const color = theme.palette.warning.dark;

  return (
    <Alert
      severity="warning"
      icon={<WarningIcon sx={{ color }} />}
      sx={{
        background: "transparent",
        padding: 0,
        color,
        paddingLeft: spacing[16],
        "& > .MuiAlert-message": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        },
        "& > *": {
          padding: 0,
        },
      }}
    >
      {children},
      <strong
        onClick={(e) => {
          e.stopPropagation();
          onSolve && onSolve();
        }}
        style={{ textDecoration: "underline", marginLeft: spacing[4] }}
      >
        click here to solve
      </strong>
    </Alert>
  );
};

export const ImportConfirmSection = ({
  entities,
  title,
  entityType,
  openConflictDialog,
  newNamesLayer,
  isEntityNameConflict,
  isMissingEnvironmentVariables,
  entityRequirementType,
  isEntityRequired,
  isEntitySelected,
  isVersionSelected,
  toggleEntity,
  toggleVersion,
  toggleAll,
  onRowExpand,
  disableAutoToggle,
  innerScroll,
}: Props) => {
  const entityNames = Object.keys(entities || {});

  // auto toogle entities if they're not yet selected but required
  useEffect(() => {
    entityNames.forEach((entityName) => {
      if (!isEntitySelected(entityName) && isEntityRequired?.(entityName)) {
        if (!disableAutoToggle) toggleEntity(entityName, true);
      }
    });
  }, [
    disableAutoToggle,
    entityNames,
    isEntityRequired,
    isEntitySelected,
    toggleEntity,
  ]);

  const isAllSelected = useMemo(
    () => entityNames.every((name: string) => isEntitySelected(name)),
    [entityNames, isEntitySelected]
  );

  const isSomeSelected = useMemo(
    () => entityNames.some((name: string) => isEntitySelected(name)),
    [entityNames, isEntitySelected]
  );

  const isEveryVersionSelected = useCallback(
    (name: string) => {
      const versions = Object.keys(entities?.[name]?.versions || {});

      return versions.every((version) => isVersionSelected(name, version));
    },
    [entities, isVersionSelected]
  );

  const showVersions = useCallback(
    (name: string) =>
      Object.keys(entities?.[name]?.versions || {}).length > 0 ||
      entities?.[name]?.versions,
    [entities]
  );

  const entityTypeString = title.toLocaleLowerCase();

  const getEntityFinalName = (name: string) =>
    (newNamesLayer[entityTypeString as EntityType] as EntityTypeNames)?.[
      name
    ] || name;

  return (
    <Grid>
      <Grid style={{ marginLeft: spacing[4], marginBottom: 0 }}>
        <Typography variant="h4">{title}</Typography>
        {toggleAll && (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(isAllSelected)}
                indeterminate={isSomeSelected && !isAllSelected}
                onChange={(event) => toggleAll(event.target.checked)}
              />
            }
            label={<Typography variant="h5">Select all</Typography>}
          />
        )}
      </Grid>
      <Grid
        style={innerScroll ? { maxHeight: 320, overflow: "scroll" } : undefined}
      >
        {Object.keys(entities || {}).map((name) => (
          <Accordion
            key={name}
            disableGutters
            elevation={0}
            onChange={() => onRowExpand?.(name)}
            sx={{
              marginBottom: `${spacing[8]} !important`,
              marginLeft: showVersions(name) ? 0 : spacing[28],
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                padding: 0,
                justifyContent: "start",
                alignItems: "center",
                minHeight: spacing[32],
                flexDirection: "row-reverse",
                "& > .MuiAccordionSummary-content": {
                  flexGrow: "unset",
                  margin: 0,
                  marginRight: spacing[16],
                },
              }}
              expandIcon={showVersions(name) && <ExpandMoreIcon />}
            >
              <Grid container alignItems="center" flexWrap="nowrap">
                <Grid>
                  <Tooltip
                    title={
                      isEntityRequired?.(name)
                        ? `This ${entityType} is used by one of the ${entityRequirementType}. You cannot remove it.`
                        : ""
                    }
                  >
                    <span>
                      <Checkbox
                        disabled={isEntityRequired?.(name)}
                        checked={isEntitySelected(name)}
                        indeterminate={
                          isEntitySelected(name) &&
                          !isEveryVersionSelected(name) &&
                          !isEntityRequired?.(name)
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          // using onclick instead of onChange to prevent
                          // accordion open after entity toggle
                          // @ts-ignore
                          toggleEntity(name, event.target.checked);
                        }}
                        style={{
                          padding: 0,
                          marginRight: spacing[8],
                          marginLeft: spacing[8],
                        }}
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Typography variant="h4">{getEntityFinalName(name)}</Typography>
                {isEntityNameConflict?.(name) &&
                  !isMissingEnvironmentVariables?.(name) && (
                    <ImportWarning
                      onSolve={() =>
                        openConflictDialog?.(entityTypeString, name)
                      }
                    >
                      Name conflict
                    </ImportWarning>
                  )}
                {isMissingEnvironmentVariables?.(name) &&
                  !isEntityNameConflict?.(name) && (
                    <ImportWarning
                      onSolve={() =>
                        openConflictDialog?.(entityTypeString, name)
                      }
                    >
                      Missing environment variables
                    </ImportWarning>
                  )}
                {isMissingEnvironmentVariables?.(name) &&
                  isEntityNameConflict?.(name) && (
                    <ImportWarning
                      onSolve={() =>
                        openConflictDialog?.(entityTypeString, name)
                      }
                    >
                      Multiple conflicts
                    </ImportWarning>
                  )}
              </Grid>
            </AccordionSummary>
            {showVersions(name) && (
              <AccordionDetails
                style={{
                  marginLeft: spacing[32],
                  paddingBottom: 0,
                }}
              >
                {Object.keys(entities?.[name]?.versions || {}).map(
                  (versionName: string) => (
                    <Grid
                      container
                      key={versionName}
                      alignItems="center"
                      marginBottom={spacing[8]}
                      flexWrap="nowrap"
                    >
                      {entities?.[name]?.versions[versionName] && (
                        <Grid>
                          <Checkbox
                            disabled={
                              isEntityRequired?.(name) &&
                              isVersionSelected(name, versionName)
                            }
                            checked={isVersionSelected(name, versionName)}
                            onChange={(event) =>
                              toggleVersion(
                                name,
                                versionName,
                                event.target.checked
                              )
                            }
                            style={{
                              padding: 0,
                              marginRight: spacing[8],
                              marginLeft: spacing[8],
                            }}
                          />
                        </Grid>
                      )}
                      {!entities?.[name]?.versions[versionName] && (
                        <CircularProgress
                          size={spacing[16]}
                          sx={{
                            marginRight: spacing[8],
                          }}
                        />
                      )}
                      <Typography variant="h4">
                        {versionName}
                        {entities?.[name]?.versions[versionName]?.default && (
                          <HighlightedText>default</HighlightedText>
                        )}
                      </Typography>
                    </Grid>
                  )
                )}
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};
