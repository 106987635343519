import { FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Skeleton from "react-loading-skeleton";

import { predefinedLabels } from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";

import { AddButton, FormTextField, LabelChip } from "components/atoms";

import { LabelKeyField } from "./LabelKeyField";
import { LabelsPreselect } from "./LabelsPreselect";

type LabelsFormProps = {
  name: string;
  type?: string;
  loading?: boolean;
};

export const LabelsForm = ({ name, type, loading }: LabelsFormProps) => {
  const [suggestions, setSuggestions] = useState(predefinedLabels);
  const FIELD_LABEL_KEY = `${name}_key`;
  const FIELD_LABEL_VALUE = `${name}_value`;

  const { control, getValues, register, setValue, trigger, errors, setError } =
    useFormContext();

  const [warningText, setWarningText] = useState(false);
  const [timeoutObject, setTimeoutObject] =
    useState<NodeJS.Timeout | null>(null);

  const {
    fields: labelsFields,
    append: labelsAppend,
    remove: labelsRemove,
  } = useFieldArray({
    control,
    name,
  });

  const {
    fields: preselectFields,
    append: preselectAppend,
    remove: preselectRemove,
  } = useFieldArray({
    control,
    name: `${name}_preselectLabels`,
  });

  const onLabelAdd = async () => {
    const result = await trigger([FIELD_LABEL_VALUE, FIELD_LABEL_KEY]);
    const values = getValues();
    if (result && values[FIELD_LABEL_KEY] && values[FIELD_LABEL_VALUE]) {
      const uniqueKeys = labelsFields.every(
        (labelKey) => labelKey.key !== values[FIELD_LABEL_KEY].value
      );
      if (uniqueKeys) {
        labelsAppend({
          key: values[FIELD_LABEL_KEY].value,
          value: values[FIELD_LABEL_VALUE],
        });
        setValue(FIELD_LABEL_VALUE, null);
        setValue(FIELD_LABEL_KEY, null);
      } else {
        setError(FIELD_LABEL_KEY, {
          type: "manual",
          message: validators.uniqueKeys.message,
        });
      }
    } else {
      if (!values[FIELD_LABEL_KEY]) {
        setError(FIELD_LABEL_KEY, {
          type: "required",
          message: validators.required.message("key"),
        });
      }
      if (!values[FIELD_LABEL_VALUE]) {
        setError(FIELD_LABEL_VALUE, {
          type: "required",
          message: validators.required.message("value"),
        });
      }
    }
  };

  const onLabelRemove = (index: number, key: string, value: string) => () => {
    labelsRemove(index);
    const duplicatedLabels = suggestions.some(
      (label) => label.key === key && label.value === value
    );
    const predefinedLabel = predefinedLabels.some(
      (label) => label.key === key && label.value === value
    );
    if (!duplicatedLabels && predefinedLabel) {
      preselectAppend({ key, value });
    }
    setWarningText(false);
  };

  const onLabelClick = (index: number, key?: string, value?: string) => {
    const uniqueKeys = labelsFields.every((label) => label.key !== key);

    if (uniqueKeys) {
      preselectRemove(index);
      labelsAppend({ key, value });
      setWarningText(false);
      setSuggestions((_suggestions) =>
        _suggestions.filter((s) => !(s.key === key && s.value === value))
      );
    } else {
      setWarningText(true);
      setTimeoutObject(
        setTimeout(() => {
          setWarningText(false);
        }, 2000)
      );
    }
  };

  useEffect(() => {
    return () => {
      timeoutObject ? clearTimeout(timeoutObject) : null;
    };
  }, [timeoutObject]);

  const handlePressEnter =
    (field: string) =>
    (event: {
      keyCode: number;
      which: number;
      key: string;
      preventDefault: () => void;
    }) => {
      const keyCode = event.keyCode ?? event.which;
      if (event.key === "Enter" || keyCode === 13) {
        event.preventDefault();
        if (field === "value") onLabelAdd();
      }
    };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ paddingBottom: 12 }}>
        <LabelsPreselect
          onLabelClick={onLabelClick}
          preselectFields={preselectFields}
          preselectAppend={preselectAppend}
          type={type}
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelKeyField
          handleOnPressEnter={handlePressEnter("label")}
          name={FIELD_LABEL_KEY}
          error={errors[FIELD_LABEL_KEY]?.message}
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          name={FIELD_LABEL_VALUE}
          label="Value"
          rules={{
            pattern: {
              value: validators.labelValue.pattern,
              message: validators.labelValue.message,
            },
          }}
          withError={false}
          size="small"
          margin="none"
          placeholder={`Ex: "connector"`}
          onKeyPress={handlePressEnter("value")}
          loading={loading}
        />
      </Grid>
      {(errors[FIELD_LABEL_KEY]?.message ||
        errors[FIELD_LABEL_VALUE]?.message) && (
        <Grid container item xs={12} style={{ marginTop: -4 }}>
          <Grid item xs={6}>
            <FormHelperText
              id={FIELD_LABEL_KEY}
              error={!!errors[FIELD_LABEL_KEY]}
              hidden={!errors[FIELD_LABEL_KEY]}
            >
              {errors[FIELD_LABEL_KEY]?.message}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <FormHelperText
              id={FIELD_LABEL_VALUE}
              error={!!errors[FIELD_LABEL_VALUE]}
              hidden={!errors[FIELD_LABEL_VALUE]}
            >
              {errors[FIELD_LABEL_VALUE]?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} style={{ paddingTop: 8 }}>
        {loading ? (
          <Skeleton width={100} height={40} />
        ) : (
          <AddButton onClick={onLabelAdd}>Add label</AddButton>
        )}
      </Grid>
      <Grid item xs={12}>
        {labelsFields.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="caption">No labels specified</Typography>
          </Grid>
        )}
        <Grid container direction="row" item xs={12}>
          <Grid item xs={12} container alignItems="center" spacing={1}>
            {labelsFields.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="caption">Added labels:</Typography>
              </Grid>
            )}
            {labelsFields.map((item, index) => (
              <Grid item key={index}>
                <LabelChip
                  label={item.key}
                  value={item.value}
                  onDelete={onLabelRemove(index, item.key, item.value)}
                />
                <input
                  type="hidden"
                  name={`${name}[${index}].key`}
                  defaultValue={item.key}
                  ref={register()}
                />
                <input
                  type="hidden"
                  name={`${name}[${index}].value`}
                  defaultValue={item.value}
                  ref={register()}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              {warningText && (
                <Typography color="error" variant="caption">
                  {validators.uniqueKeys.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
