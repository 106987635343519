/* eslint-disable react/prop-types */
import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_ADVANCED_PERMISSIONS,
  FIELD_CREDIT_LIMIT,
  FIELD_CREDIT_LIMIT_ENABLED,
  FIELD_PROJECT_NAME,
} from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import {
  useProjectDelete,
  useProjectUpdate,
} from "libs/data/customized/projects";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { useGetProjects } from "store/features/organizationProjects";
import {
  useGetCurrentOrganization,
  useGetOrganizationFeatures,
} from "store/features/organizations";

import { Loader, PrimaryButton, TableLink } from "components/atoms";
import { BaseTable, PageContainer } from "components/molecules";
import { UpgradeSubscriptionDialog } from "components/organisms";

import { AddNewProjectDialog } from "./AddNewProjectDialog";
import { DeleteProjectDialog } from "./DeleteProjectDialog";
import { EditProjectDialog } from "./EditProjectDialog";

const ProjectsOverview = () => {
  useGoogleAnalytics();
  const theme = useTheme();
  const history = useHistory();
  const { organizationName } = useParams();
  const match = useRouteMatch();

  const [selectedProject, setSelectedProject] = useState(undefined);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  const rootUrl = useContext(RootUrlContext);

  const currentOrganization = useGetCurrentOrganization();
  const organizationFeatures = useGetOrganizationFeatures();
  const projects = useGetProjects();

  const updateProject = useProjectUpdate(organizationName);
  const deleteProject = useProjectDelete(organizationName);

  const columns = useMemo(
    () => [
      {
        title: "Name",
        field: "name",
        defaultSort: "asc",
        width: "40%",
        nowrap: true,
        render: (rowData) => (
          <TableLink
            variant="bold"
            to={`${rootUrl}/projects/${rowData.name}`}
            onClick={(e) => e.stopPropagation()}
          >
            {rowData.name}
          </TableLink>
        ),
      },
      {
        title: "Advanced permissions enabled",
        field: "advanced_permissions",
        width: "30%",
        render: ({ advanced_permissions }) =>
          advanced_permissions ? (
            <CheckIcon
              fontSize="small"
              htmlColor={theme.palette.success.main}
            />
          ) : (
            <CloseIcon fontSize="small" color="error" />
          ),
      },
      {
        sorting: false,
        disableClick: true,
        nowrap: true,
        align: "right",
        width: "30%",
        render: (rowData) => (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedProject(rowData);
                setEditDialogOpen(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedProject(rowData);
                setDeleteDialogOpen(true);
              }}
            >
              <Trash />
            </IconButton>
          </Box>
        ),
      },
    ],
    [rootUrl, theme]
  );

  const onProjectDelete = () => {
    deleteProject(selectedProject?.name);
    setSelectedProject(undefined);
    setDeleteDialogOpen(false);
  };

  const onProjectUpdate = async (data) => {
    await updateProject(selectedProject.name, {
      name: data[FIELD_PROJECT_NAME],
      [FIELD_ADVANCED_PERMISSIONS]: data[FIELD_ADVANCED_PERMISSIONS],
      [FIELD_CREDIT_LIMIT]: data[FIELD_CREDIT_LIMIT_ENABLED]
        ? data[FIELD_CREDIT_LIMIT]
        : null,
    });
    setSelectedProject(undefined);
    setEditDialogOpen(false);
  };

  return (
    <PageContainer>
      <BreadcrumbsItem to={match.url}>Projects</BreadcrumbsItem>
      <PageHeader
        title={organizationName}
        info={explanations.projects.general}
      />
      {!projects ? (
        <Loader />
      ) : (
        <Box>
          <PrimaryButton
            disabled={currentOrganization?.status !== "active"}
            startIcon={<Plus />}
            onClick={() => setCreateDialogOpen(true)}
          >
            Add project
          </PrimaryButton>

          <BaseTable
            columns={columns}
            data={projects}
            onRowClick={(_, rowData) =>
              history.push(`/projects/${rowData.name}`)
            }
          />
        </Box>
      )}

      <DeleteProjectDialog
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={onProjectDelete}
        open={deleteDialogOpen}
        projectName={selectedProject?.name}
      />

      <EditProjectDialog
        currentProject={selectedProject}
        onConfirm={onProjectUpdate}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        loading={!projects}
      />

      <UpgradeSubscriptionDialog
        open={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
        handleGoBack={() => setIsSubscriptionDialogOpen(false)}
      >
        You&apos;ve reached your current subscription&apos;s limit of{" "}
        <b>{organizationFeatures?.max_projects} maximum project(s)</b>. Please
        upgrade your subscription.
      </UpgradeSubscriptionDialog>

      <AddNewProjectDialog
        onClose={() => setCreateDialogOpen(false)}
        open={createDialogOpen}
      />
    </PageContainer>
  );
};

export default ProjectsOverview;
