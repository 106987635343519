import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";

import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";
import { TIME_OUT_FILES } from "libs/constants/constants";
import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DOCKER_IMAGE,
  FIELD_FILE_TYPE,
  FIELD_NAME,
  FIELD_REQUIREMENTS_FILE,
  FIELD_SUPPORT_REQUESTS,
} from "libs/constants/fields";
import {
  environmentRevisionsFileUpload,
  environmentsCreate,
  useEnvironmentsList,
} from "libs/data/endpoints/environments/environments";
import { explanations } from "libs/utilities/explanations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import validators, {
  environmentDependenciesValidator,
} from "libs/utilities/validators";
import { useGetOrganizationFeatures } from "store/features/organizations";

import {
  Checkbox,
  Dialog,
  FormTextField,
  FormWrapper,
  Icon,
  PrimaryButton,
  Radio,
} from "components/atoms";
import {
  formatCodeEnvironment,
  FormSection,
  SupportRequestSection,
} from "components/molecules";

import { BaseEnvironmentSelect } from "./BaseEnvironmentSelect";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";

const REQUIREMENTS_FILE_FIELD = "requirements_file";

interface FormData {
  [FIELD_NAME]: string;
  [FIELD_SUPPORT_REQUESTS]: boolean;
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]: AutocompleteSelectOption;
  [REQUIREMENTS_FILE_FIELD]: File[];
}

interface CodeEnvironmentDialogProps {
  gpuEnabled: boolean;
  onClose: () => void;
  onCreate: (option: AutocompleteSelectOption) => void;
  open: boolean;
  projectName: string;
  shouldSupportRequests?: boolean;
}

export const CodeEnvironmentDialog = ({
  gpuEnabled,
  onClose,
  onCreate,
  open,
  projectName,
  shouldSupportRequests,
}: CodeEnvironmentDialogProps) => {
  const dispatch = useDispatch();
  const organizationFeatures = useGetOrganizationFeatures();
  const formMethods = useForm<FormData>();
  const {
    clearErrors,
    errors,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    control,
  } = formMethods;
  const { mutate: mutateEnvironments } = useEnvironmentsList(projectName);
  const [dependencyFile, setDependencyFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const fileType = useWatch({
    name: FIELD_FILE_TYPE,
    defaultValue: REQUIREMENTS_FILE_FIELD,
    control,
  });

  const supportsRequestFormat = useWatch({
    name: FIELD_SUPPORT_REQUESTS,
    defaultValue: true,
    control,
  });

  useEffect(() => {
    register(REQUIREMENTS_FILE_FIELD, {
      required: validators.required.message("custom dependency file"),
    });
  }, [register]);

  const handleChange = (file: File) => {
    if (fileType === FIELD_REQUIREMENTS_FILE) {
      if (file && environmentDependenciesValidator(file.name)) {
        clearErrors();
        setValue(REQUIREMENTS_FILE_FIELD, [file]);
        setDependencyFile(file);
      } else {
        setValue(REQUIREMENTS_FILE_FIELD, undefined);
        setDependencyFile(null);
        setError(REQUIREMENTS_FILE_FIELD, {
          message:
            "Your requirements must be contained within a requirements.txt, ubiops.yaml or a zip file.",
        });
      }
    } else if (file) {
      clearErrors();
      setValue(REQUIREMENTS_FILE_FIELD, [file]);
      setDependencyFile(file);
    }
  };

  const handleTypeChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value === FIELD_DOCKER_IMAGE) {
      setValue(FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT, undefined);
    }
    setValue(FIELD_FILE_TYPE, value);
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const response = await environmentsCreate(projectName, {
        name: data[FIELD_NAME],
        supports_request_format: data[FIELD_SUPPORT_REQUESTS],
        base_environment: data[FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]
          ?.value as string,
      });

      if (dependencyFile) {
        await environmentRevisionsFileUpload(
          projectName,
          response.name,
          {
            file: dependencyFile,
          },
          { timeout: TIME_OUT_FILES }
        );
      }

      dispatch(createSuccessNotification("Environment created"));
      await mutateEnvironments();
      onCreate(formatCodeEnvironment(response));
      onClose();
      setDependencyFile(null);
      reset();
    } catch (err: any) {
      dispatch(createErrorNotification(err?.message ?? "Something went wrong"));
    }
    setLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title="Create new environment"
      Actions={
        <PrimaryButton loading={loading} onClick={handleSubmit(onSubmit)}>
          Save
        </PrimaryButton>
      }
      fullWidth
      maxWidth={"lg"}
    >
      <Box height="100%">
        <FormProvider {...formMethods}>
          <FormWrapper fullWidth>
            <FormSection title="Name">
              <FormTextField
                name={FIELD_NAME}
                label="Name"
                placeholder="Ex: example-environment"
                rules={{
                  required: validators.required.message("environment name"),
                }}
                withError={true}
              />
            </FormSection>
            <FormSection
              title="Custom dependencies"
              description={
                fileType === FIELD_REQUIREMENTS_FILE
                  ? explanations.environments.customDependencies
                  : explanations.environments.dockerDependency
              }
            >
              {organizationFeatures?.custom_images_enabled && (
                <Grid container display="flex" flexDirection="row">
                  <Radio
                    value={FIELD_REQUIREMENTS_FILE}
                    name={FIELD_FILE_TYPE}
                    id={`${FIELD_FILE_TYPE}_requirements`}
                    register={register}
                    onChange={handleTypeChange}
                    label="Package with dependencies"
                    defaultChecked={true}
                    style={{ width: "40%", minWidth: "max-content" }}
                  />
                  <Radio
                    value={FIELD_DOCKER_IMAGE}
                    name={FIELD_FILE_TYPE}
                    register={register}
                    onChange={handleTypeChange}
                    id={`${FIELD_FILE_TYPE}_docker`}
                    label="Docker image"
                    style={{ width: "25%", minWidth: "max-content" }}
                  />
                </Grid>
              )}
              <DropSelectFile
                handleChange={handleChange}
                types={
                  fileType === FIELD_REQUIREMENTS_FILE
                    ? ["zip", "txt", "R", "yaml"]
                    : undefined
                }
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginBottom={2}
                >
                  <Typography align="center">
                    Drag and drop your file here to upload it
                  </Typography>
                  <Typography>or</Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <PrimaryButton>
                    {dependencyFile ? "Change file" : "Upload file"}
                  </PrimaryButton>
                </Box>
                {dependencyFile && (
                  <Box display="flex" alignItems="center" marginTop={2}>
                    <Icon
                      component={CheckCircleRoundedIcon}
                      status="available"
                    />
                    <Typography variant="body2" display="inline">
                      {dependencyFile?.name}
                    </Typography>
                  </Box>
                )}
              </DropSelectFile>
              {!!errors?.[REQUIREMENTS_FILE_FIELD] && (
                <FormHelperText
                  id={REQUIREMENTS_FILE_FIELD}
                  error={!!errors?.[REQUIREMENTS_FILE_FIELD]}
                >
                  {(errors[REQUIREMENTS_FILE_FIELD] as any).message}
                </FormHelperText>
              )}
            </FormSection>
            {fileType === FIELD_REQUIREMENTS_FILE && (
              <FormSection
                title="Base environment"
                description={explanations.environments.baseEnvironment}
              >
                <BaseEnvironmentSelect
                  name={FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT}
                  gpuEnabled={gpuEnabled}
                  projectName={projectName}
                />
              </FormSection>
            )}
            {fileType === FIELD_DOCKER_IMAGE &&
              !organizationFeatures?.request_format_only && (
                <SupportRequestSection
                  entity="environment"
                  isChecked={supportsRequestFormat}
                >
                  <Checkbox
                    name={FIELD_SUPPORT_REQUESTS}
                    label={"Support inferencing"}
                    defaultChecked={true}
                    color="secondary"
                    fullWidth={false}
                    checked={supportsRequestFormat}
                    onChange={(_e, checked) =>
                      setValue(FIELD_SUPPORT_REQUESTS, checked)
                    }
                    disabled={shouldSupportRequests}
                  />
                </SupportRequestSection>
              )}
          </FormWrapper>
        </FormProvider>
      </Box>
    </Dialog>
  );
};
