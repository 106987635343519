import Plus from "@mui/icons-material/AddBoxRounded";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { ENVIRONMENT_PERMISSIONS } from "libs/constants/permissions";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";
import { useGetPermissions } from "store/features/permissions";

import { PageTabs, PrimaryButton } from "components/atoms";

import type { ReactNode } from "react";

type EnvironmentsOverviewProps = { children: ReactNode };

export const EnvironmentsOverview = ({
  children,
}: EnvironmentsOverviewProps) => {
  const history = useHistory();
  const { organizationName, projectName } =
    useParams<{
      projectName: string;
      environmentName: string;
      organizationName: string;
    }>();
  const currentOrganization = useGetCurrentOrganization();
  const [projectPermissions] = useGetPermissions();

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName).environments,
    [organizationName, projectName]
  );
  const tabs = [
    {
      link: baseUrl.base.index(),
      label: "Base environments",
    },
    {
      link: baseUrl.custom.index(),
      label: "Custom environments",
    },
  ];

  return (
    <DetailsContainer
      title="Environments"
      actions={
        <PrimaryButton
          startIcon={<Plus />}
          onClick={() => history.push(baseUrl.custom.create.index())}
          disabled={
            !projectPermissions[ENVIRONMENT_PERMISSIONS["create"]] ||
            currentOrganization?.status !== "active"
          }
          style={{ marginLeft: "auto" }}
        >
          Custom environment
        </PrimaryButton>
      }
    >
      <PageTabs tabs={tabs}>{children}</PageTabs>
    </DetailsContainer>
  );
};
