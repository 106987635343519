import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { useGoogleAnalytics } from "libs/hooks";
import { useGetPermissions } from "store/features/permissions";

import { PageTabs } from "components/atoms";

const Permissions = ({ children }) => {
  useGoogleAnalytics();

  const [currentPermissions] = useGetPermissions();

  const { url } = useRouteMatch();

  const tabs = [
    {
      link: `${url}/users`,
      label: "Project members",
    },
    {
      link: `${url}/api-tokens`,
      label: "API tokens",
    },
    {
      link: `${url}/roles`,
      label: "Roles",
      disabled: !currentPermissions[USER_ROLE_PERMISSIONS["role_list"]],
    },
  ];

  return (
    <DetailsContainer title="Permissions">
      <PageTabs tabs={tabs}>{children}</PageTabs>
    </DetailsContainer>
  );
};

Permissions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Permissions;
