import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  projectsUpdate,
  useProjectsList,
} from "libs/data/endpoints/projects/projects";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { setProject, setProjects } from "store/features/organizationProjects";

import type { AxiosError } from "axios";
import type { ProjectUpdate } from "libs/data/models";

export const useProjectUpdate = (
  organizationName: string,
  successNotification?: string
) => {
  const dispatch = useDispatch();
  const { mutate } = useProjectsList(
    {
      organization: organizationName,
    },
    { swr: { enabled: Boolean(organizationName) } }
  );

  return useCallback(
    async (name: string, data: ProjectUpdate) => {
      if (!name) return;

      try {
        const response = await projectsUpdate(name, data);
        dispatch(
          createSuccessNotification(
            successNotification || `Project was updated`
          )
        );
        mutate().then((data) => data && dispatch(setProjects(data)));
        dispatch(setProject(response));
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [dispatch, mutate, successNotification]
  );
};
