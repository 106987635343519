import styled from "@emotion/styled";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { rgba } from "polished";

import { ReactComponent as Close } from "assets/images/close.svg";
import { spacing } from "assets/styles/theme";
import { useElementSize, usePortal } from "libs/hooks";

import { Divider } from "components/atoms";

import { DIAGRAM_SIDEBAR_PARENT_ID } from "../constants";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { CSSProperties, DependencyList, FC, HTMLAttributes } from "react";
import "./SidebarRight.scss";

export type SidebarRightProps = {
  title: string;
  description?: string | React.ReactElement;
  onClose: () => void;
  children?: React.ReactNode;
};

export const SidebarRight = ({
  title,
  description,
  onClose,
  children,
}: SidebarRightProps) => {
  const Portal = usePortal(document.getElementById(DIAGRAM_SIDEBAR_PARENT_ID));
  const theme = useTheme() as AppThemeProps;

  return (
    <Portal>
      <OverCanvas
        centerVertically
        className="SidebarRight"
        style={{
          boxShadow: `0px 4px 4px ${rgba(theme.palette.neutrals[900], 0.25)}`,
          backgroundColor: theme.palette.pipelineDiagram.dialogBackground,
          transform: "translateY(-60px)",
        }}
      >
        <Box display="flex" flexDirection="column" marginBottom={spacing[4]}>
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            paddingBottom="8px"
          >
            <SidebarFormCancelButton onClick={onClose}>
              <Close fontSize="small" />
            </SidebarFormCancelButton>
          </Box>
          <Box display="flex" flexDirection="column" textAlign="center">
            <Typography variant="h6" color="textPrimary">
              {title}
            </Typography>
            {description && (
              <>
                <Divider marginY={1} />
                <Typography variant="subtitle2" color="textSecondary">
                  {description}
                </Typography>
              </>
            )}
          </Box>
        </Box>

        {children}
      </OverCanvas>
    </Portal>
  );
};

// The Box of materials-ui doesn't have the ref support
const S_OverCanvas = styled.div`
  position: absolute;
  display: inline-block;
`;

const OverCanvas: FC<
  React.PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
      reCalculateSizeDependencies?: DependencyList;
      centerHorizontally?: boolean;
      centerVertically?: boolean;
    }
  >
> = ({
  centerVertically = false,
  centerHorizontally = false,
  reCalculateSizeDependencies = [],
  style = {},
  children,
  ...props
}) => {
  const [ref, { width, height }] = useElementSize<HTMLDivElement>(
    undefined,
    reCalculateSizeDependencies
  );

  const theme = useTheme() as AppThemeProps;

  const styleOverride: CSSProperties = {
    ...style,
    transition: "all 0.125s",
    border: `0.3px solid ${theme.palette.pipelineDiagram.dialogBorder}`,
    left: centerHorizontally
      ? (window.innerWidth - width) / 2
      : style.left ?? "unset",
    top: centerVertically
      ? (window.innerHeight - height - 44) / 2
      : style.top ?? "unset",
  };

  return (
    <S_OverCanvas ref={ref} {...props} style={styleOverride}>
      {children}
    </S_OverCanvas>
  );
};

export const SidebarFormCancelButton = styled(IconButton)`
  width: fit-content;
  padding: 0px;
`;
