/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { Key, SWRConfiguration } from "swr";
import type { ErrorType } from "../../axios/index";
import type {
  PermissionList,
  PermissionValidationCreate,
  PermissionValidationList,
  RoleAssignmentCreate,
  RoleAssignmentList,
  RoleAssignmentsPerObjectListParams,
  RoleCreate,
  RoleDetailList,
  RoleList,
  RoleUpdate,
} from "../../models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List all the available permissions which can be used to create custom roles.

### Response Structure
A list of available permissions
- `name`: Name of the permission

 * @summary List the available permissions
 */
export const permissionsList = (
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PermissionList[]>(
    { url: `/permissions`, method: "get" },
    options
  );
};

export const getPermissionsListKey = () => [`/permissions`] as const;

export type PermissionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof permissionsList>>
>;
export type PermissionsListQueryError = ErrorType<unknown>;

/**
 * @summary List the available permissions
 */
export const usePermissionsList = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof permissionsList>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof orvalAxios>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getPermissionsListKey() : null));
  const swrFn = () => permissionsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the roles assigned to a user or on an object in the scope of a project.

### Optional Parameters
These parameters should be given as query parameters
- `resource`: Name of the object on which the assigned roles will be listed
- `resource_type`: Type of the object on which the assigned roles will be listed
- `assignee`: UUID of the user or the name of the object for which the assigned roles will be listed
- `assignee_type`: Type of the assignee for which the assigned roles will be listed

### Response Structure
- `id`: Unique identifier for the role assignment (UUID)
- `assignee`: UUID of the user or the name of the object
- `assignee_type`: Type of the assignee
- `role`: Name of the role assigned to the user/object
- `resource`: Name of the object for which the role is assigned
- `resource_type`: Type of the object for which the role is assigned

#### Response Examples
Roles assigned to user with id 02b77d8f-b312-47ef-990f-4685a7ab9363
```
[
  {
    "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
    "assignee": "02b77d8f-b312-47ef-990f-4685a7ab9363",
    "assignee_type": "user",
    "role": "deployment-admin",
    "resource": "project-1",
    "resource_type": "project"
  },
  {
    "id": "13cf9dd7-7356-4797-b453-e0cb6b416162",
    "assignee": "02b77d8f-b312-47ef-990f-4685a7ab9363",
    "assignee_type": "user",
    "role": "pipeline-admin",
    "resource": "pipeline-1",
    "resource_type": "pipeline"
  }
]
```

Roles assigned on deployment with name deployment-1
```
[
  {
    "id": "a24aabc2-c7df-4f7b-b177-f80827aea1bb",
    "assignee": "258771bb-1bc4-4f2f-a3f4-9309cc64d1dd",
    "assignee_type": "user",
    "role": "deployment-admin",
    "resource": "deployment-1",
    "resource_type": "deployment"
  },
  {
    "id": "dfd4e714-9c2d-446e-ae96-4db18f91d3de",
    "assignee": "0ca8a61d-962e-48e3-b558-61e8ca2dae88",
    "assignee_type": "user",
    "role": "deployment-viewer",
    "resource": "deployment-1",
    "resource_type": "deployment"
  }
]
```

 * @summary List roles on object/user
 */
export const roleAssignmentsPerObjectList = (
  projectName: string,
  params?: RoleAssignmentsPerObjectListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleAssignmentList[]>(
    { url: `/projects/${projectName}/role-assignments`, method: "get", params },
    options
  );
};

export const getRoleAssignmentsPerObjectListKey = (
  projectName: string,
  params?: RoleAssignmentsPerObjectListParams
) =>
  [
    `/projects/${projectName}/role-assignments`,
    ...(params ? [params] : []),
  ] as const;

export type RoleAssignmentsPerObjectListQueryResult = NonNullable<
  Awaited<ReturnType<typeof roleAssignmentsPerObjectList>>
>;
export type RoleAssignmentsPerObjectListQueryError = ErrorType<unknown>;

/**
 * @summary List roles on object/user
 */
export const useRoleAssignmentsPerObjectList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: RoleAssignmentsPerObjectListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof roleAssignmentsPerObjectList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getRoleAssignmentsPerObjectListKey(projectName, params)
        : null);
  const swrFn = () =>
    roleAssignmentsPerObjectList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Assign a role to a user or an object in the scope of a project. This role can be assigned on either project level or on object level, which can be a deployment, pipeline or bucket.

### Required Parameters
- `user_id`: Unique identifier for the user (UUID)
- `role`: Name of the role to be assigned to the user
- `assignee`: UUID of the user or the name of the object for which the role will be assigned
- `assignee_type`: Type of the assignee. It can be user or deployment.
- `resource`: Name of the object for which the role will be assigned
- `resource_type`: Type of the object for which the role will be assigned. It can be project, deployment, pipeline or bucket.

**resource and resource_type must be provided together. If neither of them is provided, the role is set on project level.**

#### Request Examples
Setting the role deployment-admin on project level for user with id 02b77d8f-b312-47ef-990f-4685a7ab9363
```
{
  "assignee": "02b77d8f-b312-47ef-990f-4685a7ab9363",
  "assignee_type": "user",
  "role": "deployment-admin"
}
```

Setting the role deployment-viewer on deployment-1 for user with id 02b77d8f-b312-47ef-990f-4685a7ab9363
```
{
  "assignee": "02b77d8f-b312-47ef-990f-4685a7ab9363",
  "assignee_type": "user",
  "role": "deployment-viewer",
  "resource": "deployment-1",
  "resource_type": "deployment"
}
```

Setting the role files-reader on bucket-1 for deployment-1
```
{
  "assignee": "deployment-1",
  "assignee_type": "deployment",
  "role": "file-reader",
  "resource": "bucket-1",
  "resource_type": "bucket"
}
```

### Response Structure
Details of the created role assignment
- `id`: Unique identifier for the role assignment (UUID)
- `assignee`: UUID of the user or the name of the object
- `assignee_type`: Type of the assignee
- `role`: Name of the role assigned to the user
- `resource`: Name of the object for which the role is assigned
- `resource_type`: Type of the object for which the role is assigned

#### Response Examples
```
{
  "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
  "assignee": "02b77d8f-b312-47ef-990f-4685a7ab9363",
  "assignee_type": "user",
  "role": "deployment-admin",
  "resource": "project-1",
  "resource_type": "project"
}
```

```
{
  "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
  "assignee": "deployment-1",
  "assignee_type": "deployment",
  "role": "file-reader",
  "resource": "bucket-1",
  "resource_type": "bucket"
}
```

 * @summary Assign role to user/object
 */
export const roleAssignmentsCreate = (
  projectName: string,
  roleAssignmentCreate: RoleAssignmentCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleAssignmentList>(
    {
      url: `/projects/${projectName}/role-assignments`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: roleAssignmentCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a role assignment of a user/deployment.

### Response Structure
Details of the role assignment
- `id`: Unique identifier for the role assignment (UUID)
- `assignee`: UUID of the user or the name of the object
- `assignee_type`: Type of the assignee
- `role`: Name of the role assigned to the user/object
- `resource`: Name of the object for which the role is assigned
- `resource_type`: Type of the object for which the role is assigned

#### Response Examples
```
{
  "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
  "assignee": "02b77d8f-b312-47ef-990f-4685a7ab9363",
  "assignee_type": "user",
  "role": "deployment-admin",
  "resource": "project-1",
  "resource_type": "project"
}
```

 * @summary Get role assignment
 */
export const roleAssignmentsGet = (
  projectName: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleAssignmentList>(
    { url: `/projects/${projectName}/role-assignments/${id}`, method: "get" },
    options
  );
};

export const getRoleAssignmentsGetKey = (projectName: string, id: string) =>
  [`/projects/${projectName}/role-assignments/${id}`] as const;

export type RoleAssignmentsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof roleAssignmentsGet>>
>;
export type RoleAssignmentsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get role assignment
 */
export const useRoleAssignmentsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof roleAssignmentsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(projectName && id);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getRoleAssignmentsGetKey(projectName, id) : null));
  const swrFn = () => roleAssignmentsGet(projectName, id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Delete a role of a user.  It is possible for a user to delete their own role if they have permissions to do so.

 * @summary Delete role of user
 */
export const roleAssignmentsDelete = (
  projectName: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/role-assignments/${id}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
List the roles available in the scope of a project. Information on which permissions each role contains, can be obtained with a call to get details of a single role.

### Response Structure
- `id`: Unique identifier for the role (UUID)
- `name`: Name of the role
- `default`: A boolean value that indicates whether the role is a default role or not

#### Response Examples
```
[
  {
    "id": "34e53855-9b50-47bc-b516-6cb971b1949c",
    "name": "project-admin",
    "default": true
  },
  {
    "id": "00132911-b5dd-4017-b399-85f3ffd2a7c3",
    "name": "project-editor",
    "default": true
  },
  {
    "id": "bf0d5823-8062-40f6-bbd2-21bc732f3c3b",
    "name": "project-viewer",
    "default": true
  }
]
```

 * @summary List the available roles in a project
 */
export const rolesList = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleList[]>(
    { url: `/projects/${projectName}/roles`, method: "get" },
    options
  );
};

export const getRolesListKey = (projectName: string) =>
  [`/projects/${projectName}/roles`] as const;

export type RolesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesList>>
>;
export type RolesListQueryError = ErrorType<unknown>;

/**
 * @summary List the available roles in a project
 */
export const useRolesList = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof rolesList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getRolesListKey(projectName) : null));
  const swrFn = () => rolesList(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a custom role in the scope of a project. This role is not accessible from other projects.

The user making the request must have appropriate permissions.

### Required Parameters
- `name`: Name of the role which will be created. It can only consist of lowercase letters, numbers and dashes (-), and must start with a lowercase letter.
- `permissions`: A list of permissions which the role will contain. The list of available permissions can be obtained with *\/permissions* endpoint.

#### Request Examples
```
{
  "name": "custom-deployment-editor-role",
  "permissions": [
    "deployments.list",
    "deployments.get",
    "deployments.delete"
  ]
}
```

### Response Structure
Details of the created role
- `id`: Unique identifier for the created role (UUID)
- `name`: Name of the created role
- `default`: A boolean value that indicates whether the role is a default role or not
- `permissions`: A list of permissions which the role contains

#### Response Examples
```
{
  "id": "18a4a60d-d5f0-4099-9c6e-543bf2fd5a1d",
  "name": "custom-deployment-editor-role",
  "default": false,
  "permissions": [
    "deployments.list",
    "deployments.get",
    "deployments.delete"
  ]
}
```

 * @summary Create a custom role scoped in a project
 */
export const rolesCreate = (
  projectName: string,
  roleCreate: RoleCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleDetailList>(
    {
      url: `/projects/${projectName}/roles`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: roleCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a role. The user making the request must have appropriate permissions.

### Response Structure
Details of the role
- `id`: Unique identifier for the role (UUID)
- `name`: Name of the role
- `default`: A boolean value that indicates whether the role is a default role or not
- `permissions`: A list of permissions which the role contains

#### Response Examples
```
{
  "id": "18a4a60d-d5f0-4099-9c6e-543bf2fd5a1d",
  "name": "custom-deployment-editor-role",
  "default": false,
  "permissions": [
    "deployments.list",
    "deployments.get",
    "deployments.delete"
  ]
}
```

 * @summary Get details of a role
 */
export const rolesGet = (
  projectName: string,
  roleName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleDetailList>(
    { url: `/projects/${projectName}/roles/${roleName}`, method: "get" },
    options
  );
};

export const getRolesGetKey = (projectName: string, roleName: string) =>
  [`/projects/${projectName}/roles/${roleName}`] as const;

export type RolesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof rolesGet>>
>;
export type RolesGetQueryError = ErrorType<unknown>;

/**
 * @summary Get details of a role
 */
export const useRolesGet = <TError = ErrorType<unknown>>(
  projectName: string,
  roleName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof rolesGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && roleName);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getRolesGetKey(projectName, roleName) : null));
  const swrFn = () => rolesGet(projectName, roleName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a role in a project. The user making the request must have appropriate permissions.

**Default roles cannot be updated.**

### Optional Parameters
- `name`: New name for the role. It can only consist of lowercase letters, numbers and dashes (-), and must start with a lowercase letter.
- `permissions`: A new list of permissions which the role will contain. The previous permissions will be replaced with the given ones. The list of available permissions can be obtained with *\/permissions* endpoint.

#### Request Examples
```
{
  "name": "new-deployment-editor-role",
  "permissions": [
    "deployments.list",
    "deployments.get"
  ]
}
```

### Response Structure
Details of the updated role
- `id`: Unique identifier for the role (UUID)
- `name`: Name of the updated role
- `default`: A boolean value that indicates whether the role is a default role or not
- `permissions`: A list of permissions which the role contains

#### Response Examples
```
{
  "id": "18a4a60d-d5f0-4099-9c6e-543bf2fd5a1d",
  "name": "new-deployment-editor-role",
  "default": false,
  "permissions": [
    "deployments.list",
    "deployments.get"
  ]
}
```

 * @summary Update a role in a project
 */
export const rolesUpdate = (
  projectName: string,
  roleName: string,
  roleUpdate: RoleUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RoleDetailList>(
    {
      url: `/projects/${projectName}/roles/${roleName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: roleUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a role from a project. The user making the request must have appropriate permissions.

**Default roles cannot be deleted.**

 * @summary Delete a role from a project
 */
export const rolesDelete = (
  projectName: string,
  roleName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}/roles/${roleName}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
Check if the user making the request has the given permissions on the given object. The user making the request must be in the organization where the project is defined.

### Required Parameters
- `permissions`: A list of permission names to be checked for the user

### Optional Parameters
- `resource`: Name of the object for which the permissions will be checked
- `resource_type`: Type of the object for which the permissions will be checked. It can be project, deployment, pipeline or bucket.

**resource and resource_type must be provided together. If neither of them is provided, the permissions are checked on project level.**

#### Request Examples
```
{
  "resource_type": "deployment",
  "resource": "deployment-1",
  "permission": [
    "deployments.get",
    "deployments.delete"
  ]
}
```

```
{
  "permission": [
    "deployments.get",
    "pipelines.get"
  ]
}
```

### Response Structure
A list of dictionaries containing the following fields:
- `permission`: Name of the permission
- `success`: A boolean indicating whether the user making the request has the given permission

#### Response Examples
```
[
  {
    "permission": "deployments.get",
    "success": True
  },
  {
    "permission": "deployments.delete",
    "success": False
  },
]
```

 * @summary Validate permissions of a user
 */
export const validatePermissionsForUser = (
  projectName: string,
  permissionValidationCreate: PermissionValidationCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PermissionValidationList[]>(
    {
      url: `/projects/${projectName}/validate-permissions`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: permissionValidationCreate,
    },
    options
  );
};
