import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import { BaseEnvironments } from "./base/BaseEnvironments";
import { EnvironmentRoutesContainer } from "./custom/:environmentName";
import { EnvironmentCreate } from "./custom/EnvironmentCreate";
import { CustomEnvironments } from "./CustomEnvironments";
import { EnvironmentsOverview } from "./EnvironmentsOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").environments;

export const EnvironmentsRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).environments;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Environments</BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path={basePath.custom.create.index()}
          component={EnvironmentCreate}
        />
        <Route
          path={basePath.custom[":environmentName"](":environmentName").index()}
          component={EnvironmentRoutesContainer}
        />
        <Route path={basePath.index()}>
          <EnvironmentsOverview>
            <Switch>
              <Route
                exact
                path={basePath.base.index()}
                component={BaseEnvironments}
              />
              <Route
                exact
                path={basePath.custom.index()}
                component={CustomEnvironments}
              />
              <Redirect to={baseUrl.custom.index()} />
            </Switch>
          </EnvironmentsOverview>
        </Route>
        <Redirect to={baseUrl.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};
