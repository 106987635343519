import { useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics } from "libs/hooks";

import { BatchRequestCreate } from "components/organisms";

const PipelineVersionBatchRequestCreate = () => {
  useGoogleAnalytics();

  const baseUrl = useContext(BaseUrlContext);
  const { projectName, pipelineName } = useParams();
  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);

  return (
    <>
      <BreadcrumbsItem to={`${baseUrl}/requests`}>Requests</BreadcrumbsItem>
      {pipeline && (
        <BatchRequestCreate
          inputFields={pipeline.input_fields}
          inputType={pipeline.input_type}
          type="pipelines"
        />
      )}
    </>
  );
};

export default PipelineVersionBatchRequestCreate;
