import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import StopInstanceIcon from "@mui/icons-material/StopCircle";
import { Box, Grid, IconButton, TablePagination, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { CONTINUOUS_INSTANCES_DELAY } from "libs/constants/constants";
import { useEditInstance } from "libs/data/customized/instances/useInstanceEdit";
import { useInstancesGet } from "libs/data/endpoints/instances/instances";
import {
  InstanceUpdateStatus,
  ProjectInstanceListStatus,
} from "libs/data/models";
import { useDeviceDetect, useInterval, useLogsUrl } from "libs/hooks";
import { renderTimestamp } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import { toUpperFirstCase } from "libs/utilities/util-functions";

import {
  ActionDialog,
  DialogWarningHeader,
  NavLink,
  RefreshButton,
  StatusIcon,
  ToggleButtonGroup,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { STATUS_FILTERS } from "./constants";
import { InstanceDetailsDialog } from "./InstanceDetailsDialog";
import { getCursor } from "./utils";

import LogsIcon from "@mui/icons-material/SubjectRounded";
import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  InstanceList,
  InstanceListPaginated,
  InstancesListParams,
  ProjectInstanceList,
} from "libs/data/models";
import type { DateType } from "libs/utilities/date-util";
import { routeMaker, routes } from "routes";

type ActiveInstancesTableProps = {
  query?: InstancesListParams;
  setQuery: (query: InstancesListParams) => void;
  data?: InstanceListPaginated;
  showDeployment?: boolean;
  refreshTable?: () => void;
  deploymentName: string;
  versionName: string;
};

export const ActiveInstancesTable = ({
  query,
  setQuery,
  data,
  showDeployment = false,
  deploymentName,
  versionName,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshTable = () => {},
}: ActiveInstancesTableProps) => {
  const { isMobile } = useDeviceDetect();
  const { organizationName, projectName } =
    useParams<{
      organizationName: string;
      projectName: string;
      deploymentName?: string;
      versionName?: string;
    }>();

  const [page, setPage] = useState(0);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] =
    useState<ProjectInstanceList | InstanceList | undefined>(undefined);
  const [stoppingInstance, setStoppingInstance] =
    useState<ProjectInstanceList | InstanceList | undefined>(undefined);

  const { data: instanceDetails, mutate } = useInstancesGet(
    projectName,
    deploymentName ??
      (selectedInstance as ProjectInstanceList)?.deployment ??
      "",
    versionName ?? (selectedInstance as ProjectInstanceList)?.version ?? "",
    selectedInstance?.id ?? "",
    {
      swr: { enabled: !!selectedInstance },
    }
  );

  const handleInstanceStopping = useEditInstance(
    projectName,
    deploymentName ??
      (stoppingInstance as ProjectInstanceList)?.deployment ??
      "",
    versionName ?? (stoppingInstance as ProjectInstanceList)?.version ?? ""
  );

  const redirectUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName),
    [organizationName, projectName]
  );
  const logsUrl = useLogsUrl({
    redirectUrl: redirectUrl.index(),
  });

  const onViewDetails = (rowData: ProjectInstanceList | InstanceList) => {
    setSelectedInstance(rowData);
    setIsDetailsDialogOpen(true);
  };

  useInterval(
    () => {
      if (isDetailsDialogOpen) mutate();
    },
    [mutate, isDetailsDialogOpen],
    CONTINUOUS_INSTANCES_DELAY
  );

  const columns = [
    {
      title: "Status",
      field: "status",
      nowrap: true,
      width: "10%",
      render: (rowData: { status: string }) => (
        <StatusIcon
          label={rowData ? toUpperFirstCase(rowData.status) : ""}
          status={rowData?.status}
          animation={rowData?.status !== "running"}
        />
      ),
    },
    {
      title: "ID",
      field: "id",
      nowrap: true,
      width: "25%",
    },
    showDeployment && {
      title: "Deployment",
      field: "deployment",
      nowrap: true,
      width: "15%",
    },
    showDeployment && {
      title: "Deployment version",
      field: "version",
      nowrap: true,
      width: "15%",
    },
    !isMobile && {
      title: "Created",
      field: "time_created",
      nowrap: true,
      width: "15%",
      render: (rowData: { time_created: string }) =>
        renderTimestamp(rowData, "time_created"),
    },
    !isMobile && {
      title: "Updated",
      field: "time_updated",
      nowrap: true,
      width: "15%",
      render: (rowData: Record<string, DateType>) =>
        renderTimestamp(rowData, "time_updated"),
    },
    {
      sorting: false,
      disableClick: true,
      nowrap: true,
      align: "right",
      render: (rowData: ProjectInstanceList | InstanceList) => (
        <div className="actions_container">
          <Tooltip title="View details">
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                color="secondary"
                onClick={() => onViewDetails(rowData)}
              >
                <GetResultsIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="View logs">
            <span>
              <IconButton
                component={NavLink}
                to={routeMaker(logsUrl, { instance_id: rowData.id })}
              >
                <LogsIcon color="secondary" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Stop instance">
            <span>
              <IconButton
                color="primary"
                disabled={
                  rowData?.status === ProjectInstanceListStatus.initialising
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setStoppingInstance(rowData);
                }}
              >
                <StopInstanceIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ].filter(Boolean);

  const handlePageChange = (newPage: number) => {
    const { cursor } = getCursor(
      (newPage > page ? data?.next : data?.previous) ?? ""
    );

    setQuery({ ...query, cursor });
    setPage(newPage);
  };

  return (
    <Grid
      container
      item
      xs={12}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          items={STATUS_FILTERS}
          onChange={(value) => {
            const newValue =
              value === STATUS_FILTERS[0].title ? undefined : value;
            setQuery({ ...query, status: newValue, cursor: undefined });
          }}
          value={query?.status ?? STATUS_FILTERS[0].title}
        />
        <RefreshButton tooltip="Refresh table" onClick={refreshTable} />
      </Box>
      <BaseTable
        hasPagination={false}
        hasSearchBar={false}
        onRowClick={(_e, row) => onViewDetails(row)}
        columns={columns as unknown as BaseColumn[]}
        data={data?.results}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        nextIconButtonProps={{
          disabled: !data?.next,
        }}
        count={-1}
        labelDisplayedRows={({ from, to }) => {
          const rows = data?.results.length || 0;
          const rowCount = rows < (query?.limit ?? 0) ? from + rows - 1 : to;

          return `${from}-${rowCount}`;
        }}
        rowsPerPage={query?.limit || 10}
        page={page}
        onRowsPerPageChange={(event) => {
          setQuery({
            ...query,
            limit: Number(event.target.value),
            cursor: undefined,
          });
          setPage(0);
        }}
        onPageChange={(_event, page: number) => handlePageChange(page)}
      />
      <InstanceDetailsDialog
        isOpen={isDetailsDialogOpen}
        onClose={() => setIsDetailsDialogOpen(false)}
        details={instanceDetails}
        projectName={projectName}
        organizationName={organizationName}
      />
      <ActionDialog
        Header={<DialogWarningHeader title="Stop running instance" />}
        actionButtonText="Stop instance"
        onClose={() => setStoppingInstance(undefined)}
        onAction={() => {
          handleInstanceStopping(stoppingInstance?.id ?? "", {
            status: InstanceUpdateStatus.stopped,
          });
          setStoppingInstance(undefined);
        }}
        open={!!stoppingInstance}
      >
        {explanations.deployments.versions.activeInstances.stoppingInstanceDialog.message(
          stoppingInstance?.id
        )}
      </ActionDialog>
    </Grid>
  );
};
