import moment from "moment";

import {
  DATE_TIME_FORMAT,
  getTzAwareDate,
  TIME_FORMAT,
} from "libs/utilities/date-util";

import type {
  FormRange,
  InternalDateTimeRange,
} from "components/molecules/Fields/DateTimeRangeSelector/types";

export const endDateTimeFromDateTimeRange = (range: InternalDateTimeRange) =>
  `${moment(range.endDate).format("YYYY-MM-DD")} ${range.endTime}:59`;

export const validateRange = (
  range: InternalDateTimeRange
): null | { startTime?: string; endTime?: string } => {
  const validateTime = (time: string) =>
    /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);

  if (!validateTime(range.startTime)) {
    return { startTime: "Your start time is incorrectly formatted" };
  }

  if (!validateTime(range.endTime)) {
    return { endTime: "Your end time is incorrectly formatted" };
  }

  const startDateTimeAfterEndDateTime =
    moment(startDateTimeFromDateTimeRange(range)).unix() >
    moment(endDateTimeFromDateTimeRange(range)).unix();

  if (startDateTimeAfterEndDateTime) {
    return {
      endTime:
        "Your start date and time cannot be before your end date and time",
    };
  }

  return null;
};

export const startDateTimeFromDateTimeRange = (range: InternalDateTimeRange) =>
  `${moment(range.startDate).format("YYYY-MM-DD")} ${range.startTime}:00`;

export const formatDates = (from: string, to: string) => {
  return `${moment(from).format(DATE_TIME_FORMAT)} - ${moment(to).format(
    DATE_TIME_FORMAT
  )}`;
};

export const formRangeToPickerRange = (range?: FormRange) => {
  return (
    range && {
      startDate: moment(range.startDate).toDate(),
      endDate: moment(range.endDate).toDate(),
      startTime: moment(range.startDate).format(TIME_FORMAT),
      endTime: moment(range.endDate).format(TIME_FORMAT),
    }
  );
};

export type TimeRangeObject = { [title: string]: InternalDateTimeRange };

export const predefinedTimeRanges: TimeRangeObject[] = [
  {
    "Last Hour": {
      startDate: getTzAwareDate().subtract(1, "hour").toDate(),
      startTime: getTzAwareDate().subtract(1, "hour").format("HH:mm"),
      endDate: getTzAwareDate().toDate(),
      endTime: getTzAwareDate().format("HH:mm"),
    },
  },
  {
    "Last Day": {
      startDate: getTzAwareDate().subtract(1, "day").toDate(),
      startTime: getTzAwareDate().startOf("day").format("HH:mm"),
      endDate: getTzAwareDate().toDate(),
      endTime: getTzAwareDate().format("HH:mm"),
    },
  },
  {
    "Last Week": {
      startDate: getTzAwareDate().subtract(7, "days").toDate(),
      startTime: getTzAwareDate().startOf("day").format("HH:mm"),
      endDate: getTzAwareDate().toDate(),
      endTime: getTzAwareDate().format("HH:mm"),
    },
  },
  {
    "This Month": {
      startDate: getTzAwareDate().startOf("month").toDate(),
      startTime: getTzAwareDate().startOf("day").format("HH:mm"),
      endDate: getTzAwareDate().toDate(),
      endTime: getTzAwareDate().format("HH:mm"),
    },
  },
];
