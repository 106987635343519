import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_REQUIREMENTS_FILE } from "libs/constants/fields";
import { useEnvironmentCreate } from "libs/data/customized/environments/useEnvironmentCreate";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { EnvironmentBaseForm } from "./EnvironmentsBaseForm";

import type { FormikHelpers } from "formik";
import type {
  EnvironmentCreate as EnvironmentCreateRequest,
  EnvironmentRevisionsFileUploadBody,
} from "libs/data/models";
import type { EnvironmentBaseProps } from "./EnvironmentsBaseForm";

export const EnvironmentCreate = () => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const createEnvironment = useEnvironmentCreate(projectName);
  const history = useHistory();

  const handleOnSubmit = async (
    values: EnvironmentBaseProps,
    actions: FormikHelpers<EnvironmentBaseProps>
  ) => {
    const { [FIELD_REQUIREMENTS_FILE]: file, ...request } = values;
    const response = await createEnvironment(
      request as EnvironmentCreateRequest,
      file as EnvironmentRevisionsFileUploadBody
    );
    actions.setSubmitting(false);

    if (response) {
      actions.resetForm();

      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .environments.custom[":environmentName"](response.name)
          .details.index()
      );
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Create new custom environment" />
      <FormWrapper>
        <EnvironmentBaseForm
          dependenciesRequired={true}
          onSubmit={handleOnSubmit}
        />
      </FormWrapper>
    </PageContainer>
  );
};
