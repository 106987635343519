import { useState } from "react";
import { useParams } from "react-router-dom";

import { ActiveInstancesTable } from "components/organisms/ActiveInstances/ActiveInstancesTable";
import { CONTINUOUS_INSTANCES_DELAY } from "libs/constants/constants";
import { useInstancesList } from "libs/data/endpoints/instances/instances";
import { useInterval } from "libs/hooks";

import type { InstancesListParams } from "libs/data/models";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

export const ActiveInstances = () => {
  const { projectName, experimentName } =
    useParams<{
      projectName: string;
      experimentName: string;
    }>();
  const [query, setQuery] = useState<InstancesListParams>({
    limit: 10,
  });

  const { data: instances, mutate } = useInstancesList(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    query,
    { swr: { dedupingInterval: 0 } }
  );

  useInterval(
    () => {
      mutate();
    },
    [mutate],
    CONTINUOUS_INSTANCES_DELAY
  );

  return (
    <ActiveInstancesTable
      query={query}
      setQuery={setQuery}
      data={instances}
      refreshTable={mutate}
      deploymentName={TRAINING_DEPLOYMENT}
      versionName={experimentName}
    />
  );
};
