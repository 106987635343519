import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Dialog } from "components/atoms";

import { RequestDetails } from "./RequestDetails";

import type { Dispatch, SetStateAction } from "react";
import type { RequestDetailsProps } from "./RequestDetails";

type RequestResultsDialogProps = RequestDetailsProps & {
  container?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  fetchOnlyMetadata?: boolean;
};

export const RequestResultsDialog = ({
  container,
  isOpen,
  setIsOpen,
  ...props
}: RequestResultsDialogProps) => {
  const {
    location: { pathname },
  } = useHistory();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    onClose();
  }, [pathname, onClose]);

  return (
    <Dialog
      container={container ?? ".main-layout"}
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
      title="Results"
    >
      <RequestDetails {...props} />
    </Dialog>
  );
};
