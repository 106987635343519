import FileIcon from "@mui/icons-material/DescriptionOutlined";
import FolderCloseIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { TreeItem, TreeView } from "@mui/lab";
import { Box, FormHelperText, Grid } from "@mui/material";
import { get as lodashGet } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useReducer } from "react";
import { useFormContext } from "react-hook-form";

import { UploadPackageFieldFileError } from "components/molecules/DeploymentVersion/UploadPackageFieldFileError";
import { UploadPackageFieldFileName } from "components/molecules/DeploymentVersion/UploadPackageFieldFileName";
import {
  MAX_DEPLOYMENT_FILE_SIZE_IN_BYTE,
  MAX_DEPLOYMENT_FILE_SIZE_TO_LOCAL_CHECK_IN_BYTE,
} from "libs/constants/constants";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { useDialog } from "libs/hooks";
import {
  createTreeStructure,
  getZipFileDirectoryStructure,
  validateZipFileSize,
  validateZipFileType,
} from "libs/utilities/upload-helper";
import { isArrayHasData } from "libs/utilities/utils";

import { Accordion, ExternalLink } from "components/atoms";
import { PackageValidationErrorDialog } from "components/organisms";

import { UploadPackageFieldDropzone } from "./UploadPackageFieldDropzone";

const initialState = {
  fileName: "No file chosen",
  contentTree: [],
  failedSteps: [],
  fileStructureError: "",
  uploading: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "setFileName":
      return {
        ...state,
        fileName: action.payload,
      };

    case "setContentTree":
      return {
        ...state,
        contentTree: action.payload,
      };

    case "setFailedSteps":
      return {
        ...state,
        failedSteps: [...state.failedSteps, action.payload],
      };

    case "setFileStructureError":
      return {
        ...state,
        fileStructureError: action.payload,
      };

    case "toggleUploading":
      return {
        ...state,
        uploading: !state.uploading,
      };

    case "reset":
      return initialState;

    default:
      return state;
  }
};

interface UploadPackageFieldProps {
  hasRequirementsName?: string;
  hasUnwantedRequirements?: boolean;
  name: string;
  rules: any;
  label?: string;
  passFormValidation: any;
  withLoadedExample?: boolean;
}

export const UploadPackageField = ({
  hasRequirementsName,
  hasUnwantedRequirements = false,
  name,
  rules,
  label,
  passFormValidation,
  withLoadedExample,
}: UploadPackageFieldProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { errors, register, setValue, unregister } = useFormContext();

  useEffect(() => {
    register(name);

    return () => unregister(name);
  }, [name, register, unregister]);

  useEffect(() => {
    hasRequirementsName && register(hasRequirementsName);

    return () => {
      hasRequirementsName && unregister(hasRequirementsName);
    };
  }, [hasRequirementsName, register, unregister]);

  const formError = lodashGet(errors, name);
  const uploadErrorDialogMethods = useDialog();
  const {
    toggleDialog: toggleErrorDialog,
    open,
    setOpen,
  } = uploadErrorDialogMethods;
  const foundFailedSteps = useMemo(
    () => isArrayHasData(state.failedSteps),
    [state.failedSteps]
  );

  const fileChangedHandler = useCallback(
    async (file: any) => {
      if (file) {
        setValue(name, [file]);
        dispatch({ type: "reset" });
        dispatch({ type: "toggleUploading" });
        if (open) toggleErrorDialog();

        // skip local checks if the file is too big for ArrayBuffer
        // https://stackoverflow.com/questions/17823225/do-arraybuffers-have-a-maximum-length
        if (
          file.size > MAX_DEPLOYMENT_FILE_SIZE_TO_LOCAL_CHECK_IN_BYTE &&
          file.size < MAX_DEPLOYMENT_FILE_SIZE_IN_BYTE
        ) {
          dispatch({ type: "toggleUploading" });

          dispatch({
            type: "setContentTree",
            payload: [{ name: "File is too large to inspect" }],
          });
          dispatch({ type: "setFileName", payload: file.name });

          return;
        }

        const contentTree = await createTreeStructure(file);
        if (hasRequirementsName) {
          const files = (contentTree?.[0]?.children ?? []) as {
            name: string;
          }[];
          const hasRequirementFiles = [
            "requirements.txt",
            "ubiops.yaml",
            "renv.lock",
          ];
          const hasRequirements = !!files.find((f) =>
            hasRequirementFiles.includes(f.name)
          );
          setValue(hasRequirementsName, hasRequirements);
        }

        const isFileTypeZip = validateZipFileType(file);
        const isFileSizeValidated = validateZipFileSize(file);
        const fileStructureResult = await getZipFileDirectoryStructure(file);

        if (!isFileTypeZip) dispatch({ type: "setFailedSteps", payload: 0 });

        if (!isFileSizeValidated)
          dispatch({ type: "setFailedSteps", payload: 1 });

        if (!fileStructureResult.valid) {
          dispatch({
            type: "setFileStructureError",
            payload: fileStructureResult.reason,
          });
          dispatch({ type: "setFailedSteps", payload: 2 });
        }
        dispatch({ type: "toggleUploading" });

        dispatch({ type: "setContentTree", payload: contentTree });
        dispatch({ type: "setFileName", payload: file.name });
      }
    },
    [hasRequirementsName, name, open, setValue, toggleErrorDialog]
  );

  useEffect(() => {
    if (foundFailedSteps) {
      toggleErrorDialog();
      passFormValidation(false);
    } else {
      passFormValidation(true);
      setOpen(false);
    }

    return () => {
      passFormValidation(true);
    };
  }, [toggleErrorDialog, foundFailedSteps, passFormValidation, setOpen]);

  const RenderTree = useCallback((item: any) => {
    const RenderTreeItem = ({ item }: any) => (
      <TreeItem
        key={item.nodeId}
        nodeId={item.nodeId}
        label={item.name}
        icon={
          !item.children ? (
            <FileIcon />
          ) : !isArrayHasData(item.children) ? (
            <FolderCloseIcon />
          ) : null
        }
        collapseIcon={<FolderCloseIcon />}
        expandIcon={<FolderOpenIcon />}
      >
        {isArrayHasData(item.children) &&
          item.children.map((child: any, index: any) => (
            <Fragment key={index}>{RenderTree(child)}</Fragment>
          ))}
      </TreeItem>
    );

    return isArrayHasData(item) ? (
      item.map((obj: any, index: any) => (
        <RenderTreeItem item={obj} key={index} />
      ))
    ) : (
      <RenderTreeItem item={item} />
    );
  }, []);

  return (
    <>
      <div>
        {!withLoadedExample && (
          <UploadPackageFieldDropzone
            disabled={state.uploading}
            label={label as string}
            name={name}
            onChange={fileChangedHandler}
            rules={rules}
            uploading={state.uploading}
          />
        )}
        {isArrayHasData(state.failedSteps) ? (
          <UploadPackageFieldFileError onClick={toggleErrorDialog}>
            Failed to validate! Click to see details.
          </UploadPackageFieldFileError>
        ) : hasUnwantedRequirements ? (
          <UploadPackageFieldFileError>
            {state.fileName}
          </UploadPackageFieldFileError>
        ) : withLoadedExample ? (
          <Box paddingTop={2}>
            We got the code covered! You can check the code{" "}
            <ExternalLink href={DOC_LINKS.MULTIPLICATION_DEPLOYMENT}>
              here
            </ExternalLink>{" "}
            if you want
          </Box>
        ) : (
          <UploadPackageFieldFileName
            name={state.fileName}
            original={state.fileName === initialState.fileName}
          />
        )}

        {isArrayHasData(state.contentTree) && (
          <Box mt={2}>
            <Accordion title="Show zip file content">
              <Grid>
                <TreeView>{RenderTree(state.contentTree)}</TreeView>
              </Grid>
            </Accordion>
          </Box>
        )}

        <FormHelperText id={name} error={!!formError} hidden={!formError}>
          {formError?.message}
        </FormHelperText>
      </div>

      <PackageValidationErrorDialog
        fieldName={name}
        failedSteps={state.failedSteps}
        fileStructureError={state.fileStructureError}
        dialogMethods={uploadErrorDialogMethods}
      />
    </>
  );
};
