import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import { DESCRIPTION_FIELD_MAX_CHARACTERS } from "libs/constants/constants";
import { FIELD_DESCRIPTION } from "libs/constants/fields";
import {
  deploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import {
  pipelineVersionsGet,
  pipelineVersionsList,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";
import { EnvironmentListStatus } from "libs/data/models";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";

import { PrimaryButton, TextField } from "components/atoms";
import { FormSection, PageContainer } from "components/molecules";

import { AddDeployments } from "./AddDeployments";
import { AddEnvironments } from "./AddEnvironments";
import { AddPipelines } from "./AddPipelines";
import { useCreateExport } from "./useCreateExport";
import useEntities from "./useEntities";
import useRequiredDeployments from "./useRequiredDeployments";
import useRequiredEnvironments from "./useRequiredEnvironments";

import type { AddedEntity } from "./types";

const ExportCreate = () => {
  useGoogleAnalytics();
  const { projectName } = useParams<{ projectName: string }>();
  const { data: allEnvironments } = useEnvironmentsList(projectName);
  const [pipelines, setPipelines] = useState<AddedEntity[]>([]);
  const [deployments, setDeployments] = useState<AddedEntity[]>([]);
  const [environments, setEnvironments] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [includeEnvVars, setIncludeEnvVars] = useState(true);

  // filter out available custom envs
  const customEnvironments = allEnvironments?.filter(
    ({ system, status }) =>
      !system && status === EnvironmentListStatus.available
  );

  const {
    entities: deploymentEntities,
    fetchEntityVersions: fetchDeploymentVersions,
    setEntities: setDeploymentEntities,
  } = useEntities({
    useFetchFunction: useDeploymentsList,
    fetchVersions: deploymentVersionsList,
  });

  const {
    entities: pipelineEntities,
    fetchEntityVersions: fetchPipelineVersions,
    setEntities: setPipelineEntities,
  } = useEntities({
    useFetchFunction: usePipelinesList,
    fetchVersions: pipelineVersionsList,
    fetchVersion: pipelineVersionsGet,
  });

  const requiredDeployments = useRequiredDeployments({
    pipelineEntities,
    pipelines,
  });

  const requiredEnvironments = useRequiredEnvironments({
    deploymentEntities,
    deployments,
    customEnvironments,
  });

  const { createExport, isLoading } = useCreateExport({
    environments,
    deployments,
    customEnvironments,
    deploymentEntities,
    requiredEnvironments,
    pipelines,
    pipelineEntities,
    description,
    includeEnvVars,
  });

  const isEmptyExport =
    pipelines.length + deployments.length + environments.length === 0;

  return (
    <PageContainer>
      <PageHeader title="Create export" />
      <Grid
        container
        spacing={spacing[32]}
        paddingTop={spacing[32]}
        maxWidth={1000}
        marginLeft="auto"
        marginRight="auto"
      >
        <FormSection
          title="General"
          description={explanations.importsExports.exports.createExport.general}
        >
          <TextField
            label="Description"
            name={FIELD_DESCRIPTION}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={"Ex: my first export"}
            multiline
            minRows={3}
            inputProps={{
              maxLength: DESCRIPTION_FIELD_MAX_CHARACTERS,
            }}
          />
        </FormSection>
        <FormSection
          title="Add pipelines"
          description={
            explanations.importsExports.exports.createExport.pipelineDescription
          }
        >
          <AddPipelines
            pipelineEntities={pipelineEntities}
            fetchPipelineVersions={fetchPipelineVersions}
            pipelines={pipelines}
            setPipelines={setPipelines}
            setPipelineEntities={setPipelineEntities}
          />
        </FormSection>
        <FormSection
          title="Add deployments"
          description={
            explanations.importsExports.exports.createExport
              .deploymentDescription
          }
        >
          <AddDeployments
            deploymentEntities={deploymentEntities}
            setDeploymentEntities={setDeploymentEntities}
            fetchDeploymentVersions={fetchDeploymentVersions}
            deployments={deployments}
            setDeployments={setDeployments}
            requiredDeployments={requiredDeployments}
          />
        </FormSection>
        <FormSection
          title="Add environments"
          description={
            explanations.importsExports.exports.createExport
              .environmentDescription
          }
        >
          <AddEnvironments
            environments={environments}
            setEnvironments={setEnvironments}
            customEnvironments={customEnvironments}
            requiredEnvironments={requiredEnvironments}
          />
        </FormSection>
        <FormSection
          title="Environment variables"
          description={
            explanations.importsExports.exports.createExport
              .environmentVariables
          }
        >
          <Grid container>
            <Grid item>
              <Typography variant="body2">
                Do you want to include all the environment variables of your
                deployments and deployment versions in this export?
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeEnvVars}
                    onChange={() => setIncludeEnvVars(!includeEnvVars)}
                  />
                }
                label="Include all environment variables"
              />
            </Grid>
          </Grid>
        </FormSection>
        <Grid item container spacing={5} justifyContent="flex-end">
          <Grid item>
            <PrimaryButton
              onClick={createExport}
              disabled={isEmptyExport || isLoading}
            >
              Create export
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ExportCreate;
