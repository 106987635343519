import { Card, Tab, Tabs } from "@mui/material";
import { useCallback, useState } from "react";

import { useDeviceDetect } from "libs/hooks";

import type { ReactElement, SetStateAction } from "react";

const TabPanel = ({
  children,
  activeTab,
  tabIndex,
}: {
  children: ReactElement;
  activeTab: number;
  tabIndex: number;
}) => (activeTab === tabIndex ? children : null);

type SimpleTabsProps = {
  tabs: { title: string; content: ReactElement }[];
  onChange?: () => void;
  elevation?: number;
};

const SimpleTabs = ({ tabs, onChange, elevation = 0 }: SimpleTabsProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { isMobile } = useDeviceDetect();

  const handleChange = useCallback(
    (_: any, idx: SetStateAction<number>) => {
      if (onChange) onChange();
      setTabIndex(idx);
    },
    [onChange]
  );

  return (
    <Card variant="outlined" elevation={elevation}>
      <Tabs
        variant={isMobile ? "scrollable" : "fullWidth"}
        indicatorColor="secondary"
        textColor="secondary"
        value={tabIndex}
        onChange={handleChange}
      >
        {tabs.map(({ title }, index) => (
          <Tab key={index} label={title} style={{ flexGrow: 1 }} />
        ))}
      </Tabs>

      {tabs.map(({ content }, index) => (
        <TabPanel key={index} tabIndex={index} activeTab={tabIndex}>
          {content}
        </TabPanel>
      ))}
    </Card>
  );
};

export default SimpleTabs;
