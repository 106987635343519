import Trash from "@mui/icons-material/DeleteRounded";
import { Box, Card, Typography } from "@mui/material";
import { useReactFlow } from "reactflow";

import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";

import { Divider, ExternalLink, TextButton } from "components/atoms";

import { OPERATOR_COUNT_MANY } from "../constants";
import { IncomingEdges } from "../IncomingEdges";
import { SidebarRight } from "../SidebarRight";
import { OperatorConfigurationInfo } from "./OperatorConfigurationInfo";

import type {
  EdgeDataType,
  NodeDataType,
} from "components/templates/Diagram/types";
import type { Key } from "react";
import type { OperatorType } from "../constants";

type OperatorInfoSidebarProps = {
  id: string;
  isOpen: boolean;
  operator: OperatorType;
  onClose: () => void;
  onClickDelete: () => void;
};

export const OperatorInfoSidebar = ({
  id,
  isOpen,
  onClose,
  operator,
  onClickDelete,
}: OperatorInfoSidebarProps) => {
  const { getNode } = useReactFlow<NodeDataType, EdgeDataType>();
  const node = getNode(id);
  const operatorName = node?.data.pipelineObject.name;
  const currentOperatorConfiguration =
    node?.data?.pipelineObject?.configuration;

  if (!isOpen) {
    return null;
  }

  return (
    <SidebarRight
      title="Operator information"
      description={
        <>
          Please find the details of your operator below. For more information
          on operators, please see the{" "}
          <ExternalLink href={DOC_LINKS.OPERATORS}>documentation</ExternalLink>
        </>
      }
      onClose={onClose}
    >
      <Divider marginY={1} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin={`${spacing[12]} 0`}
      >
        <Typography variant="h6" color="textPrimary">
          {operatorName}
        </Typography>
        <TextButton
          size="small"
          style={{ textTransform: "none" }}
          color="primary"
          startIcon={<Trash />}
          onClick={() => onClickDelete()}
        >
          Delete
        </TextButton>
      </Box>
      <Divider marginY={1} />
      {/* The count many operator doesn't have a configuration */}
      {operator?.id !== OPERATOR_COUNT_MANY && (
        <>
          <OperatorConfigurationInfo
            operator={operator}
            configuration={currentOperatorConfiguration}
            id={id}
          />
          <Divider marginY={1} />
        </>
      )}

      {currentOperatorConfiguration?.input_fields && (
        <Box display="flex" flexDirection="column" margin={`${spacing[8]} 0`}>
          <Box my={1}>
            <Typography variant="h6" color="textPrimary">
              Input fields
            </Typography>
          </Box>
          <Card
            variant="outlined"
            style={{ height: "100%", padding: "9px 13px" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" color="textSecondary">
                Name
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Data type
              </Typography>
            </Box>
            <Divider my={1} />
            {currentOperatorConfiguration?.input_fields?.map(
              ({ name, data_type }, key: Key | null | undefined) => (
                <Box key={key} display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2" color="textSecondary">
                    {name}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {data_type}
                  </Typography>
                </Box>
              )
            )}
          </Card>
        </Box>
      )}
      {currentOperatorConfiguration?.output_fields && (
        <Box display="flex" flexDirection="column" margin={`${spacing[8]} 0`}>
          <Box my={1}>
            <Typography variant="h6" color="textPrimary">
              Output fields
            </Typography>
          </Box>
          <Card
            variant="outlined"
            style={{ height: "100%", padding: "9px 13px" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" color="textSecondary">
                Name
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Data type
              </Typography>
            </Box>
            <Divider my={1} />
            {currentOperatorConfiguration?.output_fields?.map(
              ({ name, data_type }, key: Key | null | undefined) => (
                <Box key={key} display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2" color="textSecondary">
                    {name}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {data_type}
                  </Typography>
                </Box>
              )
            )}
          </Card>
        </Box>
      )}
      <Box display="flex" flexDirection="column" margin={`${spacing[8]} 0`}>
        <Box my={1}>
          <Typography variant="h6" color="textPrimary">
            Incoming connections
          </Typography>
        </Box>
        <Card
          variant="outlined"
          style={{ height: "100%", padding: "9px 13px" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" color="textSecondary">
              ID
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Sources
            </Typography>
          </Box>
          <Divider my={1} />

          <IncomingEdges objectId={id} pipelineObjectName={id} />
        </Card>
      </Box>
    </SidebarRight>
  );
};
