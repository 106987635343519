import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { routes } from "routes";
import {
  setProject,
  useGetCurrentProject,
  useGetProjects,
} from "store/features/organizationProjects";
import { useGetOrganizations } from "store/features/organizations";

import { FullScreenLoader } from "components/atoms";
import { Route } from "components/utilities";

import ProjectRoutesContainer from "./:projectName";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects;

const ProjectsRoutesContainer = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { organizationName } = useParams<{ organizationName: string }>();

  const dispatch = useDispatch();
  const organizations = useGetOrganizations();
  const projects = useGetProjects();
  const currentProject = useGetCurrentProject();

  const baseUrl = useMemo(
    () => routes.organizations[":organizationName"](organizationName).projects,
    [organizationName]
  );

  useEffect(() => {
    if (
      !currentProject &&
      !isEmpty(organizations) &&
      !isEmpty(projects) &&
      match.isExact
    ) {
      // Check localStorage for previously selected project; if it exists, navigate to it
      // Also check if the previously saved project hasn't been deleted
      const savedProject = projects?.find(
        ({ id }) => id === env.get(ENV_NAMES.LAST_SEEN_PROJECT)
      );

      if (savedProject) {
        dispatch(setProject(savedProject));
        history.replace(baseUrl[":projectName"](savedProject.name).index());
      }
    }
  }, [
    currentProject,
    projects,
    history,
    organizations,
    match.isExact,
    dispatch,
    baseUrl,
  ]);

  return (
    <RootUrlContext.Provider value={match.url}>
      <BaseUrlContext.Provider value={match.url}>
        <FullScreenLoader displayed={!projects} />
        {!match.isExact && (
          <Switch>
            <Route
              path={basePath[":projectName"](":projectName").index()}
              component={ProjectRoutesContainer}
            />
            {currentProject && (
              <Redirect
                to={baseUrl[":projectName"](currentProject.name).index()}
              />
            )}
          </Switch>
        )}
      </BaseUrlContext.Provider>
    </RootUrlContext.Provider>
  );
};

export default ProjectsRoutesContainer;
