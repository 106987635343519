import { Box, Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_RETENTION_TIME,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import { getPossibleRequestRetentionTimes } from "libs/utilities/labels-mapping";
import { useGetOrganizationFeatures } from "store/features/organizations";

import { FormTextField, InfoTooltip } from "components/atoms";
import { FormSection } from "components/molecules";

import type { TemplateDeploymentListDetails } from "libs/data/models";

type AdvancedParametersProps = {
  experiment?: TemplateDeploymentListDetails;
};

export const AdvancedParameters = ({ experiment }: AdvancedParametersProps) => {
  const organizationFeatures = useGetOrganizationFeatures();
  const { setValue } = useFormContext();

  const possibleRequestRetentionTimesOptions = useMemo(
    () =>
      getPossibleRequestRetentionTimes(
        organizationFeatures?.max_retention_requests ?? 0
      ),
    [organizationFeatures]
  );

  useEffect(() => {
    setValue(
      FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
      experiment?.[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]
    );

    const retentionDefaultValue = possibleRequestRetentionTimesOptions.find(
      (option) => option.value === experiment?.[FIELD_RETENTION_TIME]
    ) ?? {
      label: experiment?.[FIELD_RETENTION_TIME],
      value: experiment?.[FIELD_RETENTION_TIME],
    };
    setValue(FIELD_RETENTION_TIME, retentionDefaultValue);
  }, [experiment, setValue, possibleRequestRetentionTimesOptions]);

  return (
    <FormSection
      title="Scaling & data retention"
      description={explanations.training.experiments.scaling}
    >
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item>
          <FormTextField
            name={FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY}
            id={FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY}
            label="Maximum number of instances"
            defaultValue={experiment?.[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            tooltip={explanations.training.experiments.maxInstances}
            fullWidth
          />
        </Grid>
        <Grid item display="flex" style={{ paddingRight: "15px" }}>
          <AutoCompleteSelectHookForm
            name={FIELD_RETENTION_TIME}
            id={FIELD_RETENTION_TIME}
            freeSolo
            defaultValue={experiment?.[FIELD_RETENTION_TIME]?.toString()}
            label="Run retention time (seconds)"
            options={possibleRequestRetentionTimesOptions}
            placeholder="Type and press enter..."
          />
          <Grid item xs={1} component={Box} paddingTop={1}>
            <InfoTooltip>
              {explanations.training.experiments.retentionTime}
            </InfoTooltip>
          </Grid>
        </Grid>
      </Grid>
    </FormSection>
  );
};
