import Delete from "@mui/icons-material/Delete";
import { Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useProjectDelete } from "libs/data/customized/projects";
import { DeleteProjectDialog } from "pages/organizations/:organizationName/overview/DeleteProjectDialog";
import { useGetCurrentProject } from "store/features/organizationProjects";

import { PrimaryButton } from "components/atoms";

export const RemoveProjectCard = () => {
  const theme = useTheme();
  const history = useHistory();
  const { organizationName } = useParams<{ organizationName: string }>();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const currentProjectName = useGetCurrentProject()?.name;
  const projectDelete = useProjectDelete(organizationName);

  const onProjectDelete = () => {
    if (currentProjectName) {
      projectDelete(currentProjectName);
    }
    setDeleteDialogOpen(false);
    history.push(`/organizations`);
  };

  return (
    <>
      <Typography variant="h6" mb={6.5}>
        Deleting a project is{" "}
        <span style={{ color: theme.palette.error.main, fontWeight: "bold" }}>
          permanent
        </span>
        , you will not be able to restore this project after deletion. By
        deleting a project you also delete all its contents.
      </Typography>

      <PrimaryButton
        onClick={() => setDeleteDialogOpen(true)}
        startIcon={<Delete />}
        style={{ backgroundColor: theme.palette.error.main }}
        size={"small"}
      >
        Delete project
      </PrimaryButton>
      <DeleteProjectDialog
        open={deleteDialogOpen}
        projectName={currentProjectName}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={onProjectDelete}
      />
    </>
  );
};
