import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, useTheme } from "@mui/material";
import { rgba } from "polished";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  addEdge,
  Background,
  BackgroundVariant,
  ControlButton,
  Controls,
  getIncomers,
  getOutgoers,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";

import {
  DIAGRAM_DRAG_ID_DEPLOYMENT,
  DIAGRAM_DRAG_ID_OPERATOR,
  DIAGRAM_DRAG_ID_PIPELINE,
  DIAGRAM_DRAG_ID_VARIABLE,
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
} from "./constants";
import { EdgeCustom } from "./EdgeCustom";
import { getId } from "./getId";
import { getLayoutedElements } from "./getLayoutedElements";
import { NodeDiamond } from "./NodeDiamond";
import { NodeOperator } from "./NodeOperator/NodeOperator";
import { NodePipelineEnd } from "./NodePipelineEnd";
import { NodePipelineStart } from "./NodePipelineStart";
import { NodeVariable } from "./NodeVariable/NodeVariable";
import { NodeDeploymentPipeline } from "./SharedDeploymentPipelineComponents";
import { suggestNewNameForPipelineObject } from "./suggestNewNameForPipelineObject";
import { NodeTypes } from "./types";
import { useZoomContext } from "./ZoomContext";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { InputOutputFieldList, OutputValueList } from "libs/data/models";
import type { DragEventHandler } from "react";
import type { Connection, Edge, Node } from "reactflow";
import type { EdgeDataType, NodeDataType } from "./types";

const edgeTypes = {
  custom: EdgeCustom,
};

const nodeTypes = {
  pipeline_start: NodePipelineStart,
  pipeline_end: NodePipelineEnd,
  deployment: NodeDeploymentPipeline,
  pipeline: NodeDeploymentPipeline,
  operator: NodeOperator,
  diamond: NodeDiamond,
  variable: NodeVariable,
};

const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};

const onDragOver: DragEventHandler = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

export type DiagramComponentProps = {
  defaultNodes: Node<NodeDataType>[];
  defaultEdges: Edge<EdgeDataType>[];
  organizationName: string;
  projectName: string;
  pipelineName: string;
  versionName: string;
  isReadonly?: boolean;
  validateDiagram: () => Promise<unknown>;
  hasSavedLayout: boolean;
};

export const DiagramComponent = ({
  defaultNodes,
  defaultEdges,
  organizationName,
  projectName,
  pipelineName,
  versionName,
  isReadonly = false,
  hasSavedLayout = false,
  validateDiagram,
}: DiagramComponentProps) => {
  const theme = useTheme() as AppThemeProps;
  const [zoomEnabled] = useZoomContext();
  const { project, getNode, fitView, getNodes, getEdges } =
    useReactFlow<NodeDataType, EdgeDataType>();

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(
    () => getLayoutedElements(defaultNodes, defaultEdges, !hasSavedLayout),
    [defaultNodes, defaultEdges, hasSavedLayout]
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [nodes, setNodes, onNodesChange] =
    useNodesState<NodeDataType>(layoutedNodes);
  const [edges, setEdges, onEdgesChange] =
    useEdgesState<EdgeDataType>(layoutedEdges);

  const reorder = useCallback(() => {
    const { nodes: orderedNodes, edges: orderedEdges } = getLayoutedElements(
      getNodes(),
      getEdges(),
      true
    );

    setNodes(orderedNodes);
    setEdges(orderedEdges);

    setTimeout(() => {
      fitView();
    }, 200);
  }, [fitView, getEdges, getNodes, setEdges, setNodes]);

  useEffect(() => {
    validateDiagram();
  }, [validateDiagram, nodes, edges]);

  const onConnect = useCallback(
    ({
      source: sourceId,
      target: targetId,
      sourceHandle,
      targetHandle,
    }: Connection) => {
      const sourceNode = getNode(sourceId || "");
      const targetNode = getNode(targetId || "");
      const sourceName = sourceNode?.data.pipelineObject.name;

      if (targetNode) {
        const incomers = getIncomers(targetNode, nodes, edges);
        const targetReferenceType =
          targetNode.data.pipelineObject.reference_type;
        const targetReferenceName =
          targetNode.data.pipelineObject.reference_name;

        if (
          targetReferenceType === OBJECT_REFERENCE_TYPE_OPERATOR &&
          targetReferenceName === OPERATOR_ONE_TO_MANY
        ) {
          if (incomers.length) {
            return setEdges((edges) => edges);
          }
        }
      }

      if (sourceNode) {
        const outgoers = getOutgoers(sourceNode, nodes, edges);
        const sourceReferenceType =
          sourceNode.data.pipelineObject.reference_type;
        const sourceReferenceName =
          sourceNode.data.pipelineObject.reference_name;

        if (
          sourceReferenceType === OBJECT_REFERENCE_TYPE_OPERATOR &&
          sourceReferenceName === OPERATOR_MANY_TO_ONE
        ) {
          if (outgoers.length) {
            return setEdges((edges) => edges);
          }
        }
      }

      if (sourceId === targetId) {
        return setEdges((edges) => edges);
      }

      if (!sourceName || !sourceId || !targetId) {
        return setEdges((edges) => edges);
      }

      const newEdge: Edge<EdgeDataType> = {
        id: getId(),
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
        source: sourceId,
        target: targetId,
        type: "custom",
        data: {
          new: true,
          organizationName,
          projectName,
          pipelineName,
          versionName,
          sources: [
            {
              source_name: sourceName,
              mapping: [],
            },
          ],
        },
      };

      return setEdges((edges) => addEdge(newEdge, edges));
    },
    [
      getNode,
      setEdges,
      organizationName,
      pipelineName,
      projectName,
      versionName,
      edges,
      nodes,
    ]
  );

  const onDrop: DragEventHandler = (event) => {
    event.preventDefault();

    if (wrapperRef.current) {
      const wrapperBounds = wrapperRef.current.getBoundingClientRect();
      const deploymentObject =
        event.dataTransfer.getData(DIAGRAM_DRAG_ID_DEPLOYMENT) &&
        JSON.parse(event.dataTransfer.getData(DIAGRAM_DRAG_ID_DEPLOYMENT));
      const pipelineObject =
        event.dataTransfer.getData(DIAGRAM_DRAG_ID_PIPELINE) &&
        JSON.parse(event.dataTransfer.getData(DIAGRAM_DRAG_ID_PIPELINE));
      const operatorObject =
        event.dataTransfer.getData(DIAGRAM_DRAG_ID_OPERATOR) &&
        JSON.parse(event.dataTransfer.getData(DIAGRAM_DRAG_ID_OPERATOR));
      const variableObject =
        event.dataTransfer.getData(DIAGRAM_DRAG_ID_VARIABLE) &&
        JSON.parse(event.dataTransfer.getData(DIAGRAM_DRAG_ID_VARIABLE));
      const position = project({
        x: event.clientX - wrapperBounds.x - 80,
        y: event.clientY - wrapperBounds.top - 20,
      });
      const type = deploymentObject
        ? NodeTypes.deployment
        : pipelineObject
        ? NodeTypes.pipeline
        : variableObject
        ? NodeTypes.variable
        : operatorObject
        ? NodeTypes.operator
        : null;
      const object = deploymentObject
        ? deploymentObject
        : pipelineObject
        ? pipelineObject
        : variableObject
        ? variableObject
        : operatorObject ?? null;

      const nodeName = suggestNewNameForPipelineObject(nodes, object.name);

      const newNode: Node<NodeDataType> | null =
        type && object
          ? {
              id: getId(),
              type,
              position,
              data: {
                new: true,
                isReadonly,
                type,
                pipelineObject: {
                  ...object,
                  name: nodeName,
                },
                organizationName,
                projectName,
                pipelineName,
                versionName,
              },
            }
          : null;

      if (newNode && type === NodeTypes.variable) {
        (
          newNode.data.pipelineObject.configuration
            ?.output_fields?.[0] as InputOutputFieldList
        ).name = nodeName;
        (
          newNode.data.pipelineObject.configuration
            ?.output_values?.[0] as OutputValueList
        ).name = nodeName;
      }

      if (newNode && type === NodeTypes.operator && object.defaultConfig) {
        newNode.data.pipelineObject.configuration = object.defaultConfig;
      }

      if (newNode) {
        setNodes((nodes) => [...nodes, newNode]);
      }
    }
  };

  return (
    <section ref={wrapperRef} style={{ flex: 1, position: "relative" }}>
      <ReactFlow
        fitView
        fitViewOptions={{ maxZoom: 1 }}
        snapToGrid
        nodesDraggable={!isReadonly}
        nodesConnectable={!isReadonly}
        elementsSelectable={!isReadonly}
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onDrop={onDrop}
        onDragOver={onDragOver}
        deleteKeyCode={null}
        zoomOnScroll={zoomEnabled && !isReadonly}
        selectNodesOnDrag={false}
      >
        <Background
          variant={BackgroundVariant.Lines}
          gap={24}
          size={1}
          color={rgba(theme.palette.pipelineDiagram.backgroundLine, 0.5)}
        />
        <Box
          sx={{
            button: {
              backgroundColor: theme.palette.pipelineDiagram.controlsBackground,
              color: theme.palette.pipelineDiagram.controlsIcons,
              borderColor: theme.palette.pipelineDiagram.controlsBorder,
              borderWidth: "1px",
              svg: {
                fill: theme.palette.pipelineDiagram.controlsIcons,
              },
            },
          }}
        >
          <Controls showInteractive={!isReadonly}>
            <ControlButton onClick={() => reorder()} title="reorder">
              <AutorenewIcon />
            </ControlButton>
          </Controls>
        </Box>
      </ReactFlow>
    </section>
  );
};
