import { useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { useUserGet } from "libs/data/endpoints/user/user";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { configureSentryScope, gtmEvent } from "libs/third-parties";
import { routes } from "routes";
import { useAuthentication } from "store/features/authentication";

import { Route } from "./Route";

import type { RouteProps } from "react-router-dom";

export const PrivateRoute = ({
  component: Component,
  ...props
}: PrivateRouteComponentProps) => {
  const location = useLocation();
  const history = useHistory();
  const [, , removeCookie] = useCustomCookies([
    COOKIE_NAMES.COOKIES_PREFERENCE,
    COOKIE_NAMES.STATE_KEY,
  ]);

  const { isAuthenticated } = useAuthentication();

  const { data: user } = useUserGet({
    swr: {
      enabled: isAuthenticated,
    },
  });
  useEffect(() => {
    configureSentryScope({
      email: user?.email,
    });
  }, [user]);

  useEffect(() => {
    if (
      location.pathname !== routes.signout.index() &&
      location.pathname !== routes.profile.account.index()
    ) {
      env.set(ENV_NAMES.REDIRECT_URL, location);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      removeCookie(COOKIE_NAMES.STATE_KEY);
      removeCookie(COOKIE_NAMES.NEWSLETTER);
    }
  }, [isAuthenticated, removeCookie]);

  useEffect(() => {
    if (window.performance) {
      // Feature detects Navigation Timing API support.
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      const timeSincePageLoad = Math.round(performance.now());

      // Sends the timing event to Google Analytics.
      gtmEvent("page-navigation-finished", timeSincePageLoad);
    }
  }, [history.location]);

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.signin.index()} />
        )
      }
    />
  );
};

type PrivateRouteComponentProps = {
  component: React.ElementType;
} & RouteProps;
