import { Box, Grid, Link, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import { spacing } from "assets/styles/theme";
import { getRandomId } from "libs/utilities/utils";

import { AddButton } from "components/atoms";

import { Field } from "../Field";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { InputOutputFieldList } from "libs/data/models";
import type { ElementType } from "react";

export type DynamicFieldsProps = {
  fields?: InputOutputFieldList[];
  name: string;
  disabled?: boolean;
  component?: ElementType;
  color?: "primary" | "default" | "inherit" | "secondary";
  placeholder?: string;
};

export const DynamicFields = ({
  fields,
  disabled = false,
  component,
  placeholder,
  name,
}: DynamicFieldsProps) => {
  const [data, setData] = useState<
    { id: string; value?: InputOutputFieldList }[]
  >([]);
  const theme = useTheme() as AppThemeProps;
  const FieldComponent = component ?? Field;
  const handleRowDelete = (index: number) => () => {
    if (data.length === 1) {
      handleClearAll();
    } else {
      setData([...data.slice(0, index), ...data.slice(index + 1)]);
    }
  };

  const handleRowAdd = () => {
    setData([...data, { id: getRandomId() }]);
  };

  const handleClearAll = () => {
    setData([]);
  };

  useEffect(() => {
    if (fields?.length) {
      setData(
        fields.map((field) => ({
          id: getRandomId(),
          value: field,
        }))
      );
    }
  }, [fields]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      gap={spacing[16]}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={spacing[8]}
        paddingTop={1}
      >
        {data.map(
          (item, index) =>
            item && (
              <FieldComponent
                index={item.id}
                key={item.id}
                onRowDelete={handleRowDelete(index)}
                defaultValue={item.value}
                disabled={disabled}
                placeholder={placeholder}
                name={name}
                isNew={index === data.length - 1}
              />
            )
        )}
      </Box>
      {!disabled && (
        <Box display="flex" alignItems="center" columnGap={spacing[16]}>
          <Grid item>
            <AddButton onClick={handleRowAdd} style={{ height: "36.5px" }}>
              Add field
            </AddButton>
          </Grid>
          <Grid item>
            <Link
              component="button"
              underline="always"
              variant="button"
              type="button"
              onClick={handleClearAll}
              style={{ color: theme.palette.neutrals[400] }}
            >
              Clear all
            </Link>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
