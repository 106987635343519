import AddBoxIcon from "@mui/icons-material/AddBox";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { spacing } from "assets/styles/theme";

import {
  Dialog,
  InfoAlert,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";

import { ImportConfirmSection } from "../../imports/create/ImportConfirmSection";
import { EntityType } from "../../imports/create/types";
import { defaultNewNamesLayer } from "../../imports/create/useImportConflicts";
import { EntityChip } from "./EntityChip";
import useEntitySelection from "./useEntitySelection";
import { useSelectAllEntities } from "./useSelectAllEntities";

import type {
  ImportDetailPipelines,
  PipelineVersionDetail,
} from "libs/data/models";
import type { AddedEntity, Entity } from "./types";

interface AddPipelinesProps {
  pipelines: AddedEntity[];
  pipelineEntities: ImportDetailPipelines | undefined;
  fetchPipelineVersions: (
    name: string
  ) => Promise<{ [key: string]: PipelineVersionDetail }>;
  setPipelines: (pipelines: AddedEntity[]) => void;
  setPipelineEntities: (entity: Entity) => void;
}

export const AddPipelines = ({
  pipelines,
  pipelineEntities,
  fetchPipelineVersions,
  setPipelines,
  setPipelineEntities,
}: AddPipelinesProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    isEntitySelected,
    toggleEntity,
    isVersionSelected,
    toggleVersion,
    setSelectedVersions,
    selectedVersions,
  } = useEntitySelection(pipelineEntities, fetchPipelineVersions);

  const { selectAll } = useSelectAllEntities(
    pipelineEntities,
    fetchPipelineVersions,
    setSelectedVersions,
    setPipelineEntities
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedVersions([]);
    }
  }, [isOpen, setSelectedVersions]);

  const onAdd = () => {
    setPipelines(selectedVersions);
    setIsOpen(false);
  };

  return (
    <>
      <SecondaryButton
        onClick={() => {
          setSelectedVersions(pipelines);
          setIsOpen(true);
        }}
        style={{
          width: "fit-content",
        }}
        startIcon={<AddBoxIcon />}
      >
        Add pipelines
      </SecondaryButton>
      <Dialog
        title="Select pipelines"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        Actions={
          <>
            <SecondaryButton
              onClick={() => setIsOpen(false)}
              style={{ marginRight: spacing[16] }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onAdd}>Add</PrimaryButton>
          </>
        }
      >
        <>
          <Typography variant="h5" marginBottom={spacing[8]}>
            Select which pipelines to add to your export
          </Typography>
          <ImportConfirmSection
            title=""
            entityType="deployment"
            newNamesLayer={defaultNewNamesLayer}
            onRowExpand={fetchPipelineVersions}
            entityRequirementType={EntityType.pipelines}
            entities={pipelineEntities}
            isEntitySelected={isEntitySelected}
            isEntityRequired={() => false}
            toggleEntity={toggleEntity}
            isVersionSelected={isVersionSelected}
            toggleVersion={toggleVersion}
            toggleAll={selectAll}
            openConflictDialog={() => false}
            isMissingEnvironmentVariables={() => false}
            isEntityNameConflict={() => false}
            disableAutoToggle
            innerScroll
          />
        </>
      </Dialog>
      {pipelines.length === 0 && (
        <InfoAlert style={{ width: "fit-content" }}>
          No pipelines selected
        </InfoAlert>
      )}
      {pipelines.length > 0 && <Typography>Added pipelines: </Typography>}
      <Box>
        {pipelines.map(({ name, version }) => (
          <EntityChip
            key={`${name}-${version}`}
            name={name}
            version={version}
            onDelete={() =>
              setPipelines(
                pipelines.filter(
                  (pipeline) =>
                    pipeline.name !== name || pipeline.version !== version
                )
              )
            }
          />
        ))}
      </Box>
    </>
  );
};
