import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  FIELD_ADVANCED_PERMISSIONS,
  FIELD_PROJECT_NAME,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";
import { useGetProjects } from "store/features/organizationProjects";

import {
  DetailsItem,
  Dialog,
  Divider,
  FormTextField,
  InfoTooltip,
  Loader,
  PrimaryButton,
  Switch,
  WarningText,
} from "components/atoms";
import { ProjectComputeLimitSliderBar } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

interface EditProjectDialogProps {
  currentProject?: any;
  loading: boolean;
  onClose: () => void;
  onConfirm: any;
  open: boolean;
}

export const EditProjectDialog = ({
  currentProject,
  loading,
  onClose,
  onConfirm,
  open,
}: EditProjectDialogProps) => {
  const theme = useTheme() as AppThemeProps;
  const projectsOfCurrentOrganization = useGetProjects();

  const formMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, control, watch } = formMethods;
  const newProjectName = watch(FIELD_PROJECT_NAME);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title="Update project settings"
      Actions={
        <PrimaryButton onClick={handleSubmit(onConfirm)}>Update</PrimaryButton>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <form>
          <FormProvider {...formMethods}>
            <DetailsItem title="Update project name" titleVariant="h6">
              <FormTextField
                id={FIELD_PROJECT_NAME}
                name={FIELD_PROJECT_NAME}
                label="Project name"
                rules={{
                  required: validators.required.message(FIELD_PROJECT_NAME),
                  pattern: {
                    value: validators.name.pattern,
                    message: validators.name.message(FIELD_PROJECT_NAME),
                  },
                }}
                defaultValue={currentProject?.name}
                placeholder="Ex: my-project-1"
              />
              {newProjectName && newProjectName !== currentProject?.name && (
                <WarningText
                  variant="caption"
                  color={theme.palette.primary.main}
                  textAlign="center"
                >
                  <Box component="span">{explanations.projects.nameUpdate}</Box>
                </WarningText>
              )}
            </DetailsItem>
            <DetailsItem
              title="Enable advanced permissions"
              titleVariant="h6"
              icon={
                <InfoTooltip>
                  {explanations.projects.advancedPermissions}
                </InfoTooltip>
              }
            >
              <Controller
                render={({ value, onChange, ...params }) => (
                  <Switch
                    checked={value}
                    onChange={(_, data) => onChange(data)}
                    {...params}
                  />
                )}
                type="checkbox"
                name={FIELD_ADVANCED_PERMISSIONS}
                control={control}
                defaultValue={currentProject?.[FIELD_ADVANCED_PERMISSIONS]}
              />
            </DetailsItem>
            {projectsOfCurrentOrganization?.length && (
              <>
                <Box margin="1em 0">
                  <Divider />
                </Box>
                <ProjectComputeLimitSliderBar />
              </>
            )}
          </FormProvider>
        </form>
      )}
    </Dialog>
  );
};
