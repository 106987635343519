import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Icon, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";

import {
  borderRadius,
  getBorders,
  getShadows,
  spacing,
} from "assets/styles/theme";

import type { TextFieldProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ForwardedRef } from "react";

interface SearchBarProps {
  onChange?: (value: string) => void;
}

export const SearchBar = forwardRef(function SearchFieldForwardRef(
  {
    onChange,
    ...props
  }: SearchBarProps & Omit<TextFieldProps, "onChange" | "innerRef">,
  ref: ForwardedRef<HTMLInputElement | undefined>
) {
  const innerRef = useRef<HTMLInputElement>();
  useImperativeHandle(ref, () => innerRef.current, []);

  const onClickClear = () => {
    if (innerRef.current) {
      innerRef.current.value = "";
    }
    onChange?.("");
  };

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <Icon component={SearchIcon} sx={{ marginInlineEnd: "5px" }} />
        ),
        endAdornment: (
          <Icon
            onClick={onClickClear}
            component={ClearIcon}
            sx={{ cursor: "pointer" }}
          />
        ),
      }}
      {...props}
      inputRef={innerRef}
      size="small"
      onChange={(e) => onChange?.(e.target.value)}
    />
  );
});

export const SearchBarCustomStyle = styled(SearchBar)((props) => ({
  ".MuiInputBase-root": {
    padding: spacing[8],
    borderRadius: borderRadius[5],
    "& > .MuiInputBase-input": {
      padding: "6px 0 7px",
    },
    "&.Mui-focused,&:hover": {
      border: getBorders(props.theme as AppThemeProps).primary,
      boxShadow: getShadows(props.theme as AppThemeProps).primary,
    },
    ":before,:after": {
      border: "none !important",
    },
  },
}));
