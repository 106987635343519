import { Box, Typography, useTheme } from "@mui/material";

import { spacing } from "assets/styles/theme";

import { Card, Chip, Divider } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { InstanceTypeItem } from "libs/data/models";

export const InstanceTypeGroupDetails = ({
  instanceTypes,
}: {
  instanceTypes: InstanceTypeItem[];
}) => {
  const theme = useTheme() as AppThemeProps;

  const groupedInstanceTypes = instanceTypes?.reduce(
    (acc: { [key: number]: InstanceTypeItem[] }, current) => {
      const priority = current.priority || 0;
      if (!acc[priority]) {
        acc[priority] = [];
      }
      acc[priority].push(current);

      return acc;
    },
    {}
  );

  return (
    <Card
      title="Instance type group details"
      contentStyle={{ paddingBottom: spacing[8] }}
    >
      {Object.entries(groupedInstanceTypes || []).map(([key, value]) => (
        <>
          <Divider />
          <Box
            pt={spacing[8]}
            pb={spacing[8]}
            display="flex"
            alignItems="center"
          >
            <Typography variant="h4">priority {Number(key) + 1}:</Typography>
            {value?.map((instance: InstanceTypeItem) => (
              <Chip
                label={instance?.display_name || instance?.name}
                key={instance?.name}
                sx={{
                  marginLeft: spacing[16],
                  backgroundColor: theme.palette.select.multiValue,
                  color: theme.palette.text.primary,
                }}
              />
            ))}
          </Box>
        </>
      ))}
    </Card>
  );
};
