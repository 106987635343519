import Mail from "@mui/icons-material/Mail";
import { useEffect, useState } from "react";

import { BaseButtonWithTooltip } from "components/atoms/Button/BaseButtonWithTooltip";

import type { OrganizationDetail } from "libs/data/models";

export type SubscribeButtonProps = {
  organization?: OrganizationDetail;
  isSidebar?: boolean;
};

export const SubscribeButton = ({
  organization,
  isSidebar = false,
}: SubscribeButtonProps) => {
  const [salesEmailContent, setSalesEmailContent] = useState({
    email: "sales@ubiops.com",
    subject: "Managing my UbiOps subscription",
    body: "I would like to [upgrade/downgrade/cancel] it.",
  });

  useEffect(() => {
    setSalesEmailContent((prevState) => ({
      ...prevState,
      subject:
        organization?.status !== "cancelled"
          ? "Managing my UbiOps subscription"
          : "Renew my UbiOps subscription",
      body: `${
        organization?.status !== "cancelled"
          ? `My current subscription is "${organization?.subscription}". I would like to [upgrade/downgrade/cancel] it.`
          : "My subscription has been cancelled. I would like to renew it."
      }`,
    }));
  }, [organization]);

  return (
    <BaseButtonWithTooltip
      startIcon={<Mail />}
      href={encodeURI(
        `mailto:${salesEmailContent.email}?subject=${salesEmailContent.subject}&body=${salesEmailContent.body}`
      )}
      color="secondary"
      variant="contained"
    >
      {isSidebar ? "My subscription" : "Contact sales"}
    </BaseButtonWithTooltip>
  );
};
