import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LogsIcon from "@mui/icons-material/Subject";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { BaseButton } from "components/atoms/Button/BaseButton";
import {
  BUILDING_DELAY,
  CONTINUOUS_REQUEST_DELAY,
} from "libs/constants/constants";
import {
  useEnvironmentBuildsGet,
  useEnvironmentsGet,
} from "libs/data/endpoints/environments/environments";
import { EnvironmentBuildListStatus } from "libs/data/models";
import { useInterval, useLogsUrl } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import { formatLabels } from "libs/utilities/labels-util";
import { LOADED } from "libs/utilities/request-statuses";
import { formatStatusLabel } from "libs/utilities/statuses";
import { EnvironmentDependencies } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/EnvironmentDependencies";

import {
  Alert,
  Card,
  CopyToClipboardButton,
  DetailsItem,
  Dialog,
  DialogHeaderTitle,
  InfoTooltip,
  Link,
  StatusIcon,
} from "components/atoms";
import { DescriptionBlock } from "components/molecules";

import { Logs } from "../../../logs";
import { loadLogs } from "../../../logs/utils";
import { UploadEnvironmentPackageButton } from "./UploadEnvironmentPackageButton";

import type { LogsCreate } from "libs/data/models";
import { useGetOrganizationFeatures } from "store/features/organizations";
import type { LogRecord } from "../../../logs/types";

export const EnvironmentDetailsGeneral = () => {
  const theme = useTheme();
  const { projectName, environmentName } =
    useParams<{ projectName: string; environmentName: string }>();
  const [isLogsDialogOpen, setIsLogsDialogOpen] = useState(false);
  const [logs, setLogs] = useState<LogRecord[]>([]);

  const { data: environmentDetails, mutate } = useEnvironmentsGet(
    projectName,
    environmentName
  );

  const organizationFeatures = useGetOrganizationFeatures();

  const { data: revision, mutate: mutateRevision } = useEnvironmentBuildsGet(
    projectName,
    environmentDetails?.name ?? "",
    environmentDetails?.latest_revision ?? "",
    environmentDetails?.latest_build ?? ""
  );
  const logsUrl = useLogsUrl({
    queryParameters: {
      environment_build_id: "",
    },
  });

  const getBuildLogs = useCallback(
    async (params: LogsCreate) => {
      const newLogs = await loadLogs(projectName, {
        ...params,
        filters: {
          environment_name: environmentName,
        },
        limit: 100,
      });
      if (newLogs.length) setLogs(logs ? logs.concat(newLogs) : newLogs);
    },
    [environmentName, logs, projectName]
  );

  const isRunning = useMemo(() => {
    return (
      revision?.status === EnvironmentBuildListStatus.building ||
      revision?.status === EnvironmentBuildListStatus.queued
    );
  }, [revision?.status]);

  useInterval(
    () => {
      if (isRunning) {
        if (logs?.length) {
          getBuildLogs({
            id: logs[logs.length - 1].id,
            date_range: CONTINUOUS_REQUEST_DELAY,
          });
        } else {
          getBuildLogs({
            date: moment(environmentDetails?.creation_date).toISOString(),
            date_range: CONTINUOUS_REQUEST_DELAY,
          });
        }
      }
    },
    [isRunning, logs, getBuildLogs, environmentDetails?.creation_date],
    CONTINUOUS_REQUEST_DELAY,
    true
  );

  useInterval(
    () => {
      if (isRunning) {
        mutate();
      }
    },
    [isRunning, mutate],
    BUILDING_DELAY
  );

  useInterval(
    () => {
      if (
        revision?.status === EnvironmentBuildListStatus.building ||
        EnvironmentBuildListStatus.queued === revision?.status
      ) {
        mutateRevision();
      }
    },
    [mutateRevision, revision?.status],
    BUILDING_DELAY
  );

  return (
    <>
      {revision?.error_message && (
        <Alert variant="filled" severity="error">
          Last revision failed: {revision?.error_message}{" "}
          {!!logs.length && (
            <Link to={`${logsUrl}${revision?.id}`}>View logs</Link>
          )}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem
              title="Reference name"
              icon={
                <InfoTooltip>
                  {explanations.environments.referenceName}
                </InfoTooltip>
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {environmentDetails?.name}
                {environmentDetails?.name && (
                  <CopyToClipboardButton
                    defaultLabel="Copy Reference name"
                    contentToCopy={environmentDetails?.name}
                    size="small"
                    htmlColor="secondary"
                  />
                )}
              </Box>
            </DetailsItem>
            {environmentDetails?.base_environment && (
              <DetailsItem title="Base environment">
                <Typography>
                  {environmentDetails?.base_environment_display_name}
                </Typography>
              </DetailsItem>
            )}
            <DetailsItem title="Type">
              <Typography>
                {environmentDetails?.base_environment
                  ? "Dependencies in package"
                  : "Docker image"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Status">
              <StatusIcon
                label={formatStatusLabel(environmentDetails?.status)}
                status={formatStatusLabel(environmentDetails?.status)}
              />
            </DetailsItem>
            {(revision?.status === EnvironmentBuildListStatus.building ||
              revision?.status === EnvironmentBuildListStatus.queued) && (
              <DetailsItem title="Latest revision status">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <StatusIcon
                    label={revision?.status ?? ""}
                    status={revision?.status ?? ""}
                  />
                  <Tooltip title="Building logs">
                    <BaseButton
                      color="secondary"
                      size="small"
                      startIcon={<LogsIcon />}
                      onClick={() => setIsLogsDialogOpen(true)}
                    >
                      Logs
                    </BaseButton>
                  </Tooltip>
                </Box>
              </DetailsItem>
            )}
            <DetailsItem title="Created">
              <Typography>
                {getTzAwareDate(environmentDetails?.creation_date).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Edited">
              <Typography>
                {getTzAwareDate(environmentDetails?.last_updated).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            {!organizationFeatures?.request_format_only && (
              <DetailsItem title="Supports request format">
                {environmentDetails?.supports_request_format ? (
                  <CheckIcon
                    fontSize="small"
                    htmlColor={theme.palette.success.main}
                  />
                ) : (
                  <CloseIcon fontSize="small" color="error" />
                )}
              </DetailsItem>
            )}
            <Grid
              container
              spacing={3}
              paddingTop={spacing[16]}
              justifyContent="center"
            >
              <Grid item>
                <UploadEnvironmentPackageButton
                  environmentStatus={environmentDetails?.status}
                  isDockerType={!environmentDetails?.base_environment}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <DescriptionBlock
          description={environmentDetails?.description}
          labels={
            environmentDetails?.labels
              ? formatLabels(environmentDetails?.labels)
              : []
          }
        />
        <Grid item xs={12} sx={{ marginTop: spacing[8] }}>
          <EnvironmentDependencies />
        </Grid>
      </Grid>
      <Dialog
        open={isLogsDialogOpen}
        onClose={() => setIsLogsDialogOpen(false)}
        Header={
          <>
            <DialogHeaderTitle>{environmentName} logs</DialogHeaderTitle>
            <StatusIcon
              label={environmentDetails?.status}
              status={environmentDetails?.status ?? ""}
            />
          </>
        }
        maxWidth="md"
        dialogBodyStyles={{
          paddingTop: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          maxHeight: "680px",
        }}
      >
        <Logs
          logSet={{ logs: logs }}
          autoLoading={isRunning}
          logsStatus={LOADED}
          refresh={false}
          displayNoLogsMessage={false}
          isBuildingLogs={true}
        />
      </Dialog>
    </>
  );
};
