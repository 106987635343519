import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { TIME_OUT_FILES } from "libs/constants/constants";
import {
  environmentRevisionsFileUpload,
  environmentsUpdate,
  useEnvironmentsGet,
  useEnvironmentsList,
} from "libs/data/endpoints/environments/environments";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type {
  EnvironmentRevisionsFileUploadBody,
  EnvironmentUpdate,
} from "libs/data/models";

export const useEnvironmentUpdate = (
  projectName: string,
  environmentName: string
) => {
  const { mutate } = useEnvironmentsList(projectName, undefined, {
    swr: {
      swrKey: `/projects/${projectName}/environments`,
    },
  });
  const { mutate: mutateDetails } = useEnvironmentsGet(
    projectName,
    environmentName
  );
  const dispatch = useDispatch();

  return useCallback(
    async (
      data: EnvironmentUpdate,
      uploadBody?: EnvironmentRevisionsFileUploadBody
    ) => {
      try {
        const result = await environmentsUpdate(
          projectName,
          environmentName,
          data
        );
        if (uploadBody) {
          await environmentRevisionsFileUpload(
            projectName,
            result.name,
            uploadBody,
            { timeout: TIME_OUT_FILES }
          );
        }
        await mutate();
        await mutateDetails();
        dispatch(createSuccessNotification(`Environment has been updated`));

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, environmentName, mutate, mutateDetails, projectName]
  );
};
