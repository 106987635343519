import { Box, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useGoogleAnalytics } from "libs/hooks";
import validators from "libs/utilities/validators";
import { useAuthentication } from "store/features/authentication";

import {
  AuthenticationLink,
  Divider,
  FormTextField,
  SecondaryButton,
} from "components/atoms";
import { AuthenticationContainer } from "components/organisms";

import { useResetPassword } from "../../libs/data/customized/user/useResetPassword";

const PasswordReset = () => {
  useGoogleAnalytics();
  const { email } = useAuthentication();

  const { resetPassword, isLoading } = useResetPassword();

  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, watch } = methods;
  const currentEmail = watch("email");

  const handleResetPassword = () => {
    resetPassword(currentEmail || email);
  };

  return (
    <AuthenticationContainer title="Reset password">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <Grid container direction="column">
            <FormTextField
              id="email"
              name="email"
              autoComplete="email"
              type="email"
              label="Email"
              rules={{
                required: validators.required.message("email"),
                pattern: {
                  value: validators.email.pattern,
                  message: validators.email.message,
                },
              }}
              defaultValue={email}
            />
            <Box mt={2}>
              <SecondaryButton
                style={{ width: "100%" }}
                type="submit"
                loading={isLoading}
              >
                Reset password
              </SecondaryButton>
            </Box>
            <Divider my={3} />
            <Box textAlign="center">
              <Box component="span">Already have login and password? </Box>
              <AuthenticationLink to="/sign-in">Sign in</AuthenticationLink>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </AuthenticationContainer>
  );
};

export default PasswordReset;
