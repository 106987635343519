import { Grid } from "@mui/material";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { DEFAULT_TIME_OUT_BATCH_REQUESTS } from "libs/constants/constants";
import {
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_OBJECT_NAME,
  FIELD_OBJECT_TYPE,
  FIELD_REQUEST_DATA,
  FIELD_SCHEDULE,
  FIELD_TIMEOUT,
  FIELD_VERSION,
} from "libs/constants/fields";
import {
  useDeploymentsList,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  usePipelinesList,
  usePipelineVersionsList,
} from "libs/data/endpoints/pipelines/pipelines";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";

import { Notes, PrimaryButton } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import {
  ControlledLabelsInput,
  FormikSelect,
  FormikTextInput,
  FormSection,
} from "components/molecules";

import { getRequestScheduleDefaultValues } from "./constants";
import CronScheduler from "./CronScheduler/CronScheduler";
import { RequestScheduleInputData } from "./RequestScheduleInputData";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect/types";
import type { FormikHelpers, FormikProps, FormikValues } from "formik";
import type {
  DeploymentList,
  PipelineList,
  ScheduleCreate,
} from "libs/data/models";

type RequestScheduleBaseFormProps = {
  handleOnSubmit: (data: ScheduleCreate, actions: FormikHelpers<any>) => void;
  defaultValues?: ScheduleCreate;
  isEditForm?: boolean;
};

const objectTypes = [
  { label: "Deployment", value: "deployment" },
  { label: "Pipeline", value: "pipeline" },
];

export const requestSchedulekCreateSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string()
    .required(validators.required.message(FIELD_NAME))
    .matches(validators.name.pattern, validators.name.message(FIELD_NAME)),
});

export const RequestScheduleBaseForm = ({
  handleOnSubmit,
  defaultValues,
  isEditForm = false,
}: RequestScheduleBaseFormProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [objects, setObjects] = useState<AutocompleteSelectOption[]>([]);
  const [selectedObject, setSelectedObject] =
    useState<PipelineList | DeploymentList | undefined>(undefined);
  const [versionOptions, setVersionOptions] = useState<
    AutocompleteSelectOption[]
  >([]);
  const [objectType, setObjectType] =
    useState<"deployment" | "pipeline">("deployment");

  const { data: pipelines } = usePipelinesList(projectName);
  const { data: deploymentList } = useDeploymentsList(projectName);
  const { data: pipelineVersions } = usePipelineVersionsList(
    projectName,
    selectedObject?.name as string,
    undefined,
    { swr: { enabled: !!selectedObject && objectType === "pipeline" } }
  );
  const { data: deploymentVersions } = useDeploymentVersionsList(
    projectName,
    selectedObject?.name as string,
    undefined,
    { swr: { enabled: !!selectedObject && objectType === "deployment" } }
  );

  // we only want deployments that support requests for scheduler
  const deployments = useMemo(
    () =>
      deploymentList?.filter(
        (deployment) => deployment?.supports_request_format
      ),
    [deploymentList]
  );

  useEffect(() => {
    if (deployments && pipelines) {
      if (defaultValues) {
        setObjectType(defaultValues.object_type);
        const objectsList =
          (defaultValues.object_type === "deployment"
            ? deployments
            : pipelines) ?? [];
        setObjects(
          objectsList?.map((object) => ({
            label: object.name,
            value: object.name,
          })) || []
        );
        setSelectedObject(
          (objectsList as DeploymentList[])?.find(
            (object: DeploymentList | PipelineList) =>
              object.name === defaultValues?.object_name
          )
        );
      } else {
        setObjects(
          deployments?.map((deployment) => ({
            label: deployment.name,
            value: deployment.name,
          })) || []
        );
        setSelectedObject(deployments[0]);
      }
    }
  }, [defaultValues, deployments, pipelines]);

  useEffect(() => {
    if (objectType === "deployment" && deploymentVersions) {
      setVersionOptions(
        deploymentVersions?.map(({ version }) => ({
          label: version,
          value: version,
        })) || []
      );
    } else if (objectType === "pipeline" && pipelineVersions) {
      setVersionOptions(
        pipelineVersions?.map(({ version }) => ({
          label: version,
          value: version,
        })) || []
      );
    }
  }, [deploymentVersions, objectType, pipelineVersions]);

  const validate = (values: ScheduleCreate) => {
    if (
      selectedObject?.input_fields?.length &&
      isEmpty(values[FIELD_REQUEST_DATA])
    )
      return { [FIELD_REQUEST_DATA]: "This field is required." };

    return;
  };

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={
        // todo: this should be removed when BE supports unified null/undefined for version in creation vs. details
        defaultValues
          ? { ...defaultValues, version: defaultValues?.version ?? undefined }
          : getRequestScheduleDefaultValues(
              selectedObject?.name ?? deployments?.[0]?.name ?? ""
            )
      }
      validationSchema={requestSchedulekCreateSchema}
      validate={validate}
    >
      {/** @ts-expect-error */}
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, setFieldValue, values } = control;

        const handleObjectTypeChange = async (
          newValue: AutocompleteSelectOption | null
        ) => {
          const value = newValue?.value as "deployment" | "pipeline";
          const selectionList =
            value === "deployment" ? deployments : pipelines;

          setObjects(
            selectionList?.map(({ name }) => ({ label: name, value: name })) ||
              []
          );
          setObjectType(value);
          setSelectedObject(selectionList?.[0]);

          await setFieldValue(FIELD_OBJECT_NAME, selectionList?.[0]?.name);
          await setFieldValue(FIELD_VERSION, undefined);
          await setFieldValue(FIELD_REQUEST_DATA, {});
        };

        const handleObjectNameChange = async (
          newValue: AutocompleteSelectOption | null
        ) => {
          const objectList =
            (objectType === "deployment" ? deployments : pipelines) ?? [];
          setSelectedObject(
            (objectList as PipelineList[])?.find(
              (object: { name: string }) => object.name === newValue?.value
            )
          );
          await setFieldValue(FIELD_REQUEST_DATA, {});
          await setFieldValue(FIELD_VERSION, undefined);
        };

        const handleVersionChange = async (newValue: string) => {
          if (!newValue) await setFieldValue(FIELD_VERSION, undefined);
        };

        return (
          <>
            <FormSection
              description={explanations.requestSchedules.description}
              title="General"
            >
              <FormikTextInput
                control={control}
                label="Name"
                placeholder="Ex: my-schedule-1"
                name={FIELD_NAME}
                showErrors
                required
              />

              <FormikTextInput
                control={control}
                label="Description"
                placeholder="Ex: A request schedule that does or runs XYZ"
                name={FIELD_DESCRIPTION}
                minRows={3}
                multiline
              />
              {!isEditForm && (
                <>
                  <FormikSelect
                    label="Object type"
                    options={objectTypes}
                    onChange={handleObjectTypeChange}
                    control={control}
                    name={FIELD_OBJECT_TYPE}
                    value={objectTypes.find(
                      (type) => type.value === values[FIELD_OBJECT_TYPE]
                    )}
                  />
                  <FormikSelect
                    control={control}
                    name={FIELD_OBJECT_NAME}
                    onChange={handleObjectNameChange}
                    label="Object name"
                    value={{
                      label: selectedObject?.name ?? "",
                      value: selectedObject?.name ?? "",
                    }}
                    options={objects}
                  />
                  <FormikSelect
                    control={control}
                    name={FIELD_VERSION}
                    options={versionOptions}
                    label="Version - optional"
                    onInputChange={handleVersionChange}
                    value={{
                      label: values[FIELD_VERSION] ?? "",
                      value: values[FIELD_VERSION] ?? "",
                    }}
                    disabled={versionOptions.length === 0}
                  />{" "}
                  <Notes>
                    Leaving this field empty allows the request schedule to
                    always use the <b>default version</b> of the selected{" "}
                    {objectType}.
                  </Notes>
                </>
              )}
            </FormSection>
            <FormSection title="Schedule">
              <CronScheduler
                control={control}
                name={FIELD_SCHEDULE}
                defaultValue={defaultValues?.[FIELD_SCHEDULE]}
              />
            </FormSection>
            <FormSection
              title="Request data"
              description={explanations.requestSchedules.requestData}
            >
              <RequestScheduleInputData
                object={selectedObject}
                type={objectType}
                control={control}
                defaultRequestData={values[FIELD_REQUEST_DATA]}
              />
            </FormSection>
            <FormSection
              title="Labels"
              description={explanations.labels.description("requestSchedules")}
            >
              <ControlledLabelsInput
                onChange={async (value: LabelsDict) => {
                  await setFieldValue(FIELD_LABELS, value);
                  await control.setFieldTouched(FIELD_LABELS, true);
                }}
                value={values[FIELD_LABELS] || {}}
              />
            </FormSection>
            <FormSection
              title="Timeout"
              description={explanations.requestSchedules.timeout}
            >
              <FormikTextInput
                control={control}
                name={FIELD_TIMEOUT}
                label="Timeout (seconds)"
                type="number"
                defaultValue={DEFAULT_TIME_OUT_BATCH_REQUESTS}
              />
            </FormSection>
            <Grid item container spacing={5} justifyContent="flex-end">
              <Grid item>
                <PrimaryButton
                  loading={control.isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </PrimaryButton>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};
