import { Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  deploymentVersionRequestsBatchDelete,
  deploymentVersionRequestsDelete,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import {
  pipelineVersionRequestsBatchDelete,
  pipelineVersionRequestsDelete,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import { DeleteDialog } from "components/atoms";

import type { AxiosError } from "axios";
import type {
  DeploymentRequestSingleDetail,
  PipelineRequestSingleDetail,
} from "libs/data/models";
import type { RequestParameter, RequestRow } from "./types";

interface RequestsDeleteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRequests: string[];
  selectedRequest: RequestRow | null;
  setSelectedRequest: (request: RequestRow) => void;
  setSelectedRequests: (ids: string[]) => void;
  requestParameters: RequestParameter;
  refresh: () => void;
}

export const RequestsDeleteDialog = ({
  isOpen,
  onClose,
  selectedRequests,
  selectedRequest,
  setSelectedRequest,
  setSelectedRequests,
  requestParameters,
  refresh,
}: RequestsDeleteDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { projectName, deploymentName, pipelineName } =
    useParams<{
      projectName: string;
      deploymentName: string;
      pipelineName: string;
    }>();
  const dispatch = useDispatch();
  const handleConfirmMultiDelete = useCallback(async () => {
    try {
      setLoading(true);
      const fetcher = deploymentName
        ? deploymentVersionRequestsBatchDelete
        : pipelineVersionRequestsBatchDelete;
      const entityName = deploymentName || pipelineName;
      if (entityName && requestParameters.resourceVersion) {
        await fetcher(
          projectName,
          entityName,
          requestParameters.resourceVersion,
          selectedRequests
        );
        setSelectedRequest(null);
        setSelectedRequests([]);
        setLoading(false);
        refresh();
        onClose();
      }
    } catch (error) {
      setLoading(false);
      const axiosError = error as AxiosError;
      setSelectedRequest(null);
      setSelectedRequests([]);
      dispatch(createErrorNotification(axiosError.message));
    }
  }, [
    deploymentName,
    requestParameters,
    pipelineName,
    projectName,
    selectedRequests,
    setSelectedRequests,
    onClose,
    setSelectedRequest,
    dispatch,
    refresh,
  ]);

  const handleConfirmSingleDelete = useCallback(() => {
    if (selectedRequest?.id) {
      const isDeployment = !!(selectedRequest as DeploymentRequestSingleDetail)
        ?.deployment;
      const isPipeline = !!(selectedRequest as PipelineRequestSingleDetail)
        ?.pipeline;
      if ((isDeployment || isPipeline) && !!selectedRequest.version) {
        const request = isDeployment
          ? deploymentVersionRequestsDelete(
              projectName,
              (selectedRequest as DeploymentRequestSingleDetail)
                .deployment as string,
              selectedRequest.version,
              selectedRequest.id
            )
          : pipelineVersionRequestsDelete(
              projectName,
              (selectedRequest as PipelineRequestSingleDetail)
                .pipeline as string,
              selectedRequest.version,
              selectedRequest.id
            );

        request
          .then(() => {
            dispatch(createSuccessNotification("Selected request deleted"));
          })
          .catch((error) => {
            dispatch(createErrorNotification(error.message));
          })
          .finally(() => {
            onClose();
            setSelectedRequest(null);
          });
      }
    }
  }, [dispatch, onClose, projectName, selectedRequest, setSelectedRequest]);

  return (
    <DeleteDialog
      open={isOpen}
      onClose={onClose}
      actionDisabled={loading}
      onDelete={() =>
        selectedRequests.length
          ? handleConfirmMultiDelete()
          : handleConfirmSingleDelete()
      }
    >
      {" "}
      <>
        <Typography variant="body2">
          {selectedRequests.length > 1
            ? "Are you sure you want to delete the selected requests and all of their content?"
            : "Are you sure you want to delete the request and all of its content?"}
        </Typography>
        {requestParameters?.type === "pipeline" && (
          <Box mt={3}>
            <b>
              Deleting a pipeline request also deletes all deployment requests
              in it.
            </b>
          </Box>
        )}
      </>
    </DeleteDialog>
  );
};
