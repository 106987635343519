import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { CreateBatchRequestSection } from "components/organisms/RequestDialog/CreateBatchRequestSection";
import { CreateDirectRequestSection } from "components/organisms/RequestDialog/CreateDirectRequestSection";
import { RequestDetails } from "components/organisms/RequestResultsDialog";
import {
  STRUCTURED_TYPE,
  TIME_OUT_PIPELINE_REQUESTS,
  TIME_OUT_REQUESTS,
} from "libs/constants/constants";
import { routes } from "routes";
import { useGetOrganizationFeatures } from "store/features/organizations";

import { Dialog, Divider, Loader, WarningText } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type {
  DeploymentDetail,
  DeploymentRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
  PipelineDetail,
  PipelineRequestCreateResponse,
} from "libs/data/models";
import type { Dispatch, SetStateAction } from "react";

export type DeploymentRequestParameters = {
  type: "deployment";
  resourceName?: string;
  resourceVersion?: string;
  inputType?: DeploymentDetail["input_type"];
  inputFields?: DeploymentDetail["input_fields"];
  outputType?: DeploymentDetail["output_type"];
  outputFields?: DeploymentDetail["output_fields"];
  requestScheduleName?: string;
};

export type PipelineRequestParameters = {
  type: "pipeline";
  resourceName?: string;
  resourceVersion?: string;
  inputType?: PipelineDetail["input_type"];
  inputFields?: PipelineDetail["input_fields"];
  outputType?: PipelineDetail["output_type"];
  outputFields?: PipelineDetail["output_fields"];
  requestScheduleName?: string;
};

type RequestDialogProps = {
  isRequestDialogOpen: boolean;
  setIsRequestDialogOpen: Dispatch<SetStateAction<boolean>>;
  status: "CREATE" | "RESULTS" | "LOADING";
  organizationName: string;
  projectName: string;
  warningText?: string;
  requestParameters: DeploymentRequestParameters | PipelineRequestParameters;
  requestTimeout: number;
  setRequestTimeout: Dispatch<SetStateAction<number>>;
  startStream: (data: DeploymentRequestsCreateDataBody) => void;
  requestResults:
    | {
        responseData:
          | DeploymentRequestCreateResponse
          | PipelineRequestCreateResponse
          | undefined;
        requestData: DeploymentRequestsCreateDataBody;
      }
    | undefined;
};

export const RequestDialog = ({
  isRequestDialogOpen,
  setIsRequestDialogOpen,
  status,
  organizationName,
  projectName,
  warningText,
  requestParameters,
  requestTimeout,
  setRequestTimeout,
  startStream,
  requestResults,
}: RequestDialogProps) => {
  const {
    type,
    resourceName,
    resourceVersion,
    inputType = STRUCTURED_TYPE,
    inputFields = [],
  } = requestParameters;
  const theme = useTheme() as AppThemeProps;
  const organizationFeatures = useGetOrganizationFeatures();
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    setIsRequestDialogOpen(false);
  }, [pathname, setIsRequestDialogOpen]);

  const createBatchRequestsUrl =
    type === "deployment"
      ? routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .deployments[":deploymentName"](resourceName || "")
          .versions[":versionName"](resourceVersion || "")
          .requests.batch.create.index()
      : type === "pipeline"
      ? routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines[":pipelineName"](resourceName || "")
          .versions[":versionName"](resourceVersion || "")
          .requests.batch.create.index()
      : "";

  const maxRequestTimeout = useMemo(
    () =>
      type === "deployment"
        ? organizationFeatures?.max_express_deployment_timeout ||
          TIME_OUT_REQUESTS
        : organizationFeatures?.max_express_pipeline_timeout ||
          TIME_OUT_PIPELINE_REQUESTS,
    [organizationFeatures, type]
  );

  return (
    <Dialog
      open={isRequestDialogOpen}
      onClose={() => {
        setIsRequestDialogOpen(false);
      }}
      maxWidth={status === "RESULTS" ? "lg" : undefined}
      title={status === "CREATE" ? "Create request" : "Results"}
    >
      {status === "CREATE" ? (
        <Grid container spacing={2} style={{ marginBottom: 3 }}>
          {warningText && (
            <Grid item>
              <WarningText color={theme.palette.primary.main}>
                {warningText}
              </WarningText>
            </Grid>
          )}

          <CreateBatchRequestSection
            objectType={type}
            createBatchRequestsUrl={createBatchRequestsUrl}
            onClickCreateBatchRequest={() => setIsRequestDialogOpen(false)}
          />

          <Grid item xs={12}>
            <Divider my={1} />
            <Typography variant="subtitle1" align="center">
              OR
            </Typography>
            <Divider my={1} />
          </Grid>

          <CreateDirectRequestSection
            objectName={resourceName as string}
            objectType={type}
            inputType={inputType}
            inputFields={inputFields}
            onSubmit={startStream}
            requestTimeout={requestTimeout}
            setRequestTimeout={setRequestTimeout}
            maxRequestTimeout={maxRequestTimeout}
          />
        </Grid>
      ) : status === "RESULTS" ? (
        <RequestDetails
          organizationName={organizationName}
          projectName={projectName}
          resourceType={type}
          resourceName={resourceName}
          resourceVersion={resourceVersion}
          requestId={requestResults?.responseData?.id}
          directResult={requestResults?.responseData}
          fetchOnlyMetadata={false}
        />
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} component={Loader} />
        </Grid>
      )}
    </Dialog>
  );
};
