import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ControlledFileUpload } from "components/molecules/ControlledInputs/ControlledFileUpload";
import { parseErrorFromControl } from "components/molecules/FormikInputs/utils";
import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DOCKER_IMAGE,
  FIELD_EXISTING_PACKAGE,
  FIELD_FILE_TYPE,
  FIELD_NEW_PACKAGE,
  FIELD_REQUIREMENTS_FILE,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import { useGetOrganizationFeatures } from "store/features/organizations";

import { FormSection } from "components/molecules";

import type { FormikProps, FormikValues } from "formik";
import type { EnvironmentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/types";
import type { ChangeEvent } from "react";

export interface CustomDependenciesSectionProps {
  control: FormikProps<FormikValues>;
  dependenciesRequired?: boolean;
  disabled?: boolean;
}

export const CustomDependenciesSection = ({
  control,
  dependenciesRequired,
  disabled = false,
}: CustomDependenciesSectionProps) => {
  const { environmentName } = useParams<EnvironmentDetailsRouteParams>();
  const organizationFeatures = useGetOrganizationFeatures();
  const [selected, setSelected] = useState(
    dependenciesRequired ? FIELD_NEW_PACKAGE : FIELD_EXISTING_PACKAGE
  );
  const [type, setType] = useState(control.values[FIELD_FILE_TYPE]);

  useEffect(() => {
    if (!dependenciesRequired) {
      control.setFieldValue(FIELD_REQUIREMENTS_FILE, {
        source_environment: environmentName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesRequired]);

  const handleOnChange = (file: File) => {
    control.setFieldValue(FIELD_REQUIREMENTS_FILE, { file }, true);
    control.setFieldTouched(FIELD_REQUIREMENTS_FILE, true);
  };

  const handleOnToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;

    setSelected(value);

    if (value === FIELD_EXISTING_PACKAGE) {
      control.setFieldValue(FIELD_REQUIREMENTS_FILE, {
        source_environment: environmentName,
      });
    } else {
      control.setFieldValue(FIELD_REQUIREMENTS_FILE, null, true);
      control.setFieldTouched(FIELD_REQUIREMENTS_FILE, true);
    }
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;

    setType(value);
    control.setFieldValue(FIELD_FILE_TYPE, value);
    if (value === FIELD_DOCKER_IMAGE) {
      control.setFieldValue(
        FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
        undefined
      );
    }
  };

  return (
    <FormSection
      title="Custom dependencies"
      description={
        type === FIELD_REQUIREMENTS_FILE
          ? explanations.environments.customDependencies
          : explanations.environments.dockerDependency
      }
    >
      <>
        {!dependenciesRequired && (
          <RadioGroup onChange={handleOnToggle} value={selected}>
            <FormControlLabel
              control={<Radio color="secondary" />}
              label="Use existing file"
              value={FIELD_EXISTING_PACKAGE}
            />
            <FormControlLabel
              control={<Radio color="secondary" />}
              label="Upload new file"
              value={FIELD_NEW_PACKAGE}
            />
          </RadioGroup>
        )}

        {selected === FIELD_NEW_PACKAGE && (
          <>
            {organizationFeatures?.custom_images_enabled && (
              <RadioGroup row onChange={handleTypeChange} value={type}>
                <FormControlLabel
                  disabled={disabled}
                  control={<Radio color="secondary" />}
                  label="Package with dependencies"
                  value={FIELD_REQUIREMENTS_FILE}
                />
                <FormControlLabel
                  disabled={disabled}
                  control={<Radio color="secondary" />}
                  label="Docker image"
                  value={FIELD_DOCKER_IMAGE}
                />
              </RadioGroup>
            )}
            <ControlledFileUpload
              error={parseErrorFromControl(control, FIELD_REQUIREMENTS_FILE)}
              onChange={handleOnChange}
              value={control.values[FIELD_REQUIREMENTS_FILE]?.file}
              allowedTypes={
                type === FIELD_REQUIREMENTS_FILE
                  ? ["zip", "txt", "R", "yaml"]
                  : undefined
              }
            />
          </>
        )}
      </>
    </FormSection>
  );
};
