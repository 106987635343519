import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import ReloadIcon from "@mui/icons-material/ReplayRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormProvider, useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import { LINK_PRIVACY } from "libs/constants/documentation-links";
import {
  FIELD_EMAIL,
  FIELD_NAME,
  FIELD_NEWSLETTER,
  FIELD_SURNAME,
} from "libs/constants/fields";
import {
  allCookies,
  COOKIE_NAMES,
  requiredCookies,
  useCustomCookies,
} from "libs/cookies";
import { useUserUpdate } from "libs/data/customized/user";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useGoogleAnalytics } from "libs/hooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import { getChanges } from "libs/utilities/patch-helper";
import validators from "libs/utilities/validators";

import {
  Card,
  Checkbox,
  DeleteButton,
  DeleteDialog,
  ExternalLink,
  FormTextField,
  Loader,
  Notes,
  PrimaryButton,
} from "components/atoms";
import { PageContainer } from "components/molecules";

import { useDeleteUser } from "../../../libs/data/customized/user/useDeleteUser";
import { useSignOut } from "../../../libs/hooks/useSignOut";
import PasswordUpdate from "./PasswordUpdate";

const AccountDetails = () => {
  useGoogleAnalytics();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: user, error, mutate } = useUserGet();
  const userLoading = !user && !error;

  const updateUser = useUserUpdate();
  const deleteUser = useDeleteUser();
  const signOut = useSignOut();

  const match = useRouteMatch();

  const [cookies, setCookie, removeCookie] = useCustomCookies();

  const [defaultCookiesPreference] = useState(
    !cookies[COOKIE_NAMES.COOKIE_CONSENT]?.marketing
  );

  const formMethods = useForm();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onRowDelete = () => {
    setDialogOpen(true);
  };

  const onDelete = async () => {
    await deleteUser();
    signOut();
    setDialogOpen(false);
  };

  const onSubmit = async (data: Record<string, string>) => {
    if (!user) return;
    const updatedUser = {
      ...data,
    };
    delete updatedUser[COOKIE_NAMES.COOKIES_PREFERENCE];
    const changes = getChanges(
      updatedUser,
      user as unknown as Record<string, string>
    );
    if (Object.keys(changes).length) {
      await updateUser(updatedUser);
      mutate();
    }

    const expires = new Date(getTzAwareDate().add(1, "years").toDate());

    const allCookiesEnabled = cookies[COOKIE_NAMES.COOKIE_CONSENT]?.marketing;

    // remove all marketing cookies
    if (data[COOKIE_NAMES.COOKIES_PREFERENCE]) {
      Object.keys(cookies).forEach((cookie) => {
        if (
          ![COOKIE_NAMES.COOKIE_CONSENT, COOKIE_NAMES.STATE_KEY].includes(
            cookie
          )
        ) {
          removeCookie(cookie);
          removeCookie(cookie, { path: "/", domain: ".ubiops.dev" });
          removeCookie(cookie, { path: "/", domain: ".ubiops.com" });
        }
      });
    }

    setCookie(
      COOKIE_NAMES.COOKIE_CONSENT,
      data[COOKIE_NAMES.COOKIES_PREFERENCE] ? requiredCookies : allCookies,
      {
        expires,
      }
    );

    if (data[COOKIE_NAMES.COOKIES_PREFERENCE] === allCookiesEnabled) {
      enqueueSnackbar("Your cookies preference has been saved.", {
        variant: "success",
        persist: Boolean(data[COOKIE_NAMES.COOKIES_PREFERENCE]),
        action: (key) => (
          <>
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              onClick={() => window.location.reload()}
              startIcon={<ReloadIcon fontSize="small" />}
            >
              Reload to apply
            </Button>
            <IconButton
              onClick={() => closeSnackbar(key)}
              size="small"
              color="inherit"
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Account" />
      <BreadcrumbsItem to={match.url}>Account</BreadcrumbsItem>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card
            title="Main Settings"
            icon={SettingsIcon}
            contentStyle={{
              paddingLeft: spacing[48],
              paddingRight: spacing[80],
            }}
          >
            {userLoading ? (
              <Loader />
            ) : (
              <FormProvider {...formMethods}>
                <form
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 8,
                    alignItems: "flex-start",
                  }}
                >
                  {user?.authentication === "ubiops" && (
                    <FormTextField
                      id={FIELD_EMAIL}
                      name={FIELD_EMAIL}
                      label="Email"
                      rules={{
                        required: validators.required.message(FIELD_EMAIL),
                        pattern: {
                          value: validators.email.pattern,
                          message: validators.email.message,
                        },
                      }}
                      defaultValue={user?.email}
                      autoCapitalize="none"
                    />
                  )}
                  <FormTextField
                    id={FIELD_NAME}
                    name={FIELD_NAME}
                    label="First name"
                    rules={{
                      required: validators.required.message(FIELD_NAME),
                    }}
                    defaultValue={user?.name}
                  />
                  <FormTextField
                    id={FIELD_SURNAME}
                    name={FIELD_SURNAME}
                    label="Last name"
                    rules={{
                      required: validators.required.message(FIELD_SURNAME),
                    }}
                    defaultValue={user?.surname}
                  />
                  <Checkbox
                    name={FIELD_NEWSLETTER}
                    color="primary"
                    defaultChecked={user?.newsletter}
                    label={
                      <Typography variant="body2">
                        Keep me posted on UbiOps product updates, news and
                        special offers.
                      </Typography>
                    }
                  />
                  <Checkbox
                    name={COOKIE_NAMES.COOKIES_PREFERENCE}
                    color="primary"
                    defaultChecked={defaultCookiesPreference}
                    label={
                      <Typography variant="body2">
                        Decline the use of cookies.
                      </Typography>
                    }
                  />
                  <Notes component="div" ml={4} mt={-3}>
                    <p>
                      To find out more about the cookies we use, see our{" "}
                      <ExternalLink href={LINK_PRIVACY}>
                        Privacy Policy
                      </ExternalLink>
                      .
                    </p>
                    <p>
                      If you decline, a single cookie will be used in your
                      browser to remember your preference not to be tracked.
                    </p>
                  </Notes>
                  <PrimaryButton type="submit" disabled={!user}>
                    Save
                  </PrimaryButton>
                </form>
              </FormProvider>
            )}
          </Card>
        </Grid>
        <Grid item container rowGap={2} xs={12} md={6}>
          <Grid item xs={12}>
            <Card title="Change your password" icon={VpnKeyIcon}>
              <PasswordUpdate />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              title="Delete Account"
              icon={Delete}
              contentStyle={{
                paddingLeft: spacing[48],
                paddingRight: spacing[48],
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                rowGap: 24,
              }}
            >
              <Typography>
                Deleting an account will permanently erase the user from the
                file system. You won&apos;t be able to restore it.
              </Typography>
              <DeleteButton onClick={onRowDelete} />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <DeleteDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        onDelete={onDelete}
      >
        Are you sure you want to delete your account permanently?
      </DeleteDialog>
    </PageContainer>
  );
};

export default AccountDetails;
