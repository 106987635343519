import * as Yup from "yup";

import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DESCRIPTION,
  FIELD_DISPLAY_NAME,
  FIELD_FILE_TYPE,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_REQUIREMENTS_FILE,
  FIELD_SUPPORT_REQUESTS,
} from "libs/constants/fields";
import validators, { yupValidators } from "libs/utilities/validators";

import type { LabelsDict } from "components/molecules";

import type { EnvironmentRevisionsFileUploadBody } from "libs/data/models";

export interface EnvironmentBaseProps {
  [FIELD_NAME]: string;
  [FIELD_DISPLAY_NAME]?: string | null;
  [FIELD_DESCRIPTION]: string;
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]?: string | null;
  [FIELD_REQUIREMENTS_FILE]?: EnvironmentRevisionsFileUploadBody;
  [FIELD_LABELS]?: LabelsDict;
  [FIELD_FILE_TYPE]: string;
  [FIELD_SUPPORT_REQUESTS]: boolean;
}

export const EnvironmentBaseValues: EnvironmentBaseProps = {
  [FIELD_NAME]: "",
  [FIELD_DISPLAY_NAME]: undefined,
  [FIELD_DESCRIPTION]: "",
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]: undefined,
  [FIELD_REQUIREMENTS_FILE]: undefined,
  [FIELD_LABELS]: {},
  [FIELD_FILE_TYPE]: FIELD_REQUIREMENTS_FILE,
  [FIELD_SUPPORT_REQUESTS]: true,
};

export const EnvironmentBaseSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string()
    .required(validators.required.message(FIELD_NAME))
    .matches(validators.name.pattern, validators.name.message(FIELD_NAME))
    .test("reserved_name", validators.name.reservedNameYup("environment")),
  [FIELD_DESCRIPTION]: Yup.string(),
  [FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]: Yup.string().nullable(),
  [FIELD_REQUIREMENTS_FILE]: Yup.mixed()
    .required(validators.required.message("file"))
    .test("file_type", yupValidators.fileExtension.message, function (value) {
      const { file_type } = this.parent;
      if (file_type === FIELD_REQUIREMENTS_FILE)
        return yupValidators.fileExtension.test(value);
      else return true;
    }),
});
