import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import PlayArrow from "@mui/icons-material/PlayArrow";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import {
  DEPLOYMENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { useDeploymentDelete } from "libs/data/customized/deployment/useDeploymentDelete";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  useDeploymentsGet,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import { useLogsUrl, useRequest } from "libs/hooks";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";
import { useGetPermissions } from "store/features/permissions";

import {
  ButtonGroup,
  DeleteDialog,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import { RequestDialog } from "components/organisms";

import type { TabType } from "components/atoms/Tabs/PageTabs";
import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";
import type { ReactNode } from "react";

interface DeploymentDetailsProps {
  children?: ReactNode;
}

export const DeploymentDetails = ({ children }: DeploymentDetailsProps) => {
  const { organizationName, projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();

  const deleteDeployment = useDeploymentDelete(projectName);
  const organization = useGetCurrentOrganization();

  const { data: deployment } = useDeploymentsGet(projectName, deploymentName);

  const { data: versions } = useDeploymentVersionsList(
    projectName,
    deploymentName
  );

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const [projectPermissions] = useGetPermissions();

  const defaultVersion = useMemo(
    () =>
      deployment &&
      versions?.find(
        (version) => version.version === deployment.default_version
      ),
    [deployment, versions]
  );

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deploymentName),
    [deploymentName, organizationName, projectName]
  );
  const logsUrl = useLogsUrl({
    queryParameters: { deployment_name: deploymentName },
  });
  const deploymentsOverviewUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments.index(),
    [organizationName, projectName]
  );
  const history = useHistory();
  const isTrainingDeployment = useMemo(
    () => deployment && deployment?.name === TRAINING_DEPLOYMENT,
    [deployment]
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { openCreateDialog, ...requestProps } = useRequest({
    type: "deployment",
    instance: deployment,
    isRetentionModeNone: defaultVersion?.request_retention_mode === "none",
  });

  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    deployment?.supports_request_format && {
      link: baseUrl.configuration.index(),
      label: "Configuration",
    },
    {
      link: baseUrl.auditEvents.index(),
      label: "Audit events",
      disabled: !currentPermissions[DEPLOYMENT_PERMISSIONS["audit_list"]],
    },
    {
      link: baseUrl.environmentVariables.index(),
      label: "Environment variables",
      disabled: !currentPermissions[DEPLOYMENT_PERMISSIONS["env_vars_list"]],
    },
    deployment?.supports_request_format && {
      link: baseUrl.useDeployment.index(),
      label: "Use deployment",
      disabled: !defaultVersion,
      disabledText:
        "There is no default version available, please create a version first and set a default version to start using your deployment.",
    },
  ].filter(Boolean);

  const handleOnDelete = async () => {
    const response = await deleteDeployment(deploymentName);

    if (response) {
      history.replace(deploymentsOverviewUrl);
    }
  };

  return (
    <>
      <RequestDialog
        organizationName={organizationName}
        projectName={projectName}
        {...requestProps}
      />

      <DetailsContainer
        title={deploymentName}
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={
                isTrainingDeployment ||
                !currentPermissions[DEPLOYMENT_PERMISSIONS["update"]] ||
                organization?.status !== "active"
              }
              link={baseUrl.edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>
            <TextButton
              color="secondary"
              disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
              link={logsUrl}
              startIcon={<LogsIcon />}
            >
              Logs
            </TextButton>
            <TextButton
              color="secondary"
              disabled={
                isTrainingDeployment ||
                !projectPermissions[DEPLOYMENT_PERMISSIONS["create"]] ||
                organization?.status !== "active"
              }
              link={baseUrl.duplicate.index()}
              startIcon={<Duplicate />}
            >
              Duplicate
            </TextButton>
            <TextButton
              color="primary"
              disabled={
                isTrainingDeployment ||
                !currentPermissions[DEPLOYMENT_PERMISSIONS["delete"]]
              }
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            {deployment?.supports_request_format && (
              <PrimaryButton
                startIcon={<PlayArrow />}
                disabled={
                  isTrainingDeployment ||
                  !defaultVersion ||
                  !currentPermissions[
                    DEPLOYMENT_PERMISSIONS["version_request_create"]
                  ] ||
                  defaultVersion.status !== "available"
                }
                onClick={openCreateDialog}
                style={{ marginLeft: "auto" }}
              >
                Create request
              </PrimaryButton>
            )}
          </ButtonGroup>
        }
      >
        {deployment && <PageTabs tabs={tabs as TabType[]}>{children}</PageTabs>}
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={handleOnDelete}
        >
          Are you sure you want to delete deployment {`"`}
          <b>{deploymentName}</b>
          {`"`} and all of its versions?
        </DeleteDialog>
      </DetailsContainer>
    </>
  );
};
