import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_CONFIGURATION,
  FIELD_CREDENTIALS,
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_PROVIDER,
  FIELD_TTL,
} from "libs/constants/fields";
import { useBucketUpdate } from "libs/data/customized/buckets/useBucketUpdate";
import { useBucketsGet } from "libs/data/endpoints/files/files";
import { routes } from "routes";

import { FormWrapper, Loader } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import { PageContainer } from "components/molecules";

import { BucketBaseForm } from "../BucketsBaseForm";

import type { FormikHelpers } from "formik";
import type { BucketUpdate } from "libs/data/models";
import type { BucketDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/storage/buckets/:bucketName/types";
import type { BucketBaseProps } from "../BucketsBaseForm/constants";

export const BucketEdit = () => {
  const { organizationName, projectName, bucketName } =
    useParams<BucketDetailsRouteParams>();

  const { data: bucket, error } = useBucketsGet(projectName, bucketName);

  const updateBucket = useBucketUpdate(projectName, bucketName);

  const history = useHistory();

  const handleOnSubmit = async (
    values: BucketBaseProps,
    actions: FormikHelpers<BucketBaseProps>
  ) => {
    if (Object.values(values.credentials ?? {}).every((value) => !value))
      delete values.credentials;

    const response = await updateBucket(values as BucketUpdate);
    actions.setSubmitting(false);

    if (response) {
      actions.resetForm();

      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .storage[":bucketName"](bucketName)
          .general.files.index()
      );
    }
  };

  const isFormLoading = !bucket && !error;

  return (
    <PageContainer>
      <PageHeader title={`Edit ${bucketName}`} />
      <FormWrapper>
        {!isFormLoading ? (
          <BucketBaseForm
            initialValues={{
              [FIELD_DESCRIPTION]: bucket?.[FIELD_DESCRIPTION],
              [FIELD_PROVIDER]: bucket?.[FIELD_PROVIDER],
              [FIELD_TTL]: bucket?.[FIELD_TTL],
              [FIELD_CONFIGURATION]: bucket?.[FIELD_CONFIGURATION],
              [FIELD_CREDENTIALS]: undefined,
              [FIELD_LABELS]: (bucket?.[FIELD_LABELS] as LabelsDict) ?? {},
            }}
            onSubmit={handleOnSubmit}
            editing
          />
        ) : (
          <Loader />
        )}
      </FormWrapper>
    </PageContainer>
  );
};
