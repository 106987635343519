import { Box, useTheme } from "@mui/material";

import { borderRadius, getBorders, spacing } from "assets/styles/theme";
import { explanations } from "libs/utilities/explanations";

import { FormSection } from "components/molecules";

import type { ReactNode } from "react";

type SupportRequestSectionProps = {
  children: ReactNode;
  isChecked: boolean;
  entity: string;
};

export const SupportRequestSection = ({
  children,
  isChecked,
  entity,
}: SupportRequestSectionProps) => {
  const theme = useTheme();

  return (
    <FormSection
      title={"Supports request format"}
      description={explanations.supportsRequestFormat(entity)}
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box
          sx={{
            paddingLeft: spacing[16],
            border: isChecked
              ? getBorders(theme).thick
              : getBorders(theme).primary,
            borderRadius: borderRadius[8],
          }}
        >
          {children}
        </Box>
      </Box>
    </FormSection>
  );
};
