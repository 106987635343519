import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";
import { REQUEST_DATA_MAX_SIZE } from "libs/constants/constants";

import { DetailsDialogItem, Icon, PrimaryButton } from "components/atoms";

import { RequestResultsData } from "./RequestResultsData";

import type {
  DeploymentRequestSingleDetailRequestData,
  DeploymentRequestSingleDetailResult,
  InputOutputFieldDetail,
  InputOutputFieldList,
} from "libs/data/models";
import type { RequestResultsDataProps } from "./RequestResultsData";

export interface RequestResultsSectionProps
  extends Omit<RequestResultsDataProps, "fields"> {
  data?: DeploymentRequestSingleDetailRequestData;
  result?: DeploymentRequestSingleDetailResult;
  inputSize?: number | null;
  outputSize?: number | null;
  handleViewHugeResult: () => void;
  inputFields: InputOutputFieldList[] | InputOutputFieldDetail[];
  outputFields: InputOutputFieldList[] | InputOutputFieldDetail[];
}

export const RequestResultSection = ({
  data,
  result,
  organizationName,
  projectName,
  inputFields,
  outputFields,
  error,
  inputSize,
  outputSize,
  handleViewHugeResult,
}: RequestResultsSectionProps) => {
  const [loading, setLoading] = useState(false);

  const handleViewResults = async () => {
    setLoading(true);
    await handleViewHugeResult();
    setLoading(false);
  };

  return (
    <Grid container item spacing={2}>
      {(inputSize || 0) > REQUEST_DATA_MAX_SIZE ||
      (outputSize || 0) > REQUEST_DATA_MAX_SIZE ? (
        <Grid item xs={10} spacing={2}>
          <DetailsDialogItem
            title="Request data - Results"
            fullWidth
            isLoaded={!!(inputSize && outputSize)}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Icon component={WarningIcon} status="warning" />
              <Typography>Too large to display</Typography>
            </Box>
            <PrimaryButton
              startIcon={<OpenInNewIcon />}
              onClick={handleViewResults}
              loading={loading}
              style={{ marginBottom: spacing[20], marginTop: spacing[8] }}
              size="small"
            >
              View in new tab
            </PrimaryButton>
          </DetailsDialogItem>
        </Grid>
      ) : (
        <>
          {" "}
          <Grid item xs={6}>
            <DetailsDialogItem
              title="Request data"
              fullWidth={true}
              isLoaded={!!data}
            >
              <RequestResultsData
                data={data}
                error={!!error}
                fields={inputFields}
                projectName={projectName}
                organizationName={organizationName}
              />
            </DetailsDialogItem>
          </Grid>
          <Grid item xs={6}>
            <DetailsDialogItem
              title="Results"
              fullWidth={true}
              isLoaded={!!result}
            >
              <RequestResultsData
                data={result}
                error={!!error}
                fields={outputFields}
                projectName={projectName}
                organizationName={organizationName}
              />
            </DetailsDialogItem>
          </Grid>
        </>
      )}
    </Grid>
  );
};
