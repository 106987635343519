import { useController } from "react-hook-form";

import { Switch } from "./Switch";

import type { UseControllerOptions } from "react-hook-form";
import type { SwitchProps } from "./Switch";

export const FormSwitch = ({
  name,
  rules,
  onFocus,
  defaultValue,
  control,
  ...props
}: UseControllerOptions & Omit<SwitchProps, "checked" | "onChange">) => {
  const {
    field: { onChange, value, ...params },
  } = useController({
    name,
    rules,
    onFocus,
    defaultValue,
    control,
  });

  return (
    <Switch
      {...props}
      {...params}
      type="checkbox"
      checked={value}
      onChange={(_, data) => onChange(data)}
    />
  );
};
