import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import Send from "@mui/icons-material/Send";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { TestResult } from "components/atoms/TestResult/TestResult";
import { DetailsContainer } from "components/molecules/PageLayout";
import { WebHookSecretDialog } from "components/organisms/WebHookSecretDialog";
import {
  PROJECT_PERMISSIONS,
  WEBHOOKS_PERMISSIONS,
} from "libs/constants/permissions";
import {
  useTestWebHook,
  useWebHookDelete,
} from "libs/data/customized/webhooks";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import {
  useWebhooksGet,
  webhooksUpdate,
} from "libs/data/endpoints/webhooks/webhooks";
import { useLogsUrl } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import { formatLabels } from "libs/utilities/labels-util";
import { toUpperFirstCase } from "libs/utilities/util-functions";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import {
  ButtonGroup,
  Card,
  CopyToClipboardButton,
  DeleteDialog,
  DetailsItem,
  Dialog,
  ExternalLink,
  HighlightedText,
  JsonPreview,
  Link,
  Loader,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import { DescriptionBlock } from "components/molecules";

import { eventTriggerLabels } from "../../constants";

import type { WebhookHeader } from "libs/data/models";
import type { WebHookCreateProps } from "../../types";

export const WebHookDetails = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [isTestDialogOpen, setIsTestDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { organizationName, projectName, webHookName } =
    useParams<{
      organizationName: string;
      projectName: string;
      webHookName: string;
    }>();

  const { data: webHookDetails, mutate } = useWebhooksGet(
    projectName,
    webHookName
  );

  const { data: deployment } = useDeploymentsGet(
    projectName,
    webHookDetails?.object_name ?? "",
    { swr: { enabled: webHookDetails?.object_type === "deployment" } }
  );
  const { data: pipeline } = usePipelinesGet(
    projectName,
    webHookDetails?.object_name ?? "",
    { swr: { enabled: webHookDetails?.object_type === "pipeline" } }
  );

  const {
    testWebHook,
    isLoading,
    isSuccess,
    errorMessage,
    setSecretDialogOpen,
    secretDialogOpen,
  } = useTestWebHook();

  const deleteWebHook = useWebHookDelete(projectName);

  const logsUrl = useLogsUrl({
    queryParameters: { webhook_name: webHookName },
  });

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .monitoring.webHooks[":webHookName"](webHookName),
    [webHookName, organizationName, projectName]
  );

  const missingWebhookHeaders = useMemo(
    () =>
      webHookDetails?.headers?.filter(
        (header) => header.protected && header.value === null
      ),
    [webHookDetails?.headers]
  );

  const [currentPermissions] = useGetPermissions();

  const getObjectLink = (objectType: string, objectName: string) =>
    `/organizations/${organizationName}/projects/${projectName}/${objectType}s/${objectName}/general`;

  const getObjectVersionLink = (
    objectType: string,
    objectName: string,
    version: string
  ) =>
    `/organizations/${organizationName}/projects/${projectName}/${objectType}s/${objectName}/versions/${version}/general`;

  const handleChange = (property: string, checked: boolean) => {
    webhooksUpdate(projectName, webHookName, {
      [property]: checked,
      url: webHookDetails?.url as string,
    }).then(mutate);
  };

  const getEventLabel = (event: string) => {
    const trigger = event?.substring(event?.lastIndexOf("_") + 1);

    return eventTriggerLabels[trigger as keyof typeof eventTriggerLabels];
  };

  const formatHeaders = (headers: WebhookHeader[]) => {
    const headerObject = headers.reduce(
      (prevValue, header) => ({
        ...prevValue,
        [header.key]: header.protected ? "*****" : header.value,
      }),
      {}
    );

    return JSON.stringify(headerObject, null, 4);
  };

  const handleDelete = async (name: string) => {
    await deleteWebHook(name);
    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .monitoring.webHooks.index()
    );
  };

  const onTestWebhookClick = () => {
    if (!webHookDetails) return;
    if (missingWebhookHeaders?.length && missingWebhookHeaders?.length > 0) {
      setSecretDialogOpen(true);
    } else {
      testWebHook(webHookDetails as WebHookCreateProps);
      setIsTestDialogOpen(true);
    }
  };

  const testWithSecretValues = (headers: WebhookHeader[] | undefined) => {
    testWebHook({
      ...webHookDetails,
      headers: headers || webHookDetails?.headers,
    } as WebHookCreateProps);
    setIsTestDialogOpen(true);
  };

  return (
    <DetailsContainer
      title={webHookName}
      actions={
        <ButtonGroup>
          <TextButton
            color="secondary"
            disabled={!currentPermissions[WEBHOOKS_PERMISSIONS["update"]]}
            link={baseUrl.edit.index()}
            startIcon={<Edit />}
          >
            Edit
          </TextButton>
          <TextButton
            color="secondary"
            disabled={!currentPermissions[PROJECT_PERMISSIONS["logs_get"]]}
            link={logsUrl}
            startIcon={<LogsIcon />}
          >
            Logs
          </TextButton>

          <TextButton
            disabled={!currentPermissions[WEBHOOKS_PERMISSIONS["delete"]]}
            onClick={() => setIsDeleteDialogOpen(true)}
            startIcon={<Trash />}
          >
            Delete
          </TextButton>
          <PrimaryButton
            startIcon={<Send />}
            onClick={onTestWebhookClick}
            style={{ marginLeft: "auto" }}
          >
            Test webhook
          </PrimaryButton>
        </ButtonGroup>
      }
    >
      <BreadcrumbsItem to={match.url}>{webHookName}</BreadcrumbsItem>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="Callback URL">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <ExternalLink
                  style={{
                    width: spacing[220],
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  href={webHookDetails?.url as string}
                  target="_blank"
                >
                  {webHookDetails?.url}
                </ExternalLink>
                {webHookDetails?.url && (
                  <CopyToClipboardButton
                    defaultLabel="Copy callback URL"
                    contentToCopy={webHookDetails?.url}
                    size="small"
                    htmlColor="secondary"
                  />
                )}
              </Box>
            </DetailsItem>
            <DetailsItem title="Object Type">
              <Typography>
                {toUpperFirstCase(webHookDetails?.object_type ?? "")}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Object name">
              <Link
                to={getObjectLink(
                  webHookDetails?.object_type ?? "",
                  webHookDetails?.object_name ?? ""
                )}
              >
                {webHookDetails?.object_name}
              </Link>
            </DetailsItem>
            <DetailsItem title="Version">
              <Link
                to={getObjectVersionLink(
                  webHookDetails?.object_type ?? "",
                  webHookDetails?.object_name ?? "",
                  webHookDetails?.version ||
                    deployment?.default_version ||
                    pipeline?.default_version ||
                    ""
                )}
                underline="none"
              >
                {webHookDetails?.version ?? (
                  <HighlightedText>default</HighlightedText>
                )}
              </Link>
            </DetailsItem>
            <DetailsItem title="Created">
              <Typography>
                {getTzAwareDate(webHookDetails?.creation_date).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Last updated">
              <Typography>
                {getTzAwareDate(webHookDetails?.last_updated).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Event">
              <Typography>
                {getEventLabel(webHookDetails?.event as string)}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Timeout">
              <Typography>{webHookDetails?.timeout}</Typography>
            </DetailsItem>
            <DetailsItem title="Retry enabled">
              <Switch
                checked={webHookDetails?.retry}
                onChange={(_e, checked) => handleChange("retry", checked)}
                size="small"
                color="secondary"
              />
            </DetailsItem>
            <DetailsItem title="Result included">
              <Switch
                checked={webHookDetails?.include_result}
                onChange={(_e, checked) =>
                  handleChange("include_result", checked)
                }
                size="small"
                color="secondary"
              />
            </DetailsItem>
            <DetailsItem title="Enabled">
              <Switch
                checked={webHookDetails?.enabled}
                onChange={(_e, checked) => handleChange("enabled", checked)}
                size="small"
                color="secondary"
              />
            </DetailsItem>
          </Card>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          spacing={2}
          padding={"8px"}
          paddingRight={0}
        >
          <DescriptionBlock
            description={webHookDetails?.description}
            labels={formatLabels(webHookDetails?.labels ?? {})}
            xs={12}
          />
          <Grid item xs={12}>
            <Card title="Headers">
              <JsonPreview
                value={formatHeaders(webHookDetails?.headers ?? [])}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        title="Testing webhook"
        open={isTestDialogOpen}
        onClose={() => setIsTestDialogOpen(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={spacing[16]}
          paddingBottom={spacing[8]}
        >
          {isLoading
            ? explanations.webhooks.testDialog.loading
            : isSuccess
            ? explanations.webhooks.testDialog.success
            : explanations.webhooks.testDialog.error}
          {isLoading && <Loader />}
          {isSuccess && <TestResult>Test successful</TestResult>}
          {errorMessage && <TestResult type="error">{errorMessage}</TestResult>}
        </Box>
      </Dialog>
      <WebHookSecretDialog
        isOpen={secretDialogOpen}
        setIsOpen={setSecretDialogOpen}
        headers={webHookDetails?.headers}
        testWebhook={testWithSecretValues}
      />
      <DeleteDialog
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={() => handleDelete(webHookDetails?.name as string)}
        open={isDeleteDialogOpen}
      >
        Are you sure you want to delete webhook <b>{webHookDetails?.name}</b>?
      </DeleteDialog>
    </DetailsContainer>
  );
};
