import { Box, Grid } from "@mui/material";
import { Chart, Interaction, TimeScale } from "chart.js";
// @ts-ignore
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
// eslint-disable-next-line import/no-unassigned-import
import "chartjs-adapter-date-fns";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { DateTimeRangeInput } from "components/molecules/Fields/DateTimeRangeSelector";
import { FIELD_DATES } from "libs/constants/fields";
import { getTzAwareDate } from "libs/utilities/date-util";

import { Loader, RefreshButton } from "components/atoms";
import type { MetricGraphProps } from "components/molecules";
import { MetricGraph } from "components/molecules";

import { AddGraphCard } from "./AddGraphCard";

import type { PredefinedTimeRangeString } from "components/molecules/Fields/DateTimeRangeSelector";
import type {
  DateRange,
  FormRange,
} from "components/molecules/Fields/DateTimeRangeSelector/types";
import type { MonitoringGraph } from "pages/organizations/:organizationName/projects/:projectName/monitoring/general/types";

type MetricsLayoutProps = {
  graphs: MetricGraphProps[];
  enableCrosshair?: boolean;
  children?: React.ReactNode;
  withDatePicker?: boolean;
  isLoading?: boolean;
  showHeader?: boolean;
  filters?: React.ReactElement;
  onDateChange?: (range: DateRange) => void;
  onGraphRefresh?: () => void;
  hasAddGraph?: boolean;
  hasRemove?: boolean;
  handleRemove?: (title: string) => void;
  visibleGraphs?: MonitoringGraph[];
  updateGraphs?: (graphs: MonitoringGraph[]) => void;
  setGraphType?: (title: string) => void;
  defaultSelectedRange?: PredefinedTimeRangeString;
};

const MetricsLayout = ({
  graphs = [],
  enableCrosshair = false,
  children,
  withDatePicker = true,
  showHeader = true,
  isLoading = false,
  hasAddGraph = false,
  filters,
  onDateChange,
  onGraphRefresh,
  hasRemove = false,
  visibleGraphs = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateGraphs = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleRemove = () => {},
  setGraphType,
  defaultSelectedRange,
  ...props
}: MetricsLayoutProps) => {
  const { setValue, control } = useFormContext();
  const dates = useWatch<FormRange>({ name: FIELD_DATES, control });

  useEffect(() => {
    if (enableCrosshair) {
      Chart.register(CrosshairPlugin, TimeScale);
      (Interaction.modes as any).interpolate = Interpolate;
    } else {
      Chart.register(TimeScale);
    }

    return () => {
      Chart.unregister(CrosshairPlugin);
    };
  }, [enableCrosshair]);

  const refreshGraph = () => {
    const startTime =
      getTzAwareDate(dates?.endDate).diff(dates?.startDate, "minutes") <= 60
        ? getTzAwareDate().subtract(1, "hour")
        : dates?.startDate;

    setValue(FIELD_DATES, {
      startDate: startTime,
      endDate: getTzAwareDate(),
    });
    onGraphRefresh?.();
  };

  const handleDateChange = (dateRange: DateRange) => {
    setValue(FIELD_DATES, {
      startDate: getTzAwareDate(dateRange.from),
      endDate: getTzAwareDate(dateRange.to),
    });
    onDateChange && onDateChange(dateRange);
  };

  return (
    <Grid container>
      {showHeader && (
        <Grid
          container
          spacing={3}
          display="flex"
          alignItems="flex-start"
          style={{ marginBottom: 16 }}
        >
          <Grid item xs={5} padding={0}>
            {children}
          </Grid>
          {withDatePicker && (
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline",
              }}
            >
              <DateTimeRangeInput
                name={FIELD_DATES}
                handleDateChange={handleDateChange}
                defaultSelectedRange={defaultSelectedRange}
              />
              <Box marginLeft={"8px"}>
                <RefreshButton onClick={refreshGraph} tooltip="Refresh graph" />
              </Box>
            </Grid>
          )}
        </Grid>
      )}

      <Grid container marginBottom={1}>
        {filters}
      </Grid>
      {isLoading ? (
        <Loader mt={10} />
      ) : (
        <Grid container spacing={1}>
          {hasAddGraph && (
            <AddGraphCard
              visibleGraphs={visibleGraphs}
              updateGraphs={updateGraphs}
            />
          )}
          {graphs.map((data, key) => (
            <MetricGraph
              noDataText="No data available for the selected filters"
              hasRemove={hasRemove}
              hasMenu
              handleRemove={handleRemove}
              setGraphType={setGraphType}
              {...data}
              {...props}
              key={key}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default MetricsLayout;
