import { Grid, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { DateTimeRangeInput } from "components/molecules/Fields/DateTimeRangeSelector";
import {
  END_DATE_FILTER,
  FIELD_DATE,
  START_DATE_FILTER,
} from "libs/constants/fields";
import { useDeviceDetect } from "libs/hooks";

import { ToggleButtonGroup as CustomToggleButtonGroup } from "components/atoms";

import { STATUS_FILTERS } from "./constants";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ReactElement } from "react";
import type { Filter } from "./RequestsToolbar";

export const RequestsFilter = ({
  setFilters,
  defaultValues,
  children,
  isProjectLevel = false,
}: RequestsFilterProps) => {
  const { isMobile } = useDeviceDetect();
  const formMethods = useForm({ defaultValues });
  const theme = useTheme() as AppThemeProps;
  const { control, getValues, setValue } = formMethods;

  const updateFilters = useCallback(() => {
    setFilters(getValues() as Filter);
  }, [getValues, setFilters]);

  const handleDateFilterRemove = useCallback(() => {
    setValue(START_DATE_FILTER, undefined);
    setValue(END_DATE_FILTER, undefined);
    updateFilters();
  }, [setValue, updateFilters]);

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        item
        xs={12}
        display="flex"
        rowGap={spacing[12]}
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
        width="100%"
      >
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          rowGap={spacing[12]}
          flexWrap={isMobile ? "wrap" : undefined}
        >
          <Controller
            control={control}
            name="status"
            render={({ onChange, onBlur, ref }) => (
              <CustomToggleButtonGroup
                onBlur={onBlur}
                onChange={(value) => {
                  if (value != null) {
                    onChange(value);
                    updateFilters();
                  }
                }}
                value={defaultValues?.status ?? STATUS_FILTERS[0].value}
                ref={ref}
                exclusive
                size="small"
                sx={{
                  marginRight: spacing[32],
                }}
                items={
                  isProjectLevel ? STATUS_FILTERS.slice(0, 3) : STATUS_FILTERS
                }
              />
            )}
          />
          {isProjectLevel && (
            <Controller
              control={control}
              name="object_type"
              render={({ onChange, onBlur, ref }) => (
                <ToggleButtonGroup
                  onBlur={onBlur}
                  onChange={(_, value) => {
                    if (value != null) {
                      onChange(value);
                      updateFilters();
                    }
                  }}
                  value={defaultValues?.object_type}
                  ref={ref}
                  exclusive
                  size="small"
                >
                  <ToggleButton
                    disableFocusRipple={true}
                    value="deployment"
                    sx={buttonStyles(theme)}
                  >
                    Deployment
                  </ToggleButton>
                  <ToggleButton
                    disableFocusRipple={true}
                    value="pipeline"
                    sx={buttonStyles(theme)}
                  >
                    Pipeline
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          )}
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            minWidth="600px"
            alignSelf={"flex-end"}
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Controller
              control={control}
              name={FIELD_DATE}
              render={({ onChange }) => (
                <DateTimeRangeInput
                  name={FIELD_DATE}
                  startName={START_DATE_FILTER}
                  endName={END_DATE_FILTER}
                  handleDateChange={(value) => {
                    updateFilters();
                    onChange(value);
                  }}
                  defaultValue={
                    !!defaultValues.start_date && !!defaultValues.end_date
                      ? {
                          startDate: defaultValues.start_date,
                          endDate: defaultValues.end_date,
                        }
                      : undefined
                  }
                  setDefaultRange={handleDateFilterRemove}
                />
              )}
            />
            {children}
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

const buttonStyles = (theme: AppThemeProps) => {
  return {
    height: "28px",
    color: theme.palette.text.secondary,
    borderColor: theme.palette.buttons.toggleButtonBorder,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderLeft: `1px solid ${theme.palette.buttons.toggleButtonBorder} !important`,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  };
};

type RequestsFilterProps = {
  setFilters: (args: Filter) => void;
  defaultValues: {
    limit?: number;
    offset?: number;
    sort?: "asc" | "desc";
    object_type?: string;
    search_id?: string;
    status?: string;
    pipeline?: string | boolean;
    start_date?: string;
    end_date?: string;
  };
  children?: ReactElement;
  isProjectLevel?: boolean;
};
