import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import PlayArrow from "@mui/icons-material/PlayArrow";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { RootUrlContext } from "libs/contexts";
import { useDeploymentBatchRequestsCreate } from "libs/data/customized/deployment-requests";
import { usePipelineBatchRequestsCreate } from "libs/data/customized/pipeline-requests";
import { useRequestScheduleDelete } from "libs/data/customized/request-schedules";
import { useRequestSchedulesGet } from "libs/data/endpoints/request-schedules/request-schedules";
import { useGoogleAnalytics } from "libs/hooks";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";

import {
  ButtonGroup,
  DeleteDialog,
  Loader,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";

import type { DeploymentRequestsCreateDataBody } from "libs/data/models";
import type { ReactNode } from "react";

const RequestScheduleDetails = ({ children }: RequestScheduleDetailsProps) => {
  const [isRunning, setIsRunning] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  useGoogleAnalytics();
  const currentOrganization = useGetCurrentOrganization();

  const dispatch = useDispatch();
  const rootUrl = useContext(RootUrlContext);
  const history = useHistory();
  const { organizationName, scheduleName, projectName } =
    useParams<{
      organizationName: string;
      projectName: string;
      scheduleName: string;
    }>();
  const { data: requestSchedule, error } = useRequestSchedulesGet(
    projectName,
    scheduleName
  );
  const isLoading = !requestSchedule && !error;

  const deleteRequestSchedule = useRequestScheduleDelete(projectName);

  const deploymentBatchRequestsCreate = useDeploymentBatchRequestsCreate(
    projectName,
    requestSchedule?.object_name,
    requestSchedule?.version,
    requestSchedule?.timeout ?? undefined
  );
  const pipelineBatchRequestsCreate = usePipelineBatchRequestsCreate(
    projectName,
    requestSchedule?.object_name,
    requestSchedule?.version,
    requestSchedule?.timeout ?? undefined
  );

  const requestHasBeenMadeTimeoutRef =
    useRef<ReturnType<typeof setTimeout> | null>(null);
  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .requestSchedules[":scheduleName"](scheduleName),
    [scheduleName, organizationName, projectName]
  );

  const onRequestHasBeenMade = () => {
    requestHasBeenMadeTimeoutRef.current = setTimeout(() => {
      dispatch(
        createSuccessNotification("Request has been made, wait for the results")
      );
    }, 500);
  };

  const handleDelete = useCallback(
    (name?: string) => {
      if (name) {
        deleteRequestSchedule(name);
        history.replace(`${rootUrl}/request-schedules`);
      }
    },
    [history, rootUrl, deleteRequestSchedule]
  );

  const handleRun = () => {
    if (!isRunning) {
      const request =
        requestSchedule?.object_type === "deployment"
          ? deploymentBatchRequestsCreate(
              [
                requestSchedule?.request_data as DeploymentRequestsCreateDataBody,
              ],
              onRequestHasBeenMade
            )
          : pipelineBatchRequestsCreate(
              [
                requestSchedule?.request_data as DeploymentRequestsCreateDataBody,
              ],
              onRequestHasBeenMade
            );

      setIsRunning(true);
      request
        .then(() => {
          dispatch(
            createSuccessNotification("Request was successfully created")
          );
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        })
        .finally(() => {
          requestHasBeenMadeTimeoutRef?.current &&
            clearTimeout(requestHasBeenMadeTimeoutRef?.current);
          setIsRunning(false);
        });
    }
  };

  const tabs = useMemo(
    () => [
      {
        link: baseUrl.general.index(),
        label: "General",
      },
    ],
    [baseUrl]
  );

  return !isLoading ? (
    <>
      <DetailsContainer
        title={scheduleName}
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={currentOrganization?.status !== "active"}
              link={baseUrl.edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>

            <TextButton
              color="secondary"
              link={baseUrl.duplicate.index()}
              startIcon={<Duplicate />}
            >
              Duplicate
            </TextButton>
            <TextButton
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<PlayArrow />}
              disabled={isRunning}
              onClick={handleRun}
              style={{ marginLeft: "auto" }}
            >
              Run now
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        <PageTabs tabs={tabs}>{children}</PageTabs>
      </DetailsContainer>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => handleDelete(requestSchedule?.name)}
      >
        Are you sure you want to delete request schedule {`"`}
        <b>{scheduleName}</b>
        {`"`}?
      </DeleteDialog>
    </>
  ) : (
    <Loader />
  );
};

type RequestScheduleDetailsProps = {
  children: ReactNode;
};

export default RequestScheduleDetails;
