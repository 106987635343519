import { Typography } from "@mui/material";
import { get, isEmpty, isObject } from "lodash";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  DICT_TYPE,
  FILE_TYPE,
  STRUCTURED_TYPE,
} from "libs/constants/constants";
import {
  FIELD_EDITOR,
  formatInput,
  formatJsonEditorValue,
  JSON_EDITOR,
  VISUAL_EDITOR,
} from "libs/utilities/input-parser";
import { fieldTypesLabels } from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";

import { Textarea } from "components/atoms";
import { EditorsView, FileInputField } from "components/molecules";

import type {
  DeploymentListOutputType,
  InputOutputFieldDetail,
} from "libs/data/models";

type RequestCreateProps = {
  objectName?: string;
  objectType?: string;
  inputType: DeploymentListOutputType;
  fields: InputOutputFieldDetail[];
  fieldName: string;
  defaultFormFields?: any;
};

const RequestCreate = ({
  defaultFormFields = {},
  fieldName,
  fields,
  inputType,
  objectName,
  objectType,
  ...props
}: RequestCreateProps) => {
  const { register, setValue, getValues, watch } = useFormContext();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const jsonAreaName = useMemo(() => `${fieldName}.json-area`, [fieldName]);
  const editorFieldName = useMemo(
    () => `${fieldName}.${FIELD_EDITOR}`,
    [fieldName]
  );
  const editorField = watch(editorFieldName, VISUAL_EDITOR);

  const handleEditorChange = (
    _e: React.ChangeEvent<unknown>,
    newValue: "jsonEditor" | "visualEditor"
  ) => {
    const values = getValues();
    // Only format changes if previous editor was visual editor
    if (newValue === JSON_EDITOR && editorField === VISUAL_EDITOR) {
      const updatedFields = get(values, fieldName);
      const requestFields = formatInput(fields, updatedFields);
      setFormFields(requestFields);
      setValue(jsonAreaName, JSON.stringify(requestFields, null, 1));
    } else {
      const requestFields = get(values, jsonAreaName);
      const parsedRequestFields = formatJsonEditorValue(requestFields);

      setFormFields(parsedRequestFields);
      setValue(fieldName, parsedRequestFields);
    }
    setValue(editorFieldName, newValue);
  };

  return inputType === STRUCTURED_TYPE ? (
    <div key={fieldName} {...props}>
      {!fields.length ? (
        <>
          <Typography variant="caption">No data required.</Typography>
          <input type="hidden" name={editorFieldName} ref={register} />
        </>
      ) : (
        <EditorsView
          onEditorChange={handleEditorChange}
          editorFieldName={editorFieldName}
          jsonAreaName={jsonAreaName}
          formFields={formFields}
        >
          {editorField === VISUAL_EDITOR &&
            fields.map(({ name, data_type }, fieldKey) =>
              data_type === FILE_TYPE ? (
                <FileInputField
                  key={objectName}
                  label={`${name} (${fieldTypesLabels[data_type]})`}
                  name={`${fieldName}.${name}`}
                  objectName={objectName}
                  objectType={objectType}
                  defaultFileName={formFields[name]}
                />
              ) : data_type === DICT_TYPE ? (
                <Textarea
                  name={`${fieldName}.${name}`}
                  label={`${name} (${fieldTypesLabels[data_type]})`}
                  rules={validators[data_type]}
                  key={fieldKey}
                  defaultValue={
                    isObject(formFields[name])
                      ? JSON.stringify(formFields[name])
                      : formFields[name]
                  }
                />
              ) : (
                <Textarea
                  name={`${fieldName}.${name}`}
                  label={`${name} (${fieldTypesLabels[data_type]})`}
                  rules={validators[data_type]}
                  key={fieldKey}
                  defaultValue={formFields[name]}
                />
              )
            )}
        </EditorsView>
      )}
    </div>
  ) : (
    <div {...props}>
      <Textarea
        name={`${fieldName}.plain-area`}
        label="Plain"
        defaultValue={isEmpty(formFields) ? null : formFields}
      />
    </div>
  );
};

export default RequestCreate;
