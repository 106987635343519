import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import Trash from "@mui/icons-material/DeleteRounded";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import {
  Box,
  Checkbox,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { stringifyUrl } from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { STATUS_FILTERS } from "components/organisms/Requests/constants";
import { getRequestsParams } from "components/organisms/Requests/utils";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { FIELD_NAME, FIELD_TRAINING_SCRIPT } from "libs/constants/fields";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useRunCancel } from "libs/data/customized/training/useRunCancel";
import {
  deploymentVersionRequestsBatchGet,
  deploymentVersionRequestsDelete,
  useDeploymentVersionRequestsList,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useDeviceDetect, useInterval, useLogsUrl } from "libs/hooks";
import {
  calculateDuration,
  getFormattedDate,
  renderTimestamp,
} from "libs/utilities/date-util";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { formatStatusLabel } from "libs/utilities/statuses";
import { routes } from "routes";

import {
  ActionDialog,
  Card,
  DeleteDialog,
  Link,
  Loader,
  NavLink,
  PrimaryButton,
  StatusIcon,
  TableLink,
  ToggleButtonGroup,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { shouldFetchMetadataOnly } from "components/organisms";

import {
  destructFilePath,
  mapStorageLinkToAppLink,
} from "../../../storage/utils";
import { TRAINING_DEPLOYMENT } from "../../constants";
import { transformTrainingRuns } from "../util";
import { RunResultsDialog } from "./RunResultsDialog";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { Query } from "components/organisms/Requests/types";
import type { DeploymentRequestDetail } from "libs/data/models";
import type { DateType } from "libs/utilities/date-util";

export const RunsOverview = ({
  allowGet,
  allowDelete,
  allowLogs,
  allowUpdate,
}: {
  allowGet?: boolean;
  allowLogs?: boolean;
  allowDelete?: boolean;
  allowUpdate?: boolean;
}) => {
  const { organizationName, projectName, experimentName } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
    }>();
  const baseUrl = useContext(BaseUrlContext);
  const rootUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .training.evaluation.index(),
    [organizationName, projectName]
  );
  const history = useHistory();
  const { isMobile } = useDeviceDetect();
  const [currentRun, setCurrentRun] =
    useState<DeploymentRequestDetail | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isResultsDialogOpen, setIsResultsDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [runDetails, setRunDetails] = useState<DeploymentRequestDetail[]>([]);
  const [selectedRuns, setSelectedRuns] = useState<
    { id: string; version: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [query, setQuery] = useState<Query>({
    pageSize: isMobile ? 5 : 10,
    page: 0,
    filters: { status: STATUS_FILTERS[0].value },
  });
  const params = getRequestsParams(undefined, query);
  const dispatch = useDispatch();
  const onCancelRun = useRunCancel(projectName, experimentName);
  const { data, mutate } = useDeploymentVersionRequestsList(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    params,
    {
      swr: {
        revalidateOnFocus: true,
        revalidateOnMount: true,
        revalidateIfStale: true,
      },
    }
  );

  // reload list after history push
  useEffect(() => {
    mutate();
  }, [history.location, mutate]);

  const hasPendingRun = useMemo(
    () =>
      data?.some((item) =>
        ["pending", "processing", "cancelled_pending"].includes(item.status)
      ),
    [data]
  );

  const fetchDetails = useCallback(async () => {
    if (data?.length)
      try {
        setLoading(true);
        const response = await deploymentVersionRequestsBatchGet(
          projectName,
          TRAINING_DEPLOYMENT,
          experimentName,
          data?.map((item) => item?.id)
        );
        response && setRunDetails(response);
      } catch (err) {
        // throw error
      } finally {
        setLoading(false);
      }
  }, [data, experimentName, projectName]);

  // only fetch details if rows have changed
  useEffect(() => {
    const dataIds = data?.map((item) => item.id).sort();
    const runIds = runDetails.map((item) => item.id).sort();
    if (dataIds?.length && JSON.stringify(dataIds) !== JSON.stringify(runIds))
      fetchDetails();
  }, [data, fetchDetails, runDetails]);

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    TRAINING_DEPLOYMENT,
    "deployment"
  );

  useInterval(
    () => {
      if (hasPendingRun) {
        mutate();
      }
    },
    [hasPendingRun, mutate],
    CONTINUOUS_REQUEST_DELAY,
    true
  );

  const onRowClick = (
    _: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowData: { id: string }
  ) => {
    history.push(`${baseUrl}/runs/${rowData.id}/general`);
  };

  const onRowDelete = useCallback(
    (rowData: any) => {
      const canDelete =
        allowDelete &&
        (rowData?.status === "completed" ||
          rowData?.status === "failed" ||
          rowData?.status === "cancelled");

      if (canDelete) {
        setCurrentRun(rowData);
        setIsDeleteDialogOpen(true);
      }
    },
    [allowDelete]
  );

  const onViewResults = useCallback(
    (event: any, rowData: any) => {
      event.stopPropagation();
      if (allowGet) {
        setIsResultsDialogOpen(allowGet);
        setCurrentRun(rowData);
      }
    },
    [allowGet]
  );

  const handleCancel = async (id: string, name: string) => {
    setCancelLoading(true);
    await onCancelRun(id, name);
    setIsCancelDialogOpen(false);
    setCancelLoading(false);
  };
  const handleRunDelete = () => {
    currentRun &&
      deploymentVersionRequestsDelete(
        projectName,
        TRAINING_DEPLOYMENT,
        experimentName,
        currentRun?.id
      )
        .then(() => {
          mutate((runs) => runs?.filter((run) => run.id !== currentRun.id));
          dispatch(createSuccessNotification("Selected run deleted"));
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        })
        .finally(() => {
          setIsDeleteDialogOpen(false);
          setCurrentRun(undefined);
        });
  };

  const transformedRuns = useMemo(() => {
    return data?.length ? transformTrainingRuns(data, runDetails) : [];
  }, [data, runDetails]);

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_name: TRAINING_DEPLOYMENT,
      deployment_version: experimentName,
      deployment_request_id: "",
    },
  });

  const RenderLogsIcon = useCallback(
    ({ rowData }: any) => {
      const toDate = moment(rowData.time_completed).add(5, "minutes");
      const fromDate = moment(rowData.time_created);

      const requestUrl = stringifyUrl({
        url: logsUrl,
        query: {
          deployment_request_id: rowData.id,
          from_date: getFormattedDate(fromDate),
          to_date: getFormattedDate(toDate),
        },
      });

      const disabled = !allowLogs;

      return (
        <Tooltip title={"View logs"}>
          <span onClick={(e) => e.stopPropagation()}>
            <IconButton
              component={NavLink}
              disabled={disabled}
              to={requestUrl}
              color="secondary"
            >
              <LogsIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    [allowLogs, logsUrl]
  );

  const handleRunComparison = (runs: { id: string; version: string }[]) => {
    const parameters = runs
      .map((current) => `${current.version}:${current.id}`)
      .join(",");
    const queryParameters = "ids=".concat(parameters);
    history.push(`${rootUrl}?${queryParameters}`);
  };

  const columns = [
    {
      title: "",
      field: "",
      type: "checkbox",
      nowrap: true,
      width: 10,
      render: (rowData: { id: string; version: string }) => (
        <td
          style={{
            paddingLeft: 5,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Checkbox
            checked={Boolean(
              rowData?.id && selectedRuns.find((run) => run.id === rowData.id)
            )}
            onChange={(_event, checked) => {
              if (checked && rowData?.id) {
                setSelectedRuns([
                  ...selectedRuns,
                  { id: rowData?.id, version: rowData.version },
                ]);
              } else {
                setSelectedRuns(
                  selectedRuns.filter((run) => run.id !== rowData?.id)
                );
              }
            }}
          ></Checkbox>
        </td>
      ),
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      editable: "never",
      render: (rowData: { status: string; success: boolean }) => (
        <StatusIcon
          label={rowData ? formatStatusLabel(rowData.status) : ""}
          status={rowData?.status}
        />
      ),
      width: 150,
    },
    {
      title: "Name",
      field: "name",
      sorting: false,
      editable: "never",
      width: 300,
      nowrap: true,
      render: ({ request_data, id }: DeploymentRequestDetail) => (
        <TableLink to={`${baseUrl}/runs/${id}/general`} variant="bold">
          {request_data?.[FIELD_NAME]}
        </TableLink>
      ),
    },
    {
      defaultSort: "desc",
      title: "Created",
      field: "time_created",
      type: "datetime",
      width: 150,
      nowrap: true,
      render: (rowData: Record<string, DateType>) =>
        renderTimestamp(rowData, "time_created"),
    },
    {
      title: "Duration",
      field: "time_started",
      type: "datetime",
      nowrap: true,
      render: ({ time_started, time_completed }: DeploymentRequestDetail) =>
        time_started &&
        time_completed &&
        calculateDuration(time_started, time_completed),
    },
    {
      title: "Script location",
      field: "script",
      sorting: false,
      nowrap: true,
      width: 400,

      render: ({ request_data }: DeploymentRequestDetail) => {
        const path = request_data?.[FIELD_TRAINING_SCRIPT];
        const { file } = destructFilePath(path);

        return (
          path && (
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={{
                ...location,
                state: { selectedFile: file },
                pathname: mapStorageLinkToAppLink(
                  path,
                  organizationName,
                  projectName
                ),
              }}
            >
              {path}
            </Link>
          )
        );
      },
    },
    {
      sorting: false,
      disableClick: true,
      nowrap: true,
      align: "right",
      cellStyle: { fontSize: "0.9em" },
      render: (rowData: DeploymentRequestDetail) => {
        const isCompletedRun = rowData?.status === "completed";
        const isFailedRun = rowData?.status === "failed";
        const isCancelledRun = rowData?.status === "cancelled";
        const isPendingRun = rowData?.status === "pending";
        const isProcessingRun = rowData?.status === "processing";
        const isCancelledPendingRun = rowData?.status === "cancelled_pending";
        const enableSingleDelete =
          allowDelete && (isCompletedRun || isFailedRun || isCancelledRun);

        return (
          <Box display="flex">
            <Tooltip title="View results">
              <span>
                <IconButton
                  disabled={!allowGet}
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewResults(e, rowData);
                  }}
                >
                  <GetResultsIcon />
                </IconButton>
              </span>
            </Tooltip>

            <RenderLogsIcon rowData={rowData} />
            <Tooltip title={"Duplicate"}>
              <span>
                <IconButton
                  color="secondary"
                  disabled={
                    !currentPermissions[
                      DEPLOYMENT_PERMISSIONS["version_request_create"]
                    ]
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`${baseUrl}/runs/${rowData.id}/duplicate`);
                  }}
                >
                  <Duplicate />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              title={
                isPendingRun || isProcessingRun
                  ? "Cancel"
                  : isCancelledPendingRun
                  ? "Run is being cancelled"
                  : "Runs can only be cancelled when in pending or processing"
              }
            >
              <span>
                <IconButton
                  disabled={
                    !(
                      allowUpdate &&
                      (rowData?.status === "pending" ||
                        rowData?.status === "processing")
                    )
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentRun(rowData);
                    setIsCancelDialogOpen(true);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              title={
                !allowDelete
                  ? "You're currently not allowed to delete runs, contact an admin to get permission"
                  : !enableSingleDelete
                  ? "Runs in pending, processing or cancelling state cannot be deleted"
                  : "Delete"
              }
            >
              <span>
                <IconButton
                  color="primary"
                  disabled={!enableSingleDelete}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRowDelete(rowData);
                  }}
                >
                  <Trash />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Card title="Runs" contentStyle={{ minHeight: spacing[36] }}>
        <ToggleButtonGroup
          onChange={(value) => {
            if (value != null) {
              setQuery({ ...query, filters: { status: value } });
            }
          }}
          value={query?.filters?.status}
          exclusive
          size="small"
          sx={{
            marginRight: spacing[32],
          }}
          items={STATUS_FILTERS}
        />{" "}
        {loading ? (
          <Loader />
        ) : (
          <>
            <BaseTable
              columns={columns as BaseColumn[]}
              data={transformedRuns}
              onRowClick={onRowClick}
              defaultSortColumn="time_created"
              defaultSortDirection="asc"
              hasPagination={false}
              isAnyChecked={selectedRuns.length > 0}
              isAllChecked={
                selectedRuns.length > 0 &&
                selectedRuns.length === transformedRuns?.length
              }
              MultiAction={() =>
                selectedRuns.length < 1 ? (
                  <></>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    columnGap={2}
                    marginY={spacing[4]}
                  >
                    <Typography variant="h6">
                      {selectedRuns?.length} row(s) selected
                    </Typography>
                    <PrimaryButton
                      onClick={() => handleRunComparison(selectedRuns)}
                      size="small"
                    >
                      Compare runs
                    </PrimaryButton>
                  </Box>
                )
              }
              onSelectAll={(checked: boolean) => {
                if (checked) {
                  setSelectedRuns(
                    transformedRuns?.map(({ id, version }) => ({
                      id,
                      version: version ?? "",
                    })) || []
                  );
                } else {
                  setSelectedRuns([]);
                }
              }}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              nextIconButtonProps={{
                disabled: Boolean(
                  query?.pageSize &&
                    (transformedRuns || []).length < query?.pageSize
                ),
              }}
              count={-1}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              rowsPerPage={query?.pageSize || 20}
              page={query?.page || 0}
              onRowsPerPageChange={(event) =>
                setQuery({
                  ...query,
                  pageSize: Number(event.target.value),
                })
              }
              onPageChange={(_event, page: number) =>
                setQuery({
                  ...query,
                  page,
                })
              }
            />
          </>
        )}
      </Card>
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleRunDelete}
      >
        Are you sure you want to delete the run{" "}
        <b>{currentRun?.request_data?.[FIELD_NAME]}</b> and all of its content?
      </DeleteDialog>
      <ActionDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        onAction={() =>
          currentRun?.id &&
          handleCancel(currentRun?.id, currentRun?.request_data?.[FIELD_NAME])
        }
        actionButtonText="Cancel run"
        disableActionButton={cancelLoading}
      >
        <Box>
          Are you sure you want to cancel run{" "}
          <b>{currentRun?.request_data?.[FIELD_NAME]}</b>?
        </Box>
      </ActionDialog>
      <RunResultsDialog
        onClose={() => setIsResultsDialogOpen(false)}
        isOpen={isResultsDialogOpen}
        organizationName={organizationName}
        projectName={projectName}
        experimentName={experimentName}
        id={currentRun?.id as string}
        shouldFetchMetadaDataOnly={shouldFetchMetadataOnly(currentRun)}
      />
    </>
  );
};
