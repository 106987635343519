import { SnackbarProvider } from "notistack";
import { AlertOctagon, AlertTriangle, CheckCircle, Info } from "react-feather";

import type { FC } from "react";

// TODO: fix this file

export const SnackbarCustomProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={7000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={{
        error: <AlertTriangle className="app__notification-icon" />,
        success: <CheckCircle className="app__notification-icon" />,
        warning: <AlertOctagon className="app__notification-icon" />,
        info: <Info className="app__notification-icon" />,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
