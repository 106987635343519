import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { FIELD_INSTANCE_TYPES, FIELD_NAME } from "libs/constants/fields";
import { useInstanceTypesList } from "libs/data/endpoints/instances/instances";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";

import { PrimaryButton, SecondaryButton } from "components/atoms";
import { FormikTextInput, FormSection } from "components/molecules";

import { InstanceGroupPriorityLevels } from "./InstanceGroupPriorityLevels";

import type { FormikHelpers, FormikProps, FormikValues } from "formik";
import type {
  InstanceTypeCreate,
  InstanceTypeGroupCreateBody,
} from "libs/data/models";

export const InstanceTypeGroupBaseSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string().required(validators.required.message(FIELD_NAME)),
});

interface InstanceTypeGroupBaseFormProps {
  initialValues?: InstanceTypeGroupCreateBody;
  onSubmit: (
    values: InstanceTypeGroupCreateBody,
    helpers: FormikHelpers<InstanceTypeGroupCreateBody>
  ) => void;
  submitLabel?: string;
}

export const InstanceTypeGroupBaseForm = ({
  initialValues,
  onSubmit,
  submitLabel,
}: InstanceTypeGroupBaseFormProps) => {
  const { projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data } = useInstanceTypesList(projectName, { limit: 50 });

  const instanceOptions = useMemo(
    () =>
      data?.results?.map(({ id, display_name, name }) => ({
        label: display_name || name,
        value: id,
      })),
    [data?.results]
  );

  return (
    <Formik
      initialValues={initialValues || ({} as InstanceTypeGroupCreateBody)}
      onSubmit={(values, helpers) => {
        // filter out placeholder instance type selection
        onSubmit(
          {
            ...values,
            [FIELD_INSTANCE_TYPES]: values?.[FIELD_INSTANCE_TYPES]?.filter(
              ({ id }) => id
            ),
          },
          helpers
        );
      }}
      validationSchema={InstanceTypeGroupBaseSchema}
    >
      {/** @ts-expect-error */}
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, values, setFieldValue, isValid } = control;

        const filteredInstanceOptions = (instanceOptions || []).filter(
          (instance) =>
            !values[FIELD_INSTANCE_TYPES]?.some(
              ({ id }: InstanceTypeCreate) => id === instance.value
            )
        );

        const uniquePriorities = [
          ...new Set(
            values?.[FIELD_INSTANCE_TYPES]?.map(
              (obj: InstanceTypeCreate) => obj.priority
            )
          ),
        ].sort();

        const priorityGroups = uniquePriorities.map(
          (priority) =>
            values?.[FIELD_INSTANCE_TYPES]?.filter(
              (obj: InstanceTypeCreate) => obj.priority === priority
            ) as InstanceTypeCreate[]
        );

        // normalize priorities to start from zero
        const normalizedPriorityGroups = priorityGroups.map((group, index) =>
          group.map((instanceType) => ({
            id: instanceType.id,
            priority: index,
          }))
        );

        const isFormDisabled =
          !isValid ||
          !(
            values[FIELD_INSTANCE_TYPES]?.filter(
              ({ id }: InstanceTypeCreate) => id
            )?.length > 0
          ) ||
          normalizedPriorityGroups.some((group) =>
            group.some(({ id }) => id === "")
          );

        return (
          <>
            <FormSection
              description={explanations.instanceGroups.createForm.helpMessage}
              title="Name your instance group"
            >
              <FormikTextInput
                control={control}
                label="Name"
                name={FIELD_NAME}
                placeholder="Ex: Instance-group-1"
                required
              />
            </FormSection>
            <FormSection
              title="Group creation"
              description={explanations.instanceGroups.createForm.groupCreation}
            >
              <Typography variant="h6">Priority levels</Typography>
              <InstanceGroupPriorityLevels
                priorityGroups={
                  normalizedPriorityGroups?.length > 0
                    ? normalizedPriorityGroups
                    : [[{ id: "", priority: 0 }]]
                }
                instanceOptions={instanceOptions || []}
                availableInstanceOptions={filteredInstanceOptions}
                onInstanceTypesChange={(change) =>
                  setFieldValue(FIELD_INSTANCE_TYPES, change)
                }
              />
              <Grid>
                <SecondaryButton
                  size="medium"
                  startIcon={<AddBoxRoundedIcon />}
                  onClick={() => {
                    setFieldValue(FIELD_INSTANCE_TYPES, [
                      ...normalizedPriorityGroups.flat(),
                      // placeholder element
                      { id: "", priority: normalizedPriorityGroups.length + 1 },
                    ]);
                  }}
                >
                  Add priority level
                </SecondaryButton>
              </Grid>
            </FormSection>
            <Grid item container spacing={5}>
              <Grid item xs={12} sm={5} />

              <Grid
                item
                xs={12}
                sm={7}
                display="flex"
                justifyContent="flex-end"
              >
                <PrimaryButton
                  loading={control.isSubmitting}
                  onClick={submitForm}
                  disabled={isFormDisabled}
                >
                  {submitLabel ?? "Create"}
                </PrimaryButton>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};
