import { isEmpty, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { useDeploymentVersionsList } from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import validators from "libs/utilities/validators";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { EnvironmentList } from "libs/data/models";

export const DEFAULT_ENVIRONMENT = "python3-10";

const languageRules = {
  required: validators.required.message("code environment"),
};

export const formatCodeEnvironment = (env: EnvironmentList) =>
  ({
    label: env.display_name,
    value: env.name,
  } as AutocompleteSelectOption);

export interface CodeEnvironmentSelectProps {
  isEnvImplicit?: boolean;
  defaultEnvironment?: string;
  deployment?: string;
  disabled?: boolean;
  gpuEnabled: boolean;
  name: string;
  projectName: string;
  supportsRequestFormat?: boolean;
}

export const CodeEnvironmentSelect = ({
  isEnvImplicit,
  defaultEnvironment,
  deployment,
  disabled,
  gpuEnabled,
  name,
  projectName,
  supportsRequestFormat = true,
}: CodeEnvironmentSelectProps) => {
  const [languageDefaultValue, setLanguageDefaultValue] = useState(
    defaultEnvironment ?? DEFAULT_ENVIRONMENT
  );
  // The available environments in its raw form are used as a reference, as users may currently have an environment
  // that they actually cannot select
  const { data: availableEnvironments } = useEnvironmentsList(projectName);

  const { data: versions } = useDeploymentVersionsList(
    projectName,
    deployment as string,
    undefined,
    // In case we have defined a default environment, we do not have to search for previous environments
    // This would be the case for edits, duplicates and prefilled data
    { swr: { enabled: !defaultEnvironment && !!deployment } }
  );
  const { formState, setValue } = useFormContext();
  const { dirtyFields } = formState;

  useEffect(() => {
    if (defaultEnvironment) {
      setLanguageDefaultValue(defaultEnvironment);
    }
  }, [defaultEnvironment]);

  useEffect(() => {
    // Only in case we are creating a new version, we would enter this logic to copy the previous environment
    if (!defaultEnvironment && !isEmpty(versions) && availableEnvironments) {
      const sortedVersions = sortBy(versions, ["creation_date"]);
      const latestVersion = sortedVersions[sortedVersions.length - 1];

      if (latestVersion?.environment) {
        setLanguageDefaultValue(latestVersion.environment);
      }
    }
  }, [availableEnvironments, defaultEnvironment, versions]);

  const environmentOptions = useMemo(() => {
    let filteredEnvironments =
      (gpuEnabled
        ? availableEnvironments
        : availableEnvironments?.filter((env) => !env.gpu_required)) ?? [];

    filteredEnvironments = supportsRequestFormat
      ? filteredEnvironments.filter((env) =>
          disabled || isEnvImplicit ? env.system : env.supports_request_format
        )
      : filteredEnvironments;

    return filteredEnvironments
      .filter(
        (env) =>
          env.status !== "unavailable" &&
          !env.deprecated &&
          !env.hidden &&
          !env.implicit
      )
      .map(formatCodeEnvironment)
      .sort((a, b) => {
        return b.label.localeCompare(a.label, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableEnvironments, isEnvImplicit, gpuEnabled]);

  useEffect(() => {
    // Wait until either a default environment is provided or the versions have loaded
    // In case deployment is undefined versions will never be loaded
    if (defaultEnvironment || versions || !deployment) {
      if (defaultEnvironment || !gpuEnabled) {
        let availableDefaultEnvironment = environmentOptions?.find(
          (env) => env.value === languageDefaultValue
        );

        // if the default environment is not available, check if its base environment is available
        if (!availableDefaultEnvironment) {
          const unavailableEnvironment = availableEnvironments?.find(
            (env) => env.name === languageDefaultValue
          );

          if (unavailableEnvironment?.base_environment) {
            const baseEnvironment = environmentOptions?.find(
              (env) => env.value === unavailableEnvironment.base_environment
            );

            if (baseEnvironment) {
              availableDefaultEnvironment = baseEnvironment;
            }
          }
        }

        if (!dirtyFields?.environment) {
          setValue(name, availableDefaultEnvironment || DEFAULT_ENVIRONMENT, {
            shouldDirty: false,
          });
        }
      } else {
        const firstGpuEnvironment = availableEnvironments?.find(
          (env) => env.gpu_required
        );

        firstGpuEnvironment &&
          setValue(name, formatCodeEnvironment(firstGpuEnvironment));
      }
    }

    // We don't want this function to always run, because otherwise it may reset to a value the user did not intend to select
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableEnvironments,
    defaultEnvironment,
    environmentOptions,
    gpuEnabled,
    languageDefaultValue,
    name,
    setValue,
    versions,
  ]);

  const rules = useMemo(() => (disabled ? {} : languageRules), [disabled]);

  return (
    <AutoCompleteSelectHookForm
      disabled={disabled}
      label="Environment"
      name={name}
      options={environmentOptions}
      rules={rules}
    />
  );
};
