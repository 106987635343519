import Plus from "@mui/icons-material/AddBoxRounded";
import DownloadIcon from "@mui/icons-material/CloudDownloadRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import { Box, IconButton, Link, Tooltip } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { EXPORTS_STATUS_FILTER } from "libs/constants/fields";
import { IMPORT_EXPORT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { axios } from "libs/data/axios";
import { useGoogleAnalytics } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { downloadFileStreaming } from "libs/utilities/download-helper";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { formatQueryParams } from "libs/utilities/request-util";
import { isArrayHasData, isObjectHasData } from "libs/utilities/utils";
import { useGetPermissions } from "store/features/permissions";

import {
  DeleteDialog,
  PrimaryButton,
  StatusIcon,
  ToggleButtonGroup,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { ImportExportDetailsDialog } from "components/organisms";

const ExportsOverview = () => {
  useGoogleAnalytics();
  const history = useHistory();
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const baseUrl = useContext(BaseUrlContext);
  const { projectName } = useParams();
  const dispatch = useDispatch();
  const timeoutId = useRef();
  const [refresh, setRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [exports, setExports] = useState([]);
  const [selectedExport, setSelectedExport] = useState(undefined);
  const [selectedFilters, setSelectedFilters] = useState(
    EXPORTS_STATUS_FILTER[0].title
  );

  const [currentPermissions] = useGetPermissions();

  const permissions = useMemo(
    () => ({
      export_list: currentPermissions[IMPORT_EXPORT_PERMISSIONS["export_list"]],
      export_get: currentPermissions[IMPORT_EXPORT_PERMISSIONS["export_get"]],
      export_create:
        currentPermissions[IMPORT_EXPORT_PERMISSIONS["export_create"]],
      export_delete:
        currentPermissions[IMPORT_EXPORT_PERMISSIONS["export_delete"]],
    }),
    [currentPermissions]
  );

  const fetchTableData = useCallback(
    (loading = true) => {
      setIsLoading(loading);
      if (timeoutId) {
        clearTimeout(timeoutId.current);
      }
      let queryParams = {};
      if (selectedFilters !== "all") queryParams["status"] = selectedFilters;
      queryParams = isObjectHasData(queryParams)
        ? `?${formatQueryParams(queryParams)}`
        : "";
      const url = `projects/${projectName}/exports${queryParams}`;

      axios({
        method: "GET",
        url,
      })
        .then(({ data }) => {
          const buildingData = data.findIndex(
            (element) => element.status === "processing"
          );
          if (buildingData === -1) {
            setRefresh(false);
          }
          setExports(data);
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, projectName, selectedFilters]
  );

  useEffect(() => {
    // Fetch data on initial mount and when filters change
    if (permissions.export_list) {
      fetchTableData();
    }
  }, [fetchTableData, permissions.export_list]);

  useEffect(() => {
    // Refresh table if there are in-building-process exports
    const intervalId = setInterval(() => {
      if (isArrayHasData(exports) && refresh) {
        fetchTableData();
      }
    }, CONTINUOUS_REQUEST_DELAY);

    return () => clearInterval(intervalId);
  }, [fetchTableData, refresh, exports]);

  const onRowClick = useCallback(
    (_, rowData) => {
      if (permissions.export_get) {
        setSelectedExport(rowData);
        setExportDialogOpen(true);
      }
    },
    [permissions.export_get]
  );

  const downloadExport = useCallback(
    (rowData, allowDownload) => (event) => {
      event.stopPropagation();
      if (allowDownload) {
        axios({
          method: "POST",
          url: `/authorize/single-use-token`,
        })
          .then((response) => {
            downloadFileStreaming(
              `/projects/${projectName}/exports/${rowData?.id}/download?authtoken=${response.data.token}`
            );
          })
          .catch((error) => {
            dispatch(createErrorNotification(error.message));
          });
      }
    },
    [dispatch, projectName]
  );

  const deleteExport = useCallback(
    (exportId) => {
      axios({
        method: "DELETE",
        url: `projects/${projectName}/exports/${exportId}`,
      })
        .then((response) => {
          if (response.status === 204) {
            setExports(
              Object.values(exports).filter(({ id }) => id !== exportId)
            );
            dispatch(createSuccessNotification("Export deleted successfully"));
          }
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        });
    },
    [dispatch, exports, projectName]
  );

  const handleDeleteExport = useCallback(
    (rowData, allowDelete) => (event) => {
      event.stopPropagation();
      if (allowDelete) {
        setSelectedExport(rowData);
        setDeleteDialogOpen(true);
      }
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        title: "Status",
        field: "status",
        width: "20%",
        render: ({ status }) => {
          return <StatusIcon label={status} status={status} />;
        },
      },
      {
        title: "ID",
        field: "id",
        width: "30%",
        nowrap: true,
        render: ({ id }) => (
          <Link component="button" variant="h5">
            {id}
          </Link>
        ),
      },
      {
        title: "Created",
        field: "creation_date",
        type: "datetime",
        width: "35%",
        nowrap: true,
        render: (rowData) =>
          getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
        defaultSort: "desc",
      },
      {
        disableClick: true,
        width: "2%",
        render: (rowData) => {
          const allowDelete =
            permissions.export_delete &&
            (rowData?.status === "failed" || rowData?.status === "completed");
          const allowDownload =
            permissions.export_get && rowData?.status === "completed";

          return (
            <div className="actions_container">
              <Tooltip title="Download">
                <span>
                  <IconButton
                    color="secondary"
                    onClick={downloadExport(rowData, allowDownload)}
                    disabled={!allowDownload}
                  >
                    <DownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color="primary"
                    onClick={handleDeleteExport(rowData, allowDelete)}
                    disabled={!allowDelete}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [permissions, downloadExport, handleDeleteExport]
  );

  return (
    <>
      <BaseTable
        columns={columns}
        isLoading={isLoading}
        data={exports}
        defaultSortColumn="creation_date"
        defaultSortDirection="asc"
        onRowClick={onRowClick}
        header={
          <Box
            display="flex"
            flexDirection="column"
            rowGap={2}
            width="fit-content"
          >
            <PrimaryButton
              startIcon={<Plus />}
              disabled={!permissions.export_create}
              onClick={() => history.push(`${baseUrl}/exports/create`)}
              style={{ width: "fit-content" }}
            >
              Create export
            </PrimaryButton>{" "}
            <ToggleButtonGroup
              onChange={(value) => {
                if (value != null) {
                  setSelectedFilters(value);
                  setExports([]);
                }
              }}
              value={selectedFilters}
              exclusive
              size="small"
              items={EXPORTS_STATUS_FILTER}
            />
          </Box>
        }
      />

      <ImportExportDetailsDialog
        onClose={() => setExportDialogOpen(false)}
        open={exportDialogOpen}
        page="Export"
        recordData={selectedExport}
      />

      <DeleteDialog
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => deleteExport(selectedExport?.id)}
        open={deleteDialogOpen}
      >
        Are you sure you want to delete export {`"`}
        <b>{selectedExport?.id}</b>
        {`"`}?
      </DeleteDialog>
    </>
  );
};

export default ExportsOverview;
