import { Box } from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";
import { useDeploymentsList } from "libs/data/endpoints/deployments/deployments";
import { usePipelinesList } from "libs/data/endpoints/pipelines/pipelines";

import { Divider, Tab, Tabs } from "components/atoms";

import {
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_PIPELINE_VARIABLE,
} from "../constants";
import { SidebarLeftCollapsed } from "./SidebarLeftCollapsed";
import { SidebarLeftDeploymentsTab } from "./SidebarLeftDeploymentsTab";
import { SidebarLeftExpandable } from "./SidebarLeftExpandableSidebar";
import { SidebarLeftOperatorsTab } from "./SidebarLeftOperatorsTab";
import { SidebarLeftVariablesTab } from "./SidebarLeftVariablesTab";
import { SidebarLeftPipelinesTab } from "./SiebarLeftPipelinesTab";

import type { PipelineVersionDetail } from "libs/data/models";

export enum SidebarLeftTabsEnum {
  Deployments = "Deployments",
  Operators = "Operators",
  Variables = "Variables",
  Pipelines = "Pipelines",
}

export type SidebarLeftProps = {
  projectName?: string;
  pipelineVersionDetail: PipelineVersionDetail | undefined;
};

export const SidebarLeft = ({
  projectName,
  pipelineVersionDetail,
}: SidebarLeftProps) => {
  const { data: deployments } = useDeploymentsList(projectName || "");
  const { data: pipelines } = usePipelinesList(projectName || "");
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState<SidebarLeftTabsEnum>(
    SidebarLeftTabsEnum.Deployments
  );

  const variables = pipelineVersionDetail?.objects?.filter(
    (object) =>
      object.reference_type === OBJECT_REFERENCE_TYPE_OPERATOR &&
      object.reference_name === OPERATOR_PIPELINE_VARIABLE
  );

  const onClickDeploymentIcon = () => {
    setIsExpanded(true);
    setActiveTab(SidebarLeftTabsEnum.Deployments);
  };
  const onClickFunctionIcon = () => {
    setIsExpanded(true);
    setActiveTab(SidebarLeftTabsEnum.Operators);
  };
  const onClickVariableIcon = () => {
    setIsExpanded(true);
    setActiveTab(SidebarLeftTabsEnum.Variables);
  };
  const onClickPipelineIcon = () => {
    setIsExpanded(true);
    setActiveTab(SidebarLeftTabsEnum.Pipelines);
  };

  return (
    <SidebarLeftExpandable
      isExpanded={isExpanded}
      toggleExpand={() => setIsExpanded((prev) => !prev)}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={`calc(100% - ${spacing[16]})`}
      >
        {!isExpanded ? (
          <>
            <Divider />
            <SidebarLeftCollapsed
              onClickDeploymentIcon={onClickDeploymentIcon}
              onClickFunctionIcon={onClickFunctionIcon}
              onClickVariableIcon={onClickVariableIcon}
              onClickPipelineIcon={onClickPipelineIcon}
            />
          </>
        ) : (
          <>
            <Divider />
            <Tabs
              variant="scrollable"
              value={activeTab}
              onChange={setActiveTab}
            >
              <Tab
                padding="compact"
                value={SidebarLeftTabsEnum.Deployments}
                label={SidebarLeftTabsEnum.Deployments}
              />

              <Tab
                padding="compact"
                value={SidebarLeftTabsEnum.Operators}
                label={SidebarLeftTabsEnum.Operators}
              />
              <Tab
                padding="compact"
                value={SidebarLeftTabsEnum.Variables}
                label={SidebarLeftTabsEnum.Variables}
              />
              <Tab
                padding="compact"
                value={SidebarLeftTabsEnum.Pipelines}
                label={SidebarLeftTabsEnum.Pipelines}
              />
            </Tabs>

            <Box padding={spacing[24]} flex={1} overflow="auto">
              {activeTab === SidebarLeftTabsEnum.Deployments && (
                <SidebarLeftDeploymentsTab deployments={deployments} />
              )}

              {activeTab === SidebarLeftTabsEnum.Operators && (
                <SidebarLeftOperatorsTab />
              )}

              {activeTab === SidebarLeftTabsEnum.Variables && (
                <SidebarLeftVariablesTab variables={variables} />
              )}

              {activeTab === SidebarLeftTabsEnum.Pipelines && (
                <SidebarLeftPipelinesTab
                  pipelines={pipelines?.filter(
                    (pipeline) =>
                      pipeline.name !== pipelineVersionDetail?.pipeline
                  )}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </SidebarLeftExpandable>
  );
};
