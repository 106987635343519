import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { FormPageWrapper } from "components/molecules";
import { Route } from "components/utilities";

import { CORSDomains } from "./cors-domains/CORSDomains";
import { ProjectEnvVars } from "./environment-variables/ProjectEnvVars";
import { ProjectSettinsGeneral } from "./general/ProjectSettingsGeneral";
import { InstanceTypeGroupEdit } from "./instance-type-groups/:instanceTypeGroupName/InstanceTypeGroupEdit";
import { InstanceTypeGroupCreate } from "./instance-type-groups/InstanceTypeGroupCreate";
import { InstanceTypeGroups } from "./instance-type-groups/InstanceTypeGroups";
import { InstanceTypes } from "./instance-types/InstanceTypes";
import { ProjectSettingsOverview } from "./ProjectSettingsOverview";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName").projectSettings;

const ProjectSettingsRoutesContainer = () => {
  const match = useRouteMatch();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName).projectSettings;

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>Project settings</BreadcrumbsItem>
      <Switch>
        <Route path={basePath.instanceTypeGroups.create.index()}>
          <FormPageWrapper
            name="Instance type groups"
            baseUrl={baseUrl.instanceTypeGroups.index()}
          >
            <InstanceTypeGroupCreate />
          </FormPageWrapper>
        </Route>
        <Route
          path={basePath.instanceTypeGroups[":groupId"](
            ":groupId"
          ).edit.index()}
        >
          <FormPageWrapper
            name="Instance type groups"
            baseUrl={baseUrl.instanceTypeGroups.index()}
          >
            <InstanceTypeGroupEdit />
          </FormPageWrapper>
        </Route>
        <Route path={basePath.index()}>
          <ProjectSettingsOverview>
            <Switch>
              <Route
                path={basePath.general.index()}
                component={ProjectSettinsGeneral}
                exact
              />
              <Route
                exact
                path={basePath.instanceTypes.index()}
                component={InstanceTypes}
              />
              <Route
                exact
                path={basePath.corsDomains.index()}
                component={CORSDomains}
              />
              <Route
                exact
                path={basePath.instanceTypeGroups.index()}
                component={InstanceTypeGroups}
              />
              <Route
                exact
                path={basePath.environmentVariables.index()}
                component={ProjectEnvVars}
              />
              <Redirect to={baseUrl.general.index()} />
            </Switch>
          </ProjectSettingsOverview>
        </Route>{" "}
      </Switch>
    </BaseUrlContext.Provider>
  );
};

export default ProjectSettingsRoutesContainer;
