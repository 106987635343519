import EditIcon from "@mui/icons-material/Edit";
import { Box, Card } from "@mui/material";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { Diagram } from "components/templates/Diagram";
import { PIPELINE_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import { routes } from "routes";

import { PrimaryButton } from "components/atoms";

import type { PipelineVersionRouteParams } from "../types";

export const PipelineDiagram = () => {
  const history = useHistory();
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();

  const [permissions] = usePermissionValidation(
    projectName,
    [PIPELINE_PERMISSIONS.version_update],
    pipelineName,
    "pipeline"
  );

  const pipelineVersionUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName)
        .versions[":versionName"](versionName),
    [organizationName, pipelineName, projectName, versionName]
  );

  return (
    <Card variant="outlined">
      <Box position="relative" display="flex" height="100%" minHeight="600px">
        <Box
          position="absolute"
          zIndex={999}
          top={spacing[12]}
          right={spacing[12]}
        >
          <PrimaryButton
            startIcon={<EditIcon />}
            disabled={!permissions[PIPELINE_PERMISSIONS.version_update]}
            onClick={() => history.push(pipelineVersionUrl.editMode.index())}
          >
            Enter edit mode
          </PrimaryButton>
        </Box>

        <Diagram
          isReadonly
          organizationName={organizationName}
          projectName={projectName}
          pipelineName={pipelineName}
          versionName={versionName}
        />
      </Box>
    </Card>
  );
};
