import { isPlainObject, merge, unionBy } from "lodash";
import { LowSync } from "lowdb";
// eslint-disable-next-line import/no-unresolved
import { LocalStorage } from "lowdb/browser";

import { ENV_NAMES } from "libs/env/env-names";

export const parseEnv = (envVar) => {
  if (["True", "true", true, 1, "yes", "Yes"].includes(envVar)) {
    return true;
  } else if (["False", "false", false, 0, "no", "No"].includes(envVar)) {
    return false;
  } else if (envVar === "") {
    return undefined;
  } else {
    return envVar;
  }
};

const ENVIRONMENTS = [
  {
    [ENV_NAMES.NAME]: window._env_?.WEBAPP_ENV,
    [ENV_NAMES.HOST]: window._env_?.WEBAPP_API_HOST,
    [ENV_NAMES.OAUTH_ENABLED]: window._env_?.WEBAPP_OAUTH_ENABLED === "True",
    // if false, the `usage` endpoint is disabled
    [ENV_NAMES.METRICS_AGGREGATION_ENABLED]:
      window._env_?.WEBAPP_METRICS_AGGREGATION_ENABLED === "True",
    [ENV_NAMES.BILLING_ENABLED]:
      window._env_?.WEBAPP_BILLING_ENABLED === "True",
    [ENV_NAMES.ON_PREMISE]: window._env_?.WEBAPP_ENV === ENV_NAMES.ON_PREMISE,
    [ENV_NAMES.GOOGLE_ANALYTICS_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.GOOGLE_ANALYTICS_ENABLED]
    ),
    [ENV_NAMES.REQUIRES_CONSENT]: parseEnv(
      window._env_?.[ENV_NAMES.REQUIRES_CONSENT]
    ),
    [ENV_NAMES.SENTRY_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.SENTRY_ENABLED]
    ),
    [ENV_NAMES.TRACKING_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.TRACKING_ENABLED]
    ),
    [ENV_NAMES.TRAINING_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.TRAINING_ENABLED]
    ),
    [ENV_NAMES.ENVIRONMENTS_ENABLED]: parseEnv(
      window._env_?.[ENV_NAMES.ENVIRONMENTS_ENABLED]
    ),
    [ENV_NAMES.GOOGLE_ANALYTICS_TOKEN]:
      window._env_?.WEBAPP_GOOGLE_ANALYTICS_TOKEN,
    [ENV_NAMES.SENTRY_DSN]: window._env_?.WEBAPP_SENTRY_DSN,
    [ENV_NAMES.REQUESTS_PAGE_ENABLED]:
      window._env_?.REQUESTS_PAGE_ENABLED === "True",
    [ENV_NAMES.REQUESTS_PAGE_URL]: window._env_?.REQUESTS_PAGE_URL,
    [ENV_NAMES.FILES_PROXY_ENABLED]:
      window._env_?.FILES_PROXY_ENABLED === "True",
    [ENV_NAMES.FILES_PROXY_HOST]: window._env_?.FILES_PROXY_HOST,
    [ENV_NAMES.DARK_MODE_ENABLED]:
      window._env_?.[ENV_NAMES.DARK_MODE_ENABLED] === "True",
    [ENV_NAMES.BRAND_THEME]: window._env_?.[ENV_NAMES.BRAND_THEME],
  },
];

const DEFAULT_LOCALSTORAGE_KEY = "UBIOPS_ENVIRONMENT_MANAGER";
const DEFAULTS = {
  current: window._env_?.WEBAPP_ENV,
  environments: [],
};

const HTTP_REGEX = /^https?:\/\//;
const isHttpUrl = (str) => HTTP_REGEX.test(str);
const isNonEmptyString = (str) => typeof str === "string" && str.length > 0;

class EnvironmentManager {
  constructor(key = DEFAULT_LOCALSTORAGE_KEY) {
    this.db = new LowSync(new LocalStorage(key), DEFAULTS);
    this.db.read();

    this.db.update((data) => {
      data.environments = unionBy(data.environments, ENVIRONMENTS, "name");

      return data;
    });

    // Hydrate environments with values from window._env_
    this.db.update((data) => {
      ENVIRONMENTS.forEach((env) => {
        data.environments = data.environments.map((e) =>
          e.name === env.name ? merge(e, env) : e
        );
      });

      return data;
    });

    if (!this.current()) {
      console.error("Invalid EnvironmentManager State", this.db.value()); // eslint-disable-line no-console
      throw new Error("Invalid EnvironmentManager State");
    }
  }

  __current() {
    return this.getEnvironment(this.db.data.current);
  }

  current() {
    return this.__current();
  }

  set(key, value) {
    if (key === "name") {
      throw new Error("Setting Current Environment Name Not Allowed");
    }

    this.__current()[key] = value;
    this.db.write();
  }

  unset(key) {
    if (key === "name") {
      throw new Error("Unsetting Current Environment Name Not Allowed");
    }
    this.__current()[key] = null;
    this.db.write();
  }

  get(key) {
    return this.__current()[key];
  }

  isValidEnvironment(env) {
    return (
      isPlainObject(env) && isNonEmptyString(env.name) && isHttpUrl(env.host)
    );
  }

  createEnvironment(newEnv) {
    if (!this.isValidEnvironment(newEnv)) {
      throw new Error("Invalid Environment Object");
    }

    if (this.hasEnvironment(newEnv.name)) {
      throw new Error("Environment Already Exists");
    }

    this.db.update((data) => {
      data.environments.push(newEnv);

      return data;
    });

    return newEnv;
  }

  removeEnvironment(name) {
    if (name === this.name()) {
      throw new Error("Cannot Remove Current Environment");
    }
    this.db.update((data) => {
      data.environments = data.environments.filter((env) => env.name !== name);

      return data;
    });
  }

  getEnvironment(name) {
    return this.db.data.environments.find((env) => env.name === name);
  }

  hasEnvironment(name) {
    return !!this.getEnvironment(name);
  }

  setEnvironmentAsCurrent(key) {
    if (!this.hasEnvironment(key)) {
      throw new Error("Environment Not Found");
    }
    this.db.update(({ data }) => {
      data.current = key;

      return data;
    });
  }

  dump() {
    console.log("EnvironmentManager", this.db.data); // eslint-disable-line no-console
  }
}

export const env = new EnvironmentManager();
