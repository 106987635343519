import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { isArray } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { MAX_CREDIT_LIMIT } from "libs/constants/constants";
import {
  FIELD_CREDIT_LIMIT,
  FIELD_CREDIT_LIMIT_ENABLED,
  FIELD_CREDIT_LIMIT_VALUE,
} from "libs/constants/fields";
import validators from "libs/utilities/validators";
import { useGetCurrentProject } from "store/features/organizationProjects";
import { useGetOrganizationFeatures } from "store/features/organizations";

import { FormTextField } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ChangeEvent } from "react";

const CustomSlider = styled(Slider)<{ sliderColor: string; railColor: string }>(
  (props) => ({
    marginTop: "1em",
    display: "flex",
    alignItems: "center",

    "& .MuiSlider-thumb": {
      height: "20px",
      width: "20px",
      color: props.sliderColor,
      position: "absolute",
      top: "50%",
      transform: "translateY(-10%, -40%)",
    },
    "& .MuiSlider-track": {
      height: "5px",
      color: props.sliderColor,
    },
    "& .MuiSlider-rail": {
      height: "5px",
      color: props.railColor,
    },
    "& .MuiSlider-mark": {
      width: 0,
      height: 0,
    },
    '& .MuiSlider-markLabel[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: "translateX(-100%)",
    },
  })
);

type ProjectComputeLimitSliderProps = {
  creatingProject?: boolean;
};

export const ProjectComputeLimitSliderBar = ({
  creatingProject = false,
}: ProjectComputeLimitSliderProps) => {
  const theme = useTheme() as AppThemeProps;

  const currentProject = useGetCurrentProject();
  const organizationFeatures = useGetOrganizationFeatures();
  const maxCompute = useMemo(
    () =>
      organizationFeatures?.max_credits === null
        ? MAX_CREDIT_LIMIT
        : Math.round(organizationFeatures?.max_credits || 0),
    [organizationFeatures?.max_credits]
  );

  const [marks, setMarks] =
    useState<{ label: string; value: number }[] | undefined>(undefined);

  const { setValue, control } = useFormContext();

  const computeLimitIsEnabled = useWatch({
    name: FIELD_CREDIT_LIMIT_ENABLED,
  });

  const currentCompute = useWatch({
    name: FIELD_CREDIT_LIMIT,
  });
  const computeLimitValue = useWatch({
    name: FIELD_CREDIT_LIMIT_VALUE,
  });

  useEffect(() => {
    if (maxCompute) {
      setMarks([
        { value: 0, label: "0 Credits" },
        { value: maxCompute, label: `${maxCompute} Credits` },
      ]);
    }
  }, [maxCompute]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInput = +event.target.value;
    setValue(FIELD_CREDIT_LIMIT, newInput > maxCompute ? maxCompute : newInput);
  };

  const handleBlur = () => {
    setValue(
      FIELD_CREDIT_LIMIT_VALUE,
      (computeLimitValue as any) < 0
        ? 0
        : (computeLimitValue as any) > maxCompute
        ? maxCompute
        : currentCompute
    );
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <>
        <Typography variant="h6">
          Monthly credit limit
          {!creatingProject ? ": " + currentProject?.name : ""}
        </Typography>
        <Typography variant="body2">
          Use the slider below to set a credit limit for this project. When the
          credit limit is reached, the project is suspended.
        </Typography>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Controller
                defaultValue={
                  !creatingProject && currentProject?.credits !== null
                }
                name={FIELD_CREDIT_LIMIT_ENABLED}
                control={control}
                render={({ onChange, value, ...params }) => (
                  <Checkbox
                    color="primary"
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    {...params}
                  />
                )}
              />
            }
            label={<Typography variant="body2">Enable credit limit</Typography>}
          />
        </Box>
        {computeLimitIsEnabled && (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={10}>
              <Controller
                name={FIELD_CREDIT_LIMIT}
                defaultValue={currentProject?.credits || maxCompute}
                control={control}
                render={({ name, onBlur, onChange, value }) => {
                  return (
                    <CustomSlider
                      sliderColor={theme.palette.secondary.main}
                      railColor={theme.palette.neutrals[500]}
                      max={maxCompute}
                      onBlur={onBlur}
                      onChange={(_, value) => {
                        onChange(value);
                        setValue(
                          FIELD_CREDIT_LIMIT_VALUE,
                          !isArray(value) && value > maxCompute
                            ? maxCompute
                            : value
                        );
                      }}
                      value={value}
                      name={name}
                      marks={marks}
                      valueLabelDisplay="auto"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormTextField
                name={FIELD_CREDIT_LIMIT_VALUE}
                defaultValue={currentProject?.credits || maxCompute}
                onChange={handleInputChange}
                onBlur={handleBlur}
                variant="standard"
                margin="dense"
                type="number"
                rules={{
                  min: {
                    value: 0,
                    message: validators.positive_number.message,
                  },
                  max: {
                    value: maxCompute,
                    message: validators.maximum_credits.message,
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};
