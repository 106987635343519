import { useState } from "react";

import { placeholderVersionKey } from "./useEntities";

import type {
  DeploymentVersionDetail,
  PipelineVersionDetail,
} from "libs/data/models";
import type { AddedEntity, Entity } from "./types";

export default (
  entities: Entity,
  fetchVersions: (name: string) => Promise<{
    [key: string]: DeploymentVersionDetail | PipelineVersionDetail;
  }>
) => {
  const [selectedVersions, setSelectedVersions] = useState<AddedEntity[]>([]);

  const toggleVersion = (name: string, version: string, isAdd: boolean) => {
    if (isAdd) {
      setSelectedVersions([...selectedVersions, { name, version }]);
    } else {
      setSelectedVersions(
        selectedVersions.filter(
          (currentVersion) =>
            currentVersion.name !== name || currentVersion.version !== version
        )
      );
    }
  };

  const isVersionSelected = (name: string, version: string) =>
    selectedVersions.some(
      (currentVersion) =>
        currentVersion.name === name && currentVersion.version === version
    );

  const isEntitySelected = (name: string) => {
    const versions = entities?.[name]?.versions;
    if (!versions) return false;
    const versionNames = Object.keys(versions);

    return versionNames.some((version) => isVersionSelected(name, version));
  };

  const toggleEntity = async (name: string, isAdd: boolean) => {
    const versions = entities?.[name]?.versions;
    if (!versions) return false;

    const storedVersionNames = Object.keys(versions);
    const needToFetch = storedVersionNames.includes(placeholderVersionKey);
    const versionObject = needToFetch ? await fetchVersions(name) : versions;

    if (isAdd) {
      const defaultVersionNames = Object.keys(versionObject).filter(
        (versionName) => versionObject[versionName]?.default
      );
      const otherEntityVersions = selectedVersions.filter(
        (selectedVersion) => selectedVersion.name !== name
      );
      setSelectedVersions([
        ...otherEntityVersions,
        ...defaultVersionNames.map((version) => ({ name, version })),
      ]);
    } else {
      setSelectedVersions(
        selectedVersions.filter(
          (selectedVersion) => selectedVersion.name !== name
        )
      );
    }

    return false;
  };

  return {
    toggleEntity,
    toggleVersion,
    isEntitySelected,
    isVersionSelected,
    selectedVersions,
    setSelectedVersions,
  };
};
