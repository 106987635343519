import Trash from "@mui/icons-material/DeleteRounded";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { BaseTable } from "components/molecules";
import {
  RequestResultsDialog,
  shouldFetchMetadataOnly,
} from "components/organisms";

import { RequestsCancelDialog } from "./RequestsCancelDialog";
import { RequestsDeleteDialog } from "./RequestsDeleteDialog";
import { useRequestsColumns } from "./useRequestsColumns";

import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  DeploymentRequestSingleDetail,
  PipelineRequestSingleDetail,
} from "libs/data/models";
import type { Query, RequestParameter, RequestRow } from "./types";

type RequestsTableProps = {
  baseUrl: (name?: string, version?: string) => string;
  logsUrl: string;
  requests?: { id: string; status: string }[];
  allowCreate?: boolean;
  allowGet?: boolean;
  allowLogs?: boolean;
  allowDelete?: boolean;
  allowUpdate?: boolean;
  requestParameters: RequestParameter;
  query: Query;
  displayDeploymentName?: boolean;
  setQuery?: (query: Query) => void;
  refresh: () => void;
  tableOptions?: {
    withoutMultiSelect?: boolean;
    withoutDeleteButton?: boolean;
    withoutCancelButton?: boolean;
  };
  hasRetryButton: boolean;
};

const RequestsTable = ({
  baseUrl,
  logsUrl,
  requests,
  allowCreate = false,
  allowGet = false,
  allowLogs = false,
  allowDelete = false,
  allowUpdate = false,
  requestParameters,
  tableOptions,
  query,
  setQuery,
  refresh,
  displayDeploymentName,
  hasRetryButton,
}: RequestsTableProps) => {
  const theme = useTheme();
  const { projectName, organizationName } =
    useParams<{
      projectName: string;
      deploymentName: string | undefined;
      pipelineName: string | undefined;
      versionName: string;
      organizationName: string;
    }>();
  const [isRequestResultsDialogOpen, setIsRequestResultsDialogOpen] =
    useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCancelRequestDialogOpen, setIsCancelRequestDialogOpen] =
    useState(false);
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
  const [selectedRequest, setSelectedRequest] =
    useState<RequestRow | null>(null);

  const displayDeployment = displayDeploymentName;

  const columns = useRequestsColumns({
    allowGet,
    allowCreate,
    allowDelete,
    allowLogs,
    allowUpdate,
    logsUrl,
    requestParameters,
    setSelectedRequest,
    setIsRequestResultsDialogOpen,
    setIsCancelRequestDialogOpen,
    setIsDeleteDialogOpen,
    tableOptions,
    selectedRequests,
    setSelectedRequests,
    query,
    setQuery,
    refresh,
    displayDeployment,
    hasRetryButton,
  });

  useEffect(() => {
    setSelectedRequests([]);
  }, [query]);

  const onViewResults = useCallback(
    (event: any, rowData: any) => {
      event.stopPropagation();

      setIsRequestResultsDialogOpen(true);
      setSelectedRequest(rowData);
    },
    [setIsRequestResultsDialogOpen]
  );

  return (
    <Grid item xs={12}>
      <BaseTable
        columns={columns as BaseColumn[]}
        data={requests}
        onRowClick={allowGet ? onViewResults : undefined}
        isAllChecked={
          selectedRequests.length > 0 &&
          selectedRequests.length === requests?.length
        }
        hasSearchBar={false}
        hasPagination={false}
        isAnyChecked={selectedRequests.length > 0}
        MultiAction={() =>
          selectedRequests.length < 1 ? (
            <></>
          ) : (
            <Box>
              <span>{selectedRequests?.length} row(s) selected</span>
              <IconButton onClick={() => setIsDeleteDialogOpen(true)}>
                <Trash sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            </Box>
          )
        }
        onSelectAll={(checked: boolean) => {
          if (checked) {
            setSelectedRequests(requests?.map(({ id }) => id) || []);
          } else {
            setSelectedRequests([]);
          }
        }}
      />

      <RequestResultsDialog
        isOpen={isRequestResultsDialogOpen}
        setIsOpen={setIsRequestResultsDialogOpen}
        organizationName={organizationName}
        projectName={projectName}
        resourceType={
          (selectedRequest as DeploymentRequestSingleDetail)?.deployment
            ? "deployment"
            : (selectedRequest as PipelineRequestSingleDetail)?.pipeline
            ? "pipeline"
            : undefined
        }
        resourceName={
          (selectedRequest as DeploymentRequestSingleDetail)?.deployment ||
          ((selectedRequest as PipelineRequestSingleDetail)?.pipeline as string)
        }
        resourceVersion={selectedRequest?.version}
        requestId={selectedRequest?.id}
        fetchOnlyMetadata={shouldFetchMetadataOnly(selectedRequest)}
      />
      <RequestsDeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        selectedRequest={selectedRequest}
        selectedRequests={selectedRequests}
        setSelectedRequest={setSelectedRequest}
        setSelectedRequests={setSelectedRequests}
        requestParameters={requestParameters}
        refresh={refresh}
      />

      <RequestsCancelDialog
        selectedRequest={selectedRequest}
        isOpen={isCancelRequestDialogOpen}
        baseUrl={baseUrl}
        onClose={() => setIsCancelRequestDialogOpen(false)}
      />
    </Grid>
  );
};

// rerender table after request id, status or permission changes
// compared to the previously rendered props
const MemoizedTable = React.memo(RequestsTable, (prev, curr) => {
  const previous = prev?.requests || [];
  const current = curr?.requests || [];

  const previousIdsStatuses = previous
    .map(({ id, status }) => `${id}${status}`)
    .join("");

  const currentIdsStatuses = current
    .map(({ id, status }) => `${id}${status}`)
    .join("");

  const idsAndStatusesAreTheSame = previousIdsStatuses === currentIdsStatuses;
  const permissionsAretheSame =
    prev?.allowCreate === curr?.allowCreate &&
    prev?.allowDelete === curr?.allowDelete &&
    prev?.allowGet === curr?.allowGet &&
    prev?.allowLogs === curr?.allowLogs &&
    prev?.allowUpdate === curr?.allowUpdate;

  return idsAndStatusesAreTheSame && permissionsAretheSame;
});

export default MemoizedTable;
