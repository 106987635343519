import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import {
  FIELD_EMAIL,
  FIELD_NAME,
  FIELD_PASSWORD,
  FIELD_SURNAME,
} from "libs/constants/fields";
import validators from "libs/utilities/validators";

import { FormTextField, PrimaryButton } from "components/atoms";

import { useCreateUser } from "libs/data/customized/user/useCreateUser";

const SignUpRegister = () => {
  const { handleSubmit, setValue } = useFormContext();
  const history = useHistory();
  const { email } = useParams<{ email: string }>();
  const { createUser, isLoading } = useCreateUser();

  useEffect(() => {
    try {
      const decodedEmail = atob(decodeURIComponent(email));
      // if the value is an actual email
      if (validators.email.pattern.test(decodedEmail)) {
        setValue(FIELD_EMAIL, decodedEmail);
      } else {
        throw Error();
      }
    } catch {
      history.push("/sign-up" + window.location.search);
    }
  }, [email, setValue, history]);

  return (
    <form onSubmit={handleSubmit(createUser)}>
      <Grid container direction="column">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormTextField
              id={FIELD_NAME}
              name={FIELD_NAME}
              label="First Name"
              rules={{ required: validators.required.message(FIELD_NAME) }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              id={FIELD_SURNAME}
              name={FIELD_SURNAME}
              label="Last Name"
              rules={{ required: validators.required.message(FIELD_SURNAME) }}
            />
          </Grid>
        </Grid>
        <FormTextField
          id={FIELD_EMAIL}
          name={FIELD_EMAIL}
          autoComplete={FIELD_EMAIL}
          autoCapitalize="none"
          type={FIELD_EMAIL}
          label="Email"
          rules={{
            required: validators.required.message(FIELD_EMAIL),
            pattern: {
              value: validators.email.pattern,
              message: validators.email.message,
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
        <FormTextField
          name={FIELD_PASSWORD}
          id={FIELD_PASSWORD}
          type={FIELD_PASSWORD}
          label="Password"
          rules={{
            required: validators.required.message(FIELD_PASSWORD),
            minLength: {
              value: validators.password_requirements.minLength,
              message: validators.password_requirements.message,
            },
          }}
        />
        <Box mt={2}>
          <PrimaryButton
            style={{ width: "100%" }}
            type="submit"
            loading={isLoading}
          >
            Sign up for UbiOps
          </PrimaryButton>
        </Box>
      </Grid>
    </form>
  );
};

export default SignUpRegister;
