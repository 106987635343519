import { Box, Grid, Typography } from "@mui/material";

import InfoTooltip from "../InfoTooltip/InfoTooltip";

export type DetailsItemProps = {
  title?: string;
  tooltip?: string;
  titleVariant?:
    | "h5"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h6"
    | "inherit"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "overline";
  children?: React.ReactNode;
  icon?: React.ReactElement;
};

const DetailsItem = ({
  title,
  tooltip,
  icon,
  titleVariant = "h5",
  children,
}: DetailsItemProps) => (
  <Grid container spacing={3}>
    <Grid item xs={6} container alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography variant={titleVariant} display="inline">
            {title}
          </Typography>
          {icon}
        </Box>
      </Grid>
      {tooltip && (
        <Grid item component={InfoTooltip}>
          {tooltip}
        </Grid>
      )}
    </Grid>
    <Grid item xs={6} sx={{ wordWrap: "break-word" }}>
      {children}
    </Grid>
  </Grid>
);

export default DetailsItem;
