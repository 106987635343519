import CancelIcon from "@mui/icons-material/Cancel";
import Trash from "@mui/icons-material/DeleteRounded";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import RetryIcon from "@mui/icons-material/RefreshRounded";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { RequestsCancelDialog } from "components/organisms/Requests/RequestsCancelDialog";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  deploymentVersionRequestsDelete,
  useDeploymentVersionRequestsGet,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useDeploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { useGoogleAnalytics, useInterval, useRetryRequest } from "libs/hooks";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import {
  ButtonGroup,
  DeleteDialog,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import {
  RequestResultsDialog,
  shouldFetchMetadataOnly,
} from "components/organisms";

import type { DeploymentRequestSingleDetail } from "libs/data/models";
import type { RequestParameters } from "libs/hooks";
import type { ReactNode } from "react";
import type { RequestDetailsRouteParams } from ".";

interface DeploymentVersionDetailsProps {
  children?: ReactNode;
}

export const RequestDetails = ({ children }: DeploymentVersionDetailsProps) => {
  useGoogleAnalytics();

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    organizationName,
    projectName,
    deploymentName,
    versionName,
    requestId,
  } = useParams<RequestDetailsRouteParams>();

  const { data: versionDetails } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );

  const { data: requestDetails, mutate } = useDeploymentVersionRequestsGet(
    projectName,
    deploymentName,
    versionName,
    requestId
  );
  const requestParameters: RequestParameters = {
    type: "deployment",
    resourceName: deploymentName,
    resourceVersion: versionName,
  };

  const { onRetry } = useRetryRequest({
    projectName,
    requestParameters,
    batchMode: true,
  });

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deploymentName)
        .versions[":versionName"](versionName)
        .requests.express[":requestId"](requestId),
    [deploymentName, organizationName, projectName, versionName, requestId]
  );

  const tabs = useMemo(
    () => [
      {
        link: baseUrl.general.index(),
        label: "General",
      },
    ],
    [baseUrl.general]
  );

  const isRunning = useMemo(
    () =>
      requestDetails?.status === "pending" ||
      requestDetails?.status === "processing" ||
      requestDetails?.status === "cancelled_pending",
    [requestDetails?.status]
  );

  const handleDelete = () => {
    requestDetails?.id &&
      deploymentVersionRequestsDelete(
        projectName,
        deploymentName,
        versionName,
        requestId
      )
        .then(() => {
          dispatch(createSuccessNotification("Request was deleted"));
          history.push(
            `/organizations/${organizationName}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}/requests`
          );
        })
        .catch((error) => {
          dispatch(createErrorNotification(error.message));
        })
        .finally(() => {
          setDeleteDialogOpen(false);
        });
  };

  const onRetryClick = useCallback(
    async (request?: DeploymentRequestSingleDetail) => {
      if (!request) return;
      await onRetry({
        id: request?.id,
        deployment: request?.deployment,
        version: request?.version,
      });
    },
    [onRetry]
  );

  useInterval(
    () => {
      if (isRunning) mutate();
    },
    [isRunning, mutate],
    CONTINUOUS_REQUEST_DELAY,
    false
  );

  const hasResults =
    requestDetails?.status &&
    ["completed", "failed"].includes(requestDetails?.status);

  return (
    <>
      <RequestResultsDialog
        isOpen={isRequestDialogOpen}
        setIsOpen={setIsRequestDialogOpen}
        organizationName={organizationName}
        projectName={projectName}
        fetchOnlyMetadata={shouldFetchMetadataOnly(requestDetails)}
        requestId={requestDetails?.id}
        resourceVersion={versionName}
        resourceName={deploymentName}
        resourceType="deployment"
      />

      <DetailsContainer
        title={requestId}
        actions={
          <ButtonGroup>
            {versionDetails?.request_retention_mode && (
              <TextButton
                color="secondary"
                disabled={
                  !currentPermissions[
                    DEPLOYMENT_PERMISSIONS["version_request_create"]
                  ]
                }
                onClick={() => onRetryClick(requestDetails)}
                startIcon={<RetryIcon />}
              >
                Retry
              </TextButton>
            )}
            {isRunning && (
              <TextButton
                color="secondary"
                disabled={requestDetails?.status === "cancelled_pending"}
                onClick={() => setIsCancelDialogOpen(true)}
                startIcon={<CancelIcon />}
              >
                Cancel
              </TextButton>
            )}
            <TextButton
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_delete"]]
              }
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<EqualizerIcon />}
              disabled={!hasResults}
              onClick={() => setIsRequestDialogOpen(true)}
              style={{ marginLeft: "auto" }}
              tooltip={
                !hasResults
                  ? "Your request hasn’t finished yet. When it’s finished, you can check the results with this button"
                  : ""
              }
            >
              View results
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        <PageTabs tabs={tabs}>{children}</PageTabs>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={handleDelete}
        >
          Are you sure you want to delete request <br />
          {`"`}
          <b>{requestDetails?.id}</b>
          {`"`}?
        </DeleteDialog>
        <RequestsCancelDialog
          isOpen={isCancelDialogOpen}
          onClose={() => setIsCancelDialogOpen(false)}
          selectedRequest={requestDetails}
          baseUrl={(name, version) =>
            `/projects/${projectName}/deployments/${name}/versions/${version}`
          }
        />
      </DetailsContainer>
    </>
  );
};
