import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, CircularProgress, Icon } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_IMPORTED_ZIP_FILE,
  FIELD_IMPORT_LINK,
} from "libs/constants/fields";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";
import { useGetCurrentProject } from "store/features/organizationProjects";

import {
  Dialog,
  FormTextField,
  LinearProgressWithLabel,
} from "components/atoms";
import { FileField, FormSection, PageContainer } from "components/molecules";
import { FormContainer, SimpleTabs } from "components/organisms";

import { ImportConfirm } from "./ImportConfirm";
import { useImportUpload } from "./useImportUpload";

const ImportCreate = () => {
  useGoogleAnalytics();

  const methods = useForm({
    mode: "onSubmit",
  });

  const importLink = useWatch({
    control: methods.control,
    name: FIELD_IMPORT_LINK,
  });

  const importZip = useWatch({
    control: methods.control,
    name: FIELD_IMPORTED_ZIP_FILE,
  });

  const { state: locationState } = useLocation<{ id: string }>();
  const overviewImportId = locationState?.id;
  const currentProjectName = useGetCurrentProject()?.name;
  const {
    uploadFile,
    isLoading,
    uploadLoading,
    importId,
    percentage,
    importError,
  } = useImportUpload(currentProjectName);

  const actionButtonLabel = uploadLoading
    ? "Uploading"
    : isLoading
    ? "Scanning"
    : "Next: Review";

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const onSubmit = useCallback(
    (data: { [x: string]: any }) => {
      uploadFile({
        file: data[FIELD_IMPORTED_ZIP_FILE]?.[0],
        import_link: data[FIELD_IMPORT_LINK],
      });
    },
    [uploadFile]
  );

  useEffect(() => {
    if (importError) {
      setIsErrorDialogOpen(true);
    }
  }, [importError]);

  const isConfirmStep = Boolean(
    !isLoading && !importError && (importId || overviewImportId)
  );

  const isEmptyForm = !importLink && !importZip;

  return (
    <PageContainer>
      <PageHeader title="New import" />
      <div hidden={isConfirmStep}>
        <FormContainer
          onSubmit={onSubmit}
          formMethods={methods}
          buttonLabel={actionButtonLabel}
          buttonProps={{
            disabled: isLoading || isEmptyForm,
            startIcon: isLoading ? <CircularProgress size={20} /> : undefined,
          }}
        >
          <FormSection
            title="Select import method"
            description={explanations.importsExports.imports.templates}
          >
            <SimpleTabs
              tabs={[
                {
                  title: "Upload zip file",
                  content: (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "200px",
                        padding: "2em",
                      }}
                    >
                      <FileField
                        verticalStyle
                        buttonText="Upload zip"
                        name={FIELD_IMPORTED_ZIP_FILE}
                        // @ts-ignore
                        accept=".zip"
                        includeIcon
                        disabled={isLoading}
                      />
                      {uploadLoading && (
                        <LinearProgressWithLabel percentage={percentage} />
                      )}
                    </Box>
                  ),
                },
                {
                  title: "Import from link",
                  content: (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "200px",
                        padding: "2em",
                      }}
                    >
                      <FormTextField
                        name={FIELD_IMPORT_LINK}
                        label="Public URL"
                        size="small"
                        tooltip={
                          explanations.importsExports.imports.importFromLink
                        }
                        rules={{
                          required: validators.required.message("url"),
                          pattern: {
                            value: validators.publicUrl.pattern,
                            message: validators.publicUrl.message,
                          },
                        }}
                        placeholder="https://storage.googleapis.com/sample_package.zip"
                        disabled={isLoading}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </FormSection>

          <Dialog
            open={isErrorDialogOpen}
            onClose={() => setIsErrorDialogOpen(false)}
            title="Failed to upload import file"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBlockEnd: "10px",
              }}
            >
              <Icon
                sx={{ marginInlineEnd: "10px" }}
                component={ErrorIcon}
                color="error"
              />
              {explanations.importsExports.imports.errorTemplate}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBlockEnd: "10px",
              }}
            >
              <Icon
                sx={{ marginInlineEnd: "10px" }}
                component={HighlightOffIcon}
              />
              {importError}
            </Box>
          </Dialog>
        </FormContainer>
      </div>

      {isConfirmStep && (
        <ImportConfirm importId={importId || overviewImportId} />
      )}
    </PageContainer>
  );
};

export default ImportCreate;
