import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { useWebhooksList } from "libs/data/endpoints/webhooks/webhooks";
import { useDeviceDetect } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";

import { Loader, PrimaryButton, TableLink } from "components/atoms";
import { BaseTable } from "components/molecules";
import { onFilterAdd } from "components/organisms";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { WebhookDetail } from "libs/data/models";

export const MonitoringWebHooks = () => {
  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  const baseUrl = useContext(BaseUrlContext);
  const match = useRouteMatch();
  const history = useHistory();
  const { isMobile } = useDeviceDetect();

  const { projectName } = useParams<{ projectName: string }>();

  const { data, error } = useWebhooksList(
    projectName,
    filters?.length
      ? {
          labels: formatLabelsForFilter(filters),
        }
      : undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/webhooks`,
      },
    }
  );

  const isLoading = !data && !error;

  const columns = [
    {
      title: "Name",
      field: "name",
      width: "20%",
      render: (rowData: WebhookDetail) => (
        <TableLink variant="bold" to={`${baseUrl}/webhooks/${rowData.name}`}>
          {rowData.name}
        </TableLink>
      ),
    },
    !isMobile && {
      title: "Object type",
      field: "object_type",
    },
    {
      title: "Object",
      field: "object_name",
    },
    {
      title: "Version",
      field: "version",
      render: (rowData: WebhookDetail) =>
        rowData.version === null ? "default" : rowData.version,
    },
    !isMobile && {
      title: "Created",
      field: "creation_date",
      type: "datetime",
      nowrap: true,
      render: (rowData: WebhookDetail) =>
        getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
    },
    !isMobile && {
      title: "Enabled",
      field: "enabled",
      render: (rowData: WebhookDetail) =>
        rowData.enabled ? (
          <CheckIcon fontSize="small" color="success" />
        ) : (
          <CloseIcon fontSize="small" color="error" />
        ),
    },
    {
      title: "Labels",
      field: "labels",
      render: renderLabels(onFilterAdd(setFilters)),
    },
  ].filter(Boolean);

  const onRowClick = (_: any, rowData: WebhookDetail) => {
    history.push(`${baseUrl}/webhooks/${rowData.name}`);
  };

  return (
    <Grid item xs={12}>
      <BreadcrumbsItem to={match.url}>Webhooks</BreadcrumbsItem>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <BaseTable
            columns={columns as BaseColumn[]}
            data={data}
            onRowClick={onRowClick}
            header={
              <PrimaryButton
                startIcon={<Plus />}
                size="small"
                onClick={() => history.push(`${baseUrl}/webhooks/create`)}
              >
                Create webhook
              </PrimaryButton>
            }
          />
        </Box>
      )}
    </Grid>
  );
};
