import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import PlayArrow from "@mui/icons-material/PlayArrow";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import {
  PIPELINE_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import {
  usePipelineDefaultVersion,
  usePipelineDelete,
} from "libs/data/customized/pipeline";
import { usePermissionValidation } from "libs/data/customized/roles";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics, useLogsUrl, useRequest } from "libs/hooks";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";
import { useGetPermissions } from "store/features/permissions";

import {
  ButtonGroup,
  DeleteDialog,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import { RequestDialog } from "components/organisms";

import type { PipelineRouteParams } from "pages/organizations/:organizationName/projects/:projectName/pipelines/:pipelineName/versions/types";
import type { ReactNode } from "react";

export const PipelineDetails = ({ children }: { children: ReactNode }) => {
  useGoogleAnalytics();
  const history = useHistory();
  const { organizationName, projectName, pipelineName } =
    useParams<PipelineRouteParams>();

  const currentOrganization = useGetCurrentOrganization();

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);
  const { data: pipelineDefaultVersion } = usePipelineDefaultVersion(
    projectName,
    pipelineName
  );
  const deletePipeline = usePipelineDelete(projectName);
  const [projectPermissions] = useGetPermissions();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(PIPELINE_PERMISSIONS),
    pipelineName,
    "pipeline"
  );

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName),
    [pipelineName, organizationName, projectName]
  );
  const logsUrl = useLogsUrl({
    queryParameters: { pipeline_name: pipelineName },
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { openCreateDialog, ...requestProps } = useRequest({
    type: "pipeline",
    instance: pipeline,
    isRetentionModeNone:
      pipelineDefaultVersion?.request_retention_mode === "none",
  });

  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    {
      link: baseUrl.configuration.index(),
      label: "Configuration",
    },
    {
      link: baseUrl.auditEvents.index(),
      label: "Audit events",
      disabled: !currentPermissions[PIPELINE_PERMISSIONS["audit_list"]],
    },
    {
      link: baseUrl.usePipeline.index(),
      label: "Use pipeline",
      disabled: !pipelineDefaultVersion,
      disabledText:
        "There is no default version available, please create a version first and set a default version to start using your pipeline.",
    },
  ];

  const onDelete = async () => {
    const response = await deletePipeline(pipelineName);

    if (response) {
      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines.index()
      );
    }
  };

  return (
    <>
      <RequestDialog
        organizationName={organizationName}
        projectName={projectName}
        {...requestProps}
      />

      <DetailsContainer
        title={pipelineName}
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[PIPELINE_PERMISSIONS["update"]] ||
                currentOrganization?.status !== "active"
              }
              link={baseUrl.edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>
            <TextButton
              color="secondary"
              disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
              link={logsUrl}
              startIcon={<LogsIcon />}
            >
              Logs
            </TextButton>
            <TextButton
              disabled={!currentPermissions[PIPELINE_PERMISSIONS["delete"]]}
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<PlayArrow />}
              disabled={
                !pipeline?.default_version ||
                !currentPermissions[
                  PIPELINE_PERMISSIONS["version_request_create"]
                ]
              }
              onClick={openCreateDialog}
              style={{ marginLeft: "auto" }}
            >
              Create request
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        {pipeline && <PageTabs tabs={tabs}>{children}</PageTabs>}
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={() => onDelete()}
        >
          Are you sure you want to delete pipeline &quot;
          <b>{pipeline?.name}</b>&quot; and all of its versions?
        </DeleteDialog>
      </DetailsContainer>
    </>
  );
};
