import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { routes } from "routes";

import { Loader } from "components/atoms";

const PipelineVersionDefault = () => {
  const history = useHistory();
  const { organizationName, projectName, pipelineName } = useParams();
  const rootUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName);

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);

  useEffect(() => {
    if (pipeline) {
      const pipelineRoute = rootUrl.pipelines[":pipelineName"](pipelineName);

      if (pipeline.default_version) {
        history.replace(
          pipelineRoute.versions[":versionName"](
            pipeline.default_version
          ).index()
        );
      } else {
        history.replace(pipelineRoute.index());
      }
    }
  }, [history, pipeline, pipelineName, rootUrl.pipelines]);

  return <Loader />;
};

export default PipelineVersionDefault;
