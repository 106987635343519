import { FormProvider, useForm } from "react-hook-form";

import { FIELD_ORGANIZATION, FIELD_VOUCHER } from "libs/constants/fields";
import { useOrganizationCreate } from "libs/data/customized/organization";
import { useOrganizationsList } from "libs/data/endpoints/organizations/organizations";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";

import { Dialog, PrimaryButton } from "components/atoms";

import { NewAccountFirstOrganization } from "./NewAccountFirstOrganization";

type NewAccountDialogProps = {
  open: boolean;
  closeDialog: (args: boolean) => void;
  setOrganizationName: (name: string) => void;
};

const NewAccountDialog = ({
  open,
  closeDialog,
  setOrganizationName,
}: NewAccountDialogProps) => {
  const createOrganization = useOrganizationCreate();

  const { mutate } = useOrganizationsList();

  const projectFormMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit: handleProjectSubmit } = projectFormMethods;

  const handleOrganizationCreate = async (data: { [x: string]: string }) => {
    const newOrg = await createOrganization({
      name: data[FIELD_ORGANIZATION],
      subscription: data[FIELD_VOUCHER]
        ? undefined
        : env.get(ENV_NAMES.ON_PREMISE)
        ? "default"
        : "free",
      voucher: data[FIELD_VOUCHER] ? data[FIELD_VOUCHER] : undefined,
    });
    if (newOrg) {
      await mutate();
      setOrganizationName(newOrg.name);
      closeDialog(false);
    }
  };

  return (
    <Dialog
      container=".main-layout"
      dismissible={false}
      open={open}
      onClose={closeDialog}
      title="Welcome to UbiOps!"
      Actions={
        <PrimaryButton onClick={handleProjectSubmit(handleOrganizationCreate)}>
          Create organization
        </PrimaryButton>
      }
    >
      <div>
        <FormProvider {...projectFormMethods}>
          <NewAccountFirstOrganization
            submitForm={handleProjectSubmit(handleOrganizationCreate)}
          />
        </FormProvider>
      </div>
    </Dialog>
  );
};

export default NewAccountDialog;
