import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { env, ENV_NAMES } from "libs/env";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { ProjectDetail, ProjectList } from "libs/data/models";
import type { RootState } from "store/store";

const initialState: {
  projects: ProjectList[] | null;
  currentProject: ProjectDetail | null;
} = {
  projects: null,
  currentProject: null,
};

export const organizationProjects = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectDetail>) => {
      env.set(ENV_NAMES.LAST_SEEN_PROJECT, action.payload?.id);
      state.currentProject = action.payload;
    },
    setProjects: (state, action: PayloadAction<ProjectList[]>) => {
      state.projects = action.payload;
    },
    addProject: (state, action: PayloadAction<ProjectList>) => {
      state.projects?.unshift(action.payload);
    },
    updateProject: (state, action: PayloadAction<ProjectDetail>) => {
      state.projects =
        state.projects
          ?.slice()
          ?.map((project: ProjectList) =>
            project.id === action.payload.id
              ? { ...project, ...action.payload }
              : project
          ) ?? null;
    },
    deleteProject: (state, action: PayloadAction<string>) => {
      state.projects =
        state.projects
          ?.slice()
          .filter((project) => project.id !== action.payload) ?? null;
    },
  },
});

export const useGetCurrentProject = () => {
  return useSelector((store: RootState) => store.projects.currentProject);
};

export const useGetProject = (name: string) => {
  const projects = useSelector((store: RootState) => store.projects.projects);

  return projects?.find((project) => project.name === name);
};

export const useGetProjects = () => {
  return useSelector((store: RootState) => store.projects.projects);
};

export const {
  addProject,
  updateProject,
  deleteProject,
  setProjects,
  setProject,
} = organizationProjects.actions;

export default organizationProjects.reducer;
