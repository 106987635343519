import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { UploadFileCode } from "assets/images/UploadFileCode";
import { UploadFileDocument } from "assets/images/UploadFileDocument";
import { UploadFileImage } from "assets/images/UploadFileImage";
import { spacing } from "assets/styles/theme";
import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";
import { useFileUpload } from "libs/data/customized/files/useFileUpload";
import { filesGet } from "libs/data/endpoints/files/files";

import { Dialog, PrimaryButton, SecondaryButton } from "components/atoms";
import type { File as UbiopsFile } from "components/organisms";

import { DuplicateFileWarning } from "./DuplicateFileWarning";

interface UploadFileDialogProps {
  bucketName: string;
  closeDialog: () => void;
  handleSuccessfulUpload: (name: string) => void;
  open: boolean;
  prefix?: string;
  types?: string[];
  multiple?: boolean;
}

export const UploadFileDialog = ({
  bucketName,
  closeDialog,
  handleSuccessfulUpload,
  prefix = "",
  open,
  types,
  multiple,
}: UploadFileDialogProps) => {
  const { projectName } =
    useParams<{
      projectName: string;
    }>();
  const fileUpload = useFileUpload(projectName, bucketName);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [duplicateFiles, setDuplicateFiles] =
    useState<UbiopsFile[] | undefined>(undefined);

  useEffect(() => {
    return () => setDuplicateFiles(undefined);
  }, []);

  const handleChange = async (file: File | FileList) => {
    if (!file) return;
    if (file instanceof FileList) {
      handleMultipleFiles([...file]);
    } else {
      handleSingleFile(file as File);
    }
  };

  const handleMultipleFiles = async (files: File[]) => {
    setLoading(true);
    const responses = await Promise.allSettled(
      files.map(
        async (file) =>
          await filesGet(
            projectName,
            bucketName,
            encodeURIComponent(prefix + file.name)
          )
      )
    );

    const duplicated = responses
      .filter(({ status }) => status === "fulfilled")
      .map((p) => (p as PromiseFulfilledResult<UbiopsFile>).value);

    if (duplicated?.length > 0) {
      setDuplicateFiles(duplicated);
      setSelectedFiles(files);
    } else {
      onChangeMultiple(files);
    }
  };

  const handleSingleFile = async (file: File) => {
    if (file) {
      setLoading(true);
      filesGet(projectName, bucketName, encodeURIComponent(prefix + file.name))
        .then((data) => {
          setDuplicateFiles([data]);
          setSelectedFiles([file]);
        })
        .catch(() => {
          onChange(file);
        });
    }
  };

  const onChangeMultiple = async (files: File[]) => {
    await Promise.all(
      files.map(async (file) => await fileUpload(prefix + file.name, file))
    );
    setLoading(false);
    closeDialog();
    handleSuccessfulUpload("");
  };

  const onChange = async (file: File) => {
    await fileUpload(prefix + file.name, file);
    setLoading(false);
    closeDialog();
    handleSuccessfulUpload(file.name);
  };

  return (
    <>
      <Dialog
        dialogBodyStyles={duplicateFiles ? { padding: 0 } : {}}
        onClose={closeDialog}
        open={open}
        title={
          duplicateFiles
            ? `Are you sure you want to overwrite ${
                duplicateFiles.length > 1 ? "these files" : " this file"
              }?`
            : "Upload a file to your bucket"
        }
        Actions={
          duplicateFiles && (
            <>
              <SecondaryButton
                onClick={() => {
                  setDuplicateFiles(undefined);
                  setLoading(false);
                }}
                style={{ marginRight: spacing[12] }}
              >
                Cancel{" "}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  if (selectedFiles && selectedFiles.length === 1)
                    onChange(selectedFiles[0]);
                  if (selectedFiles && selectedFiles.length > 1)
                    onChangeMultiple(selectedFiles);
                  setDuplicateFiles(undefined);
                }}
              >
                Overwrite
              </PrimaryButton>
            </>
          )
        }
      >
        {duplicateFiles ? (
          <DuplicateFileWarning files={duplicateFiles} />
        ) : (
          <DropSelectFile
            multiple={multiple}
            handleChange={handleChange}
            types={types}
            name="upload-file-dialog-content"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom={1}
            >
              <Box style={{ marginLeft: -5, marginRight: -5 }}>
                <UploadFileImage />
              </Box>
              <Box style={{ marginLeft: -5, marginRight: -5 }}>
                <UploadFileCode />
              </Box>
              <Box style={{ marginLeft: -5, marginRight: -5 }}>
                <UploadFileDocument />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginBottom={2}
            >
              <Typography>Drag and drop your file here to upload it</Typography>
              <Typography>or</Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <PrimaryButton loading={loading}>Browse files</PrimaryButton>
            </Box>
          </DropSelectFile>
        )}
      </Dialog>
    </>
  );
};
