import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import {
  DEFAULT_TIME_OUT_PIPELINE_REQUESTS,
  DEFAULT_TIME_OUT_REQUESTS,
} from "libs/constants/constants";
import { useDeploymentStreamingRequestCreate } from "libs/data/customized/deployment-requests/useDeploymentStreamingRequestCreate";
import { usePipelineStreamingRequestCreate } from "libs/data/customized/pipeline-requests/usePipelineStreamingRequestCreate";

import type {
  DeploymentRequestParameters,
  PipelineRequestParameters,
} from "components/organisms/RequestDialog";
import type {
  DeploymentDetail,
  DeploymentRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
  PipelineDetail,
  PipelineRequestCreateResponse,
} from "libs/data/models";
import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";
import type { Dispatch, SetStateAction } from "react";

type RequestParams = {
  type: "deployment" | "pipeline";
  instance?: DeploymentDetail | PipelineDetail;
  version?: string;
  isRetentionModeNone?: boolean;
};

type RequestDialogProps = {
  isRequestDialogOpen: boolean;
  setIsRequestDialogOpen: Dispatch<SetStateAction<boolean>>;
  status: "CREATE" | "RESULTS" | "LOADING";
  openCreateDialog: () => void;
  requestParameters: DeploymentRequestParameters | PipelineRequestParameters;
  requestTimeout: number;
  setRequestTimeout: Dispatch<SetStateAction<number>>;
  startStream: (data: DeploymentRequestsCreateDataBody) => void;
  requestResults:
    | {
        responseData:
          | DeploymentRequestCreateResponse
          | PipelineRequestCreateResponse
          | undefined;
        requestData: DeploymentRequestsCreateDataBody;
      }
    | undefined;
};

export function useRequest({
  type,
  instance,
  version,
  isRetentionModeNone,
}: RequestParams): RequestDialogProps {
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const [status, setStatus] =
    useState<"CREATE" | "RESULTS" | "LOADING">("CREATE");
  const [requestTimeout, setRequestTimeout] = useState(
    type === "deployment"
      ? DEFAULT_TIME_OUT_REQUESTS
      : DEFAULT_TIME_OUT_PIPELINE_REQUESTS
  );

  const { projectName } = useParams<DeploymentDetailsRouteParams>();

  const requestParameters = {
    type,
    resourceName: instance?.name,
    resourceVersion: version ?? instance?.default_version,
    inputType: instance?.input_type,
    inputFields: instance?.input_fields,
    outputType: instance?.output_type,
    outputFields: instance?.output_fields,
  };

  const {
    startStream: startDeploymentStream,
    streamedResponse: deploymentResponse,
  } = useDeploymentStreamingRequestCreate(
    projectName,
    type === "deployment" && instance?.name ? instance?.name : "",
    requestParameters as DeploymentRequestParameters,
    requestTimeout,
    version ?? instance?.default_version,
    isRetentionModeNone
  );

  const {
    startStream: startPipelineStream,
    streamedResponse: pipelineResponse,
  } = usePipelineStreamingRequestCreate(
    projectName,
    type === "pipeline" && instance?.name ? instance?.name : "",
    requestParameters as PipelineRequestParameters,
    requestTimeout,
    version ?? instance?.default_version,
    isRetentionModeNone
  );

  const openCreateDialog = useCallback(() => {
    setIsRequestDialogOpen(true);
    setStatus("CREATE");
  }, []);

  const startStream = useCallback(
    (data: DeploymentRequestsCreateDataBody) => {
      setStatus("LOADING");
      if (type === "deployment") {
        startDeploymentStream(data);
      } else {
        startPipelineStream(data);
      }
      setStatus("RESULTS");
    },
    [startDeploymentStream, startPipelineStream, type]
  );

  return {
    isRequestDialogOpen,
    setIsRequestDialogOpen,
    status,
    openCreateDialog,
    requestParameters,
    requestTimeout,
    setRequestTimeout,
    startStream,
    requestResults:
      type === "deployment" ? deploymentResponse : pipelineResponse,
  };
}
