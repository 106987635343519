import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import { ChevronDown } from "react-feather";

import { getRandomId } from "libs/utilities/utils";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { SyntheticEvent } from "react";
import type {
  AutocompleteSelectOption,
  BaseAutoCompleteSelectProps,
} from "./types";

export interface AutoCompleteMultipleSelectProps
  extends BaseAutoCompleteSelectProps {
  onChange: (selectedValue: AutocompleteSelectOption[] | null) => void;
  value?: AutocompleteSelectOption[];
}

export const AutoCompleteMultipleSelect = ({
  options,
  onChange,
  label,
  value,
  renderOption,
  error,
  showErrors = true,
  ...props
}: AutoCompleteMultipleSelectProps) => {
  const theme = useTheme() as AppThemeProps;

  const handleOnChange = (
    _e: SyntheticEvent<Element>,
    selectedValue: AutocompleteSelectOption[] | null
  ) => {
    onChange && onChange(selectedValue);
  };

  return (
    <>
      <Autocomplete
        id={`auto-complete-${getRandomId()}`}
        options={options}
        multiple
        renderInput={(params) => (
          <TextField
            error={showErrors && error !== undefined}
            {...params}
            label={label}
          />
        )}
        size="small"
        getOptionDisabled={(option) =>
          !!value?.find((val) => val.value === option.value) ?? false
        }
        popupIcon={<ChevronDown color={theme.palette.border.primary} />}
        onChange={handleOnChange}
        value={value}
        renderOption={renderOption}
        disableClearable
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingLeft: `${options[0]?.icon ? "18px" : "8px"}`,
          },
          "& .MuiAutocomplete-endAdornment": {
            borderLeft: `1px solid ${theme.palette.border.primary} `,
            height: "20px",
            top: "9px",
            display: "flex",
            width: "24px",
            "& svg": {
              height: "1.2rem",
            },
            "& .MuiAutocomplete-popupIndicator": {
              ":hover": {
                backgroundColor: theme.palette.neutrals[0],
              },
            },
          },
          "& .MuiAutocomplete-tag ": {
            backgroundColor: theme.palette.select.multiValue,
            color: theme.palette.text.primary,
          },
          marginBottom: "10px",
          width: "100%",
          background: theme.palette.background.default,
        }}
        {...props}
      />
      {error && showErrors && (
        // negative mt to compensate for margin bottoms for dropdowns from FormSection
        <Typography variant="caption" color="error" mt={-1.5} mb={1.5}>
          {error}
        </Typography>
      )}
    </>
  );
};
