import { getId } from "./getId";
import { getLayoutedElements } from "./getLayoutedElements";
import { NodeTypes } from "./types";

import type {
  AttachmentsList,
  PipelineVersionObjectList,
} from "libs/data/models";
import type { Edge, Node } from "reactflow";
import type { EdgeDataType, NodeDataType } from "./types";

const getObjectId = (nodes: Node[], objectName: string) =>
  nodes.find((node) => node.data.pipelineObject.name === objectName)?.id;

export const getEdgeInfoFromAttachment = (
  attachment: Omit<AttachmentsList, "id">,
  destinationObject: PipelineVersionObjectList,
  nodes: Node[],
  isReadonly = false,
  organizationName: string,
  projectName: string,
  pipelineName: string,
  versionName: string
) => {
  const sources = attachment.sources || [];
  const sourceId = getObjectId(nodes, sources[0].source_name);
  const targetId = getObjectId(nodes, attachment.destination_name);

  if (sources?.length === 1 && sourceId && targetId) {
    const edge: Edge<EdgeDataType> = {
      id: getId(),
      source: sourceId,
      target: targetId,
      type: "custom",
      data: {
        sources: sources,
        organizationName,
        projectName,
        pipelineName,
        versionName,
      },
    };

    return {
      diamondNodes: [],
      edges: [edge],
    };
  }

  const destinationNode = nodes.find(
    (node) => node.data.pipelineObject.name === attachment.destination_name
  );
  const destinationPosition = destinationNode?.position;
  const destinationHeight = destinationNode?.height || 50;
  const position = destinationPosition
    ? {
        x: destinationPosition.x - 100,
        y: destinationPosition.y + (destinationHeight + 50) / 2,
      }
    : { x: 250, y: 0 };

  const diamondNodeId = getId();
  const diamondNode: Node<NodeDataType> = {
    id: diamondNodeId,
    type: NodeTypes.diamond,
    width: 20,
    height: 20,
    position,
    data: {
      isReadonly,
      type: NodeTypes.diamond,
      organizationName,
      projectName,
      pipelineName: pipelineName,
      versionName: versionName,
      pipelineObject: {
        id: `diamond-${destinationObject?.name}`,
        name: `diamond-${destinationObject?.name}`,
        version: null,
        reference_name: `diamond-${destinationObject?.name}`,
        input_fields: destinationObject?.input_fields,
        input_type: destinationObject?.input_type,
        output_fields: destinationObject?.input_fields,
        output_type: destinationObject?.input_type,
      } as PipelineVersionObjectList,
    },
  };

  const normalEdges: Edge<EdgeDataType>[] = sources.map((source) => ({
    id: getId(),
    source: getObjectId(nodes, source.source_name) || "",
    target: diamondNodeId,
    type: "custom",
    data: {
      sources: [source],
      organizationName,
      projectName,
      pipelineName,
      versionName,
    },
  }));

  const diamondEdge: Edge<EdgeDataType> = {
    id: getId(),
    source: diamondNodeId,
    target: getObjectId(nodes, destinationObject?.name || "") || "",
    type: "custom",
    data: {
      sources: sources,
      organizationName,
      projectName,
      pipelineName,
      versionName,
    },
  };

  const { edges, nodes: diamondNodes } = getLayoutedElements(
    [diamondNode],
    [...normalEdges, diamondEdge]
  );

  return {
    diamondNodes,
    edges,
  };
};
