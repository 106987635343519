import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  removeSnackbar,
  useGetNotifications,
} from "store/features/notifications";

import type { SnackbarKey } from "notistack";

export const useNotifier = () => {
  const [displayed, setDisplayed] = useState<Set<string>>(new Set());
  const { pathname } = useLocation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const isSignInPage = pathname.includes("/sign-in");

  const notifications = useGetNotifications();
  const dispatch = useDispatch();

  useEffect(() => {
    notifications.forEach(({ key, message, options, dismissed = false }) => {
      // Close snackbar if it's been dismissed
      if (dismissed) {
        closeSnackbar(key);

        return;
      }

      // Do nothing if snackbar is already displayed
      if (displayed.has(key)) {
        return;
      }

      // Skip notification if it's an error and we're on the sign in page (we're showing it in the form)
      // or if the error is about terms and conditions (we're handling them with redirection)
      const isSignInError = isSignInPage && options?.variant === "error";
      const isTermsError = message?.includes("Could not authenticate");
      const isRequestAborted = message?.includes("Request aborted");
      const skipNotification =
        isSignInError || isTermsError || isRequestAborted;

      if (skipNotification) {
        dispatch(removeSnackbar(key));

        return;
      }

      // Display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        action: (key: SnackbarKey) => (
          <IconButton
            onClick={() => closeSnackbar(key)}
            size="small"
            color="inherit"
          >
            <Close fontSize="small" />
          </IconButton>
        ),
        onClose: (event: any, reason: any, key: SnackbarKey | undefined) => {
          if (key) {
            if (options.onClose) {
              options.onClose(event, reason, key.toString());
            }
            dispatch(removeSnackbar(key.toString()));
            removeDisplayed(key.toString());
          }
        },
        onExited: (_event: any, key: SnackbarKey) => {
          dispatch(removeSnackbar(key.toString()));
          removeDisplayed(key.toString());
        },
      });

      // Keep track of snackbars that we've displayed
      storeDisplayed(key);

      function storeDisplayed(key: string) {
        setDisplayed((prevDisplayed) => {
          const newDisplayed = new Set(prevDisplayed);
          newDisplayed.add(key);

          return newDisplayed;
        });
      }

      function removeDisplayed(key: string) {
        setDisplayed((prevDisplayed) => {
          const newDisplayed = new Set(prevDisplayed);
          newDisplayed.delete(key);

          return newDisplayed;
        });
      }
    });
  }, [
    notifications,
    displayed,
    enqueueSnackbar,
    closeSnackbar,
    isSignInPage,
    dispatch,
  ]);
};
