/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { Key, SWRConfiguration } from "swr";
import type { ErrorType } from "../../axios/index";
import type {
  WebhookCreate,
  WebhookDetail,
  WebhooksListParams,
  WebhookTestCreate,
  WebhookTestDetail,
  WebhookUpdate,
} from "../../models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List the webhooks in a project

### Response Structure
A list of details of the webhooks in the project
- `id`: Unique identifier for the webhook (UUID)
- `name`: Name of the webhook
- `url`: Callback url to send event payloads to
- `headers`: Request headers to use when calling the webhook
- `object_type`: Type of object for which the webhook is created
- `object_name`: Name of deployment or pipeline for which the webhook is created
- `version`: Name of deployment/pipeline version for which the webhook is created
- `description`: Description of the webhook
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `event`: Event that triggers the webhook
- `timeout`: Timeout in seconds on the call to webhook
- `enabled`: Boolean value indicating whether the webhook is enabled or disabled
- `retry`: Boolean value indicating whether the calls to webhook should be retried if they fail
- `include_result`: Boolean indicating whether the result of a request should be included in the webhook call

#### Response Examples
```
[
  {
    "id": "e54251d8-e518-424f-bf7d-c45aaf26f72c",
    "name": "webhook-1",
    "url": "https://callback-url-webhook-1.com",
    "headers": [],
    "object_type": "deployment",
    "object_name": "deployment-1",
    "version": "v1",
    "description": "",
    "labels": {
      "event-type": "request-finished"
    },
    "event": "deployment_request_finished",
    "timeout": 10,
    "enabled": true,
    "retry": false,
    "include_result": false
  },
  {
    "id": "cbfb3944-bbcb-4e18-907a-54d2f792a136",
    "name": "webhook-2",
    "url": "https://callback-url-webook-2.com",
    "headers": [],
    "object_type": "deployment",
    "object_name": "deployment-2",
    "version": null,
    "description": "",
    "labels": {
      "event-type": "request-failed"
    },
    "event": "deployment_request_failed",
    "timeout": 15,
    "enabled": true,
    "retry": false,
    "include_result": false
  },
]
```

 * @summary List webhooks
 */
export const webhooksList = (
  projectName: string,
  params?: WebhooksListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<WebhookDetail[]>(
    { url: `/projects/${projectName}/webhooks`, method: "get", params },
    options
  );
};

export const getWebhooksListKey = (
  projectName: string,
  params?: WebhooksListParams
) =>
  [`/projects/${projectName}/webhooks`, ...(params ? [params] : [])] as const;

export type WebhooksListQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksList>>
>;
export type WebhooksListQueryError = ErrorType<unknown>;

/**
 * @summary List webhooks
 */
export const useWebhooksList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: WebhooksListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof webhooksList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getWebhooksListKey(projectName, params) : null));
  const swrFn = () => webhooksList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a webhook

### Required Parameters
- `name`: Name of the webhook. It is unique within a project.
- `url`: Callback url to send event payloads to
- `object_type`: Type of object for which the webhook will be created. It can be either 'deployment' or 'pipeline'.
- `object_name`: Name of deployment or pipeline for which the webhook will be created
- `event`: Event that triggers the webhook. Depending on the selected *object_type*, it can be one of the following:
    - `deployment_request_started` or `pipeline_request_started`: triggers when a request starts
    - `deployment_request_completed` or `pipeline_request_completed`: triggers when a request completes successfully 
    - `deployment_request_failed` or `pipeline_request_failed`: triggers when a request fails
    - `deployment_request_finished` or `pipeline_request_finished`: triggers when a request is finished (cancelled, successful or failed)

### Optional Parameters
- `headers`: Request headers to use when calling the webhook. It defaults to an empty list.
- `description`: Description of the webhook
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `version`: Name of deployment/pipeline version for which the webhook will be created. If not provided, the default version of the deployment/pipeline will be used.
- `timeout`: Timeout in seconds on the call to webhook. It defaults to 10 seconds and the maximum value is 30 seconds.
- `enabled`: Boolean value indicating whether the webhook is enabled or disabled. It defaults to True.
- `retry`: Boolean value indicating whether the calls to the webhook should be retried if they fail (network timeout or non 2xx or 3xx HTTP response). It defaults to False.
- `include_result`: Boolean indicating whether the result of a request should be included in the webhook call. It defaults to False.

#### Request Examples
```
{
  "name": "webhook-1",
  "url": "https://callback-url-webhook-1.com",
  "headers": [
    {
      "key": "Authorization",
      "value": "Token 123",
      "protected": true
    }
  ],
  "object_type": "deployment",
  "object_name": "deployment-1",
  "version": "v1",
  "event": "deployment_request_finished"
}
```

### Response Structure
Details of the created webhook
- `id`: Unique identifier for the webhook (UUID)
- `name`: Name of the webhook
- `url`: Callback url to send event payloads to
- `headers`: Request headers to use when calling the webhook
- `object_type`: Type of object for which the webhook is created
- `object_name`: Name of deployment or pipeline for which the webhook is created
- `version`: Name of deployment/pipeline version for which the webhook is created
- `description`: Description of the webhook
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `event`: Event that triggers the webhook
- `timeout`: Timeout in seconds on the call to webhook
- `enabled`: Boolean value indicating whether the webhook is enabled or disabled
- `retry`: Boolean value indicating whether the calls to webhook should be retried if they fail

#### Response Examples
```
{
  "id": "e54251d8-e518-424f-bf7d-c45aaf26f72c",
  "name": "webhook-1",
  "url": "https://callback-url-webhook-1.com",
  "headers": [
    {
      "key": "Authorization",
      "value": "Token 123",
      "protected": true
  ],
  "object_type": "deployment",
  "object_name": "deployment-1",
  "version": "v1",
  "description": "",
  "labels": {
    "event-type": "request-finished"
  },
  "event": "deployment_request_finished",
  "timeout": 10,
  "enabled": true,
  "retry": false,
  "include_result": false
}
```

 * @summary Create webhooks
 */
export const webhooksCreate = (
  projectName: string,
  webhookCreate: WebhookCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<WebhookDetail>(
    {
      url: `/projects/${projectName}/webhooks`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: webhookCreate,
    },
    options
  );
};

/**
 * 
### Description
Test a webhook

### Required Parameters
- `url`: Callback url to send event payloads to
- `object_type`: Type of object for which the webhook will be tested. It can be either 'deployment' or 'pipeline'.
- `object_name`: Name of deployment or pipeline for which the webhook will be tested
- `event`: Event that triggers the webhook. Depending on the selected *object_type*, it can be one of the following:
    - `deployment_request_started` or `pipeline_request_started`: triggers when a request starts
    - `deployment_request_completed` or `pipeline_request_completed`: triggers when a request completes successfully 
    - `deployment_request_failed` or `pipeline_request_failed`: triggers when a request fails
    - `deployment_request_finished` or `pipeline_request_finished`: triggers when a request is finished (cancelled, successful or failed)

### Optional Parameters
- `headers`: Request headers to use when calling the webhook
- `version`: Name of deployment/pipeline version for which the webhook will be tested. If not provided, the default version of the deployment/pipeline will be used.
- `timeout`: Timeout in seconds on the call to webhook. It defaults to 10 seconds and the maximum value is 30 seconds.
- `retry`: Boolean value indicating whether the calls to the webhook should be retried if they fail (network timeout or non 2xx or 3xx HTTP response). It defaults to False.
- `include_result`: Boolean indicating whether the result of a request should be included in the webhook call. It defaults to False.

#### Request Examples
```
{
  "url": "https://callback-url-webhook-1.com",
  "object_type": "deployment",
  "object_name": "deployment-1",
  "event": "deployment_request_finished"
}
```

### Response Structure
Details of the test
- `id`: Unique identifier for the test (UUID)
- `status`: Status of the test. It can be one of the following: 'pending', 'completed' or 'failed'.
- `error_message`: An error message explaining why the test has failed

#### Response Examples
```
{
  "id": "ed64752b-5f05-4c26-9c4f-f1aba5ce38e1",
  "status": "pending",
  "error_message": null
}
```

 * @summary Create webhook tests
 */
export const webhookTestsCreate = (
  projectName: string,
  webhookTestCreate: WebhookTestCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<WebhookTestDetail>(
    {
      url: `/projects/${projectName}/webhooks-tests`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: webhookTestCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a webhook test in a project

### Response Structure
Details of a test
- `id`: Unique identifier for the test (UUID)
- `status`: Status of the test. It can be one of the following: 'pending', 'completed' or 'failed'.
- `error_message`: An error message explaining why the test has failed

#### Response Examples

Pending test
```
{
  "id": "ed64752b-5f05-4c26-9c4f-f1aba5ce38e1",
  "status": "pending",
  "error_message": null
}
```

Completed test
```
{
  "id": "ed64752b-5f05-4c26-9c4f-f1aba5ce38e1",
  "status": "completed",
  "error_message": null
}
```

Failed test
```
{
  "id": "ed64752b-5f05-4c26-9c4f-f1aba5ce38e1",
  "status": "failed",
  "error_message": "Connection error"
}
```

 * @summary Get webhook test
 */
export const webhookTestsGet = (
  projectName: string,
  testId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<WebhookTestDetail>(
    { url: `/projects/${projectName}/webhooks-tests/${testId}`, method: "get" },
    options
  );
};

export const getWebhookTestsGetKey = (projectName: string, testId: string) =>
  [`/projects/${projectName}/webhooks-tests/${testId}`] as const;

export type WebhookTestsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhookTestsGet>>
>;
export type WebhookTestsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get webhook test
 */
export const useWebhookTestsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  testId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof webhookTestsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(projectName && testId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getWebhookTestsGetKey(projectName, testId) : null));
  const swrFn = () => webhookTestsGet(projectName, testId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Retrieve details of a webhook in a project

### Response Structure
Details of a webhook
- `id`: Unique identifier for the webhook (UUID)
- `name`: Name of the webhook
- `url`: Callback url to send event payloads to
- `headers`: Request headers to use when calling the webhook
- `object_type`: Type of object for which the webhook is created
- `object_name`: Name of deployment or pipeline for which the webhook is created
- `version`: Name of deployment/pipeline version for which the webhook is created
- `description`: Description of the webhook
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `event`: Event that triggers the webhook
- `timeout`: Timeout in seconds on the call to webhook
- `enabled`: Boolean value indicating whether the webhook is enabled or disabled
- `retry`: Boolean value indicating whether the calls to webhook should be retried if they fail
- `include_result`: Boolean indicating whether the result of a request should be included in the webhook call

#### Response Examples
```
{
  "id": "e54251d8-e518-424f-bf7d-c45aaf26f72c",
  "name": "webhook-1",
  "url": "https://callback-url-webhook-1.com",
  "headers": [],
  "object_type": "deployment",
  "object_name": "deployment-1",
  "version": "v1",
  "description": "",
  "labels": {
    "event-type": "request-finished"
  },
  "event": "deployment_request_finished",
  "timeout": 10,
  "enabled": true,
  "retry": false,
  "include_result": false
}
```

 * @summary Get webhook
 */
export const webhooksGet = (
  projectName: string,
  webhookName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<WebhookDetail>(
    { url: `/projects/${projectName}/webhooks/${webhookName}`, method: "get" },
    options
  );
};

export const getWebhooksGetKey = (projectName: string, webhookName: string) =>
  [`/projects/${projectName}/webhooks/${webhookName}`] as const;

export type WebhooksGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof webhooksGet>>
>;
export type WebhooksGetQueryError = ErrorType<unknown>;

/**
 * @summary Get webhook
 */
export const useWebhooksGet = <TError = ErrorType<unknown>>(
  projectName: string,
  webhookName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof webhooksGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && webhookName);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getWebhooksGetKey(projectName, webhookName) : null));
  const swrFn = () => webhooksGet(projectName, webhookName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a webhook

### Optional Parameters
- `name`: Name for the webhook
- `url`: Callback url to send event payloads to
- `headers`: Request headers to use when calling the webhook. Use *value* None to not update the values of protected headers.
- `description`: Description of the webhook
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `event`: Event that triggers the webhook. Depending on the selected *object_type*, it can be one of the following:
    - `deployment_request_started` or `pipeline_request_started`: triggers when a request starts
    - `deployment_request_completed` or `pipeline_request_completed`: triggers when a request completes successfully 
    - `deployment_request_failed` or `pipeline_request_failed`: triggers when a request fails
    - `deployment_request_finished` or `pipeline_request_finished`: triggers when a request is finished (cancelled, successful or failed)
- `timeout`: Timeout in seconds on the call to webhook
- `enabled`: Boolean value indicating whether the webhook is enabled or disabled
- `retry`: Boolean value indicating whether the calls to webhook should be retried if they fail
- `include_result`: Boolean indicating whether the result of a request should be included in the webhook call

#### Request Examples
```
{
  "name": "new-webhook-name"
}
```

### Response Structure
Details of a webhook
- `id`: Unique identifier for the webhook (UUID)
- `name`: Name of the webhook
- `url`: Callback url to send event payloads to
- `headers`: Request headers to use when calling the webhook
- `object_type`: Type of object for which the webhook is created
- `object_name`: Name of deployment or pipeline for which the webhook is created
- `version`: Name of deployment/pipeline version for which the webhook is created
- `description`: Description of the webhook
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `event`: Event that triggers the webhook
- `timeout`: Timeout in seconds on the call to webhook
- `enabled`: Boolean value indicating whether the webhook is enabled or disabled
- `retry`: Boolean value indicating whether the calls to webhook should be retried if they fail
- `include_result`: Boolean indicating whether the result of a request should be included in the webhook call

#### Response Examples
```
{
  "id": "e54251d8-e518-424f-bf7d-c45aaf26f72c",
  "name": "new-webhook-name",
  "url": "https://callback-url-webhook-1.com",
  "headers": [],
  "object_type": "deployment",
  "object_name": "deployment-1",
  "version": "v1",
  "description": "",
  "labels": {
    "event-type": "request-finished"
  },
  "event": "deployment_request_finished",
  "timeout": 10,
  "enabled": true,
  "retry": false,
  "include_result": false
}
```

 * @summary Update a webhook
 */
export const webhooksUpdate = (
  projectName: string,
  webhookName: string,
  webhookUpdate: WebhookUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<WebhookDetail>(
    {
      url: `/projects/${projectName}/webhooks/${webhookName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: webhookUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a webhook

 * @summary Delete a webhook
 */
export const webhooksDelete = (
  projectName: string,
  webhookName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/webhooks/${webhookName}`,
      method: "delete",
    },
    options
  );
};
