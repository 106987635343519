import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { ReactComponent as GoogleLogo } from "assets/images/logo-google.svg";
import { ReactComponent as MicrosoftLogo } from "assets/images/logo-microsoft.svg";
import { formatLabel } from "libs/utilities/utils";

import { useSignUp } from "../../libs/data/customized/authorize/useSignUp";

import type { OauthSignUpProvider } from "libs/data/models";

const providers = [
  {
    name: "google",
    logo: GoogleLogo,
  },
  {
    name: "microsoft",
    logo: MicrosoftLogo,
  },
];

const SignUpOAuth = () => {
  const theme = useTheme();
  const { signUp, isLoading } = useSignUp();

  return (
    <Grid container direction="column">
      {providers.map(({ name, logo: Logo }) => (
        <Box my={1} key={name}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            disabled={isLoading}
            startIcon={<Logo />}
            onClick={() => signUp(name as OauthSignUpProvider)}
            sx={{ color: theme.palette.text.primary }}
          >
            {isLoading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              <Typography>
                <span>Continue with </span>
                <span>{formatLabel(name)}</span>
              </Typography>
            )}
          </Button>
        </Box>
      ))}
    </Grid>
  );
};

export default SignUpOAuth;
