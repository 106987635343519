/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { Key, SWRConfiguration } from "swr";
import type { ErrorType } from "../../axios/index";
import type {
  MetricCreate,
  MetricDetail,
  MetricsListParams,
  MetricUpdate,
  TimeSeriesDataCreateBody,
  TimeSeriesDataCreateResponse,
  TimeSeriesDataList,
  TimeSeriesDataListParams,
  TimeSeriesSearch,
  TimeSeriesSearchParams,
} from "../../models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List available metrics in the project

### Optional Parameters
- `custom`: A boolean indicating whether to list default or custom metrics for the project

### Response Structure
A list of details of metrics
- `id`: Unique identifier for the metric
- `name`: Name of the metric
- `description`: Description of the metric
- `creation_date`: The date when the metric was created
- `last_updated`: The date when the metric was last updated
- `custom`: A boolean indicating whether the metric is custom
- `metric_type`: Type of the metric
- `unit`: Unit of the metric
- `labels`: A list of labels that can be used to get data points containing the metric

#### Response Examples
```
[
  {
    "id": 1,
    "name": "deployments.requests",
    "description": "Requests to a deployment version",
    "creation_date": "2023-09-01T08:32:14.876451Z",
    "last_updated": "2023-09-01T10:52:23.124784Z",
    "custom": false,
    "metric_type": "delta",
    "unit": "requests",
    "labels": ["deployment_version_id", "user_id"]
  },
  {
    "id": 2,
    "name": "deployments.credits",
    "description": "Credits usage",
    "creation_date": "2023-09-02T10:12:51.195381Z",
    "last_updated": "2023-09-02T10:12:51.195381Z",
    "custom": false,
    "metric_type": "delta",
    "unit": "credits",
    "labels": ["deployment_version_id"]
  },
]
```

 * @summary List metrics
 */
export const metricsList = (
  projectName: string,
  params?: MetricsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<MetricDetail[]>(
    { url: `/projects/${projectName}/metrics`, method: "get", params },
    options
  );
};

export const getMetricsListKey = (
  projectName: string,
  params?: MetricsListParams
) => [`/projects/${projectName}/metrics`, ...(params ? [params] : [])] as const;

export type MetricsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof metricsList>>
>;
export type MetricsListQueryError = ErrorType<unknown>;

/**
 * @summary List metrics
 */
export const useMetricsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: MetricsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof metricsList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getMetricsListKey(projectName, params) : null));
  const swrFn = () => metricsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a custom metric. The name must start with *custom.*.

### Required Parameters
- `name`: Name of the metric
- `metric_type`: Type of the metric. It can be either 'delta' or 'gauge'.

### Optional Parameters
- `description`: Description of the metric
- `unit`: Unit of the metric
- `labels`: A list of labels that can be used to get data points containing the metric. For example, if the metric is defined for a deployment version and will be queried later with the ID of the deployment version, the labels list should contain 'deployment_version_id'.

#### Request Examples
```
{
  "name": "custom.metric-1",
  "metric_type": "delta",
  "unit": "seconds",
  "labels": ["deployment_version_id"]
}
```

### Response Structure
Details of the created metric
- `id`: Unique identifier for the metric
- `name`: Name of the metric
- `description`: Description of the metric
- `creation_date`: The date when the metric was created
- `last_updated`: The date when the metric was last updated
- `custom`: A boolean indicating whether the metric is custom
- `metric_type`: Type of the metric
- `unit`: Unit of the metric
- `labels`: A list of labels that can be used to get data points containing the metric

#### Response Examples
```
{
  "id": 10,
  "name": "custom.metric-1",
  "description": "My custom metric",
  "creation_date": "2023-09-01T08:32:14.876451Z",
  "last_updated": "2023-09-01T10:52:23.124784Z",
  "custom": true,
  "metric_type": "delta",
  "unit": "seconds",
  "labels": ["deployment_version_id"]
}
```

 * @summary Create metrics
 */
export const metricsCreate = (
  projectName: string,
  metricCreate: MetricCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<MetricDetail>(
    {
      url: `/projects/${projectName}/metrics`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: metricCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a metric

### Response Structure
Details of a metric
- `id`: Unique identifier for the metric
- `name`: Name of the metric
- `description`: Description of the metric
- `creation_date`: The date when the metric was created
- `last_updated`: The date when the metric was last updated
- `custom`: A boolean indicating whether the metric is custom
- `metric_type`: Type of the metric
- `unit`: Unit of the metric
- `labels`: A list of labels that can be used to get data points containing the metric

#### Response Examples
```
{
  "id": 10,
  "name": "custom.metric-1",
  "description": "My custom metric",
  "creation_date": "2023-09-01T08:32:14.876451Z",
  "last_updated": "2023-09-01T10:52:23.124784Z",
  "custom": true,
  "metric_type": "delta",
  "unit": "seconds",
  "labels": ["deployment_version_id"]
}
```

 * @summary Get metric
 */
export const metricsGet = (
  projectName: string,
  metricName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<MetricDetail>(
    { url: `/projects/${projectName}/metrics/${metricName}`, method: "get" },
    options
  );
};

export const getMetricsGetKey = (projectName: string, metricName: string) =>
  [`/projects/${projectName}/metrics/${metricName}`] as const;

export type MetricsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof metricsGet>>
>;
export type MetricsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get metric
 */
export const useMetricsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  metricName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof metricsGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && metricName);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getMetricsGetKey(projectName, metricName) : null));
  const swrFn = () => metricsGet(projectName, metricName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a metric. Only custom metrics can be updated.

### Optional Parameters
- `name`: Name of the metric
- `description`: Description of the metric
- `unit`: Unit of the metric
- `labels`: A list of labels that can be used to get data points containing the metric. For example, if the metric is defined for a deployment version and will be queried later with the ID of the deployment version, the labels list should contain 'deployment_version_id'.

#### Request Examples
```
{
  "name": "custom.metric-2"
}
```

### Response Structure
Details of the updated metric
- `id`: Unique identifier for the metric
- `name`: Name of the metric
- `description`: Description of the metric
- `creation_date`: The date when the metric was created
- `last_updated`: The date when the metric was last updated
- `custom`: A boolean indicating whether the metric is custom
- `metric_type`: Type of the metric
- `unit`: Unit of the metric
- `labels`: A list of labels that can be used to get data points containing the metric

#### Response Examples
```
{
  "id": 10,
  "name": "custom.metric-2",
  "description": "My custom metric",
  "creation_date": "2023-09-01T08:32:14.876451Z",
  "last_updated": "2023-09-01T10:52:23.124784Z",
  "custom": true,
  "metric_type": "delta",
  "unit": "seconds",
  "labels": ["deployment_version_id"]
}
```

 * @summary Update metric
 */
export const metricsUpdate = (
  projectName: string,
  metricName: string,
  metricUpdate: MetricUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<MetricDetail>(
    {
      url: `/projects/${projectName}/metrics/${metricName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: metricUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a metric. Only custom metrics can be deleted.

 * @summary Delete metric
 */
export const metricsDelete = (
  projectName: string,
  metricName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}/metrics/${metricName}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
Aggregate metric data for given date, metrics and labels. Only data up to 2 minutes ago is accepted.

### Required Parameters
- `metric`: Name of the metric
- `labels`: Dictionary containing key/value pairs where key indicates the string that can be used to query this metric later and value is the corresponding value of that
- `data`: A list of dictionaries containing 'date' and 'value' fields to indicate the value of the metric for a specific date

#### Request Examples
```
[
  {
    "metric": "deployments.requests",
    "labels": {
      "deployment_version_id": "056efa9e-67eb-45e3-a49a-0742b3f08aee"
    },
    "data": [
      {
        "date": "2023-09-15T20:12:33.210+00:00",
        "value": 182
      },
      {
        "date": "2023-09-15T21:41:12.532+00:00",
        "value": 1
      }
    ]
  }
]
```

 * @summary Aggregate metric data
 */
export const timeSeriesDataAggregate = (
  projectName: string,
  timeSeriesDataCreateBody: TimeSeriesDataCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TimeSeriesDataCreateResponse>(
    {
      url: `/projects/${projectName}/time-series/aggregate`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: timeSeriesDataCreateBody,
    },
    options
  );
};

/**
 * 
### Description
List data points for a metric

Available metrics for deployments:

- `deployments.requests`: Number of requests to a deployment version
- `deployments.failed_requests`: Number of failed requests to a deployment version
- `deployments.request_duration`: Average time in seconds for a deployment request to complete
- `deployments.input_volume`: Volume of incoming data in bytes
- `deployments.output_volume`: Volume of outgoing data in bytes
- `deployments.network_in`: Volume of inbound data traffic in bytes
- `deployments.network_out`: Volume of outbound data traffic in bytes
- `deployments.express_queue_size`: Average number of queued express requests
- `deployments.batch_queue_size`: Average number of queued batch requests
- `deployments.express_queue_time`: Average time in seconds for an express request to start processing
- `deployments.batch_queue_time`: Average time in seconds for a batch request to start processing
- `deployments.memory_utilization`: Average memory used during a request
- `deployments.instances`: Number of active deployment instances
- `deployments.credits`: Usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours the deployments are running

Available metrics for pipelines:

- `pipelines.requests`: Number of requests to a pipeline version
- `pipelines.failed_requests`: Number of failed requests to a pipeline version
- `pipelines.request_duration`: Average time in seconds for a pipeline request to complete
- `pipelines.input_volume`: Volume of incoming data in bytes
- `pipelines.output_volume`: Volume of outgoing data in bytes
- `pipelines.object_requests`: Number of object requests in a pipeline version
- `pipelines.object_failed_requests`: Number of failed object requests in a pipeline version

### Required Parameters
- `metric`: Name of the metric
- `start_date`: Start date for metric data points
- `end_date`: End date for metric data points

### Optional Parameters
- `aggregation_period`: Time period in seconds in which data points are grouped. It defaults to the highest resolution possible given the provided date range. Available values are: 60, 300, 900, 3600, 7200, 21600 and 86400.
Start and end dates are adjusted according to the aggregation period. For example, if aggregation period is 3600, start date is rounded down to the previous full hour and end date is rounded up to the next full hour.
- `labels`: Comma-separated values for labels to filter on data points. It must be in the format: key-1:value-1,key-2:value-2.

#### Request Examples
With aggregation period 60, to get the credits usage of a deployment version per minute
```
{
  "metric": "deployments.credits",
  "start_date": "2024-05-01T10:00:00Z",
  "end_date": "2024-05-01T10:30:12Z",
  "labels": "deployment_version_id:dbcc9de3-1dcb-48ad-8197-3b2ac99f5e94"
}
```

With aggregation period 3600, to get the credits usage of a deployment version per hour
```
{
  "metric": "deployments.credits",
  "start_date": "2024-05-01T10:00:00Z",
  "end_date": "2024-05-01T16:30:12Z",
  "labels": "deployment_version_id:dbcc9de3-1dcb-48ad-8197-3b2ac99f5e94"
}
```

### Response Structure
- `metric`: Name of the metric
- `metric_type`: Type of the metric
- `unit`: Unit of the metric
- `start_date`: Start date for metric data points
- `end_date`: End date for metric data points
- `aggregation_period`: Time period in seconds in which data points are grouped
- `labels`: Labels to filter on data points
- `data`: A list of dictionaries containing the data points

#### Response Examples
```
{
  "metric": "deployments.requests",
  "metric_type": "delta",
  "unit": "requests/s",
  "start_date": "2023-01-01T10:00:00Z",
  "end_date": 2023-01-01T12:00:00Z",
  "aggregation_period": 3600,
  "labels": {
    "deployment_version_id": "8935a589-8686-4ce7-8c9e-8b5e529c6b47"
  },
  "data": [
    {
      "start_date": "2023-01-01T10:00:00Z",
      "end_date": 2023-01-01T11:00:00Z",
      "value": 3
    },
    {
      "start_date": "2023-01-01T11:00:00Z",
      "end_date": 2023-01-01T12:00:00Z",
      "value": 10
    }
  ]
}
```

 * @summary List time series data
 */
export const timeSeriesDataList = (
  projectName: string,
  params?: TimeSeriesDataListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TimeSeriesDataList>(
    { url: `/projects/${projectName}/time-series/data`, method: "get", params },
    options
  );
};

export const getTimeSeriesDataListKey = (
  projectName: string,
  params?: TimeSeriesDataListParams
) =>
  [
    `/projects/${projectName}/time-series/data`,
    ...(params ? [params] : []),
  ] as const;

export type TimeSeriesDataListQueryResult = NonNullable<
  Awaited<ReturnType<typeof timeSeriesDataList>>
>;
export type TimeSeriesDataListQueryError = ErrorType<unknown>;

/**
 * @summary List time series data
 */
export const useTimeSeriesDataList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: TimeSeriesDataListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof timeSeriesDataList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTimeSeriesDataListKey(projectName, params) : null));
  const swrFn = () => timeSeriesDataList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Insert data points for a metric. Multiple metrics for different types is supported.

### Required Parameters
- `metric`: Name of the metric
- `labels`: Dictionary containing key/value pairs where key indicates the string that can be used to query this metric later and value is the corresponding value of that
- `data`: A list of dictionaries containing 'date' and 'value' fields to indicate the value of the metric for a specific date. The value is inserted for the minute provided in the date field.

#### Request Examples
```
[
  {
    "metric": "deployments.requests",
    "labels": {
      "deployment_version_id": "056efa9e-67eb-45e3-a49a-0742b3f08aee"
    },
    "data": [
      {
        "date": "2023-09-15T20:00:00.000+00:00",
        "value": 182
      },
      {
        "date": "2023-09-15T21:00:00.000+00:00",
        "value": 1
      }
    ]
  }
]
```

 * @summary Create metric data
 */
export const timeSeriesDataCreate = (
  projectName: string,
  timeSeriesDataCreateBody: TimeSeriesDataCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TimeSeriesDataCreateResponse>(
    {
      url: `/projects/${projectName}/time-series/data`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: timeSeriesDataCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Search through time series

### Optional Parameters
- `metric`: Name of the metric
- `labels`: Comma-separated values for labels to filter on data points. It must be in the format: key-1:value-1,key-2:value-2.
- `custom`: A boolean indicating whether only default or custom metrics should be returned. If this parameter is not provided, both types are returned.
- `exact_match`: A boolean indicating whether the provided labels should match exactly or whether matching a subset is allowed. Defaults to false (matching a subset is allowed).
- `limit`: The maximum number of time series to return. It defaults to 500.
- `offset`: The number that indicates the starting point of the time series to return. It defaults to 0.

### Response Structure
A list of time series
- `metric`: Name of the metric
- `labels`: Labels that the time series has
- `resolution`: Metric resolution in seconds

#### Response Examples
```
[
  {
    "metric": "deployments.requests",
    "labels": {
      "deployment_version_id": "8935a589-8686-4ce7-8c9e-8b5e529c6b47",
      "user_id": "5bb50513-2b4e-466a-ab88-e5be70d63f75"
    },
    "resolution": 60
  }
]
```

 * @summary Search time series
 */
export const timeSeriesSearch = (
  projectName: string,
  params?: TimeSeriesSearchParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TimeSeriesSearch[]>(
    {
      url: `/projects/${projectName}/time-series/search`,
      method: "get",
      params,
    },
    options
  );
};

export const getTimeSeriesSearchKey = (
  projectName: string,
  params?: TimeSeriesSearchParams
) =>
  [
    `/projects/${projectName}/time-series/search`,
    ...(params ? [params] : []),
  ] as const;

export type TimeSeriesSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof timeSeriesSearch>>
>;
export type TimeSeriesSearchQueryError = ErrorType<unknown>;

/**
 * @summary Search time series
 */
export const useTimeSeriesSearch = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: TimeSeriesSearchParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof timeSeriesSearch>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTimeSeriesSearchKey(projectName, params) : null));
  const swrFn = () => timeSeriesSearch(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Delete a time series

 * @summary Delete time series
 */
export const timeSeriesDelete = (
  projectName: string,
  timeSeriesId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/time-series/${timeSeriesId}`,
      method: "delete",
    },
    options
  );
};
