import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Icon } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

import { useOrganizationsList } from "libs/data/endpoints/organizations/organizations";

import {
  CharAvatar,
  ClickableCard,
  Dialog,
  DialogEmptyActions,
  DialogHeader,
  DialogHeaderTitle,
  FormTextField,
} from "components/atoms";

import type { OrganizationList } from "libs/data/models";
import type { RouteComponentProps } from "react-router-dom";

interface OrganizationSelectDialogProps extends RouteComponentProps {
  onClose: () => void;
  onOrganizationSelection: (org: OrganizationList) => void;
  open: boolean;
}

const OrganizationSelectDialogComponent = ({
  onClose,
  onOrganizationSelection,
  open,
}: OrganizationSelectDialogProps) => {
  const formMethods = useForm({ defaultValues: { searchTerm: "" } });
  const { watch, reset } = formMethods;
  const searchTerm = watch("searchTerm", "");
  const { data: organizations } = useOrganizationsList();

  const onOrganizationClick = (organization: OrganizationList) => () => {
    onOrganizationSelection(organization);
    onClose();
  };

  return (
    <FormProvider {...formMethods}>
      {open && (
        <Dialog
          Actions={<DialogEmptyActions />}
          dialogBodyStyles={{ paddingTop: 6, maxHeight: "50vh" }}
          dismissible={false}
          onClose={onClose}
          open={open}
          Header={
            <DialogHeader
              header={
                <>
                  <DialogHeaderTitle>Select an organization</DialogHeaderTitle>
                  <FormTextField
                    name="searchTerm"
                    size="small"
                    style={{ marginBottom: 20 }}
                    placeholder={"Search organization"}
                    InputProps={{
                      startAdornment: <Icon component={SearchIcon} />,
                      endAdornment: (
                        <Icon
                          onClick={() => reset({ searchTerm: "" })}
                          component={ClearIcon}
                          style={{ cursor: "pointer" }}
                        />
                      ),
                    }}
                  />
                </>
              }
            />
          }
          maxWidth="xs"
        >
          {organizations
            ?.filter(({ name }) =>
              name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((organization) => (
              <ClickableCard
                key={organization.id}
                title={organization.name}
                icon={<CharAvatar text={organization.name} />}
                onClick={onOrganizationClick(organization)}
              />
            ))}
        </Dialog>
      )}
    </FormProvider>
  );
};

export const OrganizationSelectDialog = withRouter(
  OrganizationSelectDialogComponent
);
