import styled from "@emotion/styled";
import { Grid, Tooltip, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

import { getBorders } from "assets/styles/theme";

import InfoTooltip from "../InfoTooltip/InfoTooltip";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export type TabType = {
  link: string;
  label: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  hidden?: boolean;
  disabledText?: string;
};

type TabsProps = {
  tabs: TabType[];
  children: React.ReactNode;
};

export const PageTabs = ({ tabs, children }: TabsProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <>
      <Grid
        container
        item
        xs={12}
        display="flex"
        flexWrap={"nowrap"}
        overflow={"auto"}
        borderBottom={getBorders(theme).primary}
        marginTop={"-24px"}
      >
        {tabs.map(
          (
            {
              link,
              label,
              tooltip,
              disabled = false,
              hidden = false,
              disabledText = "You don't have the permission to access this page.",
            },
            key
          ) =>
            !hidden &&
            (disabled ? (
              <Tooltip title={disabledText} placement="bottom-start" key={key}>
                <DisabledTab
                  borderColor={theme.palette.secondary.main}
                  color={theme.palette.text.disabled}
                >
                  {label}
                </DisabledTab>
              </Tooltip>
            ) : (
              <TabLabel
                to={link}
                key={key}
                borderColor={theme.palette.secondary.main}
                activeColor={theme.palette.text.primary}
                color={theme.palette.text.secondary}
              >
                <Grid container alignItems="center">
                  {label}
                  {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
                </Grid>
              </TabLabel>
            ))
        )}
      </Grid>
      <Grid container item xs={12} height={"100%"} paddingTop={2}>
        {children}
      </Grid>
    </>
  );
};

// remove unsupported properties from navlink property list
const TabLabel = styled(NavLink, {
  shouldForwardProp: (prop) =>
    prop !== "borderColor" && prop !== "activeColor" && prop !== "color",
})<{ borderColor: string; activeColor: string; color: string }>`
  text-decoration: none;
  outline: none;
  text-align: center;
  color: ${(props) => props.color};
  padding: 0.5em 1.5em;
  border-bottom: 3px solid transparent;
  font-weight: 600;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-child {
    border-right: none;
  }

  &:hover {
    border-bottom: 3px solid rgba(${(props) => props.borderColor}, 0.3);
  }

  &.active {
    border-bottom: 3px solid ${(props) => props.borderColor};
    color: ${(props) => props.activeColor};
  }
`;

const DisabledTab = styled.div<{ borderColor: string; color: string }>`
  text-decoration: none;
  outline: none;
  text-align: center;
  color: ${(props) => props.color};
  padding: 0.5em 1.5em;
  border-bottom: 3px solid transparent;
  font-weight: 600;

  &:hover {
    border-bottom: 3px solid rgba(${(props) => props.borderColor}, 0.2);
  }
`;
