import { Grid } from "@mui/material";

import { PageContainer } from "components/molecules/index";
import { PageHeader } from "components/molecules/PageLayout/Header/PageHeader";

import type { HeaderProps } from "components/molecules/PageLayout/Header/PageHeader";
import type { ReactNode } from "react";

export interface DetailsContainerProps extends HeaderProps {
  actions?: ReactNode;
  children?: ReactNode;
  fullHeight?: boolean;
  title: string;
}

export const DetailsContainer = ({
  children,
  fullHeight = false,
  ...props
}: DetailsContainerProps) => {
  return (
    <PageContainer fullHeight={fullHeight}>
      <Grid container item xs={12} style={{ height: "100%" }}>
        <PageHeader {...props} />
        {children}
      </Grid>
    </PageContainer>
  );
};
