import moment from "moment";

import { projectsLogList } from "libs/data/endpoints/projects/projects";
import { getFormattedDate, getTzAwareDate } from "libs/utilities/date-util";
import { formatQueryParams } from "libs/utilities/request-util";

import { DEFAULT_LIMIT_LOGS, LOGS_DELAY } from "./constants";

import type { Logs, Logs as LogsType, LogsCreate } from "libs/data/models";
import type { Moment } from "moment";
import type { Filters, LogSet } from "./types";

const getDefaultOptions = () => [
  {
    label: "deployment_name",
    value: "deployment_name",
    isDisabled: false,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "deployment_version",
    value: "deployment_version",
    isDisabled: true,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "deployment_request_id",
    value: "deployment_request_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "deployment_version_revision_id",
    value: "deployment_version_revision_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "pipeline_name",
    value: "pipeline_name",
    isDisabled: false,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "pipeline_version",
    value: "pipeline_version",
    isDisabled: true,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "pipeline_object_name",
    value: "pipeline_object_name",
    isDisabled: true,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "pipeline_request_id",
    value: "pipeline_request_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "environment_name",
    value: "environment_name",
    isDisabled: false,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "environment_build_id",
    value: "environment_build_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "webhook_name",
    value: "webhook_name",
    isDisabled: false,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "system",
    value: "system",
    isDisabled: false,
    isSelected: false,
    dropdown: true,
  },
  {
    label: "build_id",
    value: "build_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "instance_id",
    value: "instance_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "process_id",
    value: "process_id",
    isDisabled: false,
    isSelected: false,
    dropdown: false,
  },
  {
    label: "level",
    value: "level",
    isDisabled: false,
    isSelected: false,
    dropdown: true,
  },
];

const dependencies = {
  deployment_name: ["deployment_version"],
  pipeline_name: ["pipeline_version"],
  pipeline_version: ["pipeline_object_name"],
};

export const systemOptions = [
  { name: "true", value: true },
  { name: "false", value: false },
];

export const logLevels = [
  { name: "info", value: "info" },
  { name: "error", value: "error" },
];

const manageDependencies = (value: string, callback: (arg: string) => void) => {
  Object.keys(dependencies).forEach((key) => {
    if (value === key) {
      // @ts-ignore
      dependencies[key].forEach(callback);
    }
  });
};

export const updateOptions = (search: string) => {
  const query = new URLSearchParams(search);
  const options = getDefaultOptions();
  query.forEach((_, key) => {
    const selected = options.find(({ value }) => value === key);
    if (selected) {
      selected.isSelected = true;
    }

    manageDependencies(key, (dependency: string) => {
      //@ts-ignore
      options.find(({ value }) => value === dependency).isDisabled = false;
    });
  });

  return options;
};

export const createUrl = (
  baseUrl: string,
  queryParameters: Record<string, string>
) => {
  const newUrl = baseUrl + "?" + formatQueryParams(queryParameters);

  return newUrl;
};

export const getFiltersFromUrl = (params: URLSearchParams) => {
  const filters = Object.fromEntries(params);
  delete filters["date"];
  delete filters["date_range"];

  return filters;
};

export const dataIsInsideLogs = (data: Logs[], logs: Logs[]) => {
  return data.map((item) => logs.find((x) => x.id === item.id)).filter(Boolean)
    .length;
};

export const loadLogs = async (
  projectName: string,
  params: LogsCreate = {}
) => {
  const logs = await projectsLogList(projectName, params);

  return (
    logs
      ?.filter((log) => !params?.id || log.id !== params.id)
      .sort(({ id: idA }, { id: idB }) => {
        if (idA && idB) {
          return idA < idB ? -1 : idA > idB ? 1 : 0;
        } else return 0;
      }) ?? []
  );
};

const wait = async (duration: number) =>
  new Promise((resolve) => setTimeout(() => resolve(true), duration));

export const momentToFormatted = (dateTime: Moment) =>
  getFormattedDate(getTzAwareDate(dateTime));

export const loadedFromDateFromLogSet = (set: LogSet): Moment | undefined =>
  set.loaded_from?.date
    ? set.loaded_from?.date
    : set.logs?.length
    ? moment(set.logs[0].date)
    : undefined;

export const loadedUntilDateFromLogSet = (set?: LogSet): Moment | undefined =>
  set?.loaded_until?.date
    ? set?.loaded_until?.date
    : set?.logs?.length
    ? moment(set?.logs[set?.logs.length - 1].date)
    : undefined;

export const loadInitialLogs = async (
  projectName: string,
  filters?: Filters,
  from?: Moment,
  until?: Moment
) => {
  const params: Partial<LogsCreate> = {
    date_range: -86400,
    filters: { ...filters } ?? {},
    limit: DEFAULT_LIMIT_LOGS,
  };

  const now = moment();
  const untilWithDefault = until ? until.clone() : now.clone().endOf("day");
  const fromWithDefault =
    from ?? now.clone().subtract(7, "days").startOf("day");
  const untilIsTomorrowOrLater =
    untilWithDefault.diff(now.clone().endOf("day")) > 1;
  let actualUntil = untilIsTomorrowOrLater
    ? now.clone()
    : untilWithDefault.clone();
  params.date = momentToFormatted(actualUntil);
  let logs: LogsType[] = [];

  try {
    while (logs.length === 0 && actualUntil.diff(fromWithDefault) > 0) {
      logs = await loadLogs(projectName, params);
      await wait(200);
      actualUntil = actualUntil.subtract(1, "day");
      params.date = momentToFormatted(actualUntil);
    }
  } catch (e) {
    actualUntil = actualUntil.subtract(1, "day");
    params.date = momentToFormatted(actualUntil);
  }

  const untilIsLater = untilWithDefault.diff(now) > 1;

  return {
    filters: params.filters as Filters,
    from_date: fromWithDefault ? fromWithDefault.clone() : actualUntil.clone(),
    loaded_from:
      logs.length === DEFAULT_LIMIT_LOGS
        ? { id: logs[0].id }
        : { date: actualUntil.clone() },
    loaded_until: {
      date: untilWithDefault && !untilIsLater ? untilWithDefault : now,
    },
    logs,
    provided_from: from,
    provided_until: until?.clone(),
    started_at: now.clone(),
    until_date: untilIsTomorrowOrLater ? now.clone() : untilWithDefault.clone(),
  };
};

export const prependOlderLogs = async (projectName: string, logSet: LogSet) => {
  const params: Partial<LogsCreate> = {
    date_range: -86400,
    filters: logSet.filters,
    limit: DEFAULT_LIMIT_LOGS,
  };

  if (logSet.loaded_from?.id) {
    params.id = logSet.loaded_from.id;
  } else if (logSet.loaded_from?.date) {
    params.date = momentToFormatted(logSet.loaded_from.date);
  }

  const realLimit = params.id ? DEFAULT_LIMIT_LOGS - 1 : DEFAULT_LIMIT_LOGS;

  let actualUntil = loadedFromDateFromLogSet(logSet) as Moment;
  let olderLogs: LogsType[] = [];

  if (actualUntil.diff(logSet.from_date) <= 0) {
    logSet.from_date?.subtract(1, "day");
  }

  try {
    while (olderLogs.length === 0 && actualUntil.diff(logSet.from_date) > 0) {
      olderLogs = await loadLogs(projectName, params);
      await wait(200);
      actualUntil = actualUntil.subtract(1, "day");
      params.date = momentToFormatted(actualUntil);
    }
  } catch (e) {
    actualUntil = actualUntil.subtract(1, "day");
    params.date = momentToFormatted(actualUntil);
  }

  const currentLogs = logSet?.logs ?? [];
  const slicedLogs =
    currentLogs.length > 300 ? currentLogs.slice(-50) : currentLogs;
  const logs = [...olderLogs, ...slicedLogs];

  return {
    ...logSet,
    loaded_from:
      olderLogs.length === realLimit
        ? { id: logs[0].id }
        : { date: actualUntil.clone() },
    logs,
  };
};

export const appendNewerLogs = async (projectName: string, logSet?: LogSet) => {
  const params: Partial<LogsCreate> = {
    date_range: 86400,
    filters: logSet?.filters,
    limit: DEFAULT_LIMIT_LOGS,
  };

  const now = moment();
  if (logSet?.loaded_until?.id) {
    params.id = logSet?.loaded_until.id;
  } else if (logSet?.loaded_until?.date) {
    if (logSet?.logs?.length) {
      const lastLog = logSet?.logs[logSet?.logs.length - 1];
      // If there's less than LOGS_DELAY space between the loaded_until and last log, we can use
      // the id of the last record instead
      if (
        logSet.loaded_until.date.diff(moment(lastLog.date), "seconds") <
        LOGS_DELAY
      ) {
        params.id = lastLog.id;
        // If there's more than LOGS_DELAY space, we can safely request LOGS_DELAY extra time of
        // records, handling timing issues on the back-end
      } else {
        params.date = momentToFormatted(
          logSet.loaded_until.date.clone().subtract(LOGS_DELAY - 1, "seconds")
        );
      }
    } else {
      params.date = momentToFormatted(
        logSet.loaded_until.date.clone().subtract(LOGS_DELAY - 1, "seconds")
      );
    }
  }

  const currentLogs = logSet?.logs ?? [];
  let logs = currentLogs.length > 300 ? currentLogs.slice(50) : currentLogs;
  let newerLogs = [];

  try {
    newerLogs = await loadLogs(projectName, params);
    logs = [...logs, ...newerLogs];
  } catch {
    // In case of for example a 404 the logs can remain in the state they are currently in
  }

  const originalLoadedUntilDate = loadedUntilDateFromLogSet(logSet) as Moment;
  const untilIsLater =
    originalLoadedUntilDate?.clone().add(1, "day").diff(now) > 1;

  return {
    ...logSet,
    loaded_until:
      newerLogs.length === DEFAULT_LIMIT_LOGS
        ? { id: logs[logs.length - 1].id }
        : { date: untilIsLater ? now : originalLoadedUntilDate.add(1, "day") },
    logs,
  };
};
