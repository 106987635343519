import HelpIcon from "@mui/icons-material/Help";
import { Tooltip } from "@mui/material";

import Icon from "../Icon/Icon";

import type { ReactElement, ReactNode } from "react";

import "./InfoTooltip.scss";

interface InfoTooltipProps {
  children: ReactNode | ReactElement;
}

const InfoTooltip = ({ children }: InfoTooltipProps) => (
  <Tooltip
    title={
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    }
    placement="bottom-start"
    onClick={(e) => e.preventDefault()}
  >
    <Icon component={HelpIcon} className="info-tooltip" />
  </Tooltip>
);

export default InfoTooltip;
