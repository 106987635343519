/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { Key, SWRConfiguration } from "swr";
import type { ErrorType } from "../../axios/index";
import type {
  ExportCreate,
  ExportDetail,
  ExportList,
  ExportsListParams,
  ImportDetail,
  ImportList,
  ImportsCreateBody,
  ImportsListParams,
  ImportUpdate,
} from "../../models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List all exports in a project

### Optional Parameters
The following parameter should be given as query parameter:
- `status`: Status of the export. Can be 'pending', 'processing', 'completed' and 'failed'.

### Response Structure
A list of details of the exports in the project
- `id`: Unique identifier for the export (UUID)
- `creation_date`: Time the export was created
- `status`: The status of the export
- `error_message`: The error message in case of a failure
- `size`: Size of the export in bytes

#### Response Examples
```
[
  {
    "id": "ecb39626-2a14-4224-a57a-592a51567e17",
    "creation_date": "2020-05-18T11:26:57.904+00:00",
    "status": "pending",
    "error_message": "",
    "size": null
  },
  {
    "id": "f629a052-a827-44d9-97cf-3902504edc79",
    "creation_date": "2020-05-18T11:26:57.904+00:00",
    "status": "completed",
    "error_message": "",
    "size": 86400
  }
]
```

 * @summary List exports
 */
export const exportsList = (
  projectName: string,
  params?: ExportsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ExportList[]>(
    { url: `/projects/${projectName}/exports`, method: "get", params },
    options
  );
};

export const getExportsListKey = (
  projectName: string,
  params?: ExportsListParams
) => [`/projects/${projectName}/exports`, ...(params ? [params] : [])] as const;

export type ExportsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportsList>>
>;
export type ExportsListQueryError = ErrorType<unknown>;

/**
 * @summary List exports
 */
export const useExportsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: ExportsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof exportsList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getExportsListKey(projectName, params) : null));
  const swrFn = () => exportsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create an export by selecting the objects in the export

### Optional Parameters
- `deployments`: Dictionary containing the deployments to export
- `pipelines`: Dictionary containing the pipelines to export
- `environment_variables`: Dictionary containing the project-level environment variables to export
- `environments`: Dictionary containing the environments to export

#### Request Examples

```
{
  "deployments": {
    "deployment-1": {
      "versions": {
        "version-1": {
          "environment_variables": {
            "VERSION_ENV_VAR_NAME_1": {
              "include_value": true
            },
            "VERSION_ENV_VAR_NAME_2": {
              "include_value": false
            }
          }
        },
        "version-2": {}
      },
      "environment_variables": {
        "DEPLOYMENT_ENV_VAR_NAME_1": {
          "include_value": false
        }
      }
    },
    "deployment-2": {
      "versions": {}
    }
  },
  "pipelines": {
    "pipeline-1": {
      "versions": {
        "version-1": {},
        "version-2": {}
      }
    },
    "pipeline-2": {
      "versions": {}
    }
  },
  "environment_variables": {
    "PROJECT_ENV_VAR_NAME_1": {
      "include_value": false
    }
  },
  "environments": {
    "environment-1": {}
  }
}
```

### Response Structure
Details of the created export
- `id`: Unique identifier for the export (UUID)
- `status`: Status of the export
- `error_message`: The error message in case of a failure
- `creation_date`: The date when the export was created
- `size`: Size of the export in bytes

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "status": "pending",
  "error_message": "",
  "creation_date": "2020-06-18T08:32:14.876451Z",
  "size": null
}
```

 * @summary Create an export
 */
export const exportsCreate = (
  projectName: string,
  exportCreate: ExportCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ExportList>(
    {
      url: `/projects/${projectName}/exports`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: exportCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of an export in a project

### Response Structure
- `id`: Unique identifier for the export (UUID)
- `status`: Status of the export
- `error_message`: The error message in case of a failure
- `creation_date`: The date when the export was created
- `size`: Size of the export in bytes
- `deployments`: Dictionary of the deployments in the export
- `pipelines`: Dictionary of the pipelines in the export
- `environment_variables`: Dictionary of the environment variables in the export
- `environments`: Dictionary of the environments in the export

 * @summary Get an export
 */
export const exportsGet = (
  projectName: string,
  exportId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ExportDetail>(
    { url: `/projects/${projectName}/exports/${exportId}`, method: "get" },
    options
  );
};

export const getExportsGetKey = (projectName: string, exportId: string) =>
  [`/projects/${projectName}/exports/${exportId}`] as const;

export type ExportsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportsGet>>
>;
export type ExportsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get an export
 */
export const useExportsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  exportId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof exportsGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && exportId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getExportsGetKey(projectName, exportId) : null));
  const swrFn = () => exportsGet(projectName, exportId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Delete an export from a project

 * @summary Delete an export
 */
export const exportsDelete = (
  projectName: string,
  exportId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}/exports/${exportId}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
Download an export in a project

### Response Structure
- `file`: Zip file

 * @summary Download an export
 */
export const exportsDownload = (
  projectName: string,
  exportId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Blob>(
    {
      url: `/projects/${projectName}/exports/${exportId}/download`,
      method: "get",
      responseType: "blob",
    },
    options
  );
};

export const getExportsDownloadKey = (projectName: string, exportId: string) =>
  [`/projects/${projectName}/exports/${exportId}/download`] as const;

export type ExportsDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportsDownload>>
>;
export type ExportsDownloadQueryError = ErrorType<unknown>;

/**
 * @summary Download an export
 */
export const useExportsDownload = <TError = ErrorType<unknown>>(
  projectName: string,
  exportId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof exportsDownload>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && exportId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getExportsDownloadKey(projectName, exportId) : null));
  const swrFn = () => exportsDownload(projectName, exportId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List all imports in a project

### Optional Parameters
The following parameter should be given as query parameter:
- `status`: Status of the import. Can be 'pending', 'scanning', 'confirmation', 'confirmation_pending', 'processing', 'completed' and 'failed'.

### Response Structure
A list of details of the imports in the project
 - `id`: Unique identifier for the import (UUID)
 - `creation_date`: Time the import was created
 - `status`: The status of the import
 - `error_message`: The error message in case of a failure
 - `size`: Size of the import in bytes

#### Response Examples
```
[
  {
    "id": "ecb39626-2a14-4224-a57a-592a51567e17",
    "creation_date": "2020-05-18T11:26:57.904+00:00",
    "status": "pending",
    "error_message": "",
    "size": 126832
  },
  {
    "id": "f629a052-a827-44d9-97cf-3902504edc79",
    "creation_date": "2020-05-18T11:26:57.904+00:00",
    "status": "pending",
    "error_message": "",
    "size": 86400
  }
]
```

 * @summary List imports
 */
export const importsList = (
  projectName: string,
  params?: ImportsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ImportList[]>(
    { url: `/projects/${projectName}/imports`, method: "get", params },
    options
  );
};

export const getImportsListKey = (
  projectName: string,
  params?: ImportsListParams
) => [`/projects/${projectName}/imports`, ...(params ? [params] : [])] as const;

export type ImportsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof importsList>>
>;
export type ImportsListQueryError = ErrorType<unknown>;

/**
 * @summary List imports
 */
export const useImportsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: ImportsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof importsList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getImportsListKey(projectName, params) : null));
  const swrFn = () => importsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create an import by uploading a zip file, providing a link to an import file or by giving an export id.
Only one of the fields `file`, `import_link` or `export_id` may be given at a time.
When providing a link to an import file, make sure it is publicly downloadable.

### Required Parameters
Only one of the following fields should be given:
- `file`: A zip file
- `import_link`: url to a publicly downloadable zip file
- `export_id`: UUID of a previously created export in the same project

### Optional Parameters
- `skip_confirmation`: Whether to skip the confirmation step, default to False

### Response Structure
Details of the created import
- `id`: Unique identifier for the import (UUID)
- `status`: Status of the import
- `error_message`: The error message in case of a failure
- `creation_date`: The date when the import was created
- `size`: Size of the import in bytes

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "status": "pending",
  "error_message": "",
  "creation_date": "2020-06-18T08:32:14.876451Z",
  "size": 28391
}
```

 * @summary Create an import
 */
export const importsCreate = (
  projectName: string,
  importsCreateBody: ImportsCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  const formData = new FormData();
  if (importsCreateBody.file !== undefined) {
    formData.append("file", importsCreateBody.file);
  }
  if (importsCreateBody.import_link !== undefined) {
    formData.append("import_link", importsCreateBody.import_link);
  }
  if (importsCreateBody.export_id !== undefined) {
    formData.append("export_id", importsCreateBody.export_id);
  }
  if (importsCreateBody.skip_confirmation !== undefined) {
    formData.append(
      "skip_confirmation",
      importsCreateBody.skip_confirmation.toString()
    );
  }

  return orvalAxios<ImportList>(
    {
      url: `/projects/${projectName}/imports`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

/**
 * 
### Description
Get the details of an import in a project

### Response Structure
- `id`: Unique identifier for the import (UUID)
- `status`: Status of the import
- `error_message`: The error message in case of a failure
- `creation_date`: The date when the import was created
- `size`: Size of the import in bytes
- `deployments`: Dictionary of the deployments in the import
- `pipelines`: Dictionary of the pipelines in the import
- `environment_variables`: Dictionary of the environment variables in the import
- `environments`: Dictionary of the environments in the import

 * @summary Get an import
 */
export const importsGet = (
  projectName: string,
  importId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ImportDetail>(
    { url: `/projects/${projectName}/imports/${importId}`, method: "get" },
    options
  );
};

export const getImportsGetKey = (projectName: string, importId: string) =>
  [`/projects/${projectName}/imports/${importId}`] as const;

export type ImportsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof importsGet>>
>;
export type ImportsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get an import
 */
export const useImportsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  importId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof importsGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && importId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getImportsGetKey(projectName, importId) : null));
  const swrFn = () => importsGet(projectName, importId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Confirm (and update) an import by selecting the objects in the import

### Optional Parameters
- `deployments`: Dictionary containing the deployments to create
- `pipelines`: Dictionary containing the pipelines to create
- `environment_variables`: Dictionary containing the project-level environment variables to create
- `environments`: Dictionary containing the environments to create

#### Request Examples

```
{
    "deployments": {
    "deployment-1: {
      "description": "",
      "labels": {
        "my-label": "my-value"
      },
      "default_version": "v1",
      "versions": {
        "v1": {
          "zip": "deployments/deployment_deployment-1/versions/deployment_deployment-1_version_v1.zip",
          "description": "",
          "labels": {},
          "environment": "python3-8",
          "maximum_idle_time": 300,
          "maximum_instances": 5,
          "instance_type": "256mb",
          "minimum_instances": 0,
          "environment_variables": {
            "VERSION_ENV_VAR_1": {
              "value": "my-secret-value",
              "secret": true
            },
            "VERSION_ENV_VAR_2": {
              "value": "test2"
            }
          },
          "request_retention_mode": "full",
          "request_retention_time": 604800
        }
      },
      "input_type": "structured",
      "output_type": "structured",
      "input_fields": [
        {
          "name": "input",
          "data_type": "double"
        }
      ],
      "output_fields": [
        {
          "name": "output",
          "data_type": "double"
        }
      ],
      "environment_variables": {
        "DEPLOYMENT_ENV_VAR_1": {
          "value": "my-secret-value",
          "secret": true
        },
        "DEPLOYMENT_ENV_VAR_2": {
          "value": "test"
        }
      }
    }
  },
  "pipelines": {
    "pipeline-1: {
      "description": "",
      "labels": {
        "test": "label"
      },
      "default_version": "v1",
      "versions": {
        "v1": {
          "description": "",
          "labels": {},
          "objects": [
            {
              "name": "obj-1",
              "reference_name": "deployment-1",
              "reference_version": "v1"
            }
          ],
          "attachments": [
            {
              "sources": [
                {
                  "mapping": [
                    {
                      "source_field_name": "input",
                      "destination_field_name": "input"
                    }
                  ],
                  "source_name": "pipeline_start"
                }
              ],
              "destination_name": "obj-1"
            },
            {
              "sources": [
                {
                  "mapping": [
                    {
                      "source_field_name": "output",
                      "destination_field_name": "output"
                    }
                  ],
                  "source_name": "obj-1"
                }
              ],
              "destination_name": "pipeline_end"
            }
          ],
          "request_retention_mode": "full",
          "request_retention_time": 604800
        }
      },
      "input_type": "structured",
      "output_type": "structured",
      "input_fields": [
        {
          "name": "input",
          "data_type": "double"
        }
      ],
      "output_fields": [
        {
          "name": "output",
          "data_type": "double"
        }
      ]
    }
  },
  "environment_variables": {
    "PROJECT_ENV_VAR_1": {
      "value": "value1",
      "secret": true
    },
    "PROJECT_ENV_VAR_2": {
      "value": "value2"
    }
  },
  "environments": {
    "environment-1": {
        "display_name": "Environment 1",
        "description": "",
        "labels": {},
        "base_environment": "python3-8"
    }
  }
}
```

### Response Structure
Details of the updated import
- `id`: Unique identifier for the import (UUID)
- `status`: Status of the import
- `error_message`: The error message in case of a failure
- `creation_date`: The date when the import was created
- `size`: Size of the import in bytes

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "status": "pending",
  "error_message": "",
  "creation_date": "2020-06-18T08:32:14.876451Z",
  "size": null
}
```

 * @summary Confirm an import
 */
export const importsUpdate = (
  projectName: string,
  importId: string,
  importUpdate: ImportUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ImportDetail>(
    {
      url: `/projects/${projectName}/imports/${importId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: importUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete an import from a project

 * @summary Delete an import
 */
export const importsDelete = (
  projectName: string,
  importId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}/imports/${importId}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
Download an import in a project

### Response Structure
- `file`: Zip file

 * @summary Download an import
 */
export const importsDownload = (
  projectName: string,
  importId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Blob>(
    {
      url: `/projects/${projectName}/imports/${importId}/download`,
      method: "get",
      responseType: "blob",
    },
    options
  );
};

export const getImportsDownloadKey = (projectName: string, importId: string) =>
  [`/projects/${projectName}/imports/${importId}/download`] as const;

export type ImportsDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof importsDownload>>
>;
export type ImportsDownloadQueryError = ErrorType<unknown>;

/**
 * @summary Download an import
 */
export const useImportsDownload = <TError = ErrorType<unknown>>(
  projectName: string,
  importId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof importsDownload>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && importId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getImportsDownloadKey(projectName, importId) : null));
  const swrFn = () => importsDownload(projectName, importId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
