import { Paper, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { CookiesProvider } from "react-cookie";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { SWRConfig } from "swr";

import { getTheme } from "assets/styles/theme";
import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { swrConfig } from "libs/data/axios";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import {
  googleAnalyticsEnabled,
  sentryEnabled,
  trackingEnabled,
} from "libs/env/helpers";
import { useNotifier } from "libs/hooks/useNotifier";
import {
  setupGoogleAnalytics,
  setupHubspot,
  setupSentry,
} from "libs/third-parties";
import { IndexPage } from "pages";
import { useGetThemeMode } from "store/features/themeMode";
import { history, store } from "store/store";

import { Loader } from "components/atoms";
import { CookieBanner } from "components/organisms";
import {
  ComposeProviders,
  ErrorBoundary,
  SnackbarCustomProvider,
} from "components/utilities";

import "assets/styles/global.scss";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ReactNode } from "react";
import "./App.scss";

if (sentryEnabled()) {
  setupSentry({
    dsn: env.get(ENV_NAMES.SENTRY_DSN),
    release: process.env.REACT_APP_SENTRY_RELEASE || "",
    environment: env.get(ENV_NAMES.NAME),
    history,
  });
}

interface AppProps {
  cookiesEnabled?: boolean;
  children: ReactNode;
}

const App = ({ cookiesEnabled, children }: AppProps) => {
  const themeMode = useGetThemeMode();
  const history = useHistory();
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [hubspotLoaded, setHubspotLoaded] = useState(false);
  const [cookies, , removeCookie] = useCustomCookies();
  const [theme, setTheme] = useState<AppThemeProps | null>(null);

  useEffect(() => {
    const path = history.location.pathname;
    const hubspotEnabled =
      path.includes("/sign-in") || path.includes("/sign-up");

    if (googleAnalyticsEnabled() && cookiesEnabled && !googleLoaded) {
      setGoogleLoaded(true);
      setupGoogleAnalytics();
    }

    if (
      cookiesEnabled &&
      trackingEnabled() &&
      hubspotEnabled &&
      !hubspotLoaded
    ) {
      setHubspotLoaded(true);
      setupHubspot();
    }

    if (!cookiesEnabled) {
      Object.keys(cookies).forEach((cookie) => {
        if (
          ![COOKIE_NAMES.COOKIE_CONSENT, COOKIE_NAMES.STATE_KEY].includes(
            cookie
          )
        ) {
          removeCookie(cookie);
          removeCookie(cookie, { path: "/", domain: ".ubiops.dev" });
          removeCookie(cookie, { path: "/", domain: ".ubiops.com" });
        }
      });
    }
    // skip the refresh after `cookie` changes to avoid recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleLoaded, hubspotLoaded, history, cookiesEnabled]);

  useEffect(() => {
    getTheme(env.get(ENV_NAMES.BRAND_THEME), themeMode.mode).then(
      (loadedTheme: AppThemeProps) => {
        setTheme(loadedTheme);
        let link: HTMLLinkElement | null =
          document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = process.env.PUBLIC_URL + loadedTheme.favIcon;
      }
    );
  }, [themeMode.mode]);

  useNotifier();

  if (!theme) {
    return <Loader />;
  }

  return (
    // All theme-related providers need to wrap the app directly to work properly
    <ThemeProvider key="8" theme={theme}>
      <Paper
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        elevation={0}
      >
        <IndexPage />
        {children}
      </Paper>
    </ThemeProvider>
  );
};

const AppWrapper = () => {
  const [cookies] = useCustomCookies();
  const [isAllCookiesEnabled, setAllCookiesEnabled] = useState<boolean>(
    cookies[COOKIE_NAMES.COOKIE_CONSENT]?.marketing
  );

  return (
    <ComposeProviders
      providers={[
        <ErrorBoundary key="0" />,
        <CookiesProvider key="1" />,
        <ReduxProvider key="2" store={store} />,
        <Router key="3" />,
        <SWRConfig key="4" value={swrConfig} />,
        <BreadcrumbsProvider key="5" />,
        <StyledEngineProvider key="7" injectFirst></StyledEngineProvider>,
        <SnackbarCustomProvider key="9" />,
      ]}
    >
      <App cookiesEnabled={isAllCookiesEnabled}>
        <CookieBanner enableCookies={() => setAllCookiesEnabled(true)} />{" "}
      </App>
    </ComposeProviders>
  );
};

export default AppWrapper;
