import type { AttachmentsCreate } from "libs/data/models";
import type { Edge, Node } from "reactflow";
import type { EdgeDataType, NodeDataType } from "./types";

export const getCurrentAttachments = (
  nodes: Node<NodeDataType>[],
  edges: Edge<EdgeDataType>[]
) =>
  edges.reduce((attachments: AttachmentsCreate[], edge) => {
    const targetNode: Node<NodeDataType> | undefined = nodes.find(
      (node) => node.id === edge.target
    );
    const sourceNode = nodes.find((node) => node.id === edge.source);

    if (edge.data?.new) {
      return attachments;
    }

    if (targetNode?.data.type === "diamond") {
      return attachments;
    }

    if (sourceNode?.data.type === "diamond") {
      return [
        ...attachments,
        {
          destination_name: targetNode?.data.pipelineObject.name || "",
          sources: edge.data?.sources || [],
        },
      ];
    }

    if (sourceNode?.data.type && targetNode?.data.type) {
      return [
        ...attachments,
        {
          destination_name: targetNode?.data.pipelineObject.name || "",
          sources: edge.data?.sources || [],
        },
      ];
    }

    return attachments;
  }, []);
