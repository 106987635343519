import { Box, Grid, Typography } from "@mui/material";
import cronstrue from "cronstrue";
import { Field } from "formik";
import { get } from "lodash";
import { useMemo, useState } from "react";
import * as Yup from "yup";

import { getTzAwareDate } from "libs/utilities/date-util";

import { WarningText } from "components/atoms";
import { FormikTextInput } from "components/molecules";
import { SimpleTabs } from "components/organisms";

import { DEFAULT_SCHEDULE_VALUE } from "./CronFields/constants";
import { CronFields } from "./CronFields/CronFields";

import type { FormikProps, FormikValues } from "formik";

type Props = {
  name: string;
  defaultValue?: string;
  control: FormikProps<FormikValues>;
};

const CronScheduler = ({
  name,
  defaultValue = DEFAULT_SCHEDULE_VALUE,
  control,
}: Props) => {
  const [nowDate] = useState(getTzAwareDate());

  const humanReadableCron = useMemo(() => {
    const cronExpression = get(control.values, name);
    try {
      if (cronExpression) {
        const humanizeValue = cronstrue.toString(cronExpression).toLowerCase();

        return humanizeValue;
      } else return;
    } catch (err) {
      return "";
    }
  }, [control.values, name]);

  const tabsContent = [
    {
      title: "Visual Editor",
      content: (
        <Box p={2}>
          <CronFields
            control={control}
            name={name}
            defaultValue={defaultValue}
          />
        </Box>
      ),
    },
    {
      title: "Cron Expression",
      content: (
        <Box p={2}>
          <FormikTextInput
            label="Cron expression"
            name={name}
            control={control}
            validation={Yup.string().test(
              "is-cron",
              "Invalid cron expression",
              (value) => {
                try {
                  const parts = value?.replace(/\s+/g, " ").trim().split(" ");

                  if (parts?.length === 5) {
                    return true;
                  } else throw Error;
                } catch (error) {
                  return false;
                }
              }
            )}
            placeholder="Ex: 0 8 * * 1"
          />
        </Box>
      ),
    },
  ];

  return (
    <Field name={name}>
      {() => (
        <Grid container direction="column" spacing={2}>
          {nowDate.utcOffset() !== 0 && (
            <Grid item>
              <WarningText>
                Request schedules are created in UTC time. Your current timezone
                is {nowDate.format("zZ")}. Please adjust the time accordingly.
              </WarningText>
            </Grid>
          )}
          <Grid item>
            <SimpleTabs tabs={tabsContent} />
          </Grid>
          <Grid item>
            {humanReadableCron && (
              <Typography variant="h4">
                Your schedule will run{" "}
                <Typography
                  component="span"
                  variant="inherit"
                  color="secondary"
                >
                  {humanReadableCron}
                </Typography>
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Field>
  );
};

export default CronScheduler;
