import { useEffect, useMemo, useState } from "react";

import type { MonitoringObject } from "./AddObjectDialog";

const useAddObjects = (projectName: string, initialValue = []) => {
  const key = useMemo(
    () => `session-added-objects-${projectName}`,
    [projectName]
  );

  const [selectedObjects, setSelectedObjects] = useState<MonitoringObject[]>(
    () => {
      const storedValue = sessionStorage.getItem(key);

      return storedValue ? JSON.parse(storedValue) : initialValue;
    }
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(selectedObjects));
  }, [key, selectedObjects]);

  return { selectedObjects, setSelectedObjects };
};

export default useAddObjects;
