import { useEffect, useState } from "react";

import type { EntityVersion } from "../../imports/create/types";
import type { AddedEntity, Entity } from "./types";

interface RequiredEnvironmentsProps {
  pipelineEntities: Entity;
  pipelines: AddedEntity[];
}

export default ({ pipelineEntities, pipelines }: RequiredEnvironmentsProps) => {
  const [requiredDeployments, setRequiredDeployments] = useState<string[]>([]);

  useEffect(() => {
    const newRequiredDeployments = [] as string[];
    pipelines.forEach((pipeline) => {
      const currentEntity = pipelineEntities?.[pipeline.name];
      const versions = Object.entries(currentEntity?.versions || {}) as [
        string,
        EntityVersion
      ][];

      versions.forEach(([versionName, version]) => {
        if (versionName !== pipeline.version) return;
        const objects = Object.values(version?.objects || {});
        objects.forEach((object) => {
          if (object.reference_type === "deployment") {
            newRequiredDeployments.push(object.reference_name);
          }
        });
      });
    });
    const newDeploymentSet = [...new Set(newRequiredDeployments)];
    const requiredSet = [...new Set(requiredDeployments)];
    const needToUpdate =
      JSON.stringify(newDeploymentSet) !== JSON.stringify(requiredSet);
    if (needToUpdate) setRequiredDeployments(newDeploymentSet);
  }, [pipelines, pipelineEntities, requiredDeployments]);

  return requiredDeployments;
};
