import { Grid } from "@mui/material";

import {
  MAX_MAXIMUM_QUEUE_SIZE_BATCH,
  MAX_MAXIMUM_QUEUE_SIZE_EXPRESS,
  MIN_MAXIMUM_QUEUE_SIZE_BATCH,
  MIN_MAXIMUM_QUEUE_SIZE_EXPRESS,
} from "libs/constants/constants";
import {
  FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
  FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";

import { FormTextField } from "components/atoms";

type FormQueueProps = {
  defaultBatchQueueSize?: number;
  defaultQueueSize?: number;
};
export const FormQueueTime = ({
  defaultBatchQueueSize,
  defaultQueueSize,
}: FormQueueProps) => (
  <>
    <Grid item>
      <FormTextField
        fullWidth
        name={FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS}
        id={FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS}
        label="Maximum express queue size"
        type="number"
        rules={{
          min: {
            value: MIN_MAXIMUM_QUEUE_SIZE_EXPRESS,
            message: validators.minimum_queue_size.message(
              MIN_MAXIMUM_QUEUE_SIZE_EXPRESS
            ),
          },
          max: {
            value: MAX_MAXIMUM_QUEUE_SIZE_EXPRESS,
            message: validators.maximum_queue_size.message(
              MAX_MAXIMUM_QUEUE_SIZE_EXPRESS
            ),
          },
        }}
        tooltip={explanations.queueSize.express}
        defaultValue={defaultQueueSize}
      />
    </Grid>
    <Grid item>
      <FormTextField
        fullWidth
        name={FIELD_MAXIMUM_QUEUE_SIZE_BATCH}
        id={FIELD_MAXIMUM_QUEUE_SIZE_BATCH}
        label="Maximum batch queue size"
        type="number"
        rules={{
          min: {
            value: MIN_MAXIMUM_QUEUE_SIZE_BATCH,
            message: validators.minimum_queue_size.message(
              MIN_MAXIMUM_QUEUE_SIZE_BATCH
            ),
          },
          max: {
            value: MAX_MAXIMUM_QUEUE_SIZE_BATCH,
            message: validators.maximum_queue_size.message(
              MAX_MAXIMUM_QUEUE_SIZE_BATCH
            ),
          },
        }}
        tooltip={explanations.queueSize.batch}
        defaultValue={defaultBatchQueueSize}
      />
    </Grid>
  </>
);
