import {
  Box,
  Divider,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

import { spacing } from "assets/styles/theme";
import { useDeploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { useInstanceEventsList } from "libs/data/endpoints/instances/instances";
import { renderTimestamp } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import { toUpperFirstCase } from "libs/utilities/util-functions";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { routes } from "routes";

import {
  DetailsDialogItem,
  Dialog,
  Icon,
  InfoAlert,
  Link,
  Loader,
  PrimaryButton,
  StatusIcon,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { getInstanceEnvironmentIcon } from "./utils";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { InstanceDetail, InstanceEvent } from "libs/data/models";

type InstanceDetailsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  details?: InstanceDetail;
  projectName: string;
  organizationName: string;
};
export const InstanceDetailsDialog = ({
  isOpen,
  onClose,
  details,
  organizationName,
  projectName,
}: InstanceDetailsDialogProps) => {
  const eventsContainer = useRef<HTMLDivElement>(null);
  const [events, setEvents] = useState<InstanceEvent[]>([]);
  const redirectUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName),
    [organizationName, projectName]
  );

  const {
    data: eventsList,
    mutate: mutateEvents,
    isValidating,
  } = useInstanceEventsList(
    projectName,
    details?.deployment ?? "",
    details?.version ?? "",
    details?.id ?? "",
    { limit: 20 },
    { swr: { enabled: !!details } }
  );

  const { data: versionDetails } = useDeploymentVersionsGet(
    projectName,
    details?.deployment ?? "",
    details?.version ?? ""
  );

  useEffect(() => {
    if (isOpen && !events.length && eventsList?.results.length) {
      mutateEvents();
      setEvents(sortBy(eventsList.results, "time_created"));
    }
  }, [isOpen, events.length, mutateEvents, eventsList?.results]);

  useEffect(() => {
    if (eventsList?.results.length) {
      setEvents([
        ...new Set(events.concat(sortBy(eventsList.results, "time_created"))),
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsList?.results]);

  useEffect(() => {
    const scrollHeight = eventsContainer?.current?.scrollHeight ?? 0;

    if (eventsContainer?.current?.scrollTo) {
      eventsContainer.current.scrollTop = scrollHeight;
    }
  }, [events.length]);

  const columns = [
    {
      title: "Time",
      field: "time_created",
      nowrap: true,
      width: "30%",
      render: (rowData: { time_created: string }) =>
        renderTimestamp(rowData, "time_created"),
    },
    {
      title: "Description",
      field: "description",
      nowrap: true,
      width: "70%",
    },
  ];

  const notAvailableIp = details?.node_pool?.cluster?.type === "kubernetes";
  const notAvailableMessage = "not available for this instance type";
  const ipv4Address = details?.node?.ipv4_address ?? "Assigning …";
  const ipv6Address = details?.node?.ipv6_address ?? "Assigning …";

  return (
    <Dialog
      title="Instance details"
      open={isOpen}
      onClose={() => {
        setEvents([]);
        onClose();
      }}
      dialogBodyStyles={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        rowGap: spacing[12],
        height: spacing[500],
      }}
    >
      {details ? (
        <>
          <DetailsDialogItem title="Deployment">
            {details.deployment != TRAINING_DEPLOYMENT ? (
              <Link
                to={redirectUrl.deployments[":deploymentName"](
                  details.deployment || ""
                ).index()}
              >
                {details.deployment}
              </Link>
            ) : (
              details.deployment
            )}
          </DetailsDialogItem>
          <DetailsDialogItem title="Version">
            {details.deployment != TRAINING_DEPLOYMENT ? (
              <Link
                to={redirectUrl.deployments[":deploymentName"](
                  details.deployment || ""
                )
                  .versions[":versionName"](details.version ?? "")
                  .index()}
              >
                {details.version}
              </Link>
            ) : (
              <Link
                to={redirectUrl.training.experiments[":experimentName"](
                  details.version ?? ""
                ).index()}
              >
                {details.version}
              </Link>
            )}
          </DetailsDialogItem>
          <DetailsDialogItem title="Status">
            <StatusIcon
              label={toUpperFirstCase(details.status ?? "")}
              status={details.status ?? ""}
              animation={details?.status !== "running"}
            />
          </DetailsDialogItem>
          <DetailsDialogItem title="Instance type">
            <Box display="flex" gap={1} alignItems="center">
              <Tooltip title={details.node_pool?.cluster?.type}>
                <Icon
                  component={getInstanceEnvironmentIcon(
                    details.node_pool?.cluster?.type
                  )}
                  style={{ height: 25, width: 25 }}
                />
              </Tooltip>
              <Typography>
                {details.instance_type?.display_name ?? "Assigning …"}
              </Typography>
            </Box>
          </DetailsDialogItem>
          <DetailsDialogItem title="IPv4 address">
            <Box display="flex">
              <Typography fontStyle={notAvailableIp ? "italic" : undefined}>
                {notAvailableIp ? notAvailableMessage : ipv4Address}
              </Typography>
            </Box>
          </DetailsDialogItem>
          <DetailsDialogItem title="IPv6 address">
            <Box display="flex">
              <Typography fontStyle={notAvailableIp ? "italic" : undefined}>
                {notAvailableIp ? notAvailableMessage : ipv6Address}
              </Typography>
            </Box>
          </DetailsDialogItem>
          {versionDetails?.static_ip ? (
            <InfoAlert>{explanations.activeInstances.staticIp}</InfoAlert>
          ) : (
            <Divider />
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflow: "auto",
            }}
          >
            <Box
              ref={eventsContainer}
              style={{ flex: "1 1 auto", overflow: "auto" }}
            >
              <BaseTable
                hasPagination={false}
                hasSearchBar={false}
                data={events}
                columns={columns as BaseColumn[]}
              />{" "}
            </Box>
            {isValidating && (
              <Box width={"100%"}>
                <LinearProgress color="secondary" />
              </Box>
            )}
            <PrimaryButton
              onClick={() => mutateEvents()}
              style={{
                width: "100%",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              Load new events
            </PrimaryButton>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </Dialog>
  );
};
