import { keyframes } from "@emotion/react";
import Refresh from "@mui/icons-material/Autorenew";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { CSSProperties, RefObject } from "react";

export type RefreshButtonProps = {
  onClick: () => void;
  tooltip: string;
  style?: CSSProperties;
};

export const RefreshButton = ({
  onClick,
  tooltip,
  style,
}: RefreshButtonProps) => {
  const [isRotating, setIsRotating] = useState(false);
  const refreshbuttonRef = useRef<HTMLButtonElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const theme = useTheme() as AppThemeProps;
  const unsetDisabledClass = useCallback(
    (elementRef: RefObject<HTMLButtonElement>) => {
      if (elementRef.current) {
        elementRef.current.disabled = false;
        setIsRotating(false);
      }
    },
    []
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        unsetDisabledClass(refreshbuttonRef);
      }
    };
  }, [unsetDisabledClass]);

  const refresh = () => {
    if (refreshbuttonRef.current) {
      const setDisabledClass = () => {
        if (refreshbuttonRef.current) {
          setIsRotating(true);
          refreshbuttonRef.current.disabled = true;
        }
      };

      setDisabledClass();
      timeoutRef.current = setTimeout(
        () => unsetDisabledClass(refreshbuttonRef),
        1000
      );
    }

    onClick();
  };

  return (
    <Tooltip title={tooltip}>
      <IconButton
        size="small"
        ref={refreshbuttonRef}
        onClick={refresh}
        style={{
          ...style,
          color: theme.palette.primary.main,
          position: "relative",
          right: 0,
          top: 0,
          width: "2em",
          height: "2em",
        }}
        sx={{
          animation: isRotating ? `${rotate} 1s ease infinite` : "none",
        }}
      >
        <Refresh fontSize="small" sx={{ color: theme.palette.text.primary }} />
      </IconButton>
    </Tooltip>
  );
};

const rotate = keyframes`100% {
  transform: rotate(360deg);
}`;
