import { Box, FormHelperText, Grid, styled } from "@mui/material";
import { get as lodashGet, isObject } from "lodash";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { TEXT_FIELD_MAX_CHARACTERS } from "libs/constants/constants";
import { FIELD_DESCRIPTION } from "libs/constants/fields";

import InfoTooltip from "../InfoTooltip/InfoTooltip";
import { TextField } from "./TextField";

import type { ReactNode } from "react";
import type { RegisterOptions } from "react-hook-form";
import type { TextFieldProps } from "./TextField";

const CharCounter = styled(FormHelperText)({
  textAlign: "end",
});

export type FormTextFieldProps = TextFieldProps & {
  rules?: RegisterOptions;
  withError?: boolean;
  tooltip?: string | ReactNode;
  withCharCounter?: boolean;
  maxChars?: number;
  loading?: boolean;
};

export const FormTextField = ({
  name,
  rules = {},
  withError = true,
  tooltip,
  withCharCounter = false,
  loading = false,
  maxChars = undefined,
  defaultValue,
  ...props
}: FormTextFieldProps) => {
  const { errors, register, control, setValue } = useFormContext();
  const value = useWatch({ control, name });
  const error = lodashGet(errors, name);

  useEffect(() => {
    if (Array.isArray(defaultValue) || isObject(defaultValue)) {
      setValue(name, JSON.stringify(defaultValue));
    }
  }, [defaultValue, name, setValue]);

  return (
    <Grid container>
      {loading ? (
        <Skeleton
          count={1}
          enableAnimation
          height={name === FIELD_DESCRIPTION ? 120 : 40}
          width="100%"
        />
      ) : (
        <Grid item xs>
          <TextField
            name={name}
            error={!!error}
            helperText={withError ? error?.message ?? "" : ""}
            inputRef={register(rules)}
            size="small"
            InputLabelProps={{ shrink: true }}
            onWheel={(e) => (e.target as HTMLElement).blur()}
            defaultValue={defaultValue}
            {...props}
          />
          {withCharCounter && (
            <CharCounter>
              {(value as string)?.length} /{" "}
              {maxChars ?? TEXT_FIELD_MAX_CHARACTERS}
            </CharCounter>
          )}
        </Grid>
      )}
      {!!tooltip && (
        <Grid item xs={1} component={Box} paddingTop={2}>
          <InfoTooltip>{tooltip}</InfoTooltip>
        </Grid>
      )}
    </Grid>
  );
};
