import { useReactFlow } from "reactflow";

import { formatInput } from "libs/utilities/input-parser";

import { SidebarRight } from "../SidebarRight";
import { useDeleteNode } from "../useDeleteNode";
import { VariableForm } from "./VariableForm";

import type {
  NewVariableDataType,
  NodeDataType,
} from "components/templates/Diagram/types";
import type { OutputValueListValue } from "libs/data/models";
import type { Node } from "reactflow";

type NodeVariableSidebarProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

export const NodeVariableSidebar = ({
  id,
  isOpen,
  onClose,
}: NodeVariableSidebarProps) => {
  const deleteNode = useDeleteNode();
  const { getNode, getNodes, setNodes } = useReactFlow<NodeDataType>();
  const node = getNode(id) as Node<NodeDataType>;
  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    if (variableIsInvalid(node)) {
      deleteNode(id);
    }

    onClose();
  };

  const variableIsInvalid = (node: Node<NodeDataType>) => {
    return !(
      node.data.pipelineObject.name &&
      node?.data.pipelineObject.configuration?.output_values?.[0].value
    );
  };

  const onSubmit = (data: NewVariableDataType) => {
    const nodes = getNodes();
    const name = data.name;
    const value = data.field_value || data.file_value;
    const data_type = data.type.value;

    const output_fields = [{ name, data_type }];
    const formattedValue = formatInput(output_fields, { [name]: value });
    if (node?.data?.pipelineObject?.configuration) {
      node.data.pipelineObject.configuration = {
        ...node.data.pipelineObject.configuration,
        output_fields,
        output_values: [
          { name, value: formattedValue[name] as OutputValueListValue },
        ],
      };

      node.data.pipelineObject.output_fields = output_fields;
      node.data.pipelineObject.name = name;

      setNodes(nodes.map((n) => (n.id === node.id ? node : n)));

      onClose();
    } else {
      // todo: throw a silent error
    }
  };

  return (
    <SidebarRight
      title="Pipeline Variable"
      description="You can use the defined variables as a source for your objects when creating a new connection."
      onClose={handleClose}
    >
      <VariableForm onSubmit={onSubmit} node={node} />
    </SidebarRight>
  );
};
