import styled from "@emotion/styled";
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

import type { CheckboxProps as MuiCheckboxProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { RegisterOptions } from "react-hook-form";

const CustomCheckbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ theme: AppThemeProps }>`
  &.MuiCheckbox-root.Mui-checked {
    color: ${({ color, theme }) =>
      color === "primary"
        ? theme.palette.primary.main
        : color === "secondary"
        ? theme.palette.secondary.main
        : color};
  }
`;

export type CheckboxProps = {
  name: string;
  label?: string | React.ReactElement;
  rules?: RegisterOptions;
  disabled?: boolean;
  checked?: boolean;
  onChange?: MuiCheckboxProps["onChange"];
  defaultChecked?: boolean;
  indeterminate?: boolean;
  color?: "primary" | "secondary";
  className?: string;
  fullWidth?: boolean;
};

export const Checkbox = ({
  name,
  label,
  rules = {},
  disabled = false,
  checked,
  onChange,
  defaultChecked,
  indeterminate,
  color,
  fullWidth = true,
  className = "",
}: CheckboxProps) => {
  const { errors, register } = useFormContext();
  const theme = useTheme() as AppThemeProps;
  const error = errors[name];

  return (
    <FormControl error={!!error} margin="none" fullWidth={fullWidth}>
      <FormControlLabel
        label={label}
        control={
          <CustomCheckbox
            name={name}
            theme={theme}
            inputRef={register(rules)}
            color={color}
            indeterminate={indeterminate}
            checked={checked}
            onChange={onChange}
            defaultChecked={defaultChecked}
            disabled={disabled}
            className={className}
          />
        }
      />
      <FormHelperText id={name} error={!!error} hidden={!error}>
        {error?.message}
      </FormHelperText>
    </FormControl>
  );
};
