// @ts-nocheck
import { useEffect, useState } from "react";

import type {
  ImportDetail,
  ImportDetailDeployments,
  ImportDetailEnvironments,
} from "libs/data/models";
import type {
  Entities,
  EntityType,
  EntityVersion,
  EntityVersionObject,
} from "./types";

export const useImportConfirmEdit = (imports: ImportDetail | undefined) => {
  const [editedImport, setEditedImport] = useState<ImportDetail | null>(null);

  // create a clone from import payload
  // we're using it to store the changes
  // and detech conflicts, dependencies betwween the instances
  useEffect(() => {
    if (imports && !editedImport) {
      setEditedImport({
        ...imports,
      });
    }
  }, [setEditedImport, imports, editedImport]);

  const addEnvironmentVariableValue =
    (entityType: EntityType) =>
    (entityName: string, variableName: string, value: string) => {
      if (!editedImport) return;
      const currentEntities = editedImport[
        entityType
      ] as ImportDetailDeployments;

      const editedEntity: ImportDetail = {
        ...(editedImport?.[entityType]?.[entityName] as ImportDetail),
      };

      if (editedEntity.environment_variables) {
        editedEntity.environment_variables[variableName].value = value;
        setEditedImport({
          ...editedImport,
          [entityType]: {
            ...currentEntities,
            [entityName]: editedEntity,
          },
        });
      }
    };

  const addVersionEnvironmentVariableValue =
    (entityType: EntityType) =>
    (
      entityName: string,
      version: string,
      variableName: string,
      value: string
    ) => {
      if (!editedImport) return;
      const currentEntities = editedImport[
        entityType
      ] as ImportDetailDeployments;

      const editedEntity: ImportDetail = {
        ...(editedImport?.[entityType]?.[entityName] as ImportDetail),
      };

      if (editedEntity.versions?.[version]) {
        editedEntity.versions[version].environment_variables[
          variableName
        ].value = value;
        setEditedImport({
          ...editedImport,
          [entityType]: {
            ...currentEntities,
            [entityName]: editedEntity,
          },
        });
      }
    };

  // entity is required if it's part of
  // the object dependency list of an other entity
  // or if it's an environment of a deployment
  const isEntityRequired =
    (editedEntities: Entities, environments?: ImportDetailEnvironments) =>
    (entityName: string) => {
      const entities = editedEntities ? Object.values(editedEntities) : [];

      return entities.some((entity) => {
        const versions = entity?.versions
          ? Object.values(entity.versions as EntityVersion[])
          : [];

        return versions.some((version) => {
          const objects = version?.objects
            ? Object.values(version.objects)
            : [];

          if (environments) {
            const envNames = Object.values(environments).map(
              (environment) => environment?.display_name
            );

            return envNames.includes(version?.environment);
          }

          return objects?.some(
            ({ reference_type, reference_name }: EntityVersionObject) =>
              reference_type === "pipeline" && reference_name === entityName
          );
        });
      });
    };

  const isEntitySelected = (editedEntities: Entities) => (entityName: string) =>
    Boolean(editedEntities?.[entityName]);

  const isVersionSelected =
    (editedEntities: Entities) => (entityName: string, versionName: string) =>
      Boolean(editedEntities?.[entityName]?.versions?.[versionName]);

  const toggleEntity =
    (entityType: EntityType) => (entityName: string, isAdd: boolean) => {
      if (!editedImport) return;
      const currentEntities = editedImport[
        entityType
      ] as ImportDetailDeployments;

      if (isAdd && imports) {
        setEditedImport({
          ...editedImport,
          [entityType]: {
            ...currentEntities,
            [entityName]: imports?.[entityType]?.[entityName],
          },
        });
      } else {
        const entitiesWithoutSelected = {
          ...currentEntities,
          [entityName]: undefined,
        };
        setEditedImport({
          ...editedImport,
          [entityType]: entitiesWithoutSelected,
        });
      }
    };

  const toggleVersion =
    (entityType: EntityType) =>
    (entityName: string, versionName: string, isAdd: boolean) => {
      const currentEntities = {
        ...(editedImport || {})[entityType],
      } as ImportDetailDeployments;

      const currentEntity = currentEntities[entityName];

      if (isAdd && !currentEntity) {
        setEditedImport({
          ...editedImport,
          [entityType]: {
            ...currentEntities,
            [entityName]: {
              ...imports?.[entityType]?.[entityName],
              versions: {
                [versionName]:
                  imports?.[entityType]?.[entityName]?.versions?.[versionName],
              },
            },
          },
        } as ImportDetail);
      }

      if (isAdd && currentEntity) {
        setEditedImport({
          ...editedImport,
          [entityType]: {
            ...currentEntities,
            [entityName]: {
              ...currentEntity,
              versions: {
                ...currentEntity.versions,
                [versionName]:
                  imports?.[entityType]?.[entityName]?.versions?.[versionName],
              },
            },
          },
        } as ImportDetail);
      }

      if (!isAdd) {
        setEditedImport({
          ...editedImport,
          [entityType]: {
            ...currentEntities,
            [entityName]: {
              ...currentEntity,
              versions: {
                ...currentEntity.versions,
                [versionName]: undefined,
              },
            },
          },
        } as ImportDetail);
      }
    };

  const toggleAll = (entityType: EntityType) => (isAdd: boolean) => {
    if (isAdd) {
      setEditedImport({
        ...editedImport,
        [entityType]: imports?.[entityType],
      } as ImportDetail);
    } else {
      setEditedImport({
        ...editedImport,
        [entityType]: {},
      } as ImportDetail);
    }
  };

  return {
    editedImport,
    addEnvironmentVariableValue,
    addVersionEnvironmentVariableValue,
    setEditedImport,
    isEntityRequired,
    isEntitySelected,
    isVersionSelected,
    toggleEntity,
    toggleVersion,
    toggleAll,
  };
};
