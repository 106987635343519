import { Grid } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { ControlledEnvironmentSelect } from "components/molecules/Fields/CodeEnvironmentSelect/ControlledEnvironmentSelect";
import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DESCRIPTION,
  FIELD_DISPLAY_NAME,
  FIELD_DOCKER_IMAGE,
  FIELD_FILE_TYPE,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_REQUIREMENTS_FILE,
  FIELD_SUPPORT_REQUESTS,
} from "libs/constants/fields";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { explanations } from "libs/utilities/explanations";

import { PrimaryButton } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import {
  ControlledLabelsInput,
  FormikCheckbox,
  FormikTextInput,
  FormSection,
  SupportRequestSection,
} from "components/molecules";

import { EnvironmentBaseSchema, EnvironmentBaseValues } from "./constants";
import { CustomDependenciesSection } from "./CustomDependenciesSection";

import type { FormikHelpers, FormikProps, FormikValues } from "formik";
import type { EnvironmentBaseProps } from "./constants";

interface EnvironmentBaseFormProps {
  isEditForm?: boolean;
  dependenciesRequired?: boolean;
  initialValues?: Partial<EnvironmentBaseProps>;
  onSubmit: (
    values: EnvironmentBaseProps,
    helpers: FormikHelpers<EnvironmentBaseProps>
  ) => void;
  submitLabel?: string;
}

export const EnvironmentBaseForm = ({
  isEditForm,
  dependenciesRequired,
  initialValues = {},
  onSubmit,
  submitLabel,
}: EnvironmentBaseFormProps) => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;

  const mergedInitialValues: EnvironmentBaseProps = useMemo(() => {
    return isEditForm
      ? (initialValues as EnvironmentBaseProps)
      : ({
          ...EnvironmentBaseValues,
          ...initialValues,
        } as EnvironmentBaseProps);
  }, [initialValues, isEditForm]);

  return (
    <Formik
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      validationSchema={EnvironmentBaseSchema}
    >
      {/** @ts-expect-error */}
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, values, setFieldValue } = control;

        return (
          <>
            <FormSection
              description={explanations.environments.nameYourEnvironment}
              title="Name your environment"
            >
              <FormikTextInput
                control={control}
                label="Name"
                name={FIELD_NAME}
                placeholder="Ex: example-environment"
                required
              />
              <FormikTextInput
                control={control}
                label="Display Name"
                name={FIELD_DISPLAY_NAME}
                placeholder="Ex: Example Environment"
              />
              <FormikTextInput
                control={control}
                label="Description"
                name={FIELD_DESCRIPTION}
                placeholder="Ex: An environment that contains XYZ"
                minRows={3}
                multiline
              />
            </FormSection>

            <CustomDependenciesSection
              control={control}
              dependenciesRequired={dependenciesRequired}
              disabled={isEditForm}
            />
            {values[FIELD_FILE_TYPE] === FIELD_REQUIREMENTS_FILE && (
              <FormSection
                title="Base environment"
                description={explanations.environments.baseEnvironment}
              >
                <ControlledEnvironmentSelect
                  name={FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT}
                  defaultValue={
                    values[FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]
                  }
                  setFieldValue={setFieldValue}
                  disabled={isEditForm}
                  gpuEnabled={gpuEnabled}
                  projectName={projectName}
                />
              </FormSection>
            )}

            {values[FIELD_FILE_TYPE] === FIELD_DOCKER_IMAGE &&
              !organizationFeatures?.request_format_only &&
              !isEditForm && (
                <SupportRequestSection
                  entity="environment"
                  isChecked={values[FIELD_SUPPORT_REQUESTS]}
                >
                  <FormikCheckbox
                    name={FIELD_SUPPORT_REQUESTS}
                    label="Supports request format"
                    control={control}
                    disabled={isEditForm}
                  />
                </SupportRequestSection>
              )}

            <FormSection
              title="Labels"
              description={explanations.labels.description("environments")}
            >
              <ControlledLabelsInput
                onChange={async (value: LabelsDict) => {
                  await setFieldValue(FIELD_LABELS, value);
                  await control.setFieldTouched(FIELD_LABELS, true);
                }}
                value={values[FIELD_LABELS]}
              />
            </FormSection>
            <Grid item container spacing={5} justifyContent="flex-end">
              <Grid item>
                <PrimaryButton
                  loading={control.isSubmitting}
                  onClick={submitForm}
                >
                  {submitLabel ?? "Create"}
                </PrimaryButton>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};
