import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  Redirect,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { useDeploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { routes } from "routes";

import { Route } from "components/utilities";

import { RequestDetailsGeneral } from "./RequestDetailsGeneral";
import { RequestDetails } from "./ReuquestDetails";

import type { DeploymentVersionDetailsRouteParams } from "../types";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .deployments[":deploymentName"](":deploymentName")
  .versions[":versionName"](":versionName")
  .requests.express[":requestId"](":requestId");

export interface RequestDetailsRouteParams
  extends DeploymentVersionDetailsRouteParams {
  requestId: string;
}

export const RequestDetailsContainer = () => {
  const match = useRouteMatch();
  const {
    organizationName,
    projectName,
    deploymentName,
    versionName,
    requestId,
  } = useParams<RequestDetailsRouteParams>();
  const { error } = useDeploymentVersionRequestsGet(
    projectName,
    deploymentName,
    versionName,
    requestId
  );
  const history = useHistory();

  useEffect(() => {
    if (error) {
      history.push(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .deployments[":deploymentName"](deploymentName)
          .versions[":versionName"](versionName)
          .requests.index()
      );
    }
  }, [
    deploymentName,
    error,
    history,
    organizationName,
    projectName,
    versionName,
  ]);

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .deployments[":deploymentName"](deploymentName)
    .versions[":versionName"](versionName)
    .requests.express[":requestId"](requestId);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{requestId}</BreadcrumbsItem>

      <Route path={basePath.index()}>
        <RequestDetails>
          <Switch>
            <Route
              exact
              path={basePath.general.index()}
              component={RequestDetailsGeneral}
            />
            <Redirect to={baseUrl.general.index()} />
          </Switch>
        </RequestDetails>
      </Route>
    </BaseUrlContext.Provider>
  );
};
