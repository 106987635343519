import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import { rgba } from "polished";
import PropTypes from "prop-types";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { RootUrlContext } from "libs/contexts";
import { useOrganizationUser } from "libs/data/customized/user";

import { ConditionalWrapper, NavLink } from "components/atoms";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { OrganizationDetail, ProjectDetail } from "libs/data/models";
import "./Sidebar.scss";

type RouteElement = {
  label: string;
  path?: string;
  icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  hidden?: boolean;
  url?: string;
  subRoutes?: RouteElement[];
};

type SidebarProps = {
  routes: (baseUrl: string, isAdmin: boolean) => RouteElement[][];
  sidebarIsOpen: boolean;
  currentProject: ProjectDetail | null;
  currentOrganization: OrganizationDetail | null;
};

const Sidebar = ({
  routes,
  currentProject,
  currentOrganization,
  sidebarIsOpen,
}: SidebarProps) => {
  const { isAdmin } = useOrganizationUser(currentOrganization?.name);
  const [subMenuPosition, setSubMenuPosition] = useState({ left: 0, top: 0 });
  const rootUrl = useContext(RootUrlContext);
  const theme = useTheme() as AppThemeProps;
  const adminRoutes = routes(rootUrl, isAdmin).slice(0, 2);
  const projectRoutes = routes(rootUrl, isAdmin).slice(2, 6);
  const location = useLocation();
  const sideBarItemRef = useRef<HTMLElement>(null);

  const calculateHeight = () => {
    const itemWithMenu = document.getElementById("item-with-menu");
    const y = itemWithMenu?.getBoundingClientRect()?.y;
    const x = itemWithMenu?.getBoundingClientRect()?.right;
    if (y && x) setSubMenuPosition({ top: y, left: x + 1 });
  };

  useEffect(() => {
    const sideBarItem = sideBarItemRef?.current;
    if (!sideBarItem) return;
    sideBarItem.addEventListener("mouseenter", calculateHeight);

    return () => sideBarItem.removeEventListener("mouseenter", calculateHeight);
    // route list can be different after location change, re-attach listener
  }, [sideBarItemRef, location]);

  return (
    <>
      {!location.pathname.includes("/projects")
        ? // ADMIN ROUTES
          adminRoutes.map((routesArray, index) => (
            <Fragment key={index}>
              <List disablePadding className="sidebar">
                {routesArray.map(
                  ({ label, path, icon: Icon, hidden = false }) =>
                    !hidden && (
                      <ConditionalWrapper
                        condition={!sidebarIsOpen}
                        key={label}
                        wrapper={(children) => (
                          <Tooltip title={label} placement="right">
                            {children}
                          </Tooltip>
                        )}
                      >
                        {/* ListItemButton is used with NavLink props */}
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore-next-line */}
                        <ListItemButton
                          key={label}
                          component={NavLink}
                          selected={location.pathname.includes(label)}
                          to={path}
                          activeClassName="active"
                          sx={{
                            "&.active": {
                              backgroundColor: theme.palette.sidebarMenu.active,
                              "& span": {
                                color: theme.palette.secondary.main,
                                fontWeight: "bold",
                              },
                              "& svg": {
                                color: theme.palette.secondary.main,
                              },
                            },
                            "&:hover": {
                              backgroundColor: theme.palette.sidebarMenu.hover,
                              color: theme.palette.text.primary,
                            },
                          }}
                        >
                          <ListItemIcon className="sidebar__icon">
                            <Icon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText className="sidebar__text">
                            {label}
                          </ListItemText>
                        </ListItemButton>
                      </ConditionalWrapper>
                    )
                )}
              </List>
            </Fragment>
          ))
        : // ORGANIZATION ROUTES (OUTSIDE PROJECT)
          projectRoutes.map((routesArray, index) => (
            <Fragment key={index}>
              {index !== 0 && <Divider />}
              <List disablePadding className="sidebar">
                {routesArray.map(
                  ({
                    label,
                    url,
                    path,
                    icon: Icon,
                    hidden = false,
                    subRoutes = null,
                  }) =>
                    !hidden && (
                      <ConditionalWrapper
                        condition={!sidebarIsOpen}
                        key={label}
                        wrapper={(children) => (
                          <Tooltip title={label} placement="right">
                            {children}
                          </Tooltip>
                        )}
                      >
                        {/* ITEM INSIDE PROJECT MENU WITH SUB ROUTES */}
                        {subRoutes ? (
                          <ListItem
                            className="sidebar__item__menu"
                            button
                            key={label}
                            component={Box}
                            selected={subRoutes.some((route) =>
                              location.pathname.includes(
                                `${route.url}/projects/${currentProject?.name}${route.path}`
                              )
                            )}
                            id="item-with-menu"
                            ref={sideBarItemRef}
                            sx={{
                              "&.active": {
                                backgroundColor:
                                  theme.palette.sidebarMenu.active,
                                color: theme.palette.secondary.main,
                              },
                              "&.Mui-selected": {
                                color: theme.palette.secondary.main,
                                backgroundColor:
                                  theme.palette.background.active,
                              },
                            }}
                          >
                            <ListItemIcon className="sidebar__icon">
                              <Icon fontSize="small" color="inherit" />
                            </ListItemIcon>
                            <ListItemText className="sidebar__text">
                              {label}
                            </ListItemText>
                            <ListItemIcon className="sidebar__chevron">
                              <ChevronRightIcon
                                fontSize="small"
                                color="inherit"
                              />
                            </ListItemIcon>
                            <Box
                              className="sidebar__submenu"
                              style={{
                                left: subMenuPosition.left,
                                top: subMenuPosition.top,
                                boxShadow: `0px 4px 4px ${rgba(
                                  theme.palette.neutrals[900],
                                  0.25
                                )}`,
                              }}
                            >
                              {/* SUBROUTES INSIDE PROJECT INSIDE APPENDED MENU*/}
                              {subRoutes.map((route) => (
                                <ListItem
                                  button
                                  key={route.label}
                                  component={NavLink}
                                  to={`${route.url}/projects/${currentProject?.name}${route.path}`}
                                  className="sidebar__item"
                                  activeClassName="active"
                                  sx={{
                                    color: theme.palette.text.primary,
                                    backgroundColor:
                                      theme.palette.sidebarMenu.background,
                                    "&.active": {
                                      "& span": {
                                        color: theme.palette.secondary.main,
                                      },
                                      backgroundColor:
                                        theme.palette.sidebarMenu.background,
                                    },
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.sidebarMenu.hover,
                                      color: theme.palette.text.primary,
                                    },
                                  }}
                                >
                                  <ListItemText className="sidebar__submenu__text">
                                    {route.label}
                                  </ListItemText>
                                </ListItem>
                              ))}
                            </Box>
                          </ListItem>
                        ) : (
                          // ROUTES INSIDE PROJECT
                          <ListItem
                            button
                            key={label}
                            component={NavLink}
                            to={`${url}/projects/${currentProject?.name}${path}`}
                            className="sidebar__item"
                            activeClassName="active"
                            sx={{
                              "&.active": {
                                backgroundColor:
                                  theme.palette.sidebarMenu.active,
                                "& span": {
                                  color: theme.palette.secondary.main,
                                  fontWeight: "bold",
                                },
                                "& svg": {
                                  color: theme.palette.secondary.main,
                                },
                              },
                              "&:hover": {
                                backgroundColor:
                                  theme.palette.sidebarMenu.hover,
                                color: theme.palette.text.primary,
                              },
                            }}
                          >
                            <ListItemIcon className="sidebar__icon">
                              <Icon fontSize="small" color="inherit" />
                            </ListItemIcon>
                            <ListItemText className="sidebar__text">
                              {label}
                            </ListItemText>
                          </ListItem>
                        )}
                      </ConditionalWrapper>
                    )
                )}
              </List>
            </Fragment>
          ))}
    </>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func,
  currentProject: PropTypes.object,
  currentOrganization: PropTypes.object,
  sidebarIsOpen: PropTypes.bool,
};

export default Sidebar;
