import { Box, LinearProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

const LinearProgressWithLabel = ({ percentage }) => (
  <Box
    width="95%"
    m="5px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Typography color="textSecondary">Uploading...</Typography>

    <Box width="70%">
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={percentage}
      />
    </Box>

    <Typography color="textSecondary">{percentage}%</Typography>
  </Box>
);

LinearProgressWithLabel.propTypes = {
  percentage: PropTypes.number,
};

export default LinearProgressWithLabel;
