import MakeDefault from "@mui/icons-material/AssignmentTurnedInOutlined";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import PlayArrow from "@mui/icons-material/PlayArrow";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { FIELD_DEFAULT_VERSION } from "libs/constants/fields";
import {
  PIPELINE_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import {
  usePipelineUpdate,
  usePipelineVersionDelete,
} from "libs/data/customized/pipeline";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  usePipelinesGet,
  usePipelineVersionsGet,
} from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics, useLogsUrl, useRequest } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";
import { useGetPermissions } from "store/features/permissions";

import {
  ActionDialog,
  ButtonGroup,
  DeleteDialog,
  HighlightedText,
  Loader,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import { RequestDialog } from "components/organisms";

import type { ReactNode } from "react";
import type { PipelineVersionRouteParams } from "./types";

interface PipelineVersionDetailsProps {
  children?: ReactNode;
}

export const PipelineVersionDetails = ({
  children,
}: PipelineVersionDetailsProps) => {
  useGoogleAnalytics();
  const dispatch = useDispatch();

  const history = useHistory();
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();

  const currentOrganization = useGetCurrentOrganization();

  const logsUrl = useLogsUrl({
    queryParameters: {
      pipeline_name: pipelineName,
      pipeline_version: versionName,
    },
  });

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);
  const { data: pipelineVersion, error: pipelineVersionError } =
    usePipelineVersionsGet(projectName, pipelineName, versionName);
  const updatePipeline = usePipelineUpdate(projectName, pipelineName);
  const deletePipelineVersion = usePipelineVersionDelete(
    projectName,
    pipelineName
  );

  const [projectPermissions] = useGetPermissions();
  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(PIPELINE_PERMISSIONS),
    pipelineName,
    "pipeline"
  );

  const isLoadingPipelineVersion = !pipelineVersion && !pipelineVersionError;

  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { openCreateDialog, ...requestProps } = useRequest({
    type: "pipeline",
    instance: pipeline,
    version: versionName,
    isRetentionModeNone: pipelineVersion?.request_retention_mode === "none",
  });

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName)
        .versions[":versionName"](versionName),
    [organizationName, pipelineName, projectName, versionName]
  );

  const handleDefaultVersionChange = () => {
    const newPipeline = {
      [FIELD_DEFAULT_VERSION]: versionName,
    };

    updatePipeline(newPipeline)
      .then(() => {
        dispatch(createSuccessNotification("The pipeline was updated"));
      })
      .catch((e) => {
        dispatch(createErrorNotification(e.message));
      });
  };

  const handleDeletePipelineVersion = async (versionName: string) => {
    const response = await deletePipelineVersion(versionName);
    if (response) {
      history.replace(
        routes.organizations[":organizationName"](organizationName)
          .projects[":projectName"](projectName)
          .pipelines[":pipelineName"](pipelineName)
          .index()
      );
    }
  };

  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    {
      link: baseUrl.requests.index(),
      label: "Requests",
      disabled:
        !currentPermissions[PIPELINE_PERMISSIONS["version_request_list"]],
    },
    {
      link: baseUrl.metrics.index(),
      label: "Metrics",
      disabled: !projectPermissions[PROJECT_PERMISSIONS["metrics_get"]],
    },
  ];

  return (
    <>
      <RequestDialog
        organizationName={organizationName}
        projectName={projectName}
        {...requestProps}
      />

      <DetailsContainer
        title={versionName}
        pageTitle={
          <span>
            {versionName}
            {pipelineVersion?.default && (
              <HighlightedText>default</HighlightedText>
            )}
          </span>
        }
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[PIPELINE_PERMISSIONS["version_update"]] ||
                currentOrganization?.status !== "active"
              }
              link={baseUrl.edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>
            <TextButton
              color="secondary"
              disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
              link={logsUrl}
              startIcon={<LogsIcon />}
            >
              Logs
            </TextButton>

            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[PIPELINE_PERMISSIONS["version_create"]]
              }
              link={baseUrl.duplicate.index()}
              startIcon={<Duplicate />}
            >
              Duplicate
            </TextButton>
            {pipeline?.default_version !== versionName && (
              <TextButton
                color="secondary"
                onClick={() => setDefaultDialogOpen(true)}
                startIcon={<MakeDefault />}
              >
                Make default
              </TextButton>
            )}
            <TextButton
              disabled={
                !currentPermissions[PIPELINE_PERMISSIONS["version_delete"]]
              }
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            <PrimaryButton
              startIcon={<PlayArrow />}
              disabled={
                !pipeline?.default_version ||
                !currentPermissions[
                  PIPELINE_PERMISSIONS["version_request_create"]
                ]
              }
              onClick={openCreateDialog}
              style={{ marginLeft: "auto" }}
            >
              Create request
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        <PageTabs tabs={tabs}>
          {isLoadingPipelineVersion ? <Loader /> : children}
        </PageTabs>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={() => handleDeletePipelineVersion(versionName)}
        >
          Are you sure you want to delete pipeline version {`"`}
          <b>{versionName}</b>
          {`"`} and all of its content?
        </DeleteDialog>

        <ActionDialog
          actionButtonText="Make default"
          onClose={() => setDefaultDialogOpen(false)}
          onAction={() => {
            handleDefaultVersionChange();
            setDefaultDialogOpen(false);
          }}
          open={defaultDialogOpen}
        >
          {explanations.defaultVersionDialog.message(versionName)}
        </ActionDialog>
      </DetailsContainer>
    </>
  );
};
