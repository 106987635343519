import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import validators from "libs/utilities/validators";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { EnvironmentList } from "libs/data/models";

export const DEFAULT_ENVIRONMENT = "python3-10";

const languageRules = {
  required: validators.required.message("code environment"),
};

export const formatCodeEnvironment = (env: EnvironmentList) =>
  ({
    label: env.display_name,
    value: env.name,
  } as AutocompleteSelectOption);

export interface BaseEnvironmentSelectProps {
  defaultEnvironment?: string;
  disabled?: boolean;
  gpuEnabled: boolean;
  name: string;
  projectName: string;
}

export const BaseEnvironmentSelect = ({
  defaultEnvironment,
  disabled,
  gpuEnabled,
  name,
  projectName,
}: BaseEnvironmentSelectProps) => {
  const { data: availableEnvironments } = useEnvironmentsList(projectName);
  const { setValue, getValues } = useFormContext();

  const environmentOptions = useMemo(() => {
    const filteredEnvironments =
      (gpuEnabled
        ? availableEnvironments
        : availableEnvironments?.filter((env) => !env.gpu_required)) ?? [];

    return filteredEnvironments
      .filter(
        (env) =>
          env.status !== "unavailable" &&
          !env.deprecated &&
          !env.hidden &&
          env.system
      )
      .map(formatCodeEnvironment)
      .sort((a, b) => {
        return b.label.localeCompare(a.label, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
  }, [availableEnvironments, gpuEnabled]);

  useEffect(() => {
    if (!getValues(name)) {
      if (defaultEnvironment) {
        setValue(name, defaultEnvironment);
      } else {
        setValue(name, environmentOptions[0]);
      }
    }
  }, [defaultEnvironment, name, setValue, getValues, environmentOptions]);

  const rules = useMemo(() => (disabled ? {} : languageRules), [disabled]);

  return (
    <AutoCompleteSelectHookForm
      disabled={disabled}
      label="Environment"
      name={name}
      options={environmentOptions}
      rules={rules}
    />
  );
};
