import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import {
  DEPLOYMENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useDeploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { DeploymentVersionDetailRequestRetentionMode } from "libs/data/models";
import { useGoogleAnalytics, useLogsUrl } from "libs/hooks";
import { useGetPermissions } from "store/features/permissions";

import { Requests } from "components/organisms";

import type { DeploymentVersionDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/:versionName/types";

export const DeploymentVersionRequests = () => {
  useGoogleAnalytics();

  const match = useRouteMatch();
  const { deploymentName, versionName, projectName } =
    useParams<DeploymentVersionDetailsRouteParams>();

  const { data: version } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );

  const [projectPermissions] = useGetPermissions();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_name: deploymentName,
      deployment_version: versionName,
      deployment_request_id: "",
    },
  });

  return (
    <>
      <BreadcrumbsItem to={match.url}>Requests</BreadcrumbsItem>
      <Requests
        logsUrl={logsUrl}
        allowCreate={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_create"]]
        }
        allowGet={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_get"]]
        }
        allowLogs={projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
        allowDelete={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_delete"]]
        }
        allowUpdate={
          currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_update"]]
        }
        requestParameters={{
          type: "deployment",
          resourceName: deploymentName,
          resourceVersion: version?.version,
        }}
        version={version}
        hasRetryButton={
          version?.request_retention_mode ===
          DeploymentVersionDetailRequestRetentionMode.full
        }
      />
    </>
  );
};
