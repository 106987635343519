import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { ReactComponent as DeleteIcon } from "assets/images/delete-white.svg";
import { spacing } from "assets/styles/theme";
import { DialogWarningHeader } from "components/atoms/Dialog/DialogHeaderWarningTitle";
import { FilesOverviewBreadcrumbs } from "components/organisms/FilesExplorer/FilesOverviewBreadcrumbs";
import { FILES_PERMISSIONS } from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  filesDelete,
  filesDownload,
  useBucketsGet,
  useFilesList,
} from "libs/data/endpoints/files/files";
import { downloadURL } from "libs/utilities/download-helper";
import { explanations } from "libs/utilities/explanations";
import { bytesFormatter } from "libs/utilities/metrics-helper";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { UploadFileDialog } from "pages/organizations/:organizationName/projects/:projectName/storage/buckets/:bucketName/dialogs/UploadFileDialog";
import { bucketNameIsDefault } from "pages/organizations/:organizationName/projects/:projectName/storage/utils";
import { routes } from "routes";

import {
  ActionDialog,
  Card,
  CardHeader,
  InfoAlert,
  PrimaryButton,
  RefreshButton,
  SecondaryButton,
} from "components/atoms";
import { LabeledTitle } from "components/molecules";
import type { File } from "components/organisms";
import { FilesExplorer, mapFileDetailToFileItems } from "components/organisms";

import { getPrefix } from "../../utils";
import { CreateFolderDialog } from "./dialogs/CreateFolderDialog";
import { DialogDetails } from "./dialogs/DialogDetails";
import { RestrictedPermissionsDialog } from "./dialogs/RestrictedPermissionsDialog";

import type { BucketDetail } from "libs/data/models";

type FilesOverviewProps = {
  readonly?: boolean;
  bucket?: BucketDetail | undefined;
};

export const FilesOverview = ({ readonly = false }: FilesOverviewProps) => {
  const { bucketName, organizationName, projectName } =
    useParams<{
      organizationName: string;
      projectName: string;
      bucketName: string;
    }>();
  const { data: bucket } = useBucketsGet(projectName, bucketName);
  const { pathname } = useLocation();
  const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);
  const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] =
    useState(false);
  const [restrictedPermissionsDialog, setRestrictedPermissionsDialog] =
    useState<string | null>(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [continuationToken, setContinuationToken] =
    useState<string | null>(null);
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const [fileToDelete, setFileToDelete] = useState<File | null>(null);
  const [search, setSearch] = useState<string>("");
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .storage[":bucketName"](bucketName)
    .general.files.index();

  const dispatch = useDispatch();
  const history = useHistory();
  const location: { state: { selectedFile?: string } } = useLocation();

  const [permissions] = usePermissionValidation(
    projectName,
    Object.values(FILES_PERMISSIONS),
    bucketName,
    "bucket"
  );

  const readOnlyRestricted =
    !permissions[FILES_PERMISSIONS.list] && permissions[FILES_PERMISSIONS.get];

  const writeOnlyRestricted =
    !permissions[FILES_PERMISSIONS.list] &&
    permissions[FILES_PERMISSIONS.create];

  const uploadNotAllowed =
    permissions[FILES_PERMISSIONS.get] &&
    !permissions[FILES_PERMISSIONS.create];

  const prefix = getPrefix(baseUrl, pathname);

  const { data: currentData, mutate: mutateCurrent } = useFilesList(
    projectName,
    bucketName,
    {
      limit,
      delimiter: "/",
      prefix: `${prefix}${search}`,
    }
  );

  // We also fetch the next list of files, immediately after the current list of files
  // Note that if there's no continuation token in the current data, the nextData won't load
  const { data: nextData, mutate: mutateNext } = useFilesList(
    projectName,
    bucketName,
    {
      limit,
      delimiter: "/",
      prefix: `${prefix}${search}`,
      continuation_token: continuationToken as string,
    },
    {
      swr: {
        enabled: !!continuationToken,
      },
    }
  );

  useEffect(() => {
    window.addEventListener("popstate", () => resetExplorer());

    return window.removeEventListener("popstate", () => resetExplorer());
  }, []);

  useEffect(() => {
    if (readOnlyRestricted) setRestrictedPermissionsDialog("read");
    else if (writeOnlyRestricted) setRestrictedPermissionsDialog("write");
  }, [readOnlyRestricted, writeOnlyRestricted, setRestrictedPermissionsDialog]);

  useEffect(() => {
    // our first continuation token should always be from the current data
    if (!continuationToken && currentData?.continuation_token) {
      setContinuationToken(currentData?.continuation_token);
    }
  }, [continuationToken, currentData?.continuation_token]);

  useEffect(() => {
    const isLastPage = (page + 1) * limit === files?.length;
    if (nextData && isLastPage && page !== 0) {
      if (nextData.continuation_token !== continuationToken) {
        setContinuationToken(nextData.continuation_token);
      }
    }
  }, [nextData, limit, page, files?.length, continuationToken]);

  useEffect(() => {
    if (currentData) {
      setFiles(mapFileDetailToFileItems(currentData));
      setContinuationToken(currentData.continuation_token);
    }
  }, [currentData]);

  useEffect(() => {
    const newFiles = nextData && mapFileDetailToFileItems(nextData);

    // If there are any files in the next data that haven't loaded yet, add them
    if (
      newFiles
        ?.map((x) => files?.map((y) => y.file).includes(x.file))
        .includes(false)
    ) {
      setFiles((previousFiles) => {
        const existingFiles = (previousFiles || []) as File[];

        return existingFiles.concat(newFiles);
      });
    } else {
      if (search !== "") {
        setFiles([]);
        setContinuationToken(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextData]);

  const selectedFile = useMemo(() => location.state?.selectedFile, [location]);

  const onFolderClicked = (folderName: string) => {
    resetExplorer();
    history.push(`${pathname}/${folderName}`);
  };

  const onRowsPerPageChange = (pageSize: number) => {
    setLimit(pageSize);
    resetExplorer();
  };

  const resetExplorer = () => {
    setContinuationToken(null);
    setFiles(undefined);
    setSearch("");
    setPage(0);
  };

  const getFileURL = useCallback(
    (fileName: string) => `ubiops-file://${bucketName}/${prefix}${fileName}`,
    [prefix, bucketName]
  );

  const downloadFile = (fileName: string) => {
    filesDownload(projectName, bucketName, prefix + fileName)
      .then((response) => downloadURL(response.url, fileName))
      .catch((err) => dispatch(createErrorNotification(err)));
  };

  const handleFileDelete = () => {
    fileToDelete &&
      filesDelete(
        projectName,
        bucketName,
        encodeURIComponent(prefix + fileToDelete.file)
      )
        .then(() => {
          if ((!files?.length || files.length === 1) && prefix) {
            history.push(pathname.substring(0, pathname.lastIndexOf("/")));
          }
          mutateCurrent(() => undefined);
          resetExplorer();
          mutateNext(() => undefined, { revalidate: false });

          dispatch(createSuccessNotification("The file was deleted"));
        })
        .catch((err) => dispatch(createErrorNotification(err?.message)))
        .finally(() => {
          setFileToDelete(null);
        });
  };

  const handleSuccessfulUpload = () => {
    mutateCurrent(() => undefined);
    resetExplorer();
    mutateNext(() => undefined, { revalidate: false });
  };

  const refreshTable = async () => {
    resetExplorer();
    await mutateCurrent(() => undefined);
    mutateNext(() => undefined, { revalidate: false });
  };

  const labels = Object.entries(bucket?.labels || {}).map(([key, value]) => ({
    key,
    value,
  }));

  const filesOnPage = useMemo(
    () => files?.slice(page * limit, (page + 1) * limit) || [],
    [files, limit, page]
  );

  const nonEmptyFiles = useMemo(
    () =>
      filesOnPage.filter(
        (file) => file.file && (file.isFolder || (file.size && file.size > 0))
      ),
    [filesOnPage]
  );

  const noMorePages = useMemo(
    () => !filesOnPage?.length || filesOnPage?.length < limit,
    [filesOnPage?.length, limit]
  );

  return (
    <Grid item xs={12}>
      <Card>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          marginBottom={2}
        >
          <CardHeader
            header={
              <LabeledTitle
                variant="h2"
                title={`Files in ${bucketName}`}
                labels={labels}
              />
            }
          />
          {!readonly && (
            <Box
              display="flex"
              alignItems="center"
              flexShrink={0}
              paddingBottom={spacing[8]}
            >
              <SecondaryButton
                disabled={uploadNotAllowed}
                onClick={() => setIsCreateFolderDialogOpen(true)}
                startIcon={<AddBoxRoundedIcon />}
                tooltip={
                  uploadNotAllowed &&
                  "You cannot create a new folder because you have read-only permissions."
                }
              >
                Create Folder
              </SecondaryButton>
              <PrimaryButton
                disabled={uploadNotAllowed}
                startIcon={<CloudUploadIcon />}
                style={{ marginLeft: spacing[8] }}
                tooltip={
                  uploadNotAllowed &&
                  "You cannot upload a new file because you have read-only permissions."
                }
                onClick={() => setIsUploadFileDialogOpen(true)}
              >
                Upload File
              </PrimaryButton>
              <RefreshButton tooltip="Refresh table" onClick={refreshTable} />
            </Box>
          )}
        </Box>
        {bucketNameIsDefault(bucketName) && (
          <InfoAlert>{explanations.storage.defaultBucket}</InfoAlert>
        )}
        <Box display="flex" justifyContent="space-between">
          <FilesOverviewBreadcrumbs
            pathname={pathname}
            onNavigate={resetExplorer}
          />
        </Box>
        {permissions[FILES_PERMISSIONS.list] && (
          <FilesExplorer
            currentFolder={prefix}
            files={nonEmptyFiles}
            handleFolderClick={onFolderClicked}
            handlePageChange={(value: number) => {
              setPage(value);
            }}
            disableNextPage={!continuationToken || noMorePages}
            onRowsPerPageChange={onRowsPerPageChange}
            search={search}
            setSearch={(newSearch: string) => {
              setPage(0);
              setSearch(newSearch);
            }}
            page={page}
            pageSize={limit}
            getFileURL={getFileURL}
            handleFileDelete={setFileToDelete}
            handleFileDownload={downloadFile}
            selectedFile={selectedFile}
          />
        )}
      </Card>

      <ActionDialog
        actionButtonIcon={<DeleteIcon />}
        dialogBodyStyles={{ padding: 0 }}
        open={Boolean(fileToDelete)}
        onClose={() => setFileToDelete(null)}
        Header={
          <DialogWarningHeader title="Are you sure you want to delete this file?" />
        }
        onAction={handleFileDelete}
        actionButtonText="Delete"
      >
        {fileToDelete && (
          <DialogDetails
            name="File"
            entity={{
              name: fileToDelete.file,
              size: bytesFormatter(fileToDelete.size, "_"),
              creation_time: fileToDelete.time_created ?? "",
            }}
          />
        )}
      </ActionDialog>
      <UploadFileDialog
        bucketName={bucketName}
        closeDialog={() => setIsUploadFileDialogOpen(false)}
        handleSuccessfulUpload={handleSuccessfulUpload}
        open={isUploadFileDialogOpen}
        prefix={prefix}
        multiple
      />
      <CreateFolderDialog
        closeDialog={() => setIsCreateFolderDialogOpen(false)}
        open={isCreateFolderDialogOpen}
        handleSuccessfulUpload={handleSuccessfulUpload}
      />
      <RestrictedPermissionsDialog
        open={Boolean(restrictedPermissionsDialog)}
        onClose={() => setRestrictedPermissionsDialog(null)}
        type={restrictedPermissionsDialog ?? ""}
      />
    </Grid>
  );
};
