import { Grid, Typography, useTheme } from "@mui/material";
import { map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_ASSIGNEE,
  FIELD_COPY_ENV_VARS,
  FIELD_DESCRIPTION,
  FIELD_INPUT_FIELDS,
  FIELD_INPUT_TYPE,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_OUTPUT_FIELDS,
  FIELD_OUTPUT_TYPE,
  FIELD_ROLE,
  FIELD_SOURCE_DEPLOYMENT,
  FIELD_SUPPORT_REQUESTS,
} from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import { useDeploymentCreate } from "libs/data/customized/deployment/useDeploymentCreate";
import {
  deploymentEnvironmentVariablesCopy,
  useDeploymentEnvironmentVariablesList,
  useDeploymentsGet,
} from "libs/data/endpoints/deployments/deployments";
import { useBucketsList } from "libs/data/endpoints/files/files";
import {
  roleAssignmentsCreate,
  useRoleAssignmentsPerObjectList,
} from "libs/data/endpoints/roles/roles";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { formatInputOutputFields } from "libs/utilities/input-parser";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { createErrorNotification } from "libs/utilities/notifications";
import { BucketPermissionsWithoutDeployment } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/formSections/BucketPermissionsWithoutDeployment";
import { useGetOrganizationFeatures } from "store/features/organizations";

import { Checkbox, Loader, Radio } from "components/atoms";
import {
  BaseTable,
  FormSection,
  GeneralFieldsSection,
  InputOutputField,
  PageContainer,
  SupportRequestSection,
} from "components/molecules";
import {
  columns as envVarsColumns,
  FormContainer,
  LabelsForm,
} from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  DeploymentCreate,
  DeploymentInputFieldCreate,
  DeploymentOutputFieldCreate,
  RoleAssignmentCreate,
} from "libs/data/models";
import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";

import "./DeploymentDuplicate.scss";

const formatVariables = (variables: any, filterCriterias: any) =>
  map(variables, (variable) => ({
    ...variable,
    value: variable.value ?? undefined,
  })).filter(
    ({ inheritance_type }) => !filterCriterias.includes(inheritance_type)
  );

const DeploymentDuplicate = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const theme = useTheme() as AppThemeProps;
  const organizationFeatures = useGetOrganizationFeatures();

  const { deploymentName, projectName } =
    useParams<DeploymentDetailsRouteParams>();
  const createDeployment = useDeploymentCreate(
    projectName,
    "Deployment successfully duplicated"
  );

  const { data: buckets } = useBucketsList(projectName);
  const { data: deployment, error } = useDeploymentsGet(
    projectName,
    deploymentName
  );
  const { data: permissions } = useRoleAssignmentsPerObjectList(projectName, {
    assignee: deploymentName,
    assignee_type: "deployment",
  });
  const { data: deploymentEnvVarsToCopy } =
    useDeploymentEnvironmentVariablesList(projectName, deploymentName);

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      [FIELD_SUPPORT_REQUESTS]: deployment?.supports_request_format ?? true,
      [FIELD_LABELS]: [],
    },
  });
  const { register, setValue, getValues, watch } = methods;
  const history = useHistory();
  const rootUrl = useContext(RootUrlContext);

  const [variables, setVariables] = useState<any>(null);

  useEffect(() => {
    if (deployment?.labels) {
      setValue(FIELD_LABELS, formatLabels(deployment.labels));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployment?.labels]);

  useEffect(() => {
    if (deploymentEnvVarsToCopy) {
      setVariables(
        formatVariables(deploymentEnvVarsToCopy, ["project"]) as any
      );
    }
  }, [deploymentEnvVarsToCopy]);

  const supportInferencing = watch(FIELD_SUPPORT_REQUESTS);

  const onDeploymentCreate = async (data: any) => {
    const duplicate: DeploymentCreate = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_INPUT_TYPE]: data[FIELD_INPUT_TYPE],
      [FIELD_OUTPUT_TYPE]: data[FIELD_OUTPUT_TYPE],
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
      [FIELD_SUPPORT_REQUESTS]: data[FIELD_SUPPORT_REQUESTS],
    };

    if (data?.[FIELD_INPUT_FIELDS]) {
      duplicate[FIELD_INPUT_FIELDS] = formatInputOutputFields(
        data[FIELD_INPUT_FIELDS]
      ) as DeploymentInputFieldCreate[];
    }

    if (data?.[FIELD_OUTPUT_FIELDS]) {
      duplicate[FIELD_OUTPUT_FIELDS] = formatInputOutputFields(
        data[FIELD_OUTPUT_FIELDS]
      ) as DeploymentOutputFieldCreate[];
    }

    const response = await createDeployment(duplicate);

    if (response) {
      if (data[FIELD_COPY_ENV_VARS]) {
        await deploymentEnvironmentVariablesCopy(
          projectName,
          data[FIELD_NAME],
          { [FIELD_SOURCE_DEPLOYMENT]: deploymentName }
        );
      }

      data.permissions?.map((item: any) => {
        const bucketName = item[FIELD_ASSIGNEE];
        const role = item[FIELD_ROLE];

        const newRole: RoleAssignmentCreate = {
          assignee: response.name,
          assignee_type: "deployment",
          resource: bucketName,
          resource_type: "bucket",
          role: role,
        };

        roleAssignmentsCreate(projectName, newRole).catch((e) =>
          dispatch(createErrorNotification(e.message))
        );
      });

      history.push(`${rootUrl}/deployments/${response.name}/general`);
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Duplicate deployment" />
      {!deployment && !error ? (
        <Loader />
      ) : (
        <>
          <FormContainer
            onSubmit={onDeploymentCreate}
            buttonLabel="Create"
            formMethods={methods}
          >
            <GeneralFieldsSection
              description={explanations.deployments.templates}
              nameDefaultValue={`${deployment?.name}-copy`}
              descriptionDefaultValue={deployment?.description}
              validateValue="deployment"
              namePlaceholder="Ex: my-deployment-1"
              descriptionPlacholder="Ex: A deployment that does XYZ"
            />
            {!organizationFeatures?.request_format_only && (
              <SupportRequestSection
                entity="deployment"
                isChecked={getValues(FIELD_SUPPORT_REQUESTS)}
              >
                <Checkbox
                  name={FIELD_SUPPORT_REQUESTS}
                  label={"Supports request format"}
                  defaultChecked={deployment?.supports_request_format}
                  color="secondary"
                  fullWidth={false}
                  checked={getValues(FIELD_SUPPORT_REQUESTS)}
                  onChange={(_e, checked) =>
                    setValue(FIELD_SUPPORT_REQUESTS, checked)
                  }
                />
              </SupportRequestSection>
            )}
            {supportInferencing && (
              <>
                <InputOutputField
                  title="Input"
                  description={
                    explanations.deployments.inputOutput({
                      type: "input",
                      color: theme.palette.primary.main,
                    } as any) as any
                  }
                  defaultType={deployment?.input_type}
                  defaultFields={deployment?.input_fields}
                  typeName={FIELD_INPUT_TYPE}
                  fieldsName={FIELD_INPUT_FIELDS}
                />
                <InputOutputField
                  title="Output"
                  description={
                    explanations.deployments.inputOutput({
                      type: "output",
                      color: theme.palette.primary.main,
                    } as any) as any
                  }
                  defaultType={deployment?.output_type}
                  defaultFields={deployment?.output_fields}
                  typeName={FIELD_OUTPUT_TYPE}
                  fieldsName={FIELD_OUTPUT_FIELDS}
                />
                {!!buckets && buckets?.length > 1 && (
                  <FormSection
                    title="Bucket permissions"
                    description={explanations.deployments.bucketPermissions}
                  >
                    <BucketPermissionsWithoutDeployment
                      buckets={buckets}
                      permissions={permissions}
                    />
                  </FormSection>
                )}
              </>
            )}
            <FormSection title="Labels">
              <LabelsForm name={FIELD_LABELS} />
            </FormSection>

            <FormSection title="Environment variables">
              <Grid item>
                <Typography variant="h4">
                  Would you like to copy the environment variables?
                </Typography>
              </Grid>
              {deploymentEnvVarsToCopy && variables ? (
                <>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Radio
                        value="true"
                        register={
                          register({
                            required: true,
                          }) as any
                        }
                        name={FIELD_COPY_ENV_VARS}
                        id="copyEnvVarsTrue"
                        label="Yes"
                        defaultChecked={!!variables.length}
                        disabled={!variables.length}
                      />
                    </Grid>
                    <Grid item>
                      <Radio
                        value="false"
                        register={
                          register({
                            required: true,
                          }) as any
                        }
                        name={FIELD_COPY_ENV_VARS}
                        id="copyEnvVarsFalse"
                        label="No"
                        defaultChecked={!variables.length}
                        disabled={!variables.length}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    {variables.length ? (
                      <>
                        <Typography variant="subtitle1">
                          The following variables can be copied.
                        </Typography>
                        <BaseTable
                          columns={
                            envVarsColumns(
                              theme.palette.error.main
                            ) as BaseColumn[]
                          }
                          data={variables}
                          hasPagination={false}
                          hasSearchBar={false}
                        />
                      </>
                    ) : (
                      <Typography variant="subtitle1">
                        No environment variables to copy.
                      </Typography>
                    )}
                  </Grid>
                </>
              ) : (
                <Loader />
              )}
            </FormSection>
          </FormContainer>
        </>
      )}
    </PageContainer>
  );
};

export default DeploymentDuplicate;
