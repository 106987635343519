import { useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams, useRouteMatch } from "react-router-dom";

import { CustomToggle } from "components/atoms/CustomToggle";
import { EmptyCustomMetricsCard } from "components/organisms/MetricsLayout/EmptyCustomMetricsCard";
import { useCustomMetrics } from "libs/data/customized/custom-metrics/useCustomMetrics";
import { pipelineMetricsGraphIds } from "libs/data/customized/pipeline/constants";
import { usePipelineMetrics } from "libs/data/customized/pipeline/usePipelineMetrics";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics } from "libs/hooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import { getStoredChartType } from "libs/utilities/metrics-helper";

import { MetricsLayout } from "components/organisms";

import type { Moment } from "moment";

const options = [
  {
    title: "Request rate",
    metric: [pipelineMetricsGraphIds.requests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Failed request rate",
    metric: [pipelineMetricsGraphIds.failedRequests],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Request duration",
    metric: [pipelineMetricsGraphIds.requestDuration],
  },
  {
    title: "Pipeline object calls",
    metric: [pipelineMetricsGraphIds.objectRequests],
  },
  {
    title: "Input volume",
    metric: [pipelineMetricsGraphIds.inputVolume],
  },
  {
    title: "Output volume",
    metric: [pipelineMetricsGraphIds.outputVolume],
  },
];

const PipelineVersionMetrics = () => {
  useGoogleAnalytics();
  const match = useRouteMatch();
  const { projectName, pipelineName, versionName } =
    useParams<{
      projectName: string;
      pipelineName: string;
      versionName: string;
    }>();

  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  const startDate = getTzAwareDate().subtract(1, "hour");
  const endDate = getTzAwareDate();

  const methods = useForm({
    defaultValues: {
      dates: {
        startDate,
        endDate,
      },
    },
  });
  const { control } = methods;

  const dates: { startDate: Moment; endDate: Moment } | undefined = useWatch({
    control,
    name: "dates",
  });

  const [forceRefresh, setForceRefresh] = useState(false);
  const onGraphRefresh = () => {
    setForceRefresh(!forceRefresh);
  };

  const {
    isCustom,
    isLoading,
    setCustom,
    options: customOptions,
  } = useCustomMetrics(
    pipelineVersion?.id && `pipeline_version_id:${pipelineVersion?.id}`
  );

  const optionsWithChartType = useMemo(
    () => getStoredChartType("pipeline", isCustom ? customOptions : options),
    [customOptions, isCustom]
  );

  const { metricGraphs, isMetricGraphsLoading, setGraphType } =
    usePipelineMetrics(
      [pipelineVersion?.id],
      optionsWithChartType,
      forceRefresh,
      dates?.startDate || startDate,
      dates?.endDate || endDate
    );

  return (
    <FormProvider {...methods}>
      <BreadcrumbsItem to={match.url}>Metrics</BreadcrumbsItem>
      <MetricsLayout
        graphs={metricGraphs || []}
        onGraphRefresh={onGraphRefresh}
        enableCrosshair
        setGraphType={setGraphType}
        isLoading={(isMetricGraphsLoading || isLoading) && !metricGraphs}
        defaultSelectedRange={"Last Hour"}
      >
        <CustomToggle
          label="Filter graphs:"
          isCustom={isCustom}
          setCustom={setCustom}
        />
      </MetricsLayout>
      {isCustom && !isMetricGraphsLoading && metricGraphs?.length === 0 && (
        <EmptyCustomMetricsCard type="pipeline" />
      )}
    </FormProvider>
  );
};
export default PipelineVersionMetrics;
