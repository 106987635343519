import HelpIcon from "@mui/icons-material/Help";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { useWatch } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { MaterialIcon } from "components/atoms/Icon/MaterialIcon";
import { FIELD_EXPRESSION, FIELD_INPUT_FIELDS } from "libs/constants/fields";

import { FormTextField } from "components/atoms";
import { DynamicFields } from "components/molecules";

import { TestExpression } from "./TestExpression";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { Fields } from "./TestExpression";
import type { OperatorFormProps } from "./types";

export const OperatorConditionalForm = ({
  value,
  control,
}: OperatorFormProps) => {
  const expression: string | undefined = useWatch({
    control,
    name: FIELD_EXPRESSION,
  });
  const fields: Fields | undefined = useWatch({
    control,
    name: FIELD_INPUT_FIELDS,
  });

  return (
    <>
      <ExpressionField defaultValue={value?.expression ?? ""} conditionalType />
      <Box marginY={spacing[16]}>
        <Typography variant="h6" color="textPrimary">
          Expression variables
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Specify the variables used in the expression above (if any)
        </Typography>
      </Box>
      <DynamicFields
        name={FIELD_INPUT_FIELDS}
        fields={value?.input_fields ?? []}
        color="primary"
      />
      <TestExpression
        expression={expression as string}
        fields={fields as Fields}
      />
    </>
  );
};

export const ExpressionField = ({
  defaultValue,
  conditionalType,
}: {
  defaultValue: string;
  conditionalType?: boolean;
}) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box display="flex">
      <FormTextField
        id={FIELD_EXPRESSION}
        name={FIELD_EXPRESSION}
        label="Expression"
        defaultValue={defaultValue}
        placeholder={conditionalType ? "5 + 3 < 10" : "5 + 23"}
      />
      <Tooltip
        title={
          <Typography variant="h4">
            This expression is the condition that is evaluated.
          </Typography>
        }
        placement="top-start"
        onClick={(e) => e.preventDefault()}
      >
        <MaterialIcon
          component={HelpIcon}
          htmlColor={theme.palette.neutrals[400]}
          size={20}
          style={{
            alignSelf: "center",
            marginLeft: spacing[16],
          }}
        />
      </Tooltip>
    </Box>
  );
};
