import CloudUpload from "@mui/icons-material/CloudUpload";
import { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { ENVIRONMENT_PERMISSIONS } from "libs/constants/permissions";
import { useEnvironmentFileUplaod } from "libs/data/customized/environments/useEnvironmentFileUplaod";
import { usePermissionValidation } from "libs/data/customized/roles";
import { EnvironmentBuildListStatus } from "libs/data/models";

import {
  CircularProgressWithLabel,
  Dialog,
  DialogWarningHeader,
  Icon,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";

import type { EnvironmentDetailStatus } from "libs/data/models";

type UploadEnvironmentPackageButtonProps = {
  environmentStatus?: EnvironmentDetailStatus;
  revisionStatus?: EnvironmentBuildListStatus;
  isDockerType: boolean;
};

export const UploadEnvironmentPackageButton = ({
  environmentStatus,
  revisionStatus,
  isDockerType,
}: UploadEnvironmentPackageButtonProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [confirmUploadDialog, setConfirmUploadDialog] = useState(false);
  const {
    projectName,
    environmentName,
  }: { projectName: string; environmentName: string } = useParams();
  const uploadFile = useEnvironmentFileUplaod(projectName, environmentName);

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(ENVIRONMENT_PERMISSIONS),
    environmentName,
    "environment"
  );

  const onOverwriteConfirm = () => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
      setConfirmUploadDialog(false);
    }
  };

  const handleFileUpload = useCallback(
    async (event: any) => {
      const file = event.target.files[0];
      setLoading(true);
      await uploadFile({ file });
      setLoading(false);
      if (inputFileRef?.current) inputFileRef.current.value = "";
    },
    [uploadFile]
  );

  const lastBuildStatusLoading = useMemo(
    () =>
      revisionStatus === EnvironmentBuildListStatus.building ||
      revisionStatus === EnvironmentBuildListStatus.queued,
    [revisionStatus]
  );

  return (
    <div>
      <input
        type="file"
        accept={isDockerType ? undefined : ".zip, .txt, .R, .yaml"}
        hidden
        onChange={handleFileUpload}
        ref={inputFileRef}
      />

      <SecondaryButton
        startIcon={
          loading ? (
            <CircularProgressWithLabel size={24} variant="indeterminate" />
          ) : (
            <Icon component={CloudUpload} />
          )
        }
        onClick={() => setConfirmUploadDialog(true)}
        disabled={
          environmentStatus === "building" ||
          !currentPermissions[ENVIRONMENT_PERMISSIONS["version_upload"]] ||
          loading ||
          lastBuildStatusLoading
        }
      >
        Upload environment file
      </SecondaryButton>

      <Dialog
        open={confirmUploadDialog}
        onClose={() => setConfirmUploadDialog(false)}
        Header={<DialogWarningHeader title="Warning" />}
        Actions={
          <>
            <SecondaryButton
              onClick={() => setConfirmUploadDialog(false)}
              style={{ marginRight: 14 }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onOverwriteConfirm}>
              Yes, overwrite
            </PrimaryButton>
          </>
        }
      >
        Are you sure you want to <b>overwrite</b> the existing environment
        package?
        <br />
        Note: changes can take up to one minute to take effect.
      </Dialog>
    </div>
  );
};
