import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";

import { borderRadius, spacing } from "assets/styles/theme";
import { fieldTypes } from "libs/utilities/labels-mapping";
import {
  destructFilePath,
  mapStorageLinkToAppLink,
} from "pages/organizations/:organizationName/projects/:projectName/storage/utils";

import { Link } from "components/atoms";

import { ActionButtons } from "../ActionButtons";
import { NodeDeleteDialog } from "../NodeDeleteDialog";
import { useSidebarRightState } from "../SidebarRight";
import { NodeVariableSidebar } from "./NodeVariableSidebar";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { PipelineVersionObjectList } from "libs/data/models";
import type { CSSProperties } from "react";
import type { NodeProps } from "reactflow";

export type NodeVariableProps = NodeProps & {
  data: {
    pipelineObject: PipelineVersionObjectList;
  };
};

export type VariableDataType = {
  name: string;
  data_type: string;
  value: string | number;
};

export const NodeVariable = ({
  id,
  isConnectable,
  selected,
  data,
}: NodeVariableProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isEditSidebarOpen, setIsEditSidebarOpen] = useSidebarRightState(id);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { output_fields, output_values } = data?.pipelineObject?.configuration;
  const theme = useTheme() as AppThemeProps;

  useEffect(() => {
    if (data.new) {
      setIsEditSidebarOpen(true);
    }
  }, [data.new, id, setIsEditSidebarOpen]);

  const { data_type } = output_fields?.[0];
  const { name, value } = output_values?.[0];

  const nodeType = fieldTypes.find((type) => type.value === data_type)?.label;

  return (
    <>
      <NodeDeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        id={id}
        pipelineObjectName={data.pipelineObject.name}
        pipelineObjectType="variable"
      />
      <Box display="flex" justifyContent="flex-end" height={35} mb={spacing[8]}>
        {selected && (
          <ActionButtons
            onEditClicked={() => setIsEditSidebarOpen(true)}
            onDeleteClicked={() => setIsDeleteDialogOpen(true)}
          />
        )}
      </Box>
      <Box position="relative">
        <NameBox
          expanded={expanded}
          backgroundColor={theme.palette.primary.main}
          color={theme.palette.primary.contrastText}
        >
          <Typography
            variant="h6"
            color="inherit"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
          <Box
            onClick={() => setExpanded(!expanded)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer", marginLeft: spacing[8] }}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
        </NameBox>
        <NodeHandle
          expanded={expanded}
          backgroundColor={theme.palette.primary.main}
          borderColor={theme.palette.primary.contrastText}
        />
        <S_Handle
          expanded={expanded}
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
        {expanded && (
          <Details
            backgroundColor={theme.palette.pipelineDiagram.varDetails}
            borderColor={theme.palette.primary.main}
          >
            <Box display="flex">
              <Typography variant="subtitle2">Type:</Typography>{" "}
              <Typography
                variant="subtitle2"
                style={{ marginLeft: spacing[8] }}
              >
                {nodeType}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle2">Value:</Typography>
              <NodeValue
                data_type={data_type}
                value={value}
                organizationName={data.organizationName}
                projectName={data.projectName}
              />
            </Box>
          </Details>
        )}
      </Box>
      <NodeVariableSidebar
        isOpen={isEditSidebarOpen}
        onClose={() => setIsEditSidebarOpen(false)}
        id={id}
      />
    </>
  );
};

const valueStyles: CSSProperties = {
  marginLeft: spacing[8],
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
const NodeValue = ({
  data_type,
  value,
  organizationName,
  projectName,
}: {
  data_type: string;
  value: any;
  organizationName: string;
  projectName: string;
}) => {
  if (data_type === "file") {
    const path = value as string;
    const { file } = destructFilePath(path);

    return (
      <Link
        to={{
          ...location,
          state: {
            selectedFile: file,
          },
          pathname: mapStorageLinkToAppLink(
            path,
            organizationName,
            projectName
          ),
        }}
        style={valueStyles}
      >
        {file}
      </Link>
    );
  }

  return (
    <Typography variant="subtitle2" style={valueStyles}>
      {JSON.stringify(value)}
    </Typography>
  );
};

const NameBox = styled(Box)<{
  expanded: boolean;
  color: string;
  backgroundColor: string;
}>`
  background: ${(props) => props.backgroundColor};
  padding: ${spacing[12]};
  border-radius: ${borderRadius[5]};
  width: 180px;
  height: 40px;
  color: ${(props) => props.color};
  border-bottom-right-radius: ${(props) => (props.expanded ? "0" : "")};
  border-bottom-left-radius: ${(props) => (props.expanded ? "0" : "")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NodeHandle = styled.div<{
  expanded: boolean;
  backgroundColor: string;
  borderColor: string;
}>`
  background: ${(props) => props.backgroundColor};
  border: 0.2px solid ${(props) => props.borderColor};
  height: 10px;
  width: 10px;
  z-index: 100;
  border-radius: 30px;
  position: absolute;
  right: -5px;
  top: ${(props) => (props.expanded ? "35px" : "15px")};
`;

const S_Handle = styled(Handle)<{ expanded: boolean }>`
  &&& {
    background: transparent;
    border: none;
    position: absolute;
    height: 30px;
    z-index: 100;
    top: ${(props) => (props.expanded ? "39px" : "19px")};
    right: -5px;
  }
`;

const Details = styled(Box)<{ backgroundColor: string; borderColor: string }>`
  background: ${(props) => props.backgroundColor};
  row-gap: ${spacing[8]};
  display: flex;
  flex-direction: column;
  width: 180px;
  border-bottom-right-radius: ${borderRadius[5]};
  border-bottom-left-radius: ${borderRadius[5]};
  border: 0.08px solid ${(props) => props.borderColor};
  padding: ${spacing[12]};
`;
