import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, FormHelperText, Typography } from "@mui/material";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { DropSelectFile } from "components/atoms/FileInput/DropSelectFile";

import { OverflowTooltip, SecondaryButton } from "components/atoms";

import "./FileField.scss";

type FileFieldProps = {
  name: string;
  register?: (...args: any[]) => any;
  label?: string;
  buttonText?: string;
  includeIcon?: boolean;
  indicateRequired?: boolean;
  rules?: any;
  verticalStyle?: boolean;
  disabled?: boolean;
};

export const FileField = ({
  name,
  buttonText = "Choose file",
  includeIcon,
  rules,
  disabled,
}: FileFieldProps) => {
  const { errors, register, setValue } = useFormContext();
  const error = get(errors, name);
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    register(name, rules);
  }, [name, register, rules]);

  const onHandleChange = (file: any) => {
    setFile(file);
    setValue(name, [file]);
  };

  return (
    <Box width="100%">
      <DropSelectFile
        handleChange={onHandleChange}
        name={name}
        padding={0}
        types={["zip"]}
      >
        <Box
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: 160,
          }}
        >
          <Typography>Drag and drop your file here to upload it</Typography>
          <Typography style={{ marginBottom: 8 }}>or</Typography>
          <label htmlFor={name}>
            <SecondaryButton
              disabled={disabled}
              startIcon={includeIcon ? <CloudUploadIcon /> : undefined}
            >
              {buttonText}
            </SecondaryButton>
          </label>
          {!!file && (
            <Box component="span" m={1} className="file-field__filename">
              <OverflowTooltip>{(file as any)?.name}</OverflowTooltip>
            </Box>
          )}
          <FormHelperText id={name} error={!!error} hidden={!error}>
            {error?.message}
          </FormHelperText>
        </Box>
      </DropSelectFile>
    </Box>
  );
};
