import { useTheme } from "@mui/material";
import { groupBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import {
  timeSeriesDataList,
  useTimeSeriesSearch,
} from "libs/data/endpoints/metrics/metrics";
import { getMetricsGraphOptions } from "libs/utilities/metrics-helper";

import type { MetricGraphProps } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { TimeSeriesDataList } from "libs/data/models";
import type { Moment } from "moment";
import type { Graph } from "./useGraphType";

type RequestMetrics = {
  [key: string]: TimeSeriesDataList[];
};

export const useRequestMetrics = (
  requestIds: (string | undefined)[],
  name: string,
  startDate: Moment,
  endDate: Moment,
  forceRefresh: boolean,
  graphs: Graph[]
) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [requestMetrics, setRequestMetrics] =
    useState<RequestMetrics | null>(null);
  const [metricGraphs, setMetricGraphs] =
    useState<MetricGraphProps[] | null>(null);

  const colors = useGetSpecialColors();
  const theme = useTheme() as AppThemeProps;
  const { data: trainingMetrics, mutate } = useTimeSeriesSearch(projectName, {
    custom: true,
    labels: requestIds?.[0]
      ? `deployment_request_id:${requestIds?.[0]}`
      : "deployment_request_id",
  });

  useEffect(() => {
    mutate();
  }, [forceRefresh, mutate]);

  const requestIdString = JSON.stringify(requestIds);

  const trainingGraphs = useMemo(() => {
    const trainingMetricNames = [
      ...new Set(trainingMetrics?.map(({ metric }) => metric)),
    ];

    return graphs?.filter(({ metric }) =>
      trainingMetricNames.includes(metric[0])
    );
  }, [graphs, trainingMetrics]);

  useEffect(() => {
    // before setting the fetched request details the two dates are moment(undefined)
    const areDatesLoading = startDate?.format() === endDate?.format();
    if (graphs?.length && requestIds.length && !areDatesLoading) {
      setIsLoading(true);
      const timeSeriesPayloads = requestIds
        .map((requestId) =>
          graphs?.map(({ metric }) => ({
            metric: metric[0],
            labels: requestId
              ? `deployment_request_id:${requestId}`
              : undefined,
            start_date: startDate?.toISOString(),
            end_date: endDate?.toISOString(),
          }))
        )
        .flat();

      Promise.all(
        timeSeriesPayloads.map((payload) =>
          timeSeriesDataList(projectName, payload)
        )
      )
        .then((metrics) => {
          const metricsByName = groupBy(metrics, "metric");
          setRequestMetrics(metricsByName);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectName,
    requestIdString,
    startDate,
    endDate,
    forceRefresh,
    trainingGraphs,
  ]);

  useEffect(() => {
    setMetricGraphs(
      graphs?.map((graph) =>
        getMetricsGraphOptions({
          ...graph,
          title: graph.title || graph.metric[0],
          theme: theme,
          colors,
          startDate:
            requestMetrics?.[
              graph.metric[0] as keyof typeof requestMetrics
            ]?.find(({ start_date }) => !!start_date)?.start_date ??
            startDate.toISOString(),
          endDate:
            requestMetrics?.[
              graph.metric[0] as keyof typeof requestMetrics
            ]?.find(({ end_date }) => !!end_date)?.end_date ??
            endDate.toISOString(),
          labels: [name] || [graph?.title],
          unit:
            requestMetrics?.[
              graph.metric[0] as keyof typeof requestMetrics
            ]?.find(({ unit }) => !!unit)?.unit ?? "",
          datasets:
            requestMetrics?.[
              graph.metric[0] as keyof typeof requestMetrics
            ]?.map((dataset) => dataset.data_points) ?? [],
        })
      ) as unknown as MetricGraphProps[]
    );
  }, [
    requestMetrics,
    colors,
    graphs,
    name,
    theme,
    trainingMetrics,
    trainingGraphs,
    startDate,
    endDate,
  ]);

  return { metricGraphs, isMetricGraphsLoading: isLoading };
};
