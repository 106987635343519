import { createTheme } from "@mui/material";

import breakpoints from "./breakpoints";
import getComponents from "./components";
import { supportedBrandThemes } from "./constants";
import typography from "./typography";

import type { Palette } from "@mui/material";
import type { AppThemeProps } from "./theme.d";

export * from "./borders";
export * from "./shadows";
export * from "./spacing";

const loadTheme = async (orgName: string) => {
  if (!supportedBrandThemes.includes(orgName)) {
    orgName = "default";
  }

  // For a new brand theme, all these files should exist in the relevant directories
  return await Promise.all([
    import(`./${orgName}/lightPalette`),
    import(`./${orgName}/darkPalette`),
    import(`./${orgName}/logo-horizontal.svg`),
    import(`./${orgName}/logo-holidays-horizontal.svg`),
    import(`./${orgName}/LogoVertical`),
    import(`./${orgName}/logo-vertical-white.svg`),
  ]).then(
    ([
      lightPalette,
      darkPalette,
      logo,
      logoHolidays,
      logoVertical,
      logoVerticalWhite,
    ]) => ({
      lightPalette: lightPalette,
      darkPalette: darkPalette,
      logo: logo.default,
      logoHolidays: logoHolidays.default,
      logoVertical: logoVertical.LogoVertical,
      logoVerticalWhite: logoVerticalWhite.default,
      favIcon: `/${orgName}/favicon.ico`,
    })
  );
};

export const getTheme = async (
  orgName: string,
  themeMode: "light" | "dark" = "light"
): Promise<AppThemeProps> => {
  return await loadTheme(orgName).then((theme) => {
    const palette: Palette =
      themeMode === "light"
        ? (theme.lightPalette.lightPalette as Palette)
        : (theme.darkPalette.darkPalette as Palette);

    return createTheme({
      palette,
      logo: theme.logo,
      logoHolidays: theme.logoHolidays,
      logoVertical: theme.logoVertical,
      logoVerticalWhite: theme.logoVerticalWhite,
      favIcon: theme.favIcon,
      components: getComponents(palette),
      typography,
      breakpoints,
    }) as AppThemeProps;
  });
};
