import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

import { useGetThemeMode } from "store/features/themeMode";
import "./CrumbItem.scss";

import type { ReactNode } from "react";

interface CrumbItemProps {
  to: string;
  children: ReactNode;
}

export const CrumbItem = ({ to, children, ...props }: CrumbItemProps) => {
  const theme = useTheme();
  const themeMode = useGetThemeMode();

  return (
    <NavLink
      exact
      className="crumb-item"
      to={to}
      {...props}
      style={{
        color:
          themeMode.mode === "dark"
            ? theme.palette.text.primary
            : theme.palette.primary.main,
      }}
    >
      {children}
    </NavLink>
  );
};
