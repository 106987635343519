import { useState } from "react";
import { useParams } from "react-router-dom";

import { ActiveInstancesTable } from "components/organisms/ActiveInstances/ActiveInstancesTable";
import { CONTINUOUS_INSTANCES_DELAY } from "libs/constants/constants";
import { useProjectInstancesList } from "libs/data/endpoints/instances/instances";
import { useInterval } from "libs/hooks";

import type { ProjectInstancesListParams } from "libs/data/models";

export const MonitoringActiveInstances = () => {
  const { projectName, deploymentName, versionName } =
    useParams<{
      projectName: string;
      deploymentName: string;
      versionName: string;
    }>();
  const [query, setQuery] = useState<ProjectInstancesListParams>({
    limit: 10,
  });

  const { data: instances, mutate } = useProjectInstancesList(
    projectName,
    query,
    { swr: { dedupingInterval: 0 } }
  );

  useInterval(
    () => {
      mutate();
    },
    [mutate],
    CONTINUOUS_INSTANCES_DELAY
  );

  return (
    <ActiveInstancesTable
      data={instances}
      query={query}
      setQuery={setQuery}
      showDeployment
      refreshTable={mutate}
      deploymentName={deploymentName}
      versionName={versionName}
    />
  );
};
