import { useContext, useEffect, useMemo } from "react";
import { useEdges, useReactFlow } from "reactflow";

import { getEdgeInfoFromAttachment } from "../getEdgeInfoFromAttachment";
import { PendingEdgesContext } from "../PendingEdgesContext";
import { SidebarRight } from "../SidebarRight";
import { NodeTypes } from "../types";
import { useDeleteEdge } from "../useDeleteEdge";
import { EdgeForm } from "./EdgeForm";

import type {
  AttachmentsList,
  PipelineVersionObjectList,
} from "libs/data/models";
import type { EdgeDataType, NodeDataType } from "../types";

export type LinkSidebarProps = {
  id: string;
  sourceId: string;
  targetId: string;
  edgeData: EdgeDataType;
  isOpen: boolean;
  onClose: () => void;
};

export const EdgeSidebar = ({
  id,
  sourceId,
  targetId,
  edgeData,
  isOpen,
  onClose,
}: LinkSidebarProps) => {
  const { getNode, getNodes, addEdges, addNodes } =
    useReactFlow<NodeDataType, EdgeDataType>();

  const edges = useEdges<EdgeDataType>();
  const deleteEdge = useDeleteEdge();

  const [pendingEdges, setPendingEdges] = useContext(PendingEdgesContext);

  const sourceNode = getNode(sourceId);
  const targetNode = getNode(targetId);

  const sourceIds = useMemo(
    () => {
      // we want to add all the sources of previously dragged edges to this edge to enable multi-source edge
      const sameTargetEdges = pendingEdges?.filter(
        (edg) => edg.target === targetId
      );
      const sources = sameTargetEdges?.map((edg) => edg.source) || [];

      return sourceNode?.type === NodeTypes.diamond
        ? edges
            .filter((edg) => edg.target === sourceId)
            .map((edge) => edge.source)
        : [...new Set([sourceId, ...sources])];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edges, sourceId, sourceNode?.type]
  );

  useEffect(() => {
    // we'll add this edge to a pending state untill it's submitted or closed
    const currectEdge = edges.find((edg) => edg.id === id);
    if (edgeData.new && currectEdge)
      setPendingEdges([...(pendingEdges || []), currectEdge]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceIds, setPendingEdges, edgeData.new, edges, id]);

  if (!isOpen || !sourceNode || !targetNode) {
    return null;
  }

  const defaultSources = sourceIds.map((sourceId) => {
    const sourceName = getNode(sourceId)?.data.pipelineObject.name || "";

    return {
      source_name: sourceName,
      mapping:
        edgeData?.sources.find((source) => source.source_name === sourceName)
          ?.mapping || [],
    };
  });

  const defaultSourceObjects: PipelineVersionObjectList[] = sourceIds.reduce(
    (sourceObjects, sourceId) => {
      const sourceType = getNode(sourceId)?.data.type;
      const sourceObject = getNode(sourceId)?.data.pipelineObject;

      if (!sourceObject || sourceType === NodeTypes.pipeline_end) {
        return sourceObjects;
      }

      return [...sourceObjects, sourceObject];
    },
    [] as PipelineVersionObjectList[]
  );

  const handleSubmit = (attachment: Omit<AttachmentsList, "id">) => {
    setPendingEdges(null);
    pendingEdges?.forEach((edg) => deleteEdge(edg.id));
    deleteEdge(id);

    const { diamondNodes, edges: newEdges } = getEdgeInfoFromAttachment(
      attachment,
      defaultDestinationObject,
      getNodes(),
      false,
      edgeData.organizationName,
      edgeData.projectName,
      edgeData.pipelineName,
      edgeData.versionName
    );
    addNodes(diamondNodes);
    addEdges(newEdges);

    onClose();
  };

  const defaultDestinationObject = targetNode?.data.pipelineObject;

  if (!defaultSourceObjects || !defaultDestinationObject) {
    return null;
  }

  return (
    <SidebarRight
      title="Edit connection"
      description="Edit the connection between objects in your pipeline."
      onClose={() => {
        if (edgeData.new) {
          deleteEdge(id);
          setPendingEdges(null);
        }

        onClose();
      }}
    >
      <EdgeForm
        defaultSources={defaultSources}
        defaultSourceObjects={defaultSourceObjects}
        defaultDestinationObject={defaultDestinationObject}
        onSubmit={handleSubmit}
      />
    </SidebarRight>
  );
};
