import { useDispatch } from "react-redux";

import { setError } from "store/features/authentication";

import { Link } from "./Link";

type AuthenticationLinkProps = {
  to: string;
  children?: React.ReactNode;
};

export const AuthenticationLink = ({
  to,
  children,
}: AuthenticationLinkProps) => {
  const dispatch = useDispatch();

  return (
    <Link to={to} onClick={() => dispatch(setError(null))}>
      {children}
    </Link>
  );
};
