import MakeDefault from "@mui/icons-material/AssignmentTurnedInOutlined";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import Duplicate from "@mui/icons-material/FileCopyOutlined";
import PlayArrow from "@mui/icons-material/PlayArrow";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { FIELD_DEFAULT_VERSION } from "libs/constants/fields";
import {
  DEPLOYMENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { useDeploymentVersionDelete } from "libs/data/customized/deployment-versions/useDeploymentVersionDelete";
import { useDeploymentUpdate } from "libs/data/customized/deployment/useDeploymentUpdate";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  useDeploymentsGet,
  useDeploymentVersionsGet,
} from "libs/data/endpoints/deployments/deployments";
import { useTimeSeriesDataList } from "libs/data/endpoints/metrics/metrics";
import { useGoogleAnalytics, useLogsUrl, useRequest } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { extractValueFromMetrics } from "libs/utilities/metrics-helper";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features/organizations";
import { useGetPermissions } from "store/features/permissions";

import type { TabType } from "components/atoms";
import {
  ActionDialog,
  ButtonGroup,
  DeleteDialog,
  HighlightedText,
  PageTabs,
  PrimaryButton,
  TextButton,
} from "components/atoms";
import { RequestDialog } from "components/organisms";

import type { DeploymentVersionDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/:versionName/types";
import type { ReactNode } from "react";

interface DeploymentVersionDetailsProps {
  children?: ReactNode;
}

export const DeploymentVersionDetails = ({
  children,
}: DeploymentVersionDetailsProps) => {
  useGoogleAnalytics();

  const history = useHistory();
  const { organizationName, projectName, deploymentName, versionName } =
    useParams<DeploymentVersionDetailsRouteParams>();
  const { data: deployment } = useDeploymentsGet(projectName, deploymentName);
  const organization = useGetCurrentOrganization();

  const { data: version } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );

  const deleteVersion = useDeploymentVersionDelete(projectName, deploymentName);
  const updateDeployment = useDeploymentUpdate(projectName, deploymentName);

  const { data: instances, mutate } = useTimeSeriesDataList(projectName, {
    // Our best way to estimate id there is an active instance is to check the 5 minutes before "now"
    start_date: moment().subtract(5, "minutes").format(),
    end_date: moment().format(),
    labels: `deployment_version_id: ${version?.id || ""}`,
    metric: "deployments.instances",
  });

  const [projectPermissions] = useGetPermissions();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_name: deploymentName,
      deployment_version: versionName,
    },
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);

  const { openCreateDialog, ...requestProps } = useRequest({
    type: "deployment",
    instance: deployment,
    version: versionName,
    isRetentionModeNone: version?.request_retention_mode === "none",
  });

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deploymentName),
    [deploymentName, organizationName, projectName]
  );

  const onDelete = async () => {
    const result = await deleteVersion(versionName);

    if (result) {
      history.replace(baseUrl.index());
    }
  };
  const tabs = useMemo(
    () =>
      [
        {
          link: baseUrl.versions[":versionName"](versionName).general.index(),
          label: "General",
        },
        deployment?.supports_request_format && {
          link: baseUrl.versions[":versionName"](versionName).requests.index(),
          label: "Requests",
          disabled:
            !currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_list"]],
        },
        {
          link: baseUrl.versions[":versionName"](
            versionName
          ).activeInstances.index(),
          label: "Active instances",
        },
        {
          link: baseUrl.versions[":versionName"](versionName).metrics.index(),
          label: "Metrics",
          disabled: !projectPermissions[PROJECT_PERMISSIONS["metrics_get"]],
        },
        {
          link: baseUrl.versions[":versionName"](
            versionName
          ).environmentVariables.index(),
          label: "Environment variables",
          disabled:
            !currentPermissions[
              DEPLOYMENT_PERMISSIONS["version_env_vars_list"]
            ],
        },
        deployment?.supports_request_format && {
          link: baseUrl.versions[":versionName"](versionName).revisions.index(),
          label: "Revisions",
        },
      ].filter(Boolean),
    [
      baseUrl.versions,
      currentPermissions,
      projectPermissions,
      versionName,
      deployment,
    ]
  );

  useEffect(() => {
    extractValueFromMetrics(instances?.data_points ?? []).length === 0
      ? setDisplayWarning(true)
      : setDisplayWarning(false);
  }, [instances?.data_points]);

  const handleDefaultVersionChange = () => {
    updateDeployment({
      [FIELD_DEFAULT_VERSION]: versionName,
    });
  };

  return (
    <>
      <RequestDialog
        organizationName={organizationName}
        projectName={projectName}
        warningText={
          displayWarning
            ? explanations.directRequests.activeInstanceWarningMessage
            : undefined
        }
        {...requestProps}
      />

      <DetailsContainer
        title={versionName}
        pageTitle={
          <span>
            {versionName}
            {version?.default && <HighlightedText>default</HighlightedText>}
          </span>
        }
        actions={
          <ButtonGroup>
            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_update"]] ||
                organization?.status !== "active"
              }
              link={baseUrl.versions[":versionName"](versionName).edit.index()}
              startIcon={<Edit />}
            >
              Edit
            </TextButton>
            <TextButton
              color="secondary"
              disabled={!projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
              link={logsUrl}
              startIcon={<LogsIcon />}
            >
              Logs
            </TextButton>

            <TextButton
              color="secondary"
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_create"]] ||
                organization?.status !== "active"
              }
              link={baseUrl.versions[":versionName"](
                versionName
              ).duplicate.index()}
              startIcon={<Duplicate />}
            >
              Duplicate
            </TextButton>
            {deployment?.default_version !== versionName && (
              <TextButton
                color="secondary"
                onClick={() => setDefaultDialogOpen(true)}
                startIcon={<MakeDefault />}
              >
                Make default
              </TextButton>
            )}
            <TextButton
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_delete"]]
              }
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<Trash />}
            >
              Delete
            </TextButton>
            {deployment?.supports_request_format && (
              <PrimaryButton
                startIcon={<PlayArrow />}
                disabled={
                  !version?.status?.startsWith("available") ||
                  !currentPermissions[
                    DEPLOYMENT_PERMISSIONS["version_request_create"]
                  ]
                }
                onClick={() => {
                  openCreateDialog();
                  mutate();
                }}
                style={{ marginLeft: "auto" }}
              >
                Create request
              </PrimaryButton>
            )}
          </ButtonGroup>
        }
      >
        <PageTabs tabs={tabs as TabType[]}>{children}</PageTabs>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={onDelete}
        >
          Are you sure you want to delete deployment version {`"`}
          <b>{versionName}</b>
          {`"`}?
        </DeleteDialog>
        <ActionDialog
          actionButtonText="Make default"
          onClose={() => setDefaultDialogOpen(false)}
          onAction={() => {
            // @ts-ignore
            handleDefaultVersionChange(versionName);
            setDefaultDialogOpen(false);
          }}
          open={defaultDialogOpen}
        >
          {explanations.defaultVersionDialog.message(versionName)}
        </ActionDialog>
      </DetailsContainer>
    </>
  );
};
