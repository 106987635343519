import { explanations } from "libs/utilities/explanations";

import { Dialog, DialogWarningHeader, PrimaryButton } from "components/atoms";

type NoPackageWarningProps = {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
};

export const NoPackageWarning = ({
  open,
  onClose,
  onAction,
}: NoPackageWarningProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      Header={<DialogWarningHeader title="No deployment package" />}
      Actions={<PrimaryButton onClick={onAction}>Submit anyway</PrimaryButton>}
    >
      {explanations.deployments.versions.noPackage}
    </Dialog>
  );
};
