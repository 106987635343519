import Trash from "@mui/icons-material/DeleteRounded";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PROJECT_PERMISSIONS } from "libs/constants/permissions";
import { useLogsUrl } from "libs/hooks";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import { Divider, TextButton } from "components/atoms";

import { IncomingEdges } from "../IncomingEdges";
import { SidebarRight } from "../SidebarRight";

import type { PipelineVersionObjectList } from "libs/data/models";
import type { Key } from "react";

type DeploymentPipelineInfoSidebarProps = {
  id: string;
  organizationName: string;
  projectName: string;
  pipelineName: string;
  pipelineVersionName: string;
  pipelineObject: PipelineVersionObjectList;
  isOpen: boolean;
  onClose: () => void;
  onClickDelete: () => void;
};

export const NodeDeploymentPipelineSidebar = ({
  id,
  pipelineObject,
  organizationName,
  projectName,
  pipelineName,
  pipelineVersionName,
  isOpen,
  onClose,
  onClickDelete,
}: DeploymentPipelineInfoSidebarProps) => {
  const history = useHistory();
  const logsUrl = useLogsUrl({
    redirectUrl: routes.organizations[":organizationName"](organizationName)
      .projects[":projectName"](projectName)
      .index(),
    queryParameters: {
      pipeline_name: pipelineName,
      pipeline_version: pipelineVersionName,
      pipeline_object_name: pipelineObject?.name,
    },
  });

  const [projectPermissions] = useGetPermissions();

  const actions = [
    {
      label: "Logs",
      icon: <LogsIcon />,
      link: logsUrl,
      disabled: !projectPermissions[PROJECT_PERMISSIONS["logs_get"]],
      key: "logs",
    },
    {
      label: "Delete",
      icon: <Trash />,
      onClick: () => onClickDelete(),
      color: "primary",
      key: "delete",
    },
  ];

  if (!isOpen) {
    return null;
  }

  return (
    <SidebarRight
      title="Object information"
      description={`Please find details of your ${pipelineObject.reference_type} below.`}
      onClose={onClose}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin={`${spacing[12]} 0`}
      >
        <Typography variant="h5">{pipelineObject?.reference_name}</Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          wrap="nowrap"
          style={{ width: "fit-content" }}
        >
          {actions
            .filter((action) =>
              pipelineObject.reference_type === "pipeline"
                ? action.key !== "logs"
                : action
            )
            .map(
              ({
                color = "secondary",
                label,
                icon,
                link,
                onClick,
                disabled,
                key,
              }) => (
                <Grid item key={key}>
                  <TextButton
                    size="small"
                    color={color as any}
                    startIcon={icon}
                    style={{ textTransform: "none" }}
                    onClick={link ? () => history.push(link) : onClick}
                    disabled={disabled}
                    target="_blank"
                  >
                    {label}
                  </TextButton>
                </Grid>
              )
            )}
        </Grid>
      </Box>
      <Divider mb={5} />

      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${spacing[8]} 0`}
      >
        <Typography variant="body1" color="textPrimary">
          Object name
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {pipelineObject?.name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${spacing[8]} 0`}
      >
        <Typography variant="body1" color="textPrimary">
          Reference name
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {pipelineObject?.reference_name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${spacing[8]} 0`}
      >
        <Typography variant="body1" color="textPrimary">
          Version
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {pipelineObject?.version ?? "default"}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${spacing[8]} 0`}
      >
        <Typography variant="body1" color="textPrimary">
          Input type
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {pipelineObject?.input_type}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${spacing[8]} 0`}
      >
        <Typography variant="body1" color="textPrimary">
          Output type
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {pipelineObject?.output_type}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${spacing[8]} 0`}
      >
        <Typography variant="body1" color="textPrimary">
          On error policy
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {pipelineObject?.configuration?.on_error ?? "raise"}
        </Typography>
      </Box>

      {pipelineObject?.input_type === "structured" && (
        <Box display="flex" flexDirection="column" margin={`${spacing[8]} 0`}>
          <Box my={2}>
            <Typography variant="h5">Input fields</Typography>
          </Box>
          <Card variant="outlined" style={{ height: "100%", padding: "16px" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" color="textPrimary">
                Name
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Data type
              </Typography>
            </Box>
            <Divider my={1} />
            {pipelineObject?.input_fields?.map(
              (
                { name, data_type }: { name: string; data_type: string },
                key: Key | null | undefined
              ) => (
                <Box
                  key={key}
                  display="flex"
                  justifyContent="space-between"
                  margin={`${spacing[8]} 0`}
                >
                  <Typography variant="body1" color="textPrimary">
                    {name}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {data_type}
                  </Typography>
                </Box>
              )
            )}
          </Card>
        </Box>
      )}
      {pipelineObject?.output_type === "structured" && (
        <Box display="flex" flexDirection="column" margin={`${spacing[8]} 0`}>
          <Box my={2}>
            <Typography variant="h5">Output fields</Typography>
          </Box>
          <Card variant="outlined" style={{ height: "100%", padding: "16px" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" color="textPrimary">
                Name
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Data type
              </Typography>
            </Box>
            <Divider my={1} />
            {pipelineObject?.output_fields?.map(
              (
                { name, data_type }: { name: string; data_type: string },
                key: Key | null | undefined
              ) => (
                <Box
                  key={key}
                  display="flex"
                  justifyContent="space-between"
                  margin={`${spacing[8]} 0`}
                >
                  <Typography variant="body1" color="textPrimary">
                    {name}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {data_type}
                  </Typography>
                </Box>
              )
            )}
          </Card>
        </Box>
      )}
      <Box display="flex" flexDirection="column" margin={`${spacing[8]} 0`}>
        <Box my={2}>
          <Typography variant="h5">Incoming connections</Typography>
        </Box>
        <Card variant="outlined" style={{ height: "100%", padding: "16px" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" color="textPrimary">
              Destination name
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Sources
            </Typography>
          </Box>
          <Divider my={1} />

          <IncomingEdges
            objectId={id}
            pipelineObjectName={pipelineObject.name}
          />
        </Card>
      </Box>
    </SidebarRight>
  );
};
