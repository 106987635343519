import { useEffect, useMemo, useState } from "react";

import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";

import { formatCodeEnvironment } from "./CodeEnvironmentSelect";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";

interface ControlledEnvironmentSelectProps {
  defaultValue: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
  gpuEnabled: boolean;
  projectName: string;
  name: string;
}

export const ControlledEnvironmentSelect = ({
  name,
  defaultValue,
  setFieldValue,
  disabled,
  projectName,
  gpuEnabled,
}: ControlledEnvironmentSelectProps) => {
  const { data: availableEnvironments } = useEnvironmentsList(projectName);
  const [value, setValue] = useState<AutocompleteSelectOption | null>(null);

  // List of available environments
  const environmentOptions = useMemo(() => {
    const filteredEnvironments =
      (gpuEnabled
        ? availableEnvironments
        : availableEnvironments?.filter((env) => !env.gpu_required)) ?? [];

    return filteredEnvironments
      .filter(
        (env) =>
          env.status !== "unavailable" &&
          !env.deprecated &&
          !env.hidden &&
          env.system
      )
      .map(formatCodeEnvironment)
      .sort((a, b) => {
        return b.label.localeCompare(a.label, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
  }, [availableEnvironments, gpuEnabled]);

  // Set the first environment as the default value
  useEffect(() => {
    if (environmentOptions.length > 0) {
      if (!defaultValue) {
        setFieldValue(name, environmentOptions[0].value);
      } else {
        const option = environmentOptions.find(
          (option) => option.value === defaultValue
        );
        if (option) {
          setValue(option);
        }
      }
    }
  }, [defaultValue, environmentOptions, setFieldValue, name]);

  const handleChange = (option: AutocompleteSelectOption | null) => {
    setFieldValue(name, option?.value);
    const optionObject = environmentOptions.find(
      (option) => option.value === option?.value
    );
    setValue(optionObject ?? environmentOptions[0]);
  };

  return (
    <>
      {value && (
        <AutoCompleteSelect
          label="Environment"
          value={value}
          options={environmentOptions}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </>
  );
};
