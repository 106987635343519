import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { ChevronDown } from "react-feather";
import { useFormContext } from "react-hook-form";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { KeyboardEventHandler, SyntheticEvent } from "react";
import type {
  AutocompleteSelectOption,
  BaseAutoCompleteSelectProps,
} from "../AutoCompleteSelect/types";

export interface AutoCompleteMultipleSelectProps
  extends BaseAutoCompleteSelectProps {
  onChange?: (selectedValue: AutocompleteSelectOption[] | null) => void;
  value?: AutocompleteSelectOption[];
  name: string;
  rules?: any;
  label?: string;
  className?: string;
  defaultValue?: string | any;
  tooltip?: string;
  withError?: boolean;
  component?: any;
  isMulti?: boolean;
  disabled?: boolean;
  handleOnKeyDown?: KeyboardEventHandler;
  isSearchable?: boolean;
  loading?: boolean;
  maxMenuHeight?: number;
  ListboxProps?: object;
}

export const AutoCompleteSelectMultipleHookForm = ({
  name,
  options,
  onChange,
  label,
  renderOption,
  error,
  rules,
  defaultValue,
  showErrors = true,
  ...props
}: AutoCompleteMultipleSelectProps) => {
  const { register, unregister, setValue, watch } = useFormContext();
  const theme = useTheme() as AppThemeProps;

  const value = watch(name);

  const handleOnChange = (
    _e: SyntheticEvent<Element>,
    selectedValue: AutocompleteSelectOption[] | null
  ) => {
    setValue(name, selectedValue, { shouldDirty: true });
    if (onChange) {
      onChange && onChange(selectedValue);
    }
  };

  useEffect(() => {
    register({ name }, rules);

    return () => unregister(name);
  }, [register, unregister, name, rules]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  return (
    <>
      <Autocomplete
        id={name}
        options={options}
        multiple
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
              {
                name: "preventOverflow",
                enabled: false,
              },
            ],
          },
        }}
        renderInput={(params) => (
          <TextField
            error={showErrors && error !== undefined}
            {...params}
            label={label}
          />
        )}
        size="small"
        getOptionDisabled={(option) =>
          !!value?.find(
            (val: { value: string | number | null }) =>
              val.value === option.value
          ) ?? false
        }
        popupIcon={<ChevronDown color={theme.palette.border.primary} />}
        onChange={handleOnChange}
        value={value || defaultValue}
        renderOption={renderOption}
        disableClearable
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingLeft: `${options[0]?.icon ? "18px" : "8px"}`,
          },
          "& .MuiAutocomplete-endAdornment": {
            borderLeft: `1px solid ${theme.palette.border.primary} `,
            height: "20px",
            top: "9px",
            display: "flex",
            width: "24px",
            "& svg": {
              height: "1.2rem",
            },
            "& .MuiAutocomplete-popupIndicator": {
              ":hover": {
                backgroundColor: theme.palette.neutrals[0],
              },
            },
          },
          marginBottom: "10px",
          width: "100%",
        }}
        {...props}
      />
      {error && showErrors && (
        // negative mt to compensate for margin bottoms for dropdowns from FormSection
        <Typography variant="caption" color="error" mt={-1.5} mb={1.5}>
          {error}
        </Typography>
      )}
    </>
  );
};
