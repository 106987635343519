import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import PersonAdd from "@mui/icons-material/PersonAddRounded";
import RetryIcon from "@mui/icons-material/RefreshRounded";
import SecurityIcon from "@mui/icons-material/Security";
import { Box, Chip, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import {
  useOrganizationUpdate,
  useOrganizationUserCreate,
  useOrganizationUserDelete,
  useOrganizationUserUpdate,
} from "libs/data/customized/organization";
import { useOrganizationUser } from "libs/data/customized/user";
import { useOrganizationUsersList } from "libs/data/endpoints/organizations/organizations";
import { OrganizationUserDetailStatus } from "libs/data/models";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { secretValues } from "libs/utilities/labels-mapping";
import {
  setOrganization,
  useGetCurrentOrganization,
} from "store/features/organizations";

import {
  DeleteDialog,
  Dialog,
  DialogWarningHeader,
  HighlightedText,
  Icon,
  IconButton,
  OverflowTooltip,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";
import { BaseTable, PageContainer } from "components/molecules";

const OrganizationUsersOverview = () => {
  useGoogleAnalytics();
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [editRowId, setEditRowId] = useState(undefined);
  const [editedRow, setEditedRow] = useState(undefined);
  const [isEnforceDialogOpen, setIsEnforceDialogOpen] = useState(false);

  const { organizationName } = useParams();
  const user = useOrganizationUser(organizationName);
  const { data: users, error } = useOrganizationUsersList(organizationName);
  const usersLoading = !users && !error;

  const currentOrganization = useGetCurrentOrganization();

  const createOrganizationUser = useOrganizationUserCreate(organizationName);
  const updateOrganizationUser = useOrganizationUserUpdate(organizationName);
  const deleteOrganizationUser = useOrganizationUserDelete(organizationName);
  const updateOrganization = useOrganizationUpdate(organizationName);

  const match = useRouteMatch();
  const history = useHistory();

  const handleResendInvitation = useCallback(
    async (user) => {
      await createOrganizationUser(
        {
          email: user.email,
          admin: user.admin,
        },
        "Invitation resent"
      );
    },
    [createOrganizationUser]
  );

  const columns = useMemo(
    () => [
      {
        title: "Email",
        field: "email",
        defaultSort: "asc",
        nowrap: true,
        render: ({ email }) => {
          return email === user?.email ? (
            <div display="flex" style={{ flexDirection: "column" }}>
              <HighlightedText>You</HighlightedText>
              <OverflowTooltip
                title={<Typography variant="h5">{email}</Typography>}
              >
                {email}
              </OverflowTooltip>
            </div>
          ) : (
            email
          );
        },
      },
      {
        title: "First name",
        field: "name",
        nowrap: true,
      },
      {
        title: "Last name",
        field: "surname",
        nowrap: true,
      },
      {
        title: "Admin",
        field: "admin",
        editable: "always",
        lookup: secretValues,
        width: "15%",
        initialEditValue: false,
        options: Object.entries(secretValues).map(([value, label]) => ({
          label,
          value,
        })),
        render: (rowData) => (
          <>
            {rowData.admin && (
              <Chip variant="outlined" size="small" label="Admin" />
            )}
          </>
        ),
      },
      {
        title: "Status",
        field: "status",
        hidden: users?.findIndex((user) => user.status === "invited") === -1,
        render: (rowData) => (
          <>
            {rowData?.status === "invited" && (
              <Chip variant="outlined" size="small" label="Invited" />
            )}
          </>
        ),
      },
      {
        disableClick: true,
        width: "15%",
        align: "right",

        render: (rowData) => (
          <Box display="flex" justifyContent="flex-end">
            {editRowId === rowData?.id ? (
              <>
                <IconButton
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={async () => {
                    await updateOrganizationUser(rowData.id, editedRow);
                    setEditRowId(undefined);
                    setEditedRow(undefined);
                  }}
                  icon={CheckIcon}
                />
                <IconButton
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={() => {
                    setEditRowId(undefined);
                    setEditedRow(undefined);
                  }}
                  icon={CloseIcon}
                />
              </>
            ) : (
              <>
                {rowData?.status === OrganizationUserDetailStatus.invited && (
                  <IconButton
                    icon={RetryIcon}
                    tooltip="Resend invitation"
                    htmlColor="secondary"
                    hoverColor="secondary"
                    onClick={() => handleResendInvitation(rowData)}
                  />
                )}
                <IconButton
                  icon={Edit}
                  htmlColor="secondary"
                  hoverColor="secondary"
                  tooltip="Edit"
                  onClick={() => {
                    setEditedRow(rowData);
                    setEditRowId(rowData.id);
                  }}
                />
                <IconButton
                  icon={Delete}
                  tooltip="Remove user"
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                    setRowToDelete(rowData);
                  }}
                />
              </>
            )}
          </Box>
        ),
      },
    ],
    [
      editRowId,
      editedRow,
      handleResendInvitation,
      updateOrganizationUser,
      user?.email,
      users,
    ]
  );

  const handleEnforce2FA = async () => {
    const response = await updateOrganization({
      two_factor_authentication_forced: true,
    });
    if (response) {
      setIsEnforceDialogOpen(false);
      setOrganization(response);
      location.replace(`/organizations/${organizationName}/team`);
    }
  };

  return (
    <PageContainer>
      <PageHeader title={organizationName} />
      <BreadcrumbsItem to={match.url}>Team</BreadcrumbsItem>
      {currentOrganization?.two_factor_authentication_forced && (
        <Box
          display="flex"
          alignItems="center"
          gap={spacing[2]}
          marginBottom={2}
        >
          {" "}
          <Icon component={SecurityIcon} />{" "}
          {explanations.security.twoFactorAuthEnforced}{" "}
        </Box>
      )}
      <BaseTable
        columns={columns}
        data={users}
        isLoading={usersLoading}
        editedRow={editedRow}
        setEditedRow={setEditedRow}
        editRowId={editRowId}
        action={
          <Box display="flex" gap={2}>
            <PrimaryButton
              startIcon={<PersonAdd />}
              disabled={currentOrganization.status !== "active"}
              onClick={() =>
                history.push(`/organizations/${organizationName}/team/create`)
              }
            >
              Add user
            </PrimaryButton>
            {user.isAdmin &&
              !currentOrganization.two_factor_authentication_forced && (
                <SecondaryButton
                  onClick={() => setIsEnforceDialogOpen(true)}
                  startIcon={<SecurityIcon />}
                >
                  Enforce 2FA for all
                </SecondaryButton>
              )}
          </Box>
        }
      />
      <DeleteDialog
        onDelete={async () => {
          await deleteOrganizationUser(rowToDelete.id);

          setIsDeleteDialogOpen(false);
          setRowToDelete(null);
        }}
        onClose={() => {
          setIsDeleteDialogOpen(false);
          setRowToDelete(null);
        }}
        open={isDeleteDialogOpen}
      >
        Are you sure you want to delete user <b>{rowToDelete?.email}</b>?
      </DeleteDialog>
      <Dialog
        open={isEnforceDialogOpen}
        onClose={() => setIsEnforceDialogOpen(false)}
        Actions={
          <PrimaryButton
            startIcon={<SecurityIcon />}
            onClick={handleEnforce2FA}
          >
            Enforce 2FA for all
          </PrimaryButton>
        }
        Header={
          <DialogWarningHeader title="Enforce Two Factor Authentication" />
        }
      >
        {explanations.security.twoFactorAuthDialog}
      </Dialog>
    </PageContainer>
  );
};

export default OrganizationUsersOverview;
