import { FormHelperText, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { ARRAY_FILE_TYPE, FILE_TYPE } from "libs/constants/constants";
import {
  FIELD_ASSIGNEE,
  FIELD_INPUT_FIELDS,
  FIELD_OUTPUT_FIELDS,
  FIELD_ROLE,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import { fileAccessRoleOptions } from "libs/utilities/labels-mapping";

import { AddButton, ButtonGroup, DeleteChip } from "components/atoms";

import { DEFAULT_BUCKET_NAME } from "../../../storage/constants";

import type { BucketList } from "libs/data/models";
import type { InputOutputDataField } from "libs/utilities/input-parser";

export interface DeploymentBucketRole {
  assignee: string;
  role: string;
}

interface BucketPermissionsFormSectionProps {
  buckets: BucketList[];
  onAdd: (role: DeploymentBucketRole) => void;
  onDelete: (role: DeploymentBucketRole) => void;
  permissions: DeploymentBucketRole[];
}

export const BucketPermissionsFormSection = ({
  buckets,
  onAdd,
  onDelete,
  permissions,
}: BucketPermissionsFormSectionProps) => {
  const formMethods = useFormContext();
  const { getValues, trigger, setError, errors, setValue, register } =
    formMethods;
  const values = getValues();

  const bucketOptions = buckets?.filter(
    (bucket) => bucket.name !== DEFAULT_BUCKET_NAME
  );

  const handleAddRole = async () => {
    const result = await trigger([FIELD_ASSIGNEE, FIELD_ROLE]);

    const values = getValues();
    const bucketName = values[FIELD_ASSIGNEE]?.value;
    const role = values[FIELD_ROLE]?.value;

    if (result && values[FIELD_ASSIGNEE] && values[FIELD_ROLE]) {
      const uniqueBucket = permissions.every(
        (field) => field.assignee !== bucketName
      );

      if (uniqueBucket) {
        onAdd({
          assignee: bucketName,
          role,
        });
        setValue(FIELD_ASSIGNEE, null);
        setValue(FIELD_ROLE, fileAccessRoleOptions[0]);
      } else {
        setError(FIELD_ASSIGNEE, {
          type: "manual",
          //TODO: Ask warning copy
          message: "You can only assign one role per bucket.",
        });
      }
    } else {
      if (!bucketOptions?.length) {
        setError(FIELD_ASSIGNEE, {
          type: "manual",
          //TODO: Ask warning copy
          message: "You do not have any bucket. Please create one first.",
        });
      }
      if (!values[FIELD_ASSIGNEE]) {
        setError(FIELD_ASSIGNEE, {
          type: "manual",
          //TODO: Ask warning copy
          message: "Please select a bucket.",
        });
      }
    }
  };

  // show file tooltip if there is at least one file input/output
  const showFileTooltip = useMemo(() => {
    const inputOutputValues = [
      ...Object.values(values[FIELD_INPUT_FIELDS] || {}),
      ...Object.values(values[FIELD_OUTPUT_FIELDS] || {}),
    ] as InputOutputDataField[];

    return inputOutputValues.some(({ data_type }) =>
      [FILE_TYPE, ARRAY_FILE_TYPE].includes(data_type?.value)
    );
  }, [values]);

  const options = useMemo(
    () =>
      bucketOptions?.map(({ name }) => ({
        value: name,
        label: name,
      })),
    [bucketOptions]
  );

  return (
    <>
      {showFileTooltip && explanations.deployments.filePermissionTooltip}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <AutoCompleteSelectHookForm
            name={FIELD_ASSIGNEE}
            label="Buckets"
            options={options}
            isSearchable
            disabled={!bucketOptions?.length}
            placeholder="Select..."
          />
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteSelectHookForm
            name={FIELD_ROLE}
            label="Access roles"
            options={fileAccessRoleOptions}
            defaultValue={fileAccessRoleOptions[0]}
          />
        </Grid>
        <Grid item>
          <ButtonGroup>
            <AddButton onClick={handleAddRole}>Add role</AddButton>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">Permission added:</Typography>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          {permissions?.map((label, index) => {
            const title = `${label.role} | bucket: ${label.assignee}`;

            return (
              <Grid item key={title}>
                <DeleteChip label={title} onDelete={() => onDelete(label)} />
                <input
                  type="hidden"
                  name={`permissions.[${index}].assignee`}
                  defaultValue={label.assignee}
                  ref={register()}
                />
                <input
                  type="hidden"
                  name={`permissions.[${index}].role`}
                  defaultValue={label.role}
                  ref={register()}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <FormHelperText
            id={FIELD_ASSIGNEE}
            error={!!errors[FIELD_ASSIGNEE]}
            hidden={!errors[FIELD_ASSIGNEE]}
          >
            {errors[FIELD_ASSIGNEE]?.message}
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};
