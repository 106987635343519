import {
  ARRAY_DICT_TYPE,
  ARRAY_DOUBLE_TYPE,
  ARRAY_FILE_TYPE,
  ARRAY_INT_TYPE,
  ARRAY_STRING_TYPE,
  BOOLEAN_TYPE,
  DICT_TYPE,
  DOUBLE_TYPE,
  INT_TYPE,
  STRING_TYPE,
  STRUCTURED_TYPE,
} from "libs/constants/constants";

import type {
  DeploymentListOutputType,
  InputOutputFieldBase,
  InputOutputFieldDetail,
  InputOutputFieldListDataType,
} from "libs/data/models";

const ARRAY_TYPES = [
  ARRAY_STRING_TYPE,
  ARRAY_INT_TYPE,
  ARRAY_DOUBLE_TYPE,
  ARRAY_FILE_TYPE,
];

export const FIELD_EDITOR = "editorField";
export const VISUAL_EDITOR = "visualEditor";
export const JSON_EDITOR = "jsonEditor";

export type InputOutputDataField = {
  name: string;
  data_type: {
    label: string;
    value: InputOutputFieldListDataType;
  };
};

//todo: refactor this function
export const formatInput = (
  fields: Array<InputOutputFieldDetail>,
  data: Record<string, any>
) => {
  const newData: Record<string, any> = {};
  fields.forEach(({ name, data_type }) => {
    if (name) {
      if (!data[name]) {
        switch (data_type) {
          case BOOLEAN_TYPE:
            newData[name] = false;

            return;

          case INT_TYPE:
          case DOUBLE_TYPE:
            newData[name] = null;

            return;
          // array_dict type used internally for string dropdowns
          // @ts-ignore
          case ARRAY_DICT_TYPE:
          case ARRAY_DOUBLE_TYPE:
          case ARRAY_INT_TYPE:
          case ARRAY_STRING_TYPE:
            newData[name] = [];

            return;
          default:
            newData[name] = "";

            return;
        }
      }

      if (data_type) {
        // array_dict type used internally for string dropdowns
        // @ts-ignore
        if (data_type === ARRAY_DICT_TYPE) {
          let options = [];

          try {
            options = Array.isArray(data[name])
              ? data[name]
              : JSON.parse(data[name]);
          } catch {
            console.error("Failed to parse string dropdown options");
          }
          if (options.length > 0) {
            newData[name] = options.filter(
              // options are objects with label and value fields
              // @ts-ignore
              ({ value }) => !!value
            );
          } else {
            newData[name] = data[name];
          }
        } else if (ARRAY_TYPES.includes(data_type)) {
          newData[name] = toArray(data[name], data_type);
        } else {
          newData[name] = toType(data[name], data_type);
        }
      }
    }
  });

  return newData;
};

export const formatJsonEditorValue = (data: string) => {
  const parsedData = JSON.parse(data);

  return Object.entries(parsedData).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: typeof value === "object" ? JSON.stringify(value) : value,
    }),
    {}
  );
};

const toType = (s: string, data_type: string) => {
  const intValue = parseInt(s);
  const doubleValue = parseFloat(s);

  switch (data_type) {
    case INT_TYPE:
      return isNaN(intValue) ? null : intValue;
    case DOUBLE_TYPE:
      return isNaN(doubleValue) ? null : doubleValue;
    case DICT_TYPE:
      return JSON.parse(s);
    case BOOLEAN_TYPE:
      if (typeof s === "boolean") {
        return s;
      } else {
        return ["TRUE", "True", "true", "1", "t", "y", "yes"].includes(
          s.toLowerCase()
        );
      }
    case STRING_TYPE:
      if (s.startsWith('"') && s.endsWith('"')) {
        // Prevent "test" to become '"test"'
        return s.replace('"', "").replace(/"(?![\s\S]*")/, "");
      } else if (s.startsWith("'") && s.endsWith("'")) {
        // Prevent 'test' to become "'test'"
        return s.replace("'", "").replace(/'(?![\s\S]*')/, "");
      } else {
        return s.toString();
      }
    default:
      return s;
  }
};

const toArray = (s: string, object_type: string) => {
  s = toArrayString(s.trim());
  if (object_type === ARRAY_STRING_TYPE) {
    try {
      // allow ["a's", "b's"] and "a's", "b's"
      // allow [1, 2, 3] and 1, 2, 3
      return JSON.parse(s).map((x: number) => x.toString());
    } catch (e) {
      try {
        // allow ['a', 'b'] and 'a', 'b'
        return JSON.parse(s.replace(/'/g, '"')).map((x: number) =>
          x.toString()
        );
      } catch (e) {
        // allow [a, b] and a, b
        return stringToArray(s);
      }
    }
  } else {
    return castJsonString(s);
  }
};

const toArrayString = (s: string) => {
  if (s.startsWith("[") && s.endsWith("]")) {
    return s;
  } else {
    return `[${s}]`;
  }
};

const castJsonString = (s: string) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return stringToArray(s);
  }
};

const stringToArray = (s: string) => {
  // remove first [ and last ]
  s = s.replace("[", "").replace(/](?![\s\S]*])/, "");

  // @ts-ignore split by ,
  s = s.split(",");

  // trim strings
  return (s as unknown as string[]).map((x: string) => x.trim());
};

export const parseJsonNoErrors = (s: string) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return null;
  }
};

export const formatRequestData = (
  inputFields: Array<InputOutputFieldDetail>,
  inputType: DeploymentListOutputType,
  request: Record<string, string>
) => {
  if (inputFields && inputType === STRUCTURED_TYPE) {
    return request[FIELD_EDITOR] === JSON_EDITOR
      ? parseJsonNoErrors(request["json-area"])
      : formatInput(inputFields, request);
  } else {
    return request["plain-area"];
  }
};

export const formatInputOutputFields = (
  fields: Array<InputOutputFieldDetail> | Record<string, InputOutputFieldDetail>
): InputOutputFieldBase[] => {
  if (Array.isArray(fields)) {
    // can be InputOutputFieldDetail or InputOutputFieldBase
    // to support back-and-forth conversion
    // @ts-ignore
    return fields
      .map((field) => {
        return {
          name: field.name,
          data_type:
            (field.data_type as unknown as { value: string })?.value ??
            field.data_type,
        };
      })
      .filter(({ name }) => !!name);
  } else {
    // can be InputOutputFieldDetail or InputOutputFieldBase
    // to support back-and-forth conversion
    // @ts-ignore
    return Object.values(fields)
      .map((field) => {
        return {
          name: field.name,
          data_type:
            (field.data_type as unknown as { value: string })?.value ??
            field.data_type,
        };
      })
      .filter(({ name }) => !!name);
  }
};

export const formatOldInputOutputFields = (
  fields: Array<InputOutputFieldDetail>
) => {
  return fields.map((inputOutput: InputOutputFieldDetail) => {
    return {
      name: inputOutput.name,
      data_type: inputOutput.data_type,
    };
  });
};
