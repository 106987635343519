import { Redirect, Route, Switch } from "react-router-dom";

import { PipelineVersionEditModePage } from "pages/organizations/:organizationName/projects/:projectName/pipelines/:pipelineName/versions/:versionName/editMode";
import { routes } from "routes";

import { AuthenticationRoute, PrivateRoute } from "components/utilities";

import OrganizationsRoutesContainer from "./organizations";
import { ProfilePage } from "./profile";
import PasswordResetToken from "./reset-password/:token/PasswordResetToken";
import PasswordReset from "./reset-password/PasswordReset";
import SignIn2FA from "./sign-in/2fa/SignIn2FA";
import SignInOAuth from "./sign-in/:provider/SignInOAuth";
import SignInPassword from "./sign-in/password/SignInPassword";
import SignIn from "./sign-in/SignIn";
import SignOut from "./sign-out/SignOut";
import SignUpActivation from "./sign-up/activate/:token/SignUpActivation";
import SignUp from "./sign-up/SignUp";
import SignUpTerms from "./sign-up/terms/SignUpTerms";

export const IndexPage = () => {
  return (
    <Switch>
      <AuthenticationRoute
        exact
        path={routes.signin.index()}
        component={SignIn}
      />
      <AuthenticationRoute
        exact
        path={routes.signin.twoFA.index()}
        component={SignIn2FA}
      />
      <AuthenticationRoute
        exact
        path={routes.signin.password.index()}
        component={SignInPassword}
      />
      <AuthenticationRoute
        exact
        path={routes.signin[":provider"](":provider").index()}
        component={SignInOAuth}
      />
      <Route exact path={routes.signup.terms.index()} component={SignUpTerms} />
      <AuthenticationRoute
        path={routes.signup.activate[":token"](":token").index()}
        component={SignUpActivation}
      />
      <AuthenticationRoute
        strict
        path={routes.signup[":email"](":email").index()}
        component={SignUp}
      />
      <AuthenticationRoute path={routes.signup.index()} component={SignUp} />
      <AuthenticationRoute
        path={routes.resetPassword[":token"](":token").index()}
        component={PasswordResetToken}
      />
      <AuthenticationRoute
        exact
        path={routes.resetPassword.index()}
        component={PasswordReset}
      />
      {/** this path is here because it's the only page without the main layout */}
      <PrivateRoute
        exact
        path={routes.organizations[":organizationName"](":organizationName")
          .projects[":projectName"](":projectName")
          .pipelines[":pipelineName"](":pipelineName")
          .versions[":versionName"](":versionName")
          .editMode.index()}
        component={PipelineVersionEditModePage}
      />
      <PrivateRoute
        path={routes.organizations.index()}
        component={OrganizationsRoutesContainer}
      />
      <PrivateRoute exact path={routes.signout.index()} component={SignOut} />
      <PrivateRoute path={routes.profile.index()} component={ProfilePage} />
      <Redirect to={routes.organizations.index()} />
    </Switch>
  );
};
